import httpInstance from '../httpInstance';

const approvePromotionService = async (file) => {
  let res;
  await httpInstance.put(
    `/files/v1/files/cashin-promo/approve/${file}.csv`,
  ).then((response) => {
    res = response.data;
  }).catch((err) => {
    res = err.response;
  });

  return res;
};

export default approvePromotionService;
