/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Card = styled.div`
  display: flex;
  padding: 16px;
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  flex-direction: column;
`;

export const IconButtonStyled = styled(IconButton)`
  font-size: 20px;
  float: right;
`;
