export const defaultData = {
  acquiringId: '',
  activeTapOnPhone: true,
  commercialName: '',
  documentNumber: '',
  mcc: '',
  terminalId: '',
  checkedItbis: false,
};

export const chunkSize = 80;

export const FILTER_OPTIONS_TAP_TO_PHONE = [
  {
    id: 'ACQUIRING_ID',
    value: 'ID de adquirencia',
  },
  {
    id: 'DOCUMENT_NUMBER',
    value: 'RNC/Cédula',
  },
  {
    id: 'COMMERCE_NAME',
    value: 'Nombre del comercio',
  },
];

export const filters = {
  'RNC/Cédula': 'documentNumber',
  'Nombre del comercio': 'commercialName',
  'ID de adquirencia': 'acquiringId',
};
