const Config = {
  API_URL: 'https://uat.api.mipana.com/admin',
  VERSION_NUMBER: '1.189.0',
  GW_API_REQ: '1833494f309c6ac9a00be5a9f8d07673399e416b5691c9bbc4a0796ffdafa597',
  API_KEY_PAYMENT_LINK: 'aa2xPCTrsb7oiXiUbpy1x6VGY9kpDLvyamoojD3h',
  API_URL_PAYMENT_LINK: 'https://link-de-pagos.api.uat.mipana.com',
  AMPLIFY: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_FfyWSo2MY',
      userPoolWebClientId: '4fc2fdigfhlpb6qvajpjo3khku',
      oauth: {
        domain: 'cg-tp-back-office-uat.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://uat.backoffice.mipana.com/dashboard',
        redirectSignOut: 'https://uat.backoffice.mipana.com/login',
        responseType: 'token',
      },
    },
  },
  MAPS_API_KEY: 'AIzaSyAFmAdCVGycA8ZSGcnrUePcy9XoMl9qqNU',
};
export default Config;
