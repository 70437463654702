/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ActionPopUp } from '../../../Layouts';
import DataTable from './DataTable';
import { Toaster } from '../../../components';
import ListPromotions from './ListPromotions';
import { onUpdateOrderFeaturedPromotion } from '../utils/utils';
import { BoxButton } from './styles';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
  onDeletePromotion,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [canEdit, setCanEdit] = useState(false);
  const {
    handleSubmit, control, errors, reset,
  } = useForm({
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const handleSave = async (dataForm) => {
    const dataAlert = await onUpdateOrderFeaturedPromotion({ dataForm, data });
    setAlert({ ...dataAlert, status: true });
    if (dataAlert.type === 'success') onClose(true);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <ActionPopUp
      open={open}
      title={title}
      onClose={() => {
        setCanEdit(false);
        onClose();
      }}
      onSave={handleSubmit(handleSave)}
      enabledControls={canEdit}
    >
      {data !== null && !canEdit && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setCanEdit(true)}
        >
          Habilitar edición
        </Button>
      )}
      <ListPromotions
        canEdit={canEdit}
        setAlert={setAlert}
        currentPromotions={data}
        onClose={onClose}
      />
      <h2>Promociones destacadas</h2>
      {data.length === 0
        ? <h3>Aún no hay promociones destacadas</h3>
        : (
          <>
            <form noValidate autoComplete="off">
              <DataTable
                data={data}
                control={control}
                errors={errors}
                setAlert={setAlert}
                canEdit={canEdit}
                onDeletePromotion={onDeletePromotion}
                withActions
              />
            </form>
            <BoxButton withSpace>
              <Button variant="outlined" disabled={!canEdit} onClick={handleSubmit(handleSave)}>
                Editar orden
              </Button>
            </BoxButton>
          </>
        )}
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeletePromotion: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default ActionForm;
