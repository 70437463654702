import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import useStyle from './useStyle';
import PaymentLinksTable from './components/DataTable/PaymentLinksTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import ActionForm from './components/ActionForm/ActionForm';
import { FILTER_OPTIONS } from '../../components/SearchBox/constants';
import defaultData from './constants';
import { Loading, Toaster } from '../../components';
import getPaymentLinksByDateService from '../../services/paymentsLink/getPaymentLinksByDateService';

const PaymentsLinkScreen = () => {
  const classes = useStyle();
  const chunkSize = 80;
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [keyFilter, setKeyFilter] = useState(FILTER_OPTIONS.COMMERCE_ID);
  const [showAlert, setShowAlert] = useState(false);
  const [totalElementsData, setTotalElementsData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentChunk, setCurrentChunk] = useState(1);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    title: '',
    data: null,
  });

  const filters = {
    'RNC/Cédula': 'documentNumber',
    'Nombre del comercio': 'commercialName',
    'ID del comercio': 'merchantIdCardnet',
  };

  const getPaymentLinksData = async () => {
    try {
      const response = await getPaymentLinksByDateService({
        pageSize: chunkSize,
        pageNumber: currentChunk,
        searchKey: filters[keyFilter],
        searchValue: searchQuery,
      });
      setPaymentLinks((paymentLinksData) => [
        ...paymentLinksData, ...response?.data?.items,
      ]);
      setLoading(false);
      setTotalElementsData(response.data.total_elements);
    } catch (error) {
      setShowAlert(true);
    }
  };

  const handleSearchPress = () => {
    if (searchQuery) {
      setLoading(true);
      setPaymentLinks([]);
      getPaymentLinksData();
    }
  };
  const handleCbToggleSelected = (rowToggleSelected) => {
    const paymentLinksMapped = paymentLinks
      .map((paymentLink) => (paymentLink.document_number === rowToggleSelected.document_number
        ? rowToggleSelected
        : paymentLink));
    setPaymentLinks(paymentLinksMapped);
  };

  const handleNext = () => {
    if (paymentLinks.length === totalElementsData) return;
    if (
      paymentLinks.length < (currentPage + 2) * currentPageSize
    ) {
      setCurrentChunk((oldChunk) => oldChunk + 1);
    }
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handleNewPageSize = (newPageSize) => {
    setCurrentPageSize(Number(newPageSize));
    setCurrentPage(0);
  };

  useEffect(() => {
    getPaymentLinksData();
  }, [currentChunk, chunkSize]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {loading ? <Loading /> : null}
        <div>
          <SearchBox
            handleSearchPress={handleSearchPress}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setKeyFilter={setKeyFilter}
          />
        </div>
        <PaymentLinksTable
          paymentLinks={paymentLinks}
          enteredFilter={searchQuery}
          setPopUpAction={setPopUpAction}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
          totalElementsData={totalElementsData}
          onNext={handleNext}
          onSelectPageSize={handleNewPageSize}
          searchQuery={searchQuery}
          loading={loading}
          setPaymentLinks={setPaymentLinks}
          onRowRefresh={handleCbToggleSelected}
        />
        <ActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          title={popUpAction.title}
          defaultValues={popUpAction.data || defaultValues}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
            setPaymentLinks([]);
            getPaymentLinksData();
          }}
        />
      </div>
      <Toaster
        show={showAlert}
        type="error"
        text="Ha ocurrido un error, intente nuevamente. "
        onClose={() => setShowAlert(false)}
      />
    </Paper>
  );
};
export default PaymentsLinkScreen;
