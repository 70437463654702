import httpInstance from '../httpInstance';

const createTypePromotionService = async (body) => {
  let res;
  const endpoint = '/back-office/v1/promotions-type';
  await httpInstance.post(
    endpoint,
    body,
  ).then((data) => {
    res = data.data;
  }).catch(() => {
    res = null;
  });
  return res;
};

export default createTypePromotionService;
