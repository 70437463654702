const csvFileToArray = (string) => {
  let csvHeader;
  let csvRows;
  if (string.includes('\r\n')) {
    csvHeader = string.slice(0, string.indexOf('\r\n')).split(',');
    csvRows = string.slice(string.indexOf('\r\n') + 1).split('\r\n');
  } else {
    csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
  }

  const array = csvRows.map((i) => {
    const values = i.split(',');
    const obj = csvHeader.reduce((object, header, index) => {
      // eslint-disable-next-line no-param-reassign
      object[header] = values[index];
      return object;
    }, {});
    return obj;
  });
  return (array);
};

export default csvFileToArray;
