/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-computed-key */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable dot-notation */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  updateRepositionService,
  getRepositionService,
  getLabelReposition,
} from '../../services';
import { Toaster, CustomDialog } from '../../components';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { ButtonStyled } from './styles';

const RepositionScreen = () => {
  const [labelList, setLabelList] = useState([]);
  const [repositionList, setRepositionList] = useState([]);
  const [repositionListcopy, setRepositionListcopy] = useState([]);
  const [appRepositionList, setAppRepositionList] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const classes = useStyle();

  const getRepositionList = async () => {
    setLoading(true);
    const res = await getRepositionService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setRepositionList(res.data);
  };

  const handlechangeapp = (key) => (event) => {
    let extract = appRepositionList[0][key];
    if (extract) {
      extract = event.target.value;
    }
  };

  const handlechangeappBlur = (key) => (event) => {
    const update = [...repositionListcopy];
    update[0][key] = parseFloat((event.target.value).replace(/,/g, ''));
    update[0]['change'] = 1;
    setRepositionListcopy(update);
  };

  const getLabelsList = async () => {
    await getLabelReposition().then((res) => {
      if (res) {
        let tags = res.data.map((item) => {
          switch (item.toLowerCase()) {
            case 'día':
              return { label: item, key: 'day' };
            case 'semana':
              return { label: item, key: 'week' };
            case 'mes':
              return { label: item, key: 'month' };
            case 'año':
              return { label: item, key: 'year' };
            default:
              return {};
          }
        });
        setLabelList(tags);
      }
    });
  };

  useEffect(() => {
    getLabelsList();
  }, []);

  useEffect(() => {
    getRepositionList();
  }, [labelList]);

  useEffect(async () => {
    if (repositionList && repositionList.length > 0) {
      let listApp = [...repositionList];
      setAppRepositionList(listApp);
      setRepositionListcopy(repositionList);
    }
  }, [repositionList]);

  const handleedit = () => {
    setCanEdit((prev) => !prev);
  };

  const handleSave = async () => {
    const copyOriginal = [...repositionListcopy];
    if (copyOriginal[0].change !== 1) return;
    let itemUpdate = copyOriginal[0];
    const date = new Date();
    let month = date.getMonth() < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    updateRepositionService({
      id: itemUpdate.id,
      day: itemUpdate.day,
      week: itemUpdate.week,
      month: itemUpdate.month,
      year: itemUpdate.year,
      createdDate: itemUpdate.createdDate === null ? `${date.getFullYear()}-${month}-${day}` : itemUpdate.createdDate,
      updatedDate: `${date.getFullYear()}-${month}-${day}`,
    }).then(() => {
      setAlert({ status: true, type: 'success', message: 'Guardado correctamente' });
      getRepositionList();
    }).catch(() => {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    });
  };

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid container justifyContent="flex-end" style={{ marginRight: 10, marginTop: 10 }}>
                <ButtonStyled variant={canEdit ? 'outlined' : 'contained'} onClick={handleedit}>
                  Editar
                </ButtonStyled>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
                <Typography variant="h4" gutterBottom style={{ marginLeft: 10 }}>App</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Reposición</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Costo</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              {(appRepositionList.length > 0 && labelList.length > 0)
                && labelList.map((item, index) => (
                  <Grid container key={`${(index + 1)}_contitemapp`} style={{ marginTop: 10, marginLeft: 10 }}>
                    <Grid item xs={12} md={3} key={`${(index + 1)}_contitemapp_text`}>
                      <Typography>
                        {item.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} key={`${(index + 1)}_contitemappfield`}>
                      <TextField
                        id={`label${(index + 1)}`}
                        variant="outlined"
                        value={appRepositionList[0][item.key] || ''}
                        onChange={handlechangeapp(item.key)}
                        onBlur={handlechangeappBlur(item.key)}
                        name="numberformat"
                        disabled={!canEdit}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        placeholder="0"
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            {canEdit && (
              <Grid container justifyContent="space-between" className={classes.seccionButton}>
                <ButtonStyled variant="contained" onClick={() => setSaveDialog(true)}>
                  guardar
                </ButtonStyled>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Paper>
      <CustomDialog
        isOpen={saveDialog}
        openCloseFx={setSaveDialog}
        title="Guardar cambios"
        text="Se guardaran los cambios a los montos"
        okFunction={handleSave}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default RepositionScreen;
