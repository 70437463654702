/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  DataGrid,
} from '@material-ui/data-grid';
import { SnackbarProvider } from 'notistack';
import useStyle from './useStyle';

const VersionTable = ({ versions }) => {
  const classes = useStyle();
  const providerRef = React.useRef();

  const columns = [
    {
      field: 'version',
      headerName: 'Versión',
      editable: false,
      width: 140,
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: 'Titulo',
      editable: false,
      width: 400,
      headerAlign: 'center',
    },
    {
      field: 'author',
      headerName: 'Autor',
      editable: false,
      width: 250,
      headerAlign: 'center',
    },
    {
      field: 'description',
      headerName: 'Descripción',
      editable: false,
      width: 900,
      headerAlign: 'center',
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <div className={classes.dataGridDiv}>
        <DataGrid
          getRowClassName={() => 'styledrows'}
          classes={{
            root: classes.dataGridComponent,
          }}
          rows={versions}
          columns={columns}
          pageSize={10}
          density="compact"
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </div>
    </SnackbarProvider>
  );
};

export default VersionTable;
