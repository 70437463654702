import React from 'react';
import { Typography } from '@material-ui/core';
import Config from '../../config';

const Version = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {`Versión ${Config.VERSION_NUMBER}`}
  </Typography>
);

export default Version;
