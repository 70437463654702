import { makeStyles } from '@material-ui/core/styles';
import { BLACK_10 } from '../../../../theme/colors';

const useStyle = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.grey[700],
  },
  editIconReadOnly: {
    color: BLACK_10,
  },
}));

export default useStyle;
