/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const CustomInput = (props) => {
  const {
    label, name, type, className, disabled, multiline, rows, control, rule, error,
    inputProps, id, onValidate, styleTextField, styleFromHelperText, InputPropsParam, variant,
    helperText, onBlur, placeholder,
  } = props;

  const styleHelperText = () => {
    if (styleFromHelperText) return styleFromHelperText;
    if (error[name]) return { style: { margin: '0 0 0 5px', color: '#d61103' } };
    return { style: { margin: '0 0 0 5px', color: '#00000080' } };
  };

  return (
    <FormControl style={className} fullWidth>
      <Controller
        render={({ value, onChange }) => (
          <TextField
            label={label}
            id={id}
            size="small"
            type={type}
            fullWidth
            variant={variant || 'outlined'}
            multiline={multiline}
            minRows={rows}
            value={value}
            style={styleTextField}
            error={Boolean(error[name])}
            disabled={disabled}
            onBlur={onBlur}
            onChange={((e) => {
              onChange(e);
              onValidate && (onValidate(e));
            })}
            inputProps={inputProps}
            InputProps={InputPropsParam}
            FormHelperTextProps={styleHelperText()}
            helperText={error[name] ? error[name].message : helperText}
            placeholder={placeholder}
          />
        )}
        name={name}
        control={control}
        rules={rule}
        error={Boolean(error[name])}
      />
    </FormControl>
  );
};

export default CustomInput;
