/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomTable, Toaster } from '../../../components';
import tableStyle from './tableStyle';
import AlertConfirm from '../../../components/Crosscutting/AlertConfirm';
import { columns } from './constants';
import { useDeleteAssignment } from '../../../services';

const ScreensTable = (props) => {
  const providerRef = React.useRef();
  const {
    users, actionsDisabled, setDefaultValues, getUsers, setPopUpAction, enteredFilter,
  } = props;
  const [usersRows, setUsersRows] = useState([]);
  const [screenListCopy, setScreenListCopy] = React.useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = tableStyle();

  const {
    error: errorDeleteScreen,
    deleteAssignmentById,
    setAssignId,
  } = useDeleteAssignment();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const userList = codesMapped != null ? codesMapped : screenListCopy;
    setUsersRows(userList.filter(
      (user) => user.userName
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    if (errorDeleteScreen) {
      setAlert({ status: true, type: 'error', message: `Ha ocurrido un error, intente nuevamente.${errorDeleteScreen.message || ''}` });
    }
  }, [errorDeleteScreen]);

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleDeleteAssign = (assignId) => {
    setAssignId(assignId);
    setOpenAlertConfirm(true);
  };

  const openModalEdit = (idSelected) => {
    const userEdit = users.find((screenRow) => screenRow.id === idSelected);
    const editData = {
      id: userEdit.id,
      userName: userEdit.userName,
      roleId: userEdit.roleId,
    };
    setDefaultValues({ ...editData });
    setTimeout(() => {
      setPopUpAction({
        open: true,
        title: 'Editar registro',
        data: editData,
      });
    }, 500);
  };

  const deleteScreen = async () => {
    const res = await deleteAssignmentById()
      .catch(() => {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        setOpenAlertConfirm(false);
      });
    if (res?.status !== 200) {
      setOpenAlertConfirm(false);
      getUsers();
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      return;
    }
    providerRef.current.enqueueSnackbar('Se ha eliminado la asignacion del rol con el usuario exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    setOpenAlertConfirm(false);
    getUsers();
  };

  function createActions(assignId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEdit(assignId)}
          style={{ marginLeft: 0 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <EyeIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeleteAssign(assignId)}
          disabled={actionsDisabled}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(userMaps) {
    const userInfo = [];
    userMaps?.forEach((userRole, index) => {
      const screenRow = {
        // eslint-disable-next-line prefer-template
        id: 'RN' + (index + 1),
        userName: userRole.userName,
        description: userRole.description !== '' ? userRole.description : 'Rol inexistente',
        actions: createActions(userRole.id),
        active: !actionsDisabled,
      };
      userInfo.push(screenRow);
    });
    setScreenListCopy(userInfo);
    setUsersRows(userInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, userInfo);
  }

  useEffect(() => {
    setUsersRows([]);
    mapCodes(users);
  }, [users]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={usersRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Eliminar"
        textButtonAcept="Eliminar"
        colorButtonAcept="secondary"
        mensajeModal="Eliminar el rol a este usuario deshabilitará su acceso a todas las pantallas. ¿Desea continuar?"
        handleAccept={() => deleteScreen()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default ScreensTable;
