/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarProvider } from 'notistack';
import useStyle from './useStyle';
import { Toaster, CustomTable, CustomSwitch } from '../../../../components';
import COLUMNS from './constants';
import { getTapToPhoneRows } from './utils';

const TapToPhoneTable = (props) => {
  const classes = useStyle();
  const {
    tapToPhone,
    totalElementsData,
    onNext,
    onSelectPageSize,
    searchQuery,
    loading,
  } = props;
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();
  const [TapToPhoneRows, setTapToPhoneRows] = React.useState([]);

  function createActions(active, tapOnPhoneId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Cambiar estado"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              value={tapOnPhoneId}
              name="active"
              disabled
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          classes={{ disabled: classes.disabled }}
          disabled
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  useEffect(() => {
    setTapToPhoneRows([]);
    getTapToPhoneRows(tapToPhone, createActions, setTapToPhoneRows);
  }, [tapToPhone]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={TapToPhoneRows}
        columns={COLUMNS}
        totalElementsData={totalElementsData}
        onNext={onNext}
        onSelectPageSize={onSelectPageSize}
        searchQuery={searchQuery}
        loading={loading}
        readOnly
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default TapToPhoneTable;
