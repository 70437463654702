import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  btnReadOnly: {
    opacity: '0.4',
  },
}));

export default useStyle;
