import httpInstance from '../httpInstance';

const createReplacementService = async (dataParam) => {
  let res;
  const endpoint = 'back-office/v1/replacements';
  const dataSent = {};

  // Data transformation
  dataSent.id = dataParam.id;
  dataSent.channel_id = dataParam.channel_id;
  dataSent.name = dataParam.name;
  dataSent.cost = dataParam.cost;
  dataSent.order = dataParam.order;
  dataSent.cancelationmotive_id = dataParam.cancelationmotive_id;
  dataSent.delivery_type = dataParam.delivery_type;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const updateReplacementService = async (dataParam) => {
  let res;
  const endpoint = 'back-office/v1/replacements';
  const dataSent = {};

  // Data transformation
  dataSent.id = dataParam.id;
  dataSent.channel_id = dataParam.channel_id;
  dataSent.name = dataParam.name;
  dataSent.cost = dataParam.cost;
  dataSent.order = dataParam.order;
  dataSent.cancelationmotive_id = dataParam.cancelationmotive_id;
  dataSent.delivery_type = dataParam.delivery_type;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const deleteReplacementService = async (dataParam) => {
  let res;
  const endpoint = `back-office/v1/replacements/${dataParam}`;

  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const getReplacementService = async () => {
  let res;
  const endpoint = 'back-office/v1/replacements';

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const getReplacementByIdService = async (dataParam) => {
  let res;
  const endpoint = `back-office/v1/replacements${dataParam.id}`;

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const getReplacementByIdChannelService = async (id) => {
  let res;
  const endpoint = `back-office/v1/channels/${id}/replacements`;

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export {
  createReplacementService,
  updateReplacementService,
  deleteReplacementService,
  getReplacementService,
  getReplacementByIdService,
  getReplacementByIdChannelService,
};
