export const columns = [
  {
    id: 'id',
    label: 'ID',
    position: 1,
  },
  {
    id: 'description',
    label: 'Nombre rol',
    position: 2,
  },
  {
    id: 'status',
    label: 'Status',
    position: 2,
  },
  {
    id: 'permissions',
    label: 'Total secciones',
    position: 3,
  },
  {
    id: 'actions',
    label: 'Acciones',
    position: 3,
  },
];

export default columns;
