import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.grey[700],
  },
  dataGridDiv: {
    height: 480,
    width: '100%',
  },

}));

export default useStyle;
