/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Box,

  FormControlLabel,
} from '@material-ui/core';
import {
  getTransactionTypesService,
  createCommissionService,
  updateCommissionService,
  deleteCommissionService,
} from '../../../../services';
import {
  Toaster, Loading, CustomDialog, CustomSwitch,
} from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';

const commissionModel = {
  type: '',
  channel: '',
  name: '',
  description: '',
  amount: null,
  percentage: null,
  active: false,
};

const ActionForm = ({
  open, data, title, onClose,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [commission, setCommission] = useState(commissionModel);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  const getTransactionTypes = async () => {
    setLoading(true);
    const res = await getTransactionTypesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setTransactionTypes(res.data);
  };

  const createCommission = async () => {
    setLoading(true);
    const res = await createCommissionService(commission)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 201) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 201) {
      setAlert({ status: true, type: 'success', message: 'Comisión creada correctamente' });
      handleOnclose();
    }
    return true;
  };

  const updateCommission = async () => {
    setLoading(true);
    const res = await updateCommissionService(commission)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) {
      setAlert({ status: true, type: 'success', message: 'La comisión ha sido modificada' });
      handleOnclose();
    }
    return true;
  };

  const deleteCommission = async () => {
    setLoading(true);
    const res = await deleteCommissionService(commission.id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) {
      setAlert({ status: true, type: 'success', message: 'La comisión ha sido eliminada' });
      handleOnclose();
    }
    return true;
  };

  const handleSave = () => {
    if (data) {
      updateCommission();
    } else {
      createCommission();
    }
  };

  useEffect(() => {
    if (open) {
      getTransactionTypes();
      if (typeof (data?.type) === 'undefined') {
        setCanEdit(true);
      }
    }
  }, [open]);

  useEffect(() => {
    if (data) return setCommission(data);
    return setCommission(commissionModel);
  }, [data]);

  const selectorChangeHandler = (e) => {
    setCommission({
      ...commission,
      type: e.target.value,
    });
  };

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canEdit}
    >
      {loading
        ? <Loading />
        : (
          <>
            {!canEdit && <Button variant="outlined" color="primary" onClick={() => setCanEdit(true)}>Habilitar edición</Button>}
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            <form noValidate autoComplete="off">
              <Grid
                container
                justify="space-evenly"
                alignItems="stretch"
                spacing={2}
              >
                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {transactionTypes && (
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          disabled={!canEdit || !!data}
                          id="outlined-select-commission-type"
                          select
                          label="Tipo de Transaccion"
                          value={commission.type}
                          onChange={selectorChangeHandler}
                          variant="outlined"
                        >
                          {transactionTypes.map((option) => (
                            <MenuItem key={option.transaction_code} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="channel">Canal</InputLabel>
                      <OutlinedInput
                        id="channel"
                        name="channel"
                        value={commission?.channel || ''}
                        onChange={(e) => setCommission({
                          ...commission,
                          channel: e.target.value,
                        })}
                        label="Channel"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="name">Nombre</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        value={commission?.name || ''}
                        onChange={(e) => setCommission({
                          ...commission,
                          name: e.target.value,
                        })}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="amount">Monto $</InputLabel>
                      <OutlinedInput
                        id="amount"
                        name="amount"
                        type="number"
                        value={commission?.amount || ''}
                        onChange={(e) => setCommission({
                          ...commission,
                          amount: e.target.value,
                          percentage: null,
                        })}
                        label="Monto $"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="percentage">Porcentaje %</InputLabel>
                      <OutlinedInput
                        id="percentage"
                        name="percentage"
                        type="number"
                        value={commission?.percentage || ''}
                        onChange={(e) => setCommission({
                          ...commission,
                          percentage: e.target.value,
                          amount: null,
                        })}
                        label="Porcentaje %"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                      <FormControlLabel
                        control={(
                          <CustomSwitch
                            checked={commission?.active || false}
                            onChange={(e) => setCommission({
                              ...commission,
                              active: e.target.checked,
                            })}
                            name="active"
                            color="primary"
                          />
                          )}
                        label="Estado"
                        labelPlacement="start"
                      />
                    </Box>

                  </Grid>
                </Grid>
              </Grid>
              <div style={{ marginTop: 15 }}>&nbsp;</div>
              <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                <InputLabel htmlFor="description">Descripción</InputLabel>
                <OutlinedInput
                  id="description"
                  name="description"
                  value={commission?.description || ''}
                  onChange={(e) => setCommission({
                    ...commission,
                    description: e.target.value,
                  })}
                  label="Descripción"
                />
              </FormControl>
            </form>
            {data && (
              <div style={{ marginTop: 40, textAlign: 'right' }}>
                {canEdit && <Button variant="contained" color="secondary" onClick={() => setDeleteDialog(true)}>Borrar registro</Button>}
              </div>
            )}
          </>
        )}
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Eliminar comisión"
        text={`Se eliminará la comisión ${commission.name}`}
        okFunction={deleteCommission}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
