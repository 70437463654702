/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import { Toaster, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import { deleteNationalHolidaysService } from '../../../../services';

const NationalHolidaysTable = (props) => {
  const classes = useStyle();
  const {
    enteredFilter, nationalHoliday, setNationalHoliday,
  } = props;
  const [nationalHolidayRows, setNationalHolidayRows] = React.useState([]);
  const [nationalHolidayListCopy, setNationalHolidayListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    id: '',
  });
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const nationalHolidayList = codesMapped != null ? codesMapped : nationalHolidayListCopy;
    setNationalHolidayRows(nationalHolidayList.filter(
      (nationalHolidayParam) => nationalHolidayParam.holiday
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleDeleteNationalHoliday = (holidayId) => {
    setGeneralInfo({
      id: holidayId,
    });
    setOpenAlertConfirmDelete(true);
  };

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele.id !== value);
  }

  const deleteNationalHoliday = async () => {
    const res = await deleteNationalHolidaysService(generalInfo.id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intenta nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intenta nuevamente.' });
    providerRef.current.enqueueSnackbar('Se ha eliminado el día feriado exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
    const holidayDays = arrayRemove(nationalHoliday, generalInfo.id);
    setNationalHoliday(holidayDays);
    setOpenAlertConfirmDelete(false);
  };

  function createActions(nationalHolidayId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeleteNationalHoliday(nationalHolidayId)}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(nationalHolidayParam) {
    const nationalHolidayInfo = [];
    nationalHolidayParam?.forEach((nationalHolidays) => {
      const nationalHolidayRow = {
        holiday: nationalHolidays.holiday,
        actions: createActions(nationalHolidays.id),
        id: nationalHolidays.id,
        active: 1,
      };
      nationalHolidayInfo.push(nationalHolidayRow);
    });
    setNationalHolidayRows(nationalHolidayInfo);
    setNationalHolidayListCopy(nationalHolidayInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, nationalHolidayInfo);
  }

  useEffect(() => {
    setNationalHolidayRows([]);
    mapCodes(nationalHoliday);
  }, [nationalHoliday]);

  const columns = [
    {
      id: 'holiday',
      label: 'Feriado',
      paddingLeft: '40px',
      width: 150,
      position: 2,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 4,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={nationalHolidayRows}
        columns={columns}
      />
      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Está seguro que quiere eliminar este día feriado?"
        handleAccept={() => deleteNationalHoliday()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default NationalHolidaysTable;
