import httpInstance from '../httpInstance';

const putCommerceInfoService = async (body) => {
  let res;
  const endpoint = '/commerce-account/v1/commerce-info/payment-links';
  await httpInstance.put(
    endpoint,
    body,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default putCommerceInfoService;
