import httpInstance from '../httpInstance';

const updateVersionService = async (versionId, username) => {
  const URL = '/back-office/v1/commerce-version/set-minimum';
  const res = await httpInstance.put(URL,
    {
      version_id: versionId,
      update_author: username,
    });
  return res;
};

export default updateVersionService;
