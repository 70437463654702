/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = ({
  inputRef, onChange, name, suffix, decimalScale, thousandSeparator, ...other
}) => (
  <NumberFormat
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange(values.value);
    }}
    thousandSeparator={thousandSeparator}
    isNumericString
    decimalScale={decimalScale}
    suffix={suffix}
    {...other}
  />
);

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  thousandSeparator: PropTypes.bool,
};

NumberFormatCustom.defaultProps = {
  suffix: '',
  decimalScale: 2,
  thousandSeparator: true,
};

export default NumberFormatCustom;
