import { useState } from 'react';
import { useAxiosInstance } from '../httpInstance';

export const useGetAllAssignments = () => {
  const [{ loading, data, error }, getAssignments] = useAxiosInstance(
    // request all registered assignments
    {
      url: 'back-office/v1/user-roles',
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    getAssignments,
  };
};

export const useDeleteAssignment = () => {
  const [assignId, setAssignId] = useState();
  const [{ loading, data, error }, deleteAssignmentById] = useAxiosInstance(
    // request delete rol asignation
    {
      url: `back-office/v1/user-roles/${assignId}`,
      method: 'DELETE',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    deleteAssignmentById,
    assignId,
    setAssignId,
  };
};

export const useCreateAssignment = () => {
  const [method, setMethod] = useState('POST');
  const [{ loading, data, error }, registerAssignment] = useAxiosInstance(
    // request create assignment
    {
      url: 'back-office/v1/user-roles',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    registerAssignment,
    setMethod,
    method,
  };
};

export const useUpdateAssignment = () => {
  const [{ loading, data, error }, updateAssignment] = useAxiosInstance(
    // request update assignment
    {
      url: 'back-office/v1/user-roles',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    updateAssignment,
  };
};

export default {
  useGetAllAssignments,
  useDeleteAssignment,
  useCreateAssignment,
  useUpdateAssignment,
};
