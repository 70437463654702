export const columns = [
  {
    id: 'id',
    label: 'ID',
    position: 1,
  },
  {
    id: 'screenName',
    label: 'Nombre sección',
    position: 2,
  },
  {
    id: 'actions',
    label: 'Acciones',
    position: 3,
  },
];

export default columns;
