import httpInstanceCommerce from '../httpInstanceCommerce';

const postTapToPhoneService = async (dataSent) => {
  let res;
  const endpoint = 'tap-to-phone/v1/phos-commerce';
  const headers = { 'Content-Type': 'application/json' };
  await httpInstanceCommerce.post(
    endpoint,
    dataSent,
    { headers },
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default postTapToPhoneService;
