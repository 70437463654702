const getVersionsRows = (
  versionParam, setRows,
) => {
  const versionInfo = [];
  versionParam?.forEach(
    (version) => {
      const versionRow = {
        active: 1,
        version: version.version,
        title: version.title,
        author: version.author,
        description: version.description,

      };
      versionInfo.push(versionRow);
    },
  );
  setRows(versionInfo);
};

export default getVersionsRows;
