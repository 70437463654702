/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import useStyle from './useStyle';
import { Toaster } from '../../../../components';
import { Box, GridToolbarExportStyled } from './styles';

const RemittancesTable = (props) => {
  const classes = useStyle();
  const {
    remittances,
  } = props;
  const [remittancesRows, setRemittancesRows] = React.useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExportStyled csvOptions={{ fileName: 'Historial cambio tasas' }} />
      </GridToolbarContainer>
    );
  }

  function mapCodes(remittancesParam) {
    const remittancesInfo = [];
    remittancesParam?.forEach((remittance) => {
      const remittanceRow = {
        id: remittance.id,
        user: remittance.register_user,
        beforeRate: new Intl.NumberFormat().format(remittance.casham_day_rate_before),
        updatedRate: new Intl.NumberFormat().format(remittance.casham_day_rate),
        updatedDate: moment(remittance.created_date).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
        state: true,
      };
      remittancesInfo.push(remittanceRow);
    });
    setRemittancesRows(remittancesInfo);
  }

  useEffect(() => {
    setRemittancesRows([]);
    mapCodes(remittances);
  }, [remittances]);

  const columns = [
    {
      field: 'user',
      headerName: 'Usuario',
      editable: false,
      width: 250,
      headerAlign: 'center',
    },
    {
      field: 'beforeRate',
      headerName: 'Tasa anterior CASHAM',
      editable: false,
      width: 300,
      headerAlign: 'center',
    },
    {
      field: 'updatedRate',
      headerName: 'Tasa actualizada CASHAM',
      editable: false,
      width: 300,
      headerAlign: 'center',
    },
    {
      field: 'updatedDate',
      headerName: 'Fecha',
      editable: false,
      width: 300,
      headerAlign: 'center',
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <Box>
        <DataGrid
          getRowClassName={() => 'styledrows'}
          classes={{
            root: classes.dataGridComponent,
          }}
          rows={remittancesRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
        />
      </Box>

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

RemittancesTable.propTypes = {
  remittances: PropTypes.array.isRequired,
};

export default RemittancesTable;
