/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ImageStyled } from '../../styles';

const DataTable = ({
  data,
  onRowClick,
  actionsDisabled,
  onDelete,
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center">Icono</TableCell>
          <TableCell align="center">Nombre del tipo de promoción</TableCell>
          <TableCell align="center">Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow
            hover={!actionsDisabled}
            key={row.id}
            disabled={actionsDisabled}
          >
            <TableCell align="center">
              <ImageStyled src={row.icon_base64} alt="img" />
            </TableCell>
            <TableCell align="center">{row.promotionTypeName}</TableCell>
            <TableCell align="center">
              <IconButton
                edge="end"
                aria-label="Ver"
                size="small"
                onClick={() => !actionsDisabled && onRowClick(row.id)}
                disabled={actionsDisabled}
              >
                <Tooltip title="Ver" aria-label="Ver" placement="top">
                  <EyeIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="Eliminar"
                size="small"
                onClick={() => !actionsDisabled && onDelete(row)}
                disabled={actionsDisabled}
              >
                <Tooltip title="Eliminar" aria-label="Eliminar" placement="top">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool,
};

export default DataTable;
