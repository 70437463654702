import httpInstance from '../httpInstance';

const getCommerceInfoService = async (rnc) => {
  let res;
  const endpoint = `commerce-account/v1/commerce-info/${rnc}`;
  await httpInstance.get(
    endpoint,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getCommerceInfoService;
