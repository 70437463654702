import httpInstance from '../httpInstance';

const getPromotionService = async () => {
  let res;
  const endpoint = '/files/v1/files/cashin-promo';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getPromotionService;
