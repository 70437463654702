import { makeStyles } from '@material-ui/core/styles';
import { BLACK_20 } from '../../../theme/colors';

const useStyle = makeStyles(() => ({
  tableHead: {
    width: '828px',
    height: '40px',
    background: 'var(--unnamed-color-009bdd) 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    opacity: 1,
    color: '#FFF',
    backgroundColor: '#009BDD',
    textAlign: 'center',
  },
  table: {
    minWidth: 750,
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#50B940',
    fontWeight: 'bold',
    paddingRight: '0px!important',
    paddingLeft: '0px!important',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
  },
  readOnly: {
    backgroundColor: BLACK_20,
  },
  tableCell: {
    letterSpacing: '0px',
    color: 'black',
    textAlign: 'center',
  },
  tablePagination: {
    letterSpacing: '0px',
    opacity: 1,
    color: '#616161',
    textAlign: 'right',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    borderRadius: '10px',
  },
  actionsPaginTable: {
    color: '#616161',
  },
  tableSortLabel: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  disabledRow: {
    opacity: 0.4,
  },
}));

export default useStyle;
