import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  EmojiTransportation, EmojiObjects, CellWifi,
} from '@material-ui/icons';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import useStyle from './components/useStyle';
import CategoriesRechargeTable from './components/DataTable/CategoriesRechargeTable.readonly';
import { Toaster } from '../../components';

const CategoriesRechargeScreen = () => {
  const classes = useStyle();
  const [categoriesRecharge, setCategoriesRecharge] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getCategoriesPayment = async () => {
    const categoriesData = [
      {
        categoryId: '50002',
        name: 'Servicios de comunicación',
        order: 1,
        status: true,
        url: <CellWifi />,
      },
      {
        categoryId: '20002',
        name: 'Energía',
        order: 2,
        status: true,
        url: <EmojiObjects />,
      },
      {
        categoryId: '40002',
        name: 'Transporte',
        order: 3,
        status: true,
        url: <EmojiTransportation />,
      },
    ];
    return setCategoriesRecharge(categoriesData);
  };

  useEffect(() => {
    getCategoriesPayment();
  }, []);

  return (
    <>
      <Tooltip title="Refrescar página" aria-label="Refrescar página">
        <IconButton
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
        >
          <img src={reload} alt="reload" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
        <IconButton
          style={{ fontSize: '20px', float: 'right', opacity: 0.4 }}
          edge="end"
          size="small"
          disabled
        >
          <img src={newButton} alt="newButton" />
        </IconButton>
      </Tooltip>
      <div className={classes.root}>
        <CategoriesRechargeTable
          setCategoriesPayment={setCategoriesRecharge}
          categories={categoriesRecharge}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </div>
    </>
  );
};

export default CategoriesRechargeScreen;
