import httpInstance from '../httpInstance';

const cancelRemittanceService = async (id) => {
  let res;
  const endpoint = `/back-office/v1/process/remittances/cancel/${id}`;

  await httpInstance.post(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default cancelRemittanceService;
