import httpInstance from '../httpInstance';

const updateTypePromotionService = async (body) => {
  let res;
  const endpoint = '/back-office/v1/promotions-type';
  await httpInstance.put(
    endpoint,
    body,
  ).then((data) => {
    res = data.data;
  }).catch(() => {
    res = null;
  });
  return res;
};

export default updateTypePromotionService;
