import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  textInputLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  buttonsContainer: {
    width: 250,
    marginLeft: 40,
  },
  textField: {
    marginLeft: '3px',
  },
  seccionButton: {
    marginTop: 50,
    marginBottom: 20,
  },
  heightTable: {
    height: 60,
  },
  text: {
    fontSize: 16,
  },
}));

export default useStyle;
