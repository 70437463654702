import httpInstance from '../httpInstance';

const createTaxService = async (dataParam) => {
  let res;
  const endpoint = '/fares/v1/taxes';
  const dataSent = dataParam;

  // Data transformation
  dataSent.description_tax = dataParam.tax_description || dataParam.taxes[0].description;
  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default createTaxService;
