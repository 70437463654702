import httpInstanceCommerce from '../httpInstanceCommerce';

const putCommercialCategoryService = async (body) => {
  let res;
  const endpoint = '/tap-to-phone/v1/phos-commerce/back-office';
  await httpInstanceCommerce.put(
    endpoint,
    body,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default putCommercialCategoryService;
