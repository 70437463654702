/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import { SPECIAL_OPERATIONS, TYPE_TRANSACTION } from '../../constants';

const TableRowLimits = ({
  position,
  nameOperation,
  keyObject,
  out,
  segmentation,
  setSegmentation,
  edit,
}) => {
  const onChange = (value, key) => {
    const [type, section] = key.split('.');
    const newValue = SPECIAL_OPERATIONS.includes(keyObject)
      ? {
        ...segmentation,
        [keyObject]: {
          ...segmentation[keyObject],
          [out ? 'out' : 'in']: {
            ...segmentation[keyObject][out ? 'out' : 'in'],
            [type]: {
              ...segmentation[keyObject][out ? 'out' : 'in'][type],
              [section]: Number(value) || 0,
            },
          },
        },
      }
      : {
        ...segmentation,
        [keyObject]: {
          ...segmentation[keyObject],
          [type]: {
            ...segmentation[keyObject][type],
            [section]: Number(value) || 0,
          },
        },
      };
    setSegmentation(newValue);
  };
  const renderComponent = (key) => {
    const [type, section] = key.split('.');
    const value = SPECIAL_OPERATIONS.includes(keyObject)
      ? segmentation[keyObject][out ? 'out' : 'in'][type][section]
      : segmentation[keyObject][type][section];
    return (
      edit
        ? (
          <TextField
            key={key}
            variant="outlined"
            onChange={({ target }) => onChange(target.value, key)}
            type="numeric"
            value={value}
          />
        )
        : value);
  };

  return (
    <TableRow hover>
      <TableCell align="center">{position}</TableCell>
      <TableCell align="center">{nameOperation}</TableCell>
      <TableCell align="center">{TYPE_TRANSACTION[`${keyObject}_${out ? 'out' : 'in'}`]}</TableCell>

      <TableCell align="center">{renderComponent('amountLimit.daily')}</TableCell>
      <TableCell align="center">{renderComponent('amountLimit.weekly')}</TableCell>
      <TableCell align="center">{renderComponent('amountLimit.monthly')}</TableCell>

      <TableCell align="center">{renderComponent('countLimit.daily')}</TableCell>
      <TableCell align="center">{renderComponent('countLimit.weekly')}</TableCell>
      <TableCell align="center">{renderComponent('countLimit.monthly')}</TableCell>
    </TableRow>
  );
};

TableRowLimits.propTypes = {
  nameOperation: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  keyObject: PropTypes.string.isRequired,
  segmentation: PropTypes.object.isRequired,
  setSegmentation: PropTypes.func.isRequired,
  out: PropTypes.bool,
  edit: PropTypes.bool,
};

export default TableRowLimits;
