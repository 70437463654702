import httpInstance from '../httpInstance';

const getTapToPhoneService = async ({
  pageNumber = 0, pageSize = 80, keyFilter = '', searchQuery = '',
}) => {
  let res;
  const endpoint = `/commerce-account/v1/commerce-info/tap-to-phone/all?pageNumber=${pageNumber}&pageSize=${pageSize}${searchQuery ? `&${keyFilter}=${searchQuery}` : ''}`;
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getTapToPhoneService;
