/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { TableHead, TableHeaderCell } from './useStyle';
import { Rules } from '../../../helpers/RHFRules';
import { CustomSelect, CustomDialog, Loading } from '../../../components';
import { updateFeaturedPromotion } from '../../../services';

const DataTable = ({
  data,
  actionsDisabled,
  control,
  errors,
  setAlert,
  canEdit,
  withActions,
  onDeletePromotion,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dataDelete, setDataDelete] = useState();
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const isToEdit = control && errors;
  const OPTIONS = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ];

  const onDeleteFeaturedPromotion = async () => {
    setLoading(true);
    const res = await updateFeaturedPromotion({
      ...dataDelete,
      featureOrder: '0',
      featurePromotion: false,
    });
    setLoading(false);
    if (res.status === 200) {
      onDeletePromotion(dataDelete.id);
      setAlert({ status: true, type: 'success', message: 'Promoción eliminada de destacados' });
    } else {
      setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    setDataDelete(undefined);
  };

  const getLabelStatus = (status, endDate) => {
    let label;
    const currentDate = moment().format('YYYY-MM-DD');
    const isExpired = moment(endDate).isBefore(currentDate);

    if (status === 'activo') label = 'Activo';

    if (status === 'inactivo') label = 'Inactivo';

    if (isExpired) label = 'Expirado';

    return label;
  };

  useEffect(() => {
    if (data) setPromotions(data);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      {loading && <Loading />}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell align="center">Orden</TableHeaderCell>
            <TableHeaderCell align="center">Nombre</TableHeaderCell>
            <TableHeaderCell align="center">Categoría</TableHeaderCell>
            <TableHeaderCell align="center">Comercio</TableHeaderCell>
            <TableHeaderCell align="center">Estatus</TableHeaderCell>
            {withActions && <TableHeaderCell align="center">Acciones</TableHeaderCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.map((row) => (
            <TableRow
              hover={!actionsDisabled}
              key={row.id}
              disabled={actionsDisabled}
            >
              <TableCell align="center">
                {isToEdit
                  ? (
                    <CustomSelect
                      label="Orden*"
                      name={`order/${row.id}`}
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      options={OPTIONS}
                      disabled={!canEdit}
                    />
                  )
                  : row.featureOrder}
              </TableCell>
              <TableCell align="center">{row.promotionName}</TableCell>
              <TableCell align="center">{row.category}</TableCell>
              <TableCell align="center">
                <Tooltip title={row.commerceName} aria-label="name-commerce" placement="bottom">
                  <img src={row.commerceLogo} alt="img" width={50} />
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                {getLabelStatus(row.status, row.endDate)}
              </TableCell>
              {withActions && (
                <TableCell align="center">
                  <IconButton
                    edge="end"
                    aria-label="Ver"
                    size="small"
                    onClick={() => {
                      setDataDelete(row);
                      setOpenDialog(true);
                    }}
                    disabled={isToEdit && !canEdit}
                  >
                    <Tooltip title="Eliminar de promoción destacada" aria-label="edit" placement="bottom">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomDialog
        isOpen={openDialog}
        openCloseFx={setOpenDialog}
        title="Confirmación"
        text={`Deseas quitar la siguiente promoción "${dataDelete?.promotionName}" de destacadas?`}
        okFunction={onDeleteFeaturedPromotion}
        notOkFunction={() => { }}
      />
    </TableContainer>
  );
};

export default DataTable;
