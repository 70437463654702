/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  flex-direction: column;
`;

export const CardOptions = styled.div`
  flex-direction: row;
`;

export const IconButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 3px;
  margin-right: -3px;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const IconButtonAdd = styled(IconButton)`
  float: right;
`;
