import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { getCommissionsService } from '../../services';
import { Toaster } from '../../components';
import { DataTable, ReadOnlyActionForm } from './components';
import { InternalPage } from '../../Layouts';
import { WrapperButton, Button, Card } from './styles';

const CommissionsScreen = () => {
  const [commissionsList, setCommissionsList] = useState();
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(true);

  const getCommissionsList = async () => {
    setLoading(true);
    const res = await getCommissionsService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setCommissionsList(res.data);
  };

  const tableRowClickHandler = (index) => {
    setPopUpAction({ open: true, title: 'Comisión - Solo lectura', data: commissionsList[index] });
  };

  useEffect(() => {
    getCommissionsList();
  }, []);

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Card>
        {commissionsList && (
          <DataTable
            data={commissionsList}
            onRowClick={tableRowClickHandler}
          />
        )}
        <WrapperButton>
          <Button aria-label="add" disabled>
            <AddIcon />
          </Button>
        </WrapperButton>
        <ReadOnlyActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          title={popUpAction.title}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
            getCommissionsList();
          }}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Card>
    </InternalPage>
  );
};

export default CommissionsScreen;
