import httpInstance from '../httpInstance';

const getTypePromotionsService = async () => {
  let res;
  const endpoint = '/back-office/v1/promotions-type';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data.data;
  }).catch(() => {
    res = null;
  });
  return res;
};

export default getTypePromotionsService;
