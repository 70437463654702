import { useState } from 'react';
import { useAxiosInstance } from './httpInstance';

const useGetScreens = () => {
  const [username, setUsername] = useState();
  const [{ loading, data, error }, getUserScreens] = useAxiosInstance(
    // request the user role permissions
    {
      url: `/back-office/v1/roles/findByUserName/${username}`,
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    username,
    getUserScreens,
    setUsername,
  };
};

export default useGetScreens;
