/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const getColor = ({ error, theme }) => {
  if (error) {
    return `color: ${theme.firebrick};`;
  }

  return `color: ${theme.primary};`;
};

const getDisabledButton = ({ disabled, theme }) => {
  if (disabled) {
    return `
    color: ${theme.black20};
    cursor: auto;
  `;
  }

  return `
    &:hover {
      background-color: ${theme.black5};
      border-radius: 5px;
    }
  `;
};

export const ButtonStyled = styled.button`
  align-items: center;
  justify-content: center;
  min-width: 64px;
  border: none;
  text-transform: uppercase;
  padding: 6px 16px;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  background-color: ${({ theme }) => theme.white};
  box-shadow: none;
  ${getColor}
  ${getDisabledButton}
`;
