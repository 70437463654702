import httpInstance from '../httpInstance';

const getFeaturedPromotion = async (active = 'true') => {
  let res;
  await httpInstance.get(`/back-office/v1/promotions/featurePromotion/${active}`)
    .then((data) => {
      res = data;
    }).catch((err) => {
      res = err.response;
    });
  return res;
};

export default getFeaturedPromotion;
