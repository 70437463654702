import httpInstance from '../httpInstance';

const updateBannerService = async (dataParam) => {
  const { ...dataSent } = dataParam;
  let res;
  const endpoint = '/card-acceptance/v1/devices';

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateBannerService;
