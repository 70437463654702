/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import {
  getTransactionTypesService,
  createFeeService,
  updateFeeService,
  deleteFeeService,
} from '../../../../services';
import {
  Toaster, Loading, CustomDialog,
} from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';

const feeModel = {
  description_fee: '',
  fees_id: '',
  transaction_code: '',
  transaction_name: '',
  fees_documents_list: [
    {
      amount: '',
      description: '',
      id_fee: '',
      name: '',
      percent: '',
    },
  ],
};

const ActionForm = ({
  open, data, title, onClose,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [fee, setFee] = useState(feeModel);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const getTransactionTypes = async () => {
    setLoading(true);
    const res = await getTransactionTypesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setTransactionTypes(res.data);
  };

  const createFee = async () => {
    setLoading(true);
    const res = await createFeeService(fee)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 201) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 201) return setAlert({ status: true, type: 'success', message: 'Comisión creada correctamente' });
    return true;
  };

  const updateFee = async () => {
    setLoading(true);
    const res = await updateFeeService(fee)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'La Comisión ha sido modificada' });
    return true;
  };

  const deleteFee = async () => {
    setLoading(true);
    const res = await deleteFeeService(fee.fees_id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'La Comisión ha sido eliminada' });
    return true;
  };

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  const handleSave = () => {
    if (data) {
      updateFee();
    } else {
      createFee();
    }
  };

  useEffect(() => {
    if (open) getTransactionTypes();
  }, [open]);

  useEffect(() => {
    if (data) {
      return setFee(data);
    }
    return setFee(feeModel);
  }, [data]);

  const selectorChangeHandler = (e) => {
    setFee({
      ...fee,
      transaction_code: e.target.value,
      transaction_name: e.nativeEvent.target.innerText,
    });
  };

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canEdit}
    >
      {loading
        ? <Loading />
        : (
          <>
            {!canEdit && <Button variant="outlined" color="primary" onClick={() => setCanEdit(true)}>Habilitar edición</Button>}
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            <form noValidate autoComplete="off">
              <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item md>
                    {transactionTypes && (
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          disabled={!canEdit}
                          id="outlined-select-fee-type"
                          label="Tipo de transacción"
                          select
                          value={fee.transaction_code}
                          onChange={selectorChangeHandler}
                          variant="outlined"
                        >
                          {transactionTypes.map((option) => (
                            <MenuItem key={option.transaction_code} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="name">Nombre</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        value={fee?.fees_documents_list[0]?.name || ''}
                        onChange={(e) => setFee({
                          ...fee,
                          fees_documents_list: [{
                            ...fee.fees_documents_list[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="amount">Monto $</InputLabel>
                      <OutlinedInput
                        id="amount"
                        name="amount"
                        type="number"
                        value={fee?.fees_documents_list[0]?.amount || ''}
                        onChange={(e) => setFee({
                          ...fee,
                          fees_documents_list: [{
                            ...fee.fees_documents_list[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Monto $"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="percent">Porcentaje %</InputLabel>
                      <OutlinedInput
                        id="percent"
                        name="percent"
                        type="number"
                        value={fee?.fees_documents_list[0]?.percent || ''}
                        onChange={(e) => setFee({
                          ...fee,
                          fees_documents_list: [{
                            ...fee.fees_documents_list[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Porcentaje %"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
                <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                  <InputLabel htmlFor="description">Descripción</InputLabel>
                  <OutlinedInput
                    id="description"
                    name="description"
                    value={fee?.fees_documents_list[0]?.description || ''}
                    onChange={(e) => setFee({
                      ...fee,
                      fees_documents_list: [{
                        ...fee.fees_documents_list[0],
                        [e.target.name]: e.target.value,
                      }],
                    })}
                    label="Descripción"
                  />
                </FormControl>
              </Grid>
            </form>
            {data && (
              <div style={{ marginTop: 40, textAlign: 'right' }}>
                {canEdit && <Button variant="contained" color="secondary" onClick={() => setDeleteDialog(true)}>Borrar registro</Button>}
              </div>
            )}
          </>
        )}
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Eliminar comisión"
        text={`Se eliminará la comisión ${fee.transaction_name}`}
        okFunction={deleteFee}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
