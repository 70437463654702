import React from 'react';
import PropTypes from 'prop-types';
import { AlertTitle, Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { Loading } from '../../components';

const InternalPage = ({
  children,
  error,
  errorMessage,
  loading,
}) => (
  <>
    {loading && <Loading />}
    {error && (
      <Alert
        severity="error"
        action={<Button color="inherit" size="small" onClick={() => window.location.reload()}>RECARGAR</Button>}
      >
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    )}
    {!loading && !error && children}
  </>
);

InternalPage.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

InternalPage.defaultProps = {
  errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente',
};

export default InternalPage;
