/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import {
  createAmountService,
  updateAmountService,
  deleteAmountService,
  extraTypes as optionsMounts,
  KIOSKOCONST,
} from '../../../../services';
import { Toaster, Loading, CustomDialog } from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';
import { validateAmount } from '../../../../utils';

const amountModel = {
  minimum_amount: '',
  maximum_amount: '',
  amount_name: '',
  amount_id: '',
  transaction_code: '',
  transaction_name: '',
};

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  actualCreated,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [amount, setAmount] = useState(amountModel);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [optionsUnsed, setOptionsUnsed] = useState([]);

  const getTransactionTypes = async () => {
    setLoading(true);
    await optionsMounts().then((res) => {
      setLoading(false);
      setTransactionTypes(res);
    });
  };

  const createAmount = async () => {
    setLoading(true);
    // Validate inputs
    if (!validateAmount(amount)) {
      setLoading(false);
      setAlert({
        status: true,
        type: 'error',
        message: 'Formulario inválido, por favor verifique.',
      });
      return false;
    }

    const extra = optionsUnsed.filter(
      (obj) => (obj.transaction_code === amount.transaction_code && obj.type),
    );
    if (extra.length > 0) {
      amount.amount_name = `${amount.amount_name}_${extra[0].type}`;
    }
    const res = await createAmountService(amount)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    let messageErr = 'Ha ocurrido un error, intente nuevamente.';
    if (res?.status !== 201) {
      if (res.data && res.data.details) {
        const errors = res.data.details.map((detail) => detail.error_message);
        messageErr = errors.join(' ');
      }
      return setAlert({ status: true, type: 'error', message: messageErr });
    }
    if (res?.status === 201) {
      setAlert({ status: true, type: 'success', message: 'Monto creado correctamente' });
      setCanEdit(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
    return true;
  };

  const updateAmount = async () => {
    setLoading(true);
    // Validate inputs
    if (!validateAmount(amount)) {
      setLoading(false);
      setAlert({
        status: true,
        type: 'error',
        message: 'Formulario inválido, por favor verifique.',
      });
      return false;
    }

    const res = await updateAmountService(amount)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'El monto ha sido modificado' });
    return true;
  };

  const deleteFee = async () => {
    setLoading(true);
    const res = await deleteAmountService(amount.amount_id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) {
      setAlert({ status: true, type: 'success', message: 'El monto ha sido eliminado' });
      setCanEdit(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
    return true;
  };

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  const handleSave = () => {
    if (data) {
      updateAmount();
    } else {
      createAmount();
    }
  };

  useEffect(() => {
    if (open) getTransactionTypes();
  }, [open]);

  useEffect(() => {
    if (transactionTypes && actualCreated) {
      const array1 = transactionTypes;
      const array2 = actualCreated;
      const notUsed = array1.filter(
        (elem) => !array2.find((item) => elem.transaction_code === item.txn_code),
      );
      const actualTransactionCode = array1.find(
        (item) => amount.transaction_code === item.transaction_code,
      );
      if (actualTransactionCode) {
        setOptionsUnsed([
          {
            transaction_code: amount.transaction_code,
            transaction_name: `-${actualTransactionCode.transaction_name}-`,
          },
          ...notUsed,
        ]);
      } else {
        setOptionsUnsed(notUsed);
      }
    }
  }, [transactionTypes, amount]);

  useEffect(() => {
    if (data && data != null) {
      setCanEdit(false);
      return setAmount({
        ...amountModel,
        minimum_amount: data?.minimum_amount,
        maximum_amount: data?.maximum_amount,
        amount_name: data.txn_name,
        amount_id: data.id,
        transaction_code: data.txn_code,
      });
    }
    setCanEdit(true);
    return setAmount(amountModel);
  }, [data]);

  const selectorChangeHandler = (e) => {
    let sufix = '_';
    const extra = optionsUnsed.filter(
      (obj) => (obj.transaction_code === e.target.value && obj.type),
    );
    if (extra.length > 0) {
      sufix = `${e.nativeEvent.target.innerText}_${extra[0].type}`;
    } else {
      sufix = e.nativeEvent.target.innerText;
    }
    setAmount({
      ...amount,
      transaction_code: e.target.value,
      transaction_name: sufix,
    });
  };

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canEdit}
    >
      {loading
        ? <Loading />
        : (
          <>
            {!canEdit && <Button variant="outlined" color="primary" onClick={() => setCanEdit(true)}>Habilitar edición</Button>}
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            <form noValidate autoComplete="off">
              <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={1}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item md>
                    {(transactionTypes && optionsUnsed.length > 0) && (
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          disabled={!canEdit || (data && data == null)}
                          id="outlined-select-fee-type"
                          select
                          label="Transaction"
                          value={amount.transaction_code}
                          sub={amount.transaction_code}
                          onChange={selectorChangeHandler}
                          variant="outlined"
                        >
                          {data == null ? optionsUnsed.map((option, index) => (
                            <MenuItem key={`opt_${option.transaction_code}_${index + 1}`} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          )) : optionsUnsed.map((option, index) => (
                            <MenuItem key={`opt_${option.transaction_code}_${index + 1}`} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="name">Nombre</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        value={amount?.amount_name || ''}
                        onChange={(e) => setAmount({
                          ...amount,
                          amount_name: e.target.value,
                        })}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="amount">Monto minimo $</InputLabel>
                      <OutlinedInput
                        id="amountout"
                        name="amountout"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        error={amount.minimum_amount < 0}
                        value={amount?.minimum_amount || ''}
                        onChange={(e) => setAmount((state) => {
                          const newState = {
                            ...state,
                            minimum_amount: e.target.value,
                          };
                          if (state.maximum_amount < newState.minimum_amount) {
                            newState.maximum_amount = newState.minimum_amount;
                          }
                          return newState;
                        })}
                        label="Monto mínimo $"
                      />
                    </FormControl>
                  </Grid>
                  {(amount.transaction_code === KIOSKOCONST || amount.transaction_name.includes('_KIOSKO')) && (
                    <Grid item md>
                      <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                        <InputLabel htmlFor="amount">Monto maximo $</InputLabel>
                        <OutlinedInput
                          id="amount"
                          name="amount"
                          type="number"
                          inputProps={{
                            min: amount.minimum_amount,
                          }}
                          error={amount.maximum_amount < amount.minimum_amount}
                          value={amount?.maximum_amount || ''}
                          onChange={(e) => setAmount((state) => ({
                            ...state,
                            maximum_amount: e.target.value,
                          }))}
                          label="Monto máximo $"
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <div style={{ marginTop: 15 }}>&nbsp;</div>
            </form>
            {data && (
              <div style={{ marginTop: 40, textAlign: 'right' }}>
                {canEdit && <Button variant="contained" color="secondary" onClick={() => setDeleteDialog(true)}>Borrar registro</Button>}
              </div>
            )}
          </>
        )}
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Eliminar limite"
        text={`Se eliminará el limite ${amount.transaction_name}`}
        okFunction={deleteFee}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  actualCreated: PropTypes.object,
};

export default ActionForm;
