/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomTable, Toaster } from '../../../components';
import tableStyle from './tableStyle';
import AlertConfirm from '../../../components/Crosscutting/AlertConfirm';
import { columns } from './constants';
import { useDeleteScreenById } from '../../../services';

const ScreensTable = (props) => {
  const providerRef = React.useRef();
  const {
    screens, actionsDisabled, setDefaultValues, getAllRoles, setPopUpAction, enteredFilter,
  } = props;
  const [screensRows, setScreenRows] = useState([]);
  const [screenListCopy, setScreenListCopy] = React.useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = tableStyle();

  const {
    error: errorDeleteScreen,
    deleteScreenById,
    setScreenId,
  } = useDeleteScreenById();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const promoList = codesMapped != null ? codesMapped : screenListCopy;
    setScreenRows(promoList.filter(
      (promo) => promo.description
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promo.status
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    if (errorDeleteScreen) {
      setAlert({ status: true, type: 'error', message: `Ha ocurrido un error, intente nuevamente.${errorDeleteScreen.message || ''}` });
    }
  }, [errorDeleteScreen]);

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleDeletePromo = (screenId) => {
    setScreenId(screenId);
    setOpenAlertConfirm(true);
  };

  const openModalEdit = (screenIdSelected) => {
    const promoEdit = screens.find((screenRow) => screenRow.id === screenIdSelected);
    const editData = {
      id: promoEdit.id,
      active: promoEdit.active,
      description: promoEdit.description,
      screens: '',
      permissions: promoEdit.permissions,
    };
    setDefaultValues({ ...editData });
    setTimeout(() => {
      setPopUpAction({
        open: true,
        title: 'Editar rol',
        data: editData,
      });
    }, 500);
  };

  const deleteRol = async () => {
    const res = await deleteScreenById()
      .catch(() => {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        setOpenAlertConfirm(false);
      });
    if (res?.status !== 200) {
      setOpenAlertConfirm(false);
      getAllRoles();
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      return;
    }
    providerRef.current.enqueueSnackbar('Se ha eliminado la promoción exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    setOpenAlertConfirm(false);
    getAllRoles();
  };

  function createActions(screenId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEdit(screenId)}
          style={{ marginLeft: 0 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <EyeIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeletePromo(screenId)}
          disabled
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(rolesMap) {
    const rolesInfo = [];
    rolesMap?.forEach((rol, index) => {
      const rolRow = {
        // eslint-disable-next-line prefer-template
        id: 'RN' + (index + 1),
        description: rol.description,
        status: rol.active ? 'activo' : 'inactivo',
        permissions: rol.permissions.length,
        actions: createActions(rol.id),
        active: !actionsDisabled,
      };
      rolesInfo.push(rolRow);
    });
    setScreenListCopy(rolesInfo);
    setScreenRows(rolesInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, rolesInfo);
  }

  useEffect(() => {
    setScreenRows([]);
    mapCodes(screens);
  }, [screens]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={screensRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
        readOnly
      />
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Eliminar"
        textButtonAcept="Eliminar"
        colorButtonAcept="secondary"
        mensajeModal="¿Está seguro que quiere eliminar este rol?"
        handleAccept={() => deleteRol()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default ScreensTable;
