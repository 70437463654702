const COLUMNS = [
  {
    id: 'documentNumber', label: 'RNC/Cédula', paddingLeft: '40px', width: 150, position: 1,
  },
  {
    id: 'commercialName', label: 'Nombre del comercio', paddingLeft: '40px', width: 150, position: 2,
  },
  {
    id: 'acquiringId',
    label: 'ID de adquirencia',
    paddingLeft: '40px',
    width: 10,
    position: 3,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 10,
    position: 4,
  },
];

export default COLUMNS;
