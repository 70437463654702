export const columns = [
  {
    id: 'featurePromotion',
    label: '',
    position: 0,
  },
  {
    id: 'id',
    label: 'ID',
    position: 1,
  },
  {
    id: 'promotionName',
    label: 'Nombre promoción',
    position: 2,
  },
  {
    id: 'commerceName',
    label: 'Nombre comercio',
    position: 4,
  },
  {
    id: 'commerceLogo',
    label: 'logo comercio',
    position: 5,
  },
  {
    id: 'startDate',
    label: 'Fecha inicio',
    position: 6,
  },
  {
    id: 'endDate',
    label: 'Fecha fin',
    position: 7,
  },
  {
    id: 'category',
    label: 'Categoria',
    position: 9,
  },
  {
    id: 'promotionType',
    label: 'Tipo',
    position: 10,
  },
  {
    id: 'status',
    label: 'Estatus',
    position: 11,
  },
  {
    id: 'actions',
    label: 'Acciones',
    position: 13,
  },
];

export const imageProps = {
  name: '', b64: '', invalidImage: '', urlImage: '',
};

export default {
  columns,
  imageProps,
};
