/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller } from 'react-hook-form';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { getAlliesService } from '../../services';
import { Toaster } from '../../components';
import {
  Box,
  Button,
  ButtonContainer,
  Card,
} from './styles';

const AlliesScreen = () => {
  const classes = useStyle();

  const [loading, setLoading] = useState(false);
  const [creationAction, setCreationAction] = useState(true);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const {
    control, errors, setValue,
  } = useForm({ amount: ' ' });

  const getAllies = async () => {
    setLoading(true);
    const res = await getAlliesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res.data !== undefined) {
      setValue('amount', res.data.amount);
      setCreationAction(false);
    }
    return undefined;
  };

  useEffect(() => {
    getAllies();
  }, []);

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        decimalSeparator={false}
      />
    );
  }

  function NumberFormatAmount(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatAmount.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const validateAmount = (type) => {
    switch (type) {
      case 'min':
        return 'El valor debe ser mayor a 0';
      case 'required':
        return 'Campo obligatorio';
      default:
        return '';
    }
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Card>
        <>
          <form noValidate autoComplete="off">
            <Box>
              <Box>
                <InputLabel>Monto Cash-IN Referido</InputLabel>
              </Box>

              <Box>
                <FormControl>
                  <Controller
                    as={(
                      <FormControlLabel
                        control={(
                          <TextField
                            disabled
                            size="small"
                            className={classes.textFieldShape}
                            variant="outlined"
                            InputProps={{
                              inputComponent: NumberFormatAmount,
                            }}
                            onChange={(e) => setValue('amount', e.target.value)}
                            color="primary"
                            FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                            helperText={
                              validateAmount(errors.amount
                                ? errors.amount.type
                                : '')
                            }
                            inputProps={{
                              maxLength: 15,
                            }}
                          />
                        )}
                        labelPlacement="start"
                      />
                    )}
                    name="amount"
                    control={control}
                    error={errors.amount}
                    rules={{
                      required: 'Campo obligatorio',
                      min: 0.01,
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <ButtonContainer>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={(e) => e.preventDefault()}
                className={classes.button}
                disabled
              >
                {creationAction ? 'Crear' : 'Editar'}
              </Button>
            </ButtonContainer>
          </form>
          <Toaster
            show={alert.status}
            type={alert.type}
            text={alert.message}
            onClose={() => setAlert({ ...alert, status: false })}
          />
        </>
      </Card>
    </InternalPage>
  );
};

export default AlliesScreen;
