export const P2P = 'P2P';
export const P2M = 'P2M';
export const SP = 'SP';
export const KIOSKO = 'KIOSKO';
export const KIOSKO_RECHARGE = 'KIOSKO_RECHARGE';
const extraTypesfunc = async () => {
  const types = [
    {
      transaction_code: P2P,
      transaction_name: 'P2P amount',
      type: 'APP',
    },
    {
      transaction_code: P2M,
      transaction_name: 'P2M amount',
      type: 'APP',
    },
    {
      transaction_code: SP,
      transaction_name: 'SplitPayment amount',
      type: 'APP',
    },
    {
      transaction_code: KIOSKO,
      transaction_name: 'Kiosko amounts',
      type: 'KIOSKO',
    },
    {
      transaction_code: KIOSKO_RECHARGE,
      transaction_name: 'Kiosko recharges',
      type: 'KIOSKO',
    },
  ];
  return Promise.resolve(types);
};

export const TypesCost = {
  KIOSKO: 'KIOSKO',
  APP: 'APP',
};

export default extraTypesfunc;
