const FILTER_OPTIONS = [
  {
    id: 'originSequence',
    value: 'Referencia',
  },
  {
    id: 'date',
    value: 'Fecha',
  },
  {
    id: 'name',
    value: 'Nombre',
  },
  {
    id: 'phoneNumber',
    value: '# Telefónico',
  },
];

export default FILTER_OPTIONS;
