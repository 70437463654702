/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/Button';
import { Loading } from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';
import {
  InputStyled,
  InputFile,
  SpanButton,
  LabelStyled,
  PublishIconStyled,
  FindReplaceIconStyled,
  BoxFile,
  Box,
  Container,
} from './styles';
import createTypePromotion from '../../../../services/typePromotions/createTypePromotion';
import updateTypePromotion from '../../../../services/typePromotions/updateTypePromotion';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  newPromotion,
  setAlert,
}) => {
  const [canSave, setCanSave] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [base64, setBase64] = useState('');
  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const handleOnclose = (info) => {
    setName('');
    setBase64('');
    if (!newPromotion) setCanEdit(false);
    onClose(info);
  };

  const hasData = (str) => str?.length > 0;
  const onChangeName = (e) => setName(e.target.value);

  const onChangeIcon = async (e) => {
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setAlert({ status: true, type: 'error', message: 'El tamaño del archivo supera el máximo permitido (2MB).' });
      return;
    }
    const url = window.URL.createObjectURL(file);
    const getBase64 = () => fetch(url)
      .then((response) => response.blob())
      .then((blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }))
      .catch(() => { });

    const dataURL = await getBase64();
    const fullB64 = dataURL.split(',')[1];
    setBase64(fullB64);
  };

  const handleSave = async () => {
    setLoading(true);
    const info = {};
    const sameParams = {
      promotionTypeName: name,
      icon_base64: base64,
    };
    if (data) {
      info.action = 'UPDATED';
      info.res = await updateTypePromotion({
        ...sameParams,
        id: data.id,
      });
    } else {
      info.action = 'CREATED';
      info.res = await createTypePromotion(sameParams);
    }
    setLoading(false);

    if (info.res) {
      setAlert({ status: true, type: 'success', message: `Tipo de promoción ${info.action === 'UPDATED' ? 'actualizada' : 'creada'}` });
      handleOnclose(info);
    } else {
      setAlert({ status: true, type: 'error', message: 'Ops! Ocurrió un error.' });
    }
  };

  useEffect(() => {
    const isDataOk = hasData(name) && hasData(base64);
    if (isDataOk) setCanSave(true);
    else setCanSave(false);
  }, [name, base64]);

  useEffect(() => {
    if (data) {
      setName(data.promotionTypeName);
      setBase64(data.icon_base64);
    }
  }, [data]);

  useEffect(() => {
    setCanEdit(newPromotion);
  }, [newPromotion]);

  return (
    <ActionPopUp
      open={open}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canSave && canEdit}
    >
      {(!canEdit && data) && <Button variant="outlined" color="primary" onClick={() => setCanEdit(true)}>Habilitar edición</Button>}
      {loading
        ? <Loading />
        : (
          <Container>
            <div>
              <LabelStyled htmlFor="name" disabled={!canEdit}>Nombre del tipo de promoción:</LabelStyled>
              <InputStyled type="text" onChange={onChangeName} name="name" value={name} disabled={!canEdit} />
            </div>
            <Box>
              <BoxFile>
                <InputFile
                  id="iconTypePromotion"
                  name="iconTypePromotion"
                  type="file"
                  accept=".jpeg, .jpg, .png"
                  onChange={onChangeIcon}
                  disabled={!canEdit}
                />
                <LabelStyled htmlFor="iconTypePromotion">
                  <SpanButton disabled={!canEdit}>
                    Ícono (max 2MB)
                    {base64
                      ? <FindReplaceIconStyled disabled={!canEdit} />
                      : <PublishIconStyled disabled={!canEdit} />}
                  </SpanButton>
                </LabelStyled>
              </BoxFile>
              {base64 && <img src={`data:image/png;base64,${base64}`} alt="img" width={32} height={32} />}
            </Box>
          </Container>
        )}
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  newPromotion: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default ActionForm;
