/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { TableHead, TableHeaderCell } from './useStyle';
import { Rules } from '../../../helpers/RHFRules';
import { CustomSelect } from '../../../components';
import { BLACK_20 } from '../../../theme/colors';

const DataTable = ({
  data,
  actionsDisabled,
  control,
  errors,
  canEdit,
  withActions,
}) => {
  const [promotions, setPromotions] = useState([]);
  const isToEdit = control && errors;
  const OPTIONS = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ];

  const getLabelStatus = (status, endDate) => {
    let label;
    const currentDate = moment().format('YYYY-MM-DD');
    const isExpired = moment(endDate).isBefore(currentDate);

    if (status === 'activo') label = 'Activo';

    if (status === 'inactivo') label = 'Inactivo';

    if (isExpired) label = 'Expirado';

    return label;
  };

  useEffect(() => {
    if (data) setPromotions(data);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: BLACK_20 }}>
            <TableHeaderCell align="center">Orden</TableHeaderCell>
            <TableHeaderCell align="center">Nombre</TableHeaderCell>
            <TableHeaderCell align="center">Categoría</TableHeaderCell>
            <TableHeaderCell align="center">Comercio</TableHeaderCell>
            <TableHeaderCell align="center">Estatus</TableHeaderCell>
            {withActions && <TableHeaderCell align="center">Acciones</TableHeaderCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.map((row) => (
            <TableRow
              hover={!actionsDisabled}
              key={row.id}
              disabled={actionsDisabled}
            >
              <TableCell align="center">
                {isToEdit
                  ? (
                    <CustomSelect
                      label="Orden*"
                      name={`order/${row.id}`}
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      options={OPTIONS}
                      disabled={!canEdit}
                    />
                  )
                  : row.featureOrder}
              </TableCell>
              <TableCell align="center">{row.promotionName}</TableCell>
              <TableCell align="center">{row.category}</TableCell>
              <TableCell align="center">
                <Tooltip title={row.commerceName} aria-label="name-commerce" placement="bottom">
                  <img src={row.commerceLogo} alt="img" width={50} />
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                {getLabelStatus(row.status, row.endDate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
