/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  EmojiTransportation, EmojiObjects, CellWifi,
} from '@material-ui/icons';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import useStyle from './components/useStyle';
import CategoriesRechargeTable from './components/DataTable/CategoriesRechargeTable';
import ActionForm from './components/ActionForm';
import { Toaster } from '../../components';

const CategoriesRechargeScreen = () => {
  const classes = useStyle();
  const [categoriesRecharge, setCategoriesRecharge] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const defaultData = {
    nameCategory: '',
    icon: '',
    order: 1,
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getCategoriesPayment = async () => {
    const categoriesData = [
      {
        categoryId: '50002',
        name: 'Servicios de comunicación',
        order: 1,
        status: true,
        url: <CellWifi />,
      },
      {
        categoryId: '20002',
        name: 'Energía',
        order: 2,
        status: true,
        url: <EmojiObjects />,
      },
      {
        categoryId: '40002',
        name: 'Transporte',
        order: 3,
        status: true,
        url: <EmojiTransportation />,
      },
    ];
    return setCategoriesRecharge(categoriesData);
  };

  const openCategoryForm = () => {
    let initialOrderValue = 1;
    if (categoriesRecharge.length > 0) {
      for (let v = 1; v < 100; v += 1) {
        const usedOrders = categoriesRecharge.find((category) => category.order === v);
        if (usedOrders === undefined) {
          initialOrderValue = v;
          break;
        }
      }
      setDefaultValues({ ...defaultData, order: initialOrderValue });
    }
    setPopUpAction({
      open: true,
      title: 'Crear nueva categoria',
      data: null,
    });
  };

  useEffect(() => {
    getCategoriesPayment();
  }, []);

  return (
    <>
      <Tooltip title="Refrescar página" aria-label="Refrescar página">
        <IconButton
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
          disabled
        >
          <img src={reload} alt="reload" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
        <IconButton
          onClick={openCategoryForm}
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
          disabled
        >
          <img src={newButton} alt="newButton" />
        </IconButton>
      </Tooltip>
      <div className={classes.root}>
        <CategoriesRechargeTable
          setCategoriesPayment={setCategoriesRecharge}
          categories={categoriesRecharge}
          setDefaultValues={setDefaultValues}
          setPopUpAction={setPopUpAction}
        />
        <ActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          categories={categoriesRecharge}
          title={popUpAction.title}
          setDefaultValues={setDefaultValues}
          defaultValues={
          {
            ...defaultValues,
          }
        }
          getCategories={getCategoriesPayment}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
          }}
          setAlertHome={setAlert}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </div>
    </>
  );
};

export default CategoriesRechargeScreen;
