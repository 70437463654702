/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import { Alert, Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import { ButtonVersion } from './styles';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import {
  getVersionsCommerceService, getCurrentVersionCommerceService,
} from '../../services';
import { Toaster, Loading } from '../../components';
import VersionTable from './components/DataTable/VersionsTable.readonly';
import sortVersions from '../../utils/sortVersions';

const AppCommerceVersionScreen = () => {
  const classes = useStyle();
  const chunkSize = 80;
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [totalElementsData, setTotalElementsData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentChunk, setCurrentChunk] = useState(0);

  const handleNext = () => {
    if (versions.length === totalElementsData) return;
    if (
      versions.length < (currentPage + 2) * currentPageSize
    ) {
      setCurrentChunk((oldChunk) => oldChunk + 1);
    }
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handleNewPageSize = (newPageSize) => {
    setCurrentPageSize(Number(newPageSize));
    setCurrentPage(0);
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([Auth.currentAuthenticatedUser(), getVersionsCommerceService()])
      .then(([, version]) => {
        setVersions(sortVersions(version?.data, true));
        setLoading(false);
        setTotalElementsData(version?.data.length);
      })
      .catch(() => {
        setLoading(false);
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      });
  };

  const loadCurrentVersion = () => {
    setLoading(true);
    getCurrentVersionCommerceService().then((currVersion) => {
      setCurrentVersion(currVersion?.data);
    }).catch((e) => {
      setLoading(false);
      if (e?.response.status !== 404) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      } else {
        setCurrentVersion('');
      }
    });
  };

  useEffect(() => {
    loadData();
    loadCurrentVersion();
  }, [currentChunk, chunkSize]);

  const alertMessage = () => {
    if (currentVersion) {
      const { version, date_modified: dateModified, updated_by: updatedBy } = currentVersion;
      const date = new Date(dateModified);
      const dateFormated = format(date, 'dd/MM/yyyy H:mm');
      return (
        <Alert severity="info">
          {'Version mínima requerida de App Comercios: '}
          <b>{version}</b>
          {updatedBy && ` actualizada por ${updatedBy} el ${dateFormated}`}
        </Alert>
      );
    }
    return null;
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        {loading
          ? <Loading />
          : (
            <>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12}>
                  <Box mb={1}>
                    {alertMessage()}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    disablePortal
                    options={versions}
                    getOptionLabel={(row) => `${row.version} - ${row.title}`}
                    renderInput={(params) => <TextField {...params} label="Seleccionar versión de App Comercios" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ButtonVersion className={classes.btnReadOnly}>
                    Actualizar
                  </ButtonVersion>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  {versions.length > 0 ? (
                    <VersionTable
                      versions={versions}
                      onNext={handleNext}
                      onSelectPageSize={handleNewPageSize}
                      loading={loading}
                      totalElementsData={totalElementsData}
                    />
                  ) : (
                    <Alert severity="warning">
                      No hay versiones por mostrar en el momento
                    </Alert>
                  )}
                </Box>
              </Grid>
              <Toaster
                show={alert.status}
                type={alert.type}
                text={alert.message}
                onClose={() => setAlert({ ...alert, status: false })}
              />
            </>
          )}
      </Paper>
    </InternalPage>
  );
};

export default AppCommerceVersionScreen;
