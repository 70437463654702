/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  InputStyled,
  LabelWrap,
  Switch,
  Content,
} from './styles';

const CustomSwitch = ({
  check, handleChange, lblOff, lblOn, ...other
}) => (
  <Content>
    {lblOff}
    <LabelWrap>
      <InputStyled type="checkbox" onChange={handleChange} {...other} />
      <Switch checked={other.checked} disabled={other.disabled} />
    </LabelWrap>
    {lblOn}
  </Content>
);
export default CustomSwitch;
