import httpInstance from '../httpInstance';

const createDeviceService = async (dataParam) => {
  let res;
  const endpoint = '/card-acceptance/v1/devices';
  const dataSent = dataParam;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default createDeviceService;
