import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const DivImage = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const ImgSearch = styled.img`
  padding-left: 10px;
`;

const tableStyle = makeStyles(() => ({
  seeIcon: {
    color: '#50B940',
  },
  downloadIcon: {
    color: '#449FDD',
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
}));

export default tableStyle;
