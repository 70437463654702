import httpInstance from '../httpInstance';

const deleteNationalHolidaysService = async (holidayId) => {
  let res;
  const endpoint = `/back-office/v1/holidays/delete/${holidayId}`;
  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default deleteNationalHolidaysService;
