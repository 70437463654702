export const FILTER_OPTIONS = {
  DOCUMENT_NUMBER: 'RNC/Cédula',
  COMMERCE_NAME: 'Nombre del comercio',
  COMMERCE_ID: 'ID del comercio',
};

export const FILTER_OPTIONS_BUTTONS = [
  { id: '1', value: FILTER_OPTIONS.DOCUMENT_NUMBER },
  { id: '2', value: FILTER_OPTIONS.COMMERCE_NAME },
  { id: '3', value: FILTER_OPTIONS.COMMERCE_ID },
];
