// eslint-disable-next-line import/no-extraneous-dependencies
import httpInstance from '../httpInstance';
import Config from '../../config';

export const createPromoAppService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/promotions';
  const dataSent = dataParam;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const getPromotionsApp = async () => {
  let res;
  const endpoint = '/back-office/v1/promotions';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    if (data.status === 200) {
      res = data.data;
    }
  }).catch(() => {
    res = [];
  });
  return res;
};

export const deletePromoByIdService = async (id) => {
  let res;
  const endpoint = `/back-office/v1/promotions/${id}`;
  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const updatePromoService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/promotions/';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const getLocationAddress = async (address) => {
  const mapsUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${Config.MAPS_API_KEY}`;

  const response = await fetch(mapsUrl).catch(() => Promise.reject(new Error('Error fetching data')));

  const json = await response.json().catch(() => Promise.reject(new Error('Error parsing server response')));

  if (json.status === 'OK' && json.results.length > 0) {
    return json.results[0].geometry;
  }
  return Promise.reject(
    new Error(
      `${json.error_message}.\nServer returned status code ${json.status}`,
    ),
  );
};

export default {
  getPromotionsApp,
  createPromoAppService,
  deletePromoByIdService,
  updatePromoService,
  getLocationAddress,
};
