import httpInstance from '../httpInstance';

const getCardNumber = async (documentNumber) => {
  let res;
  const endpoint = `/account/v1/account/user-info?documentNumber=${documentNumber}`;

  await httpInstance.get(endpoint).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getCardNumber;
