/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { CustomTable, Toaster } from '../../components';
import { getRemittanceConfig } from '../../services/remittances/getRemittanceConfig';
import ActionForm from './components/ActionForm';
import { InternalPage } from '../../Layouts';

const RemittanceDateOverdue = () => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [dataRemittance, setDataRemittance] = useState([]);
  const [dataReminderSMS, setDataReminderSMS] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });

  const {
    getRemittance,
    data,
    loading,
  } = getRemittanceConfig();

  const openModalEditProvider = async (id) => {
    setPopUpAction({
      open: true,
      title: 'Editar',
      data: {
        id,
      },
    });
  };

  useEffect(() => {
    getRemittance();
  }, []);

  function createActions(id) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditProvider(id)}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  useEffect(() => {
    if (data) {
      const filterRemittanceDate = data.filter((item) => item.id === '1').map((item) => ({ days: item.value, actions: createActions(item.id) }));
      const filterReminderSMSDays = data.filter((item) => item.id === '2').map((item) => ({ days: item.value, actions: createActions(item.id) }));
      setDataRemittance(filterRemittanceDate);
      setDataReminderSMS(filterReminderSMSDays);
    }
  }, [data]);

  const columnsRemittanceOverdue = [
    {
      id: 'days',
      label: 'Días vencimiento remesas',
      paddingLeft: '40px',
      width: 150,
      position: 1,
    },
    {
      id: 'actions',
      label: 'Acciones',
      paddingLeft: '40px',
      width: 150,
      position: 2,
    },
  ];

  const columnsSMSReminder = [
    {
      id: 'days',
      label: 'SMS Vencimiento Remesas',
      paddingLeft: '40px',
      width: 150,
      position: 1,
    },
    {
      id: 'actions',
      label: 'Acciones',
      paddingLeft: '40px',
      width: 150,
      position: 2,
    },
  ];

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <CustomTable
        data={dataRemittance}
        columns={columnsRemittanceOverdue}
      />
      <CustomTable
        data={dataReminderSMS}
        columns={columnsSMSReminder}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
          getRemittance();
        }}
        setAlert={setAlert}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default RemittanceDateOverdue;
