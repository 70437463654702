/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import useStyle from './useStyle';
import {
  Toaster, ProgressBar, Loading, CustomTable,
} from '../../../../components';
import updatePromotionService from '../../../../services/promotions/updatePromotionService';
import { csvFileToArray, getMoneyFormat } from '../../../../utils';
import { ActionPopUp } from '../../../../Layouts';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
  setAlertHome,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [invalidCsv, setInvalidCsv] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState();
  const [fileB64, setFileB64] = useState('');
  const [startBarProgressBig, setStartBarProgressBig] = useState(false);
  const providerRef = React.useRef();
  const classes = useStyle();
  const [openDrop, setOpenDrop] = React.useState(false);
  const [csvData, setCsvData] = useState([]);
  const [registers, setRegisters] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileReader = new FileReader();
  const [promotionsRows, setPromotionsRows] = useState([]);

  const columns = [
    {
      id: 'documentNumber', label: 'Número de Documento', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'amount', label: 'Monto', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'promoName',
      label: 'Nombre de la promoción',
      paddingLeft: '40px',
      width: 150,
      position: 3,
    },
  ];

  const {
    handleSubmit, errors, reset, clearErrors,
  } = useForm({ defaultValues });

  const resetFields = () => {
    setInvalidCsv('');
    setFileName('');
    setCsvData([]);
    setRegisters(0);
    setTotalAmount(0);
    setChecked(false);
    setFileB64('');
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };

  const updatePromotionFile = async () => {
    setLoading(true);
    const promoData = {
      file_name: fileName,
      base64: fileB64,
      user_summary: registers,
      total_amount: totalAmount,
    };
    const res = await updatePromotionService({ ...promoData })
      .catch(() => {
        setAlertHome({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        return false;
      });
    if (res?.status >= 300) {
      if (res?.data.details[0].error_code === 'cashinPromo.csv.promoName.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Nombre de promoción inválido' });
        return false;
      }
      if (res?.data.details[0].error_code === 'cashinPromo.csv.amount.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Monto inválido' });
        return false;
      }
      if (res?.data.details[0].error_code === 'cashinPromo.csv.documentNumber.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Número de documento inválido' });
        return false;
      }
      if (res?.data.details[0].error_code === 'cashinPromo.rowsNumber.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Número de filas inválidas' });
        return false;
      }
      if (res?.data.details[0].error_code === 'cashinPromo.columnsNumber.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Número de columnas inválidas' });
        return false;
      }
      if (res?.data.details[0].error_code === 'cashinPromo.fileSize.invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Tamaño del archivo inválido' });
        return false;
      }
      if (res?.data.details[0].error_code === 'ext-type-invalid') {
        setAlertHome({ status: true, type: 'error', message: 'Extensión inválida' });
        return false;
      }
      if (res?.data.details[0].error_code === 's3object-error-exists') {
        setAlertHome({ status: true, type: 'error', message: `Ya existe un archivo con el nombre ${fileName}` });
        return false;
      }
      setAlertHome({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    setOpenDrop(true);
    setLoading(true);
    // Logic to save and send file to backend
    if (csvData && checked && invalidCsv === '') {
      const res = await updatePromotionFile();
      setLoading(false);
      if (res) {
        setAlertHome({ status: true, type: 'success', message: 'Archivo subido exitosamente' });
        handleOnclose();
        setOpenDrop(false);
      } else {
        handleOnclose();
        setOpenDrop(false);
      }
    } else {
      setAlert({ status: true, type: 'error', message: 'Debes de validar los datos antes de continuar' });
    }
  };

  useEffect(() => {
    if (fileName !== '') {
      clearErrors('file');
    }
  }, [fileName]);

  const setArray = (string) => {
    const arr = csvFileToArray(string);
    setCsvData(arr);
    return arr;
  };

  const onFileUpload = (event) => {
    // Get the actual file itself
    const currentFile = event.target.files[0];
    setFile(file);
    event.preventDefault();
    setInvalidCsv('');

    // Validations
    if (!currentFile.name.toLowerCase().match(/\.(csv)$/)) {
      setInvalidCsv('Debe cargar un archivo valido (csv).');
      return false;
    }
    if (currentFile.size > 5000000) {
      setInvalidCsv('El tamaño del archivo supera el máximo permitido (5MB).');
      return false;
    }
    setStartBarProgressBig(true);

    setFileName(currentFile !== null && currentFile.name !== null ? currentFile.name : '');
    setTimeout(() => {
      if (currentFile && currentFile.name !== '') {
        setStartBarProgressBig(false);
      }
    }, 2000);

    if (currentFile) {
      fileReader.readAsText(currentFile);
      fileReader.onload = (e) => {
        const text = e.target.result;
        const currentData = setArray(text);
        if (currentData.length > 0) {
          if ('documentNumber' in currentData[0] && 'amount' in currentData[0] && 'promoName' in currentData[0]) {
            setRegisters(currentData.length);
            let amount = 0;
            let invalidLengthPromo = false;
            let invalidCharPromo = false;
            currentData.forEach((element) => {
              amount += Number(element.amount);
              if (element.promoName.length > 100 && !invalidLengthPromo) {
                invalidLengthPromo = true;
              }
              const regex = /^[ a-zA-ZñÑáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ0-9@&.\\/\\'\\-]*$/gi;
              const test = regex.test(element.promoName);
              if (!test && !invalidCharPromo) {
                invalidCharPromo = true;
              }
            });
            if (invalidLengthPromo) {
              setInvalidCsv('Nombre de la promoción no debe exceder los 100 caracteres.');
              setAlert({ status: true, type: 'error', message: 'Nombre de la promoción no debe exceder los 100 caracteres.' });
            } else if (invalidCharPromo) {
              setInvalidCsv('Nombre de la promoción contiene caracteres especiales no permitidos.');
              setAlert({ status: true, type: 'error', message: 'Nombre de la promoción contiene caracteres especiales no permitidos.\nLos caracteres permitidos son letras (mayúsculas y minúsculas), números, guiones y espacios.' });
            } else {
              setTotalAmount(amount);
            }
          } else {
            setInvalidCsv('Debe cargar un archivo con los nombres de las columnas válidos (documentNumber, amount, promoName).');
          }
        }
      };
      const reader = new FileReader();
      reader.readAsDataURL(currentFile);
      reader.onload = () => {
        setFileB64(reader.result.split('base64,')[1]);
      };
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  const handleCheckbox = () => {
    setChecked(!checked);
  };

  const mapCodes = () => {
    const promotionsInfo = [];
    csvData?.forEach((promotion, index) => {
      const amountRow = getMoneyFormat(promotion?.amount);
      const promotionRow = {
        id: promotion.documentNumber + index,
        documentNumber: promotion.documentNumber,
        amount: amountRow,
        promoName: promotion.promoName,
        active: true,
      };
      promotionsInfo.push(promotionRow);
    });
    setPromotionsRows(promotionsInfo);
  };

  useEffect(() => {
    setPromotionsRows([]);
    mapCodes();
  }, [csvData]);

  return (
    <SnackbarProvider ref={providerRef}>
      {(openDrop || loading)
        && <Loading />}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <div className={classes.title}>
            Cargar Archivo .csv para promociones
          </div>
          <Grid container>
            <Grid item sm={12}>
              <form noValidate autoComplete="off">
                <div>
                  <Box display="flex" className={classes.uploadImage}>
                    <label htmlFor="fileSupportBig">
                      <input
                        onChange={onFileUpload}
                        id="fileSupportBig"
                        accept=".csv"
                        type="file"
                        style={{ display: 'none' }}
                      />
                      <Button
                        color="inherit"
                        variant="contained"
                        component="span"
                        className={classes.buttonImage}
                        endIcon={<AddCircle htmlColor="#3f51b5" className={classes.mat_icon} />}
                      >
                        Cargar archivo .csv
                      </Button>
                      {invalidCsv && (
                      <Typography className={classes.formErrors}>
                        {invalidCsv}
                      </Typography>
                      )}
                    </label>
                    <Box style={{ width: '100%', paddingLeft: 15 }}>
                      <Typography className={classes.formLabel}>
                        {fileName || (data?.image_url ? data.image_url : '')}
                      </Typography>
                      <Box style={{ width: '70%' }}>
                        <ProgressBar
                          startBarProgress={startBarProgressBig}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {
                  Object.keys(errors).length > 0 && fileName === '' && data === null
                  && (
                    <>
                      <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>
                        Campo
                        obligatorio
                      </div>
                    </>
                  )
                }
                </div>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
              </form>

              {csvData.length > 0 && (
              <div className={classes.checkboxContainer}>
                <div>Validación de datos</div>
                <div className={classes.validateData}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckbox}
                  />
                  {' '}
                  Confirmo que los datos son correctos
                </div>
              </div>
              )}

              <Box className={classes.uploadImage}>
                {csvData.length > 0 && (
                <div>
                  <div className={classes.checkboxContainer}>Información</div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow key="header">
                          <TableCell className={classes.tableCellHead}>Nombre del archivo</TableCell>
                          <TableCell className={classes.tableCellHead}>Número de registros</TableCell>
                          <TableCell className={classes.tableCellHead}>Monto total</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableCell}>{fileName}</TableCell>
                          <TableCell className={classes.tableCell}>{registers}</TableCell>
                          <TableCell className={classes.tableCell}>{getMoneyFormat(totalAmount)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                )}
              </Box>

              <Box className={classes.uploadImage}>
                {csvData.length > 0 && (
                <div>
                  <div className={classes.checkboxContainer}>
                    Contenido
                  </div>
                  <CustomTable
                    data={promotionsRows}
                    columns={columns}
                    readOnly={false}
                  />
                </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
