/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import { IconButton } from '../../styles';

const DistributionCenterTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction,
    enteredFilter,
    distributionCenters,
    setDefaultValues,
  } = props;

  const [distributionCenterRows, setDistributionCenterRows] = React.useState([]);
  const [distributionCenterListCopy, setDistrbutionCenterListCopy] = React.useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const distributionCenterList = codesMapped != null ? codesMapped : distributionCenterListCopy;
    setDistributionCenterRows(
      distributionCenterList.filter(
        (distributionCenterParam) => distributionCenterParam.name
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || distributionCenterParam.streetAddress
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || distributionCenterParam.description
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0,
      ),
    );
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const openModalEditDistributionCenter = (bannerIdSelected) => {
    const dataDistributionCenter = distributionCenters.find(
      (bannerRow) => bannerRow.id === bannerIdSelected,
    );
    setDefaultValues(dataDistributionCenter);
    setPopUpAction({
      open: true,
      title: 'Centro de Distribución - ReadOnly',
      data: dataDistributionCenter,
    });
  };

  function createActions(active, distributionCenterId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Cambiar estado"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              value={distributionCenterId}
              name="active"
              disabled
            />
          </Box>
        </Tooltip>
        <IconButton aria-label="Ver" onClick={() => openModalEditDistributionCenter(distributionCenterId)}>
          <Tooltip title="Ver" aria-label="edit" placement="top">
            <VisibilityIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton aria-label="Delete" disabled>
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(distributionCenterParam) {
    const distributionCenterInfo = [];
    distributionCenterParam?.forEach((distributionCenter) => {
      const {
        id,
        active,
        name,
        description,
        streetAddress,
        phone,
        postalCode,
        city,
        country,
        latitude,
        longitude,
        openingTime,
        closingTime,
        observations,
        availableTime,
        deliveryTypes,
      } = distributionCenter;
      const location = [latitude, longitude].join(', ');
      const distributionCenterRow = {
        name,
        description,
        streetAddress,
        phone,
        postalCode,
        city,
        country,
        latitude,
        longitude,
        openingTime,
        closingTime,
        observations,
        coordinates: location,
        availableTime,
        deliveryTypes: deliveryTypes.join(', '),
        actions: createActions(
          active,
          id,
        ),
        id,
        active: 1,
      };
      distributionCenterInfo.push(distributionCenterRow);
    });
    setDistributionCenterRows(distributionCenterInfo);
    setDistrbutionCenterListCopy(distributionCenterInfo);
    if (enteredFilter !== '') { filterInfoTable(enteredFilter, distributionCenterInfo); }
  }

  useEffect(() => {
    setDistributionCenterRows([]);
    mapCodes(distributionCenters);
  }, [distributionCenters]);

  const columns = [
    {
      id: 'name',
      label: 'Nombre',
    },
    {
      id: 'streetAddress',
      label: 'Dirección',
    },
    {
      id: 'availableTime',
      label: 'Horario',
    },
    {
      id: 'phone',
      label: 'Tel.',
    },
    {
      id: 'deliveryTypes',
      label: 'Tipo',
    },
    {
      id: 'coordinates',
      label: 'Coordenadas',
    },
    {
      id: 'actions',
      label: 'Acciones',
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={distributionCenterRows}
        columns={columns}
        readOnly
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default DistributionCenterTable;
