/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
  useState,
  Fragment,
} from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { InternalPage } from '../../Layouts';
import { Card, IconButtonStyled } from './styles';
import { ReadOnlyDataTable } from './components';
import newButton from '../../assets/img/iconos/newButton.svg';
import { getAllSegmentations } from '../../services';
import { Toaster, Loading } from '../../components';
import { INIT } from './constants';

const SegmentationScreen = () => {
  const [segmentations, setSegmentations] = useState(INIT);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const onInit = async () => {
    setLoading(true);
    const res = await getAllSegmentations()
      .catch(() => false)
      .finally(() => setLoading(false));

    if (res) {
      setSegmentations(res.data);
    } else {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <InternalPage loading={false} error={alert.type === 'error'}>
      {loading && <Loading />}
      <Card>
        <div>
          <Tooltip title="Crear banner" aria-label="Nuevo banner">
            <IconButtonStyled edge="end" size="small" disabled>
              <img src={newButton} alt="newButton" style={{ opacity: 0.4 }} />
            </IconButtonStyled>
          </Tooltip>
        </div>
        {segmentations.length === 0 && <Typography variant="h5" component="h5">Aún no hay segmentos registrados</Typography>}
        {segmentations.map((segmentation, i) => (
          <Fragment key={`${segmentation.id}-${i}`}>
            <Box marginY={2}>
              <ReadOnlyDataTable
                data={segmentation}
                index={i + 1}
              />
            </Box>
          </Fragment>
        ))}
      </Card>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default SegmentationScreen;
