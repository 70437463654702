/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-dynamic-require
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import DivImage from '../tableStyle';

const CategoriesPaymentTable = (props) => {
  const classes = useStyle();
  const {
    categoriesData, setDefaultValues, setPopUpAction, updateCategory,
    getCategories,
  } = props;
  const [categoriesRows, setCategoriesRows] = React.useState([]);
  const providerRef = React.useRef();

  const openModalEditCategory = (bannerIdSelected) => {
    const categoryEdit = categoriesData.find(
      (bannerRow) => bannerRow.categoryId === bannerIdSelected,
    );

    setDefaultValues({
      nameCategory: categoryEdit.creditorAgent,
      fileIcon: '',
      order: categoryEdit.orderId,
      id: categoryEdit.id,
      categoryId: categoryEdit.categoryId,
      categoryType: categoryEdit.categoryType,
      vendor: categoryEdit.vendor,
      vendorId: categoryEdit.vendorId,
      categoryIdUrlImg: categoryEdit.categoryIdUrlImg,
      description: categoryEdit.description,
      label: categoryEdit.label,
      status: categoryEdit.status,
      orderId: categoryEdit.orderId,
      serviceId: categoryEdit.serviceId,
      serviceType: categoryEdit.serviceType,

    });
    setPopUpAction({
      open: true,
      title: 'Editar categoría',
      data: categoryEdit,
    });
  };

  function createActions(active, categoryId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditCategory(categoryId)}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          disabled
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  const updateStatus = async (item) => {
    const body = {
      id: item.id,
      categoryId: item.categoryId,
      status: !item.status,
    };
    await updateCategory(body);
    await getCategories();
  };

  const createStatusSwitch = (categoryParam) => (
    <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
      <Box alignSelf="center">
        <CustomSwitch
          checked={categoryParam.status}
          handleChange={() => {}}
          onChange={() => updateStatus(categoryParam)}
          name="active"
        />
      </Box>
    </Tooltip>
  );

  function createImage(url) {
    return (
      <DivImage url={url}>
        {url && <img src={url} alt="" />}
      </DivImage>
    );
  }

  const mapCodes = async (categoriesParam) => {
    let categoryInfo = [];
    if (categoriesParam) {
      categoryInfo = categoriesParam.filter((category) => category.categoryType === 'Pagos' || category.categoryType === 'PagosRecargas').map((category) => ({
        id: category.categoryId,
        name: category.creditorAgent,
        order: category.orderId,
        icon: createImage(category.categoryIdUrlImg),
        actions: createActions(category.status, category.categoryId),
        state: createStatusSwitch(category),
        vendor: category.vendor,
        active: 1,
      }));
    }
    setCategoriesRows(categoryInfo);
  };

  useEffect(async () => {
    mapCodes(categoriesData);
  }, [categoriesData]);

  const columns = [
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'icon', label: 'Icono', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'order', label: 'Orden', paddingLeft: '40px', width: 150, position: 3,
    },
    {
      id: 'vendor', label: 'Facturador', paddingLeft: '40px', width: 150, position: 3,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 4,
    },
    {
      id: 'state',
      paddingLeft: '',
      label: 'Estado',
      width: 5,
      position: 5,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={categoriesRows}
        columns={columns}
      />
    </SnackbarProvider>
  );
};

export default CategoriesPaymentTable;
