import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    width: '52%',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperCard: {
    backgroundColor: '#BAB6B6',
    paddingLeft: 10,
    paddingRight: 10,
  },
  textFieldShape: {
    width: '100px',
    marginLeft: '3px',
  },
}));

export default useStyle;
