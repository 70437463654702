import fileDownload from 'js-file-download';
import httpInstance from '../httpInstance';

const downloadPromotionService = async (endpoint, fileName, type) => {
  let res;
  if (type === 'blob') {
    await httpInstance.get(
      endpoint,
      {
        responseType: 'blob',
      },
    ).then((response) => {
      fileDownload(response.data, fileName);
    }).catch((err) => {
      res = err.response;
    });
  } else {
    await httpInstance.get(
      endpoint,
    ).then((response) => {
      res = response.data;
    }).catch((err) => {
      res = err.response;
    });
  }

  return res;
};

export default downloadPromotionService;
