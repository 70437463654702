/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CenteredText = styled.h1`
  text-align: center;
`;

export const ImageStyled = styled.img`
  heigth: 40px;
  width: 40px;
`;
