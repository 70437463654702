/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import moment from 'moment';
import Image from 'material-ui-image';
import { yellow } from '@material-ui/core/colors';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import StarTwoTone from '@material-ui/icons/StarTwoTone';
import { CustomTable, Toaster } from '../../../components';
import tableStyle, { DivImage } from './tableStyle';
import { columns } from './constants';
import AlertConfirm from '../../../components/Crosscutting/AlertConfirm';
import { deletePromoByIdService } from '../../../services';

const PromotionsTable = (props) => {
  const providerRef = React.useRef();
  const {
    promotions, actionsDisabled, setDefaultValues, getPromotions, setPopUpAction, enteredFilter,
    categories, typePromotions,
  } = props;
  const [promotionsRows, setPromotionsRows] = useState([]);
  const [promoListCopy, setPromoListCopy] = React.useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [dataAction, setDataAction] = useState(null);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = tableStyle();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const promoList = codesMapped != null ? codesMapped : promoListCopy;
    setPromotionsRows(promoList.filter(
      (promo) => promo.promotionName
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promo.description
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promo.category
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promo.promotionType
          .indexOf(enteredFilterParam) >= 0
        || promo.commerceName
          .indexOf(enteredFilterParam) >= 0
        || promo.startDate
          .indexOf(enteredFilterParam) >= 0
        || promo.status
          .indexOf(enteredFilterParam) >= 0
        || promo.id
          .indexOf(enteredFilterParam) >= 0
        || enteredFilterParam === '',
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleDeletePromo = (categoryId, status) => {
    if (status !== 'activo') {
      setDataAction(categoryId);
      setOpenAlertConfirm(true);
    } else {
      setAlert({ status: true, type: 'error', message: 'No es posible eliminar una promoción activa.' });
    }
  };

  const openModalEdit = (promoIdSelected) => {
    const promoEdit = promotions.find((promoRow) => promoRow.id === promoIdSelected);
    const categoryData = categories.find(
      (categoryRow) => categoryRow.label === promoEdit.category,
    );

    const typepromoData = typePromotions.find(
      (typeRow) => typeRow.label === promoEdit.promotionType,
    );

    const editData = {
      id: promoEdit.id,
      highlight: promoEdit.highlight,
      categoryOrder: promoEdit.categoryOrder,
      featureOrder: 1,
      promotionName: promoEdit.promotionName,
      commerceName: promoEdit.commerceName,
      commerceLogo: promoEdit.commerceLogo,
      description: promoEdit.description,
      startDate: promoEdit.startDate,
      endDate: promoEdit.endDate,
      details: promoEdit.details,
      imageBig_base64: promoEdit.imageBig_base64,
      imageLit_base64: promoEdit.imageLit_base64,
      featureImage_base64: promoEdit.featureImage_base64,
      category: (categoryData && categoryData.value) || categories[0].value,
      promotionType: (typepromoData && typepromoData.value) || typePromotions[0].value,
      commerceLocation: {
        address: promoEdit.commerceLocation.address,
        geolocation: promoEdit.commerceLocation.geolocation,
        virtualCommerce: promoEdit.commerceLocation.virtualCommerce,
      },
      address: promoEdit.commerceLocation.address || '_',
      lat: promoEdit.commerceLocation.latitude,
      long: promoEdit.commerceLocation.longitude,
      contactInformation: {
        facebook: promoEdit.contactInformation.facebook,
        instagram: promoEdit.contactInformation.instagram,
        phone: promoEdit.contactInformation.phone,
        whatsapp: promoEdit.contactInformation.whatsapp,
        webPage: promoEdit.contactInformation.webPage,
      },
      phone: promoEdit.contactInformation.phone || '',
      weburl: promoEdit.contactInformation.webPage || '',
      whatsapp: promoEdit.contactInformation.whatsapp || '',
      facebook: promoEdit.contactInformation.facebook,
      instagram: promoEdit.contactInformation.instagram,
      status: promoEdit.status,
      featurePromotion: promoEdit.featurePromotion,
    };
    setDefaultValues({ ...editData });
    setTimeout(() => {
      setPopUpAction({
        open: true,
        title: 'Editar Promoción',
        data: editData,
      });
    }, 500);
  };

  const deletePromotion = async () => {
    const res = await deletePromoByIdService(dataAction)
      .catch(() => {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        setOpenAlertConfirm(false);
      });
    if (res?.status !== 200) {
      setOpenAlertConfirm(false);
      getPromotions();
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      return;
    }
    providerRef.current.enqueueSnackbar('Se ha eliminado la promoción exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    setOpenAlertConfirm(false);
    getPromotions();
  };

  function createActions(categoryId, promoEstatus) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEdit(categoryId)}
          style={{ marginLeft: 0 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <EyeIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeletePromo(categoryId, promoEstatus)}
          disabled={actionsDisabled}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }
  function createImage(url) {
    const aspectRatio = 60 / 40;
    return (
      <DivImage>
        <Image
          src={url}
          style={{ width: 60 }}
          aspectRatio={aspectRatio}
        />
      </DivImage>
    );
  }

  function mapCodes(promotionMap) {
    const categoriesInfo = [];
    promotionMap?.forEach((promo) => {
      const promoStatus = moment().format('MM/DD/yyyy') > moment(promo.endDate).format('MM/DD/yyyy') ? 'expirado' : promo.status;
      const categoryRow = {
        // eslint-disable-next-line prefer-template
        id: (promo.id).split('-')[0],
        promotionName: promo.promotionName,
        commerceName: promo.commerceName,
        commerceLogo: createImage(promo.commerceLogo),
        description: promo.description,
        startDate: promo.startDate,
        endDate: promo.endDate,
        details: promo.details,
        category: promo.category,
        promotionType: promo.promotionType,
        status: promoStatus,
        featurePromotion: promo.featurePromotion ? <StarTwoTone fontSize="large" style={{ color: yellow[500] }} /> : '',
        actions: createActions(promo.id, promoStatus),
        active: !actionsDisabled,
      };
      categoriesInfo.push(categoryRow);
    });
    setPromoListCopy(categoriesInfo);
    setPromotionsRows(categoriesInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, categoriesInfo);
  }

  useEffect(() => {
    setPromotionsRows([]);
    mapCodes(promotions);
  }, [promotions]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={promotionsRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Eliminar"
        textButtonAcept="Eliminar"
        colorButtonAcept="secondary"
        mensajeModal="¿Está seguro que quiere eliminar esta promoción?"
        handleAccept={() => deletePromotion()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default PromotionsTable;
