/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import useStyle from './useStyle';
import ProvidersPaymentTable from './components/DataTable/ProvidersPaymentTable';
import {
  getProvidersPaymentService,
  getCategoriesPaymentService,
  updateProviderPaymentService,
} from '../../services';
import ActionForm from './components/ActionForm';
import { Toaster, Loading } from '../../components';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';

const ProvidersPaymentScreen = () => {
  const classes = useStyle();
  const [providersPayment, setProvidersPayment] = useState([]);
  const [categoriesPayment, setCategoriesPayment] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });

  const defaultData = {
    reference: '',
    category: '',
    vendor: '',
    paymentMethod: '',
    partialPayment: false,
    beneficiaryId: '',
    beneficiaryType: '',
    status: 1,
  };

  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getProvidersPayment = async () => {
    if (providersPayment.length === 0) {
      setLoading(true);
    }
    const res = await getProvidersPaymentService();
    setLoading(false);
    const filteredData = res?.data?.data.filter((item) => item.serviceType === 'services');
    return setProvidersPayment(filteredData);
  };

  const updateProvider = async (category) => {
    const res = await updateProviderPaymentService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const getCategoriesPayment = async () => {
    const res = await getCategoriesPaymentService();
    const categoriesFilter = res.data.filter((category) => category.categoryType === 'Pagos').map((categoryKey) => ({
      value: categoryKey.categoryId,
      label: `${categoryKey.creditorAgent} ${categoryKey.vendor}`,
    }));
    setCategories(res.data.filter((category) => category.categoryType === 'Pagos'));
    return setCategoriesPayment(categoriesFilter);
  };

  useEffect(() => {
    getProvidersPayment();
    getCategoriesPayment();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <Tooltip title="Refrescar página" aria-label="Refrescar página">
          <IconButton
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
            disabled
          >
            <img src={reload} alt="reload" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
          <IconButton
            onClick={() => setPopUpAction({
              open: true,
              title: 'Crear nuevo proveedor',
              data: {},
            })}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
          >
            <img src={newButton} alt="newButton" />
          </IconButton>
        </Tooltip>
        <div className={classes.root}>
          <ProvidersPaymentTable
            setProvidersPayment={setProvidersPayment}
            providers={providersPayment}
            setDefaultValues={setDefaultValues}
            setPopUpAction={setPopUpAction}
            updateProvider={updateProvider}
            getProviders={getProvidersPayment}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            providers={providersPayment}
            title={popUpAction.title}
            setDefaultValues={setDefaultValues}
            categoriesOptions={categoriesPayment}
            categories={categories}
            defaultValues={defaultValues}
            getProviders={getProvidersPayment}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
            }}
            setAlertHome={setAlert}
            updateProvider={updateProvider}
          />
          <Toaster
            show={alert?.status}
            type={alert.type}
            text={alert.message}
            onClose={() => setAlert({ ...alert, status: false })}
          />
        </div>
      </>
    </Paper>
  );
};

export default ProvidersPaymentScreen;
