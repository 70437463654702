import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  uploadImage: {
    paddingBottom: 10,
  },
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#3f51b5',
    },
  },
  buttonImage: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #3f51b5',
    borderRadius: '5px',
    opacity: 1,
    width: '256px',
    height: '50px',
    letterSpacing: '0px',
    color: '#3f51b5',
    marginLeft: '20px',
  },
  formLabel: {
    color: 'var(--unnamed-color-575756)',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: 1,
  },
  mat_icon: {
    height: '15px',
    width: '15px',
  },
  title: {
    marginLeft: '20px',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '25px',
  },
  checkboxContainer: {
    marginTop: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginBottom: '20px',
    flex: 'end',
  },
  fileData: {
    marginLeft: '20px',
  },
  validateData: {
    fontSize: '15px',
    fontWeight: 'normal',
    marginTop: '15px',
  },
  formErrors: {
    color: '#d61103',
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#50B940',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
  },
}));

export default useStyle;
