import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#50B940',
    },
  },
}));

export default useStyle;
