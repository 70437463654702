/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';

const FeaturesFlagTable = (props) => {
  const classes = useStyle();
  const { enteredFilter, featuresFlag } = props;
  const [featuresFlagRows, setFeaturesFlagRows] = React.useState([]);
  const [featuresFlagListCopy, setFeaturesFlagListCopy] = React.useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const featuresFlagList = codesMapped != null ? codesMapped : featuresFlagListCopy;
    setFeaturesFlagRows(featuresFlagList.filter(
      (featuresFlagParam) => featuresFlagParam.name
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || featuresFlagParam.code
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || featuresFlagParam.description
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  function createActions(active, featureFlagId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              value={featureFlagId}
              name="active"
              disabled
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          classes={{ disabled: classes.disabled }}
          disabled
        >
          <Tooltip title="Ver" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
          disabled
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(featureFlagParam) {
    const featuresFlagInfo = [];
    featureFlagParam?.forEach((featureFlag) => {
      const featureFlagRow = {
        name: featureFlag.name,
        description: featureFlag.description,
        code: featureFlag.code,
        url: featureFlag.url,
        actions: createActions(featureFlag.active, featureFlag.id),
        id: featureFlag.id,
        active: 1,
      };
      featuresFlagInfo.push(featureFlagRow);
    });
    setFeaturesFlagRows(featuresFlagInfo);
    setFeaturesFlagListCopy(featuresFlagInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, featuresFlagInfo);
  }

  useEffect(() => {
    setFeaturesFlagRows([]);
    mapCodes(featuresFlag);
  }, [featuresFlag]);

  const columns = [
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'description', label: 'Descripción', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'code',
      label: 'Código',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'url',
      label: 'Url',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 4,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={featuresFlagRows}
        columns={columns}
        readOnly
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default FeaturesFlagTable;
