import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import useStyle from './useStyle';
import { getCategoriesPaymentService } from '../../services';
import CategoriesPaymentTable from './components/DataTable/CategoriesPaymentTable.readonly';
import { Toaster, Loading } from '../../components';

const CategoriesPaymentScreen = () => {
  const classes = useStyle();
  const [categoriesPayment, setCategoriesPayment] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getCategories = async () => {
    if (categoriesPayment.length === 0) {
      setLoading(true);
    }
    const res = await getCategoriesPaymentService();
    setLoading(false);
    return setCategoriesPayment(res?.data);
  };

  useEffect(async () => {
    await getCategories();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Tooltip title="Refrescar página" aria-label="Refrescar página">
        <IconButton
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
        >
          <img src={reload} alt="reload" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
        <IconButton
          style={{ fontSize: '20px', float: 'right', opacity: 0.4 }}
          edge="end"
          size="small"
          disabled
        >
          <img src={newButton} alt="newButton" />
        </IconButton>
      </Tooltip>
      <div className={classes.root}>
        <CategoriesPaymentTable
          categoriesData={categoriesPayment}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </div>
    </>
  );
};

export default CategoriesPaymentScreen;
