/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    textAlign: 'center',
  },
  titleAlert: {
    color: '#E45501',
    fontWeight: 700,
    fontSize: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    paddingBottom: 20,
  },
  promoForm: {
    flexDirection: 'row',
    border: '1px solid #F1F1F1',
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  ButtonCancel: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const AlertConfirm = (props) => {
  const {
    isOpen,
    onClose,
    showClose = true,
    tituloModal,
    mensajeModal,
    textButtonAcept,
    colorButtonAcept,
    onCancel,
    handleAccept,
    isOnlyMessage,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
      >
        <DialogTitle className={classes.root}>
          <Typography align="center" className={classes.titleAlert}>{tituloModal}</Typography>
          {
            showClose && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
            )
          }
        </DialogTitle>
        <DialogContent className={`${classes.root} ${classes.dialogContent}`}>
          <DialogContentText>
            {mensajeModal}
          </DialogContentText>
          {props.children}
          {!isOnlyMessage
          && (
          <Grid container justifyContent="center">
            {onCancel
              && (
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                className={classes.ButtonCancel}
              >
                Cancelar
              </Button>
              )}
            <Button
              variant="contained"
              color={colorButtonAcept || 'primary'}
              onClick={handleAccept}
            >
              {textButtonAcept || 'Aceptar'}
            </Button>
          </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlertConfirm;
