/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-computed-key */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  Typography,
  Divider,
  Hidden,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  getAmountService,
  updateAmountService,
  deleteAmountService,
  extraTypes as optionsMounts,
} from '../../services';
import { Toaster, CustomDialog } from '../../components';
import { ActionForm } from './components';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { Button, Card } from './styles';
import { validateAmount } from '../../utils';

const AmountsScreen = () => {
  const [amountList, setAmountList] = useState([]);
  const [amountListcopy, setAmountListcopy] = useState([]);
  const [appMountList, setAppMountList] = useState([]);
  const [kioskoMountList, setKioskoMountList] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canAdd, setCanAdd] = useState(true);

  const classes = useStyle();

  const getTransactionTypes = async () => {
    await optionsMounts().then((res) => {
      if (res.length === amountList.length && amountList.length > 0) {
        setCanAdd(false);
      } else {
        setCanAdd(true);
      }
    });
  };

  const getamountList = async () => {
    setLoading(true);
    const res = await getAmountService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setAmountList(res.data);
  };

  const handlechangeapp = (item) => (event) => {
    const extract = appMountList.find(({ id }) => id === item.id);
    if (extract) {
      extract.minimum_amount = event.target.value;
    }
  };

  const handlechangeappBlur = (item) => (event) => {
    const newList = amountListcopy.map((itemb) => {
      if (itemb.id === item.id) {
        const updatedItem = {
          ...itemb,
          minimum_amount: parseFloat((event.target.value).replace(/,/g, '')),
          change: 1,
        };
        return updatedItem;
      }
      return itemb;
    });
    setAmountListcopy(newList);
  };

  const handlechangekiosko = (item) => (event) => {
    const extract = kioskoMountList.find(({ id }) => id === item.id);
    if (extract) {
      extract.minimum_amount = event.target.value;
    }
  };

  const handlechangekioskoMax = (item) => (event) => {
    const extract = kioskoMountList.find(({ id }) => id === item.id);
    if (extract) {
      extract.maximum_amount = event.target.value;
    }
  };

  const handlechangekioskoBlur = (item, key) => (event) => {
    const newList = amountListcopy.map((itemb) => {
      if (itemb.id === item.id) {
        const updatedItem = {
          ...itemb,
          [key]: parseFloat((event.target.value).replace(/,/g, '')),
          change: 1,
        };
        return updatedItem;
      }
      return itemb;
    });
    setAmountListcopy(newList);
  };

  useEffect(() => {
    getamountList();
  }, []);

  useEffect(async () => {
    if (amountList) {
      let listApp = [];
      let listkiosko = [];
      let options = [];
      await optionsMounts().then((res) => {
        options = res;
      });
      for (let x = 0; x < amountList.length; x++) {
        for (let y = 0; y < options.length; y++) {
          if (amountList[x].txn_code === options[y].transaction_code && options[y].type === 'APP') {
            listApp.push(amountList[x]);
          }
          if (amountList[x].txn_code === options[y].transaction_code && options[y].type === 'KIOSKO') {
            listkiosko.push(amountList[x]);
          }
        }
      }
      setAppMountList(listApp);
      setKioskoMountList(listkiosko);
      setAmountListcopy(amountList);
      getTransactionTypes();
    }
  }, [amountList]);

  const handleedit = () => setCanEdit((prev) => !prev);

  const handleSave = async () => {
    const copyOriginal = [...amountListcopy];
    const toupdate = copyOriginal.filter((obj) => (obj.change && obj.change === 1));
    let req = [];
    let valid = true;
    // Validate inputs
    toupdate.forEach((amount) => {
      if (!validateAmount(amount)) {
        setAlert({
          status: true,
          type: 'error',
          message: 'Formulario inválido, por favor verifique.',
        });
        valid = false;
      }
    });
    if (!valid) return;
    toupdate.forEach((itemUpdate) => {
      let doupdate = updateAmountService({
        minimum_amount: itemUpdate.minimum_amount,
        maximum_amount: itemUpdate.maximum_amount,
        amount_name: itemUpdate.txn_name,
        amount_id: itemUpdate.id,
        transaction_code: itemUpdate.txn_code,
      });

      req.push(doupdate);
    });
    Promise.all(req).then(() => {
      setAlert({ status: true, type: 'success', message: 'Guardado correctamente' });
      getamountList();
    }).catch(() => {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    });
  };

  const handleDelete = (itemdel) => () => {
    setLoading(true);
    deleteAmountService(itemdel.id).then(() => {
      getamountList();
    }).catch(() => {
      setLoading(false);
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    });
  };

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} errorMessage={alert.message} error={alert.type === 'error'}>
      <Card>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid container justifyContent="flex-end" style={{ marginRight: 10, marginTop: 10 }}>
                <Button variant={canEdit ? 'outlined' : 'contained'} onClick={handleedit}>
                  Editar
                </Button>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
                <Typography variant="h4" gutterBottom style={{ marginLeft: 10 }}>Aplicación móvil</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Transacción</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Monto minimo</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              {appMountList && appMountList.map((item, index) => (
                <Grid container key={`${(index + 1)}_contitemapp`} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Grid item xs={12} md={3} key={`${(index + 1)}_contitemapp_text`}>
                    <Typography>{item?.txn_name.replace('_APP', '')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2} key={`${(index + 1)}_contitemappfield`}>
                    <TextField
                      id={`amountmaxapp${(index + 1)}`}
                      variant="outlined"
                      value={item?.minimum_amount || ''}
                      onChange={handlechangeapp(item)}
                      onBlur={handlechangeappBlur(item)}
                      name="numberformat"
                      disabled={!canEdit}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      placeholder="0"
                    />
                  </Grid>
                  {canEdit && (
                    <Grid container xs={12} md={2} key={`${(index + 1)}_contitem_dela_fieldmax`} alignItems="center">
                      <Button size="small" onClick={handleDelete(item)} error>
                        Borrar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.seccion}>
            <Grid container spacing={3}>
              <Grid container justifyContent="flex-start">
                <Typography variant="h4" gutterBottom style={{ marginLeft: 10 }}>Kioskos</Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography>Transaccion</Typography>
              </Grid>
              <Grid item sm={2} md={2}>
                <Hidden smDown>
                  <Typography>Monto minimo</Typography>
                </Hidden>
              </Grid>
              <Grid item sm={2} md={2}>
                <Hidden smDown>
                  <Typography>Monto maximo</Typography>
                </Hidden>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              {kioskoMountList && kioskoMountList.map((item, index) => (
                <Grid container key={`${(index + 1)}_contitemkiosko`} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Grid item xs={12} md={3} key={`${(index + 1)}_contitemapp_text`}>
                    <Typography>{item?.txn_name.replace('_KIOSKO', '')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2} key={`${(index + 1)}_contitem_k_fieldmin`}>
                    <Hidden smUp>
                      <Typography>Monto minimo</Typography>
                    </Hidden>
                    <TextField
                      id={`amountmink${(index + 1)}`}
                      variant="outlined"
                      value={item?.minimum_amount || ''}
                      onChange={handlechangekiosko(item, 'minimum_amount')}
                      onBlur={handlechangekioskoBlur(item, 'minimum_amount')}
                      name="numberformat"
                      disabled={!canEdit}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      placeholder="10"
                    />
                  </Grid>
                  <Grid item xs={12} md={2} key={`${(index + 1)}_contitem_k_fieldmax`}>
                    <Hidden smUp>
                      <Typography>Monto maximo</Typography>
                    </Hidden>
                    <TextField
                      id={`amountmaxk${(index + 1)}`}
                      variant="outlined"
                      value={item?.maximum_amount || ''}
                      onChange={handlechangekioskoMax(item)}
                      onBlur={handlechangekioskoBlur(item, 'maximum_amount')}
                      name="numberformat"
                      disabled={!canEdit}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      placeholder="0"
                    />
                  </Grid>
                  {canEdit && (
                    <Grid container xs={12} md={2} key={`${(index + 1)}_contitem_delk_fieldmax`} alignItems="center">
                      <Button size="small" variant="contained" onClick={handleDelete(item)} error>
                        Borrar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
            {canEdit && (
              <Grid container justifyContent={!canAdd ? 'flex-end' : 'space-between'} className={classes.seccionButton}>
                {canAdd && (
                  <Button
                    variant="outlined"
                    onClick={
                      () => setPopUpAction({
                        open: true,
                        title: 'Crear nuevo monto limite',
                        data: null,
                      })
                    }
                  >
                    Agregar
                  </Button>
                )}
                <Button variant="contained" onClick={() => setSaveDialog(true)}>
                  guardar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          title={popUpAction.title}
          actualCreated={amountList.length > 0 ? amountList : []}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
            getamountList();
          }}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Card>
      <CustomDialog
        isOpen={saveDialog}
        openCloseFx={setSaveDialog}
        title="Guardar cambios"
        text="Se guardaran los cambios a los montos"
        okFunction={handleSave}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default AmountsScreen;
