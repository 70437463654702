/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { CustomTable } from '../../../../components';
import COLUMNS from './constants';
import getVersionsRows from './utils';

const VersionTable = ({
  versions,
  onNext,
  onSelectPageSize,
  totalElementsData,
  loading,
}) => {
  const providerRef = React.useRef();
  const [versionsRows, setVersionsRows] = useState([]);

  useEffect(() => {
    setVersionsRows([]);
    getVersionsRows(
      versions, setVersionsRows,
    );
  }, [versions]);
  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={versionsRows}
        columns={COLUMNS}
        totalElementsData={totalElementsData}
        onNext={onNext}
        onSelectPageSize={onSelectPageSize}
        loading={loading}
      />
    </SnackbarProvider>
  );
};

export default VersionTable;
