import httpInstance from '../httpInstance';

const updateFeaturedPromotion = async (body) => {
  let res;
  await httpInstance.put('/back-office/v1/promotions', body)
    .then((data) => {
      res = data;
    }).catch((err) => {
      res = err.response;
    });
  return res;
};

export default updateFeaturedPromotion;
