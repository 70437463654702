/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelWrap = styled.label`
  display: flex;
  align-item: center;
  margin-left: 5px;
  margin-right: 10px;
  padding-right: 10px;
  gap: 10px;
  cursor: pointer;
`;

export const Switch = styled.div`
  position: relative;
  width: 28px;
  height: 16px;
  background-color: ${({ theme }) => theme.black10};
  border-radius: 15px;
  padding: 4px;
  transition: 300ms all;
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 50%;
    left: ${({ checked }) => (checked ? '0px' : '2px')};
    background-color: ${({ theme, disabled }) => (disabled ? theme.black20 : theme.white)};
    transform: translate(0, -50%);
  }
`;

export const InputStyled = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + ${Switch} {
    background-color: ${({ theme }) => theme.primary};
    &:before {
      transform: translate(15px, -50%);
    }
  }
  &:disabled + ${Switch} {
    background-color: ${({ theme }) => theme.black10};
    cursor: auto;
    opacity: 0.5;
  }
`;
