/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomSwitch from './CustomSwitch';

const FormCustomSwitch = (props) => {
  const {
    name, label, control, error, labelPlacement = 'start', color = 'primary',
  } = props;

  return (
    <Controller
      render={({ value, onChange }) => (
        <FormControlLabel
          control={(
            <CustomSwitch
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              name={name}
              color={color}
            />
          )}
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
      name={name}
      control={control}
      error={Boolean(error[name])}
    />
  );
};

export default FormCustomSwitch;
