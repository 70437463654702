import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Menu, MenuItem } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';
import useStyle from './useStyle';
import SidebarMenuItems from './SidebarMenuItems';
import {
  COMMISSIONS,
  FEES,
  REMITTANCES,
  BANNERS,
  PERSON_FEATURES_FLAG,
  TAXES,
  DASHBOARD,
  AMOUNTS,
  CARDS,
  REFERRALS,
  ALLIES,
  APPVERSION,
  APPCOMMERCEVERSION,
  POS_DEVICE,
  LIMIT_REPOS,
  CARDS_COST_LIMIT,
  DISTRIBUTION_CENTER,
  CARDS_QUERY,
  PROMOTIONS,
  DISTRIBUTION_POS,
  SEGMENTATION,
  TYPE_PROMOTIONS,
  PROVIDERS_SERVICE_PAY,
  PROMO_CATEGORIES,
  PROVIDERS,
  CATEGORIES_SERVICE_RECHARGE,
  CATEGORIES_SERVICE_PAYMENT,
  PROMOTIONS_APP_SCREEN,
  DISTRIBUTION_ZONE,
  FEATURED,
  ROLSCREENS,
  ROLREGISTER,
  ROLASSIGNMENT,
  PAYMENTS_LINK,
  TAP_TO_PHONE,
  NATIONAL_HOLIDAYS,
  COMMERCE_FEATURES_FLAG,
  UMBRAL_IFO1_FEATURES_FLAG,
  EXPIRATION_DATE_REMITTANCE,
  COMMERCE_WEB_FEATURES_FLAG,
  REMITTANCES_NO_USER,
} from '../../navigation/screenNames';
import {
  TaxesScreen,
  ReadOnlyTaxesScreen,
  CommissionsScreen,
  ReadOnlyCommissionsScreen,
  FeesScreen,
  ReadOnlyFeesScreen,
  AmountsScreen,
  ReadOnlyAmountsScreen,
  ReferralsScreen,
  ReadOnlyReferralsScreen,
  PaymentsLinks,
  ReadOnlyPaymentsLinks,
  AlliesScreen,
  ReadOnlyAlliesScreen,
  SegmentationScreen,
  ReadOnlySegmentationScreen,
  RemittancesScreen,
  ReadOnlyRemittancesScreen,
  PersonFeaturesFlagScreen,
  ReadOnlyPersonFeaturesFlagScreen,
  DistributionPOS,
  ReadOnlyDistributionPOS,
  DistributionCenterScreen,
  ReadOnlyDistributionCenterScreen,
  CardsScreen,
  ReadOnlyCardsScreen,
  CardsCostLimitScreen,
  RepositionScreen,
  ReadOnlyRepositionScreen,
  CardsQuery,
  ReadOnlyCardsQuery,
  BannersScreen,
  ReadOnlyBannersScreen,
  FeaturedScreen,
  ReadOnlyFeaturedScreen,
  PromoCategories,
  ReadOnlyPromoCategories,
  TypePromotionsScreen,
  ReadOnlyTypePromotionsScreen,
  PromotionsAppScreen,
  ReadOnlyPromotionsAppScreen,
  PromotionsScreen,
  ReadOnlyPromotionsScreen,
  DeviceScreen,
  ReadOnlyDeviceScreen,
  ProvidersPaymentScreen,
  ReadOnlyProvidersPaymentScreen,
  CategoriesPaymentScreen,
  ReadOnlyCategoriesPaymentScreen,
  ProvidersScreen,
  ReadOnlyProvidersScreen,
  CategoriesRechargeScreen,
  ReadOnlyCategoriesRechargeScreen,
  AppVersion,
  ReadOnlyAppVersion,
  AppCommerceVersion,
  ReadOnlyAppCommerceVersion,
  ErrorScreenPermission,
  ScreensRolesScreen,
  ReadOnlyScreensRolesScreen,
  DashBoard,
  RolesRegisterScreen,
  ReadOnlyRolesRegisterScreen,
  RolAssignment,
  ReadOnlyRolAssignment,
  NationalHolidaysScreen,
  ReadOnlyNationalHolidaysScreen,
  CommerceFeaturesFlagScreen,
  ReadOnlyCommerceFeaturesFlagScreen,
  UmbralIF01FeaturesFlagScreen,
  RemittanceDateOverdue,
  ReadOnlyRemittanceDateOverdue,
  TapToPhone,
  ReadOnlyTapToPhone,
  RemittancesNoUser,
  ReadOnlyRemittancesNoUser,
  ReadOnlyUmbralIF01FeaturesFlagScreen,
} from '../../screens';
import UserContext from '../../contexts';
import {
  useGetScreens,
} from '../../services';
import { Version, Loading } from '../../components';
import useUserStatus from '../../hooks/useUserStatus';
import WoodMark from '../../assets/img/Wordmark@2x.png';

const MainContainerLayout = ({ title }) => {
  const classes = useStyle();
  const [open, setOpen] = React.useState(true);
  const [screens, setScreens] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();
  const userStatus = useUserStatus();

  const {
    loading: loadingScreens,
    data: dataScreens,
    setUsername,
    error: errorScreens,
    username: usernameScreens,
    getUserScreens,
  } = useGetScreens();

  useEffect(() => {
    if (userStatus?.user?.username) {
      setUsername(userStatus?.user?.username);
    }
  }, [userStatus]);

  useEffect(() => {
    if (usernameScreens !== undefined) {
      getUserScreens();
    }
  }, [usernameScreens]);

  useEffect(() => {
    /// Eliminar esta validacion al completar pantallas manejo de roles
    if (errorScreens) {
      setScreens({ flagIgnore: true });
      return;
    }
    if (dataScreens) setScreens(dataScreens);
  }, [dataScreens, errorScreens]);

  const validateScreenPermission = (screenName) => {
    /// Eliminar este flag al completar pantallas manejo de roles
    if (screens.flagIgnore) return { noScreen: false, canWrite: true };

    if (screens?.permissions?.length > 0) {
      const screenFind = screens?.permissions.find(
        (permission) => (
          permission.screenName === screenName && (permission.canWrite || permission.canRead)),
      );
      if (screenFind !== undefined) return { ...screenFind, noScreen: false };
      return { ...screenFind, noScreen: true };
    }
    return { noScreen: true };
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
    switch (action) {
      case 'exit':
        history.push('/login');
        break;
      default:
        break;
    }
  };

  if ((!userStatus || loadingScreens) && screens.length === 0) return <Loading />;

  return (
    <UserContext.Provider value={{ user: userStatus.user, userScreens: screens }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {title}
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary" overlap="rectangular">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <AccountCircleIcon />
                &nbsp;
                {userStatus.user?.username}
              </MenuItem>
              <Divider component="li" />
              <MenuItem onClick={handleClose}>Mi cuenta</MenuItem>
              <MenuItem onClick={() => handleClose('exit')}>Salir</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <img src={WoodMark} alt="billet" height="35px" />
            <Typography variant="subtitle1">Backoffice</Typography>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <SidebarMenuItems />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid item xs={12}>
              <Switch>
                <Route exact path={`/${DASHBOARD}`} component={DashBoard} />
                {
                  !validateScreenPermission(ROLASSIGNMENT)?.noScreen ? <Route exact path={`/${ROLASSIGNMENT}`} component={validateScreenPermission(ROLASSIGNMENT).canWrite ? RolAssignment : ReadOnlyRolAssignment} /> : null
                }
                {
                  !validateScreenPermission(ROLREGISTER)?.noScreen ? <Route exact path={`/${ROLREGISTER}`} component={validateScreenPermission(ROLREGISTER).canWrite ? RolesRegisterScreen : ReadOnlyRolesRegisterScreen} /> : null
                }
                {
                  !validateScreenPermission(ROLSCREENS)?.noScreen ? <Route exact path={`/${ROLSCREENS}`} component={validateScreenPermission(ROLSCREENS).canWrite ? ScreensRolesScreen : ReadOnlyScreensRolesScreen} /> : null
                }
                {
                  !validateScreenPermission(COMMISSIONS)?.noScreen ? <Route exact path={`/${COMMISSIONS}`} component={validateScreenPermission(COMMISSIONS).canWrite ? CommissionsScreen : ReadOnlyCommissionsScreen} /> : null
                }
                {
                  !validateScreenPermission(APPVERSION)?.noScreen ? <Route exact path={`/${APPVERSION}`} component={validateScreenPermission(APPVERSION).canWrite ? AppVersion : ReadOnlyAppVersion} /> : null
                }
                {
                  !validateScreenPermission(APPCOMMERCEVERSION)?.noScreen ? <Route exact path={`/${APPCOMMERCEVERSION}`} component={validateScreenPermission(APPCOMMERCEVERSION).canWrite ? AppCommerceVersion : ReadOnlyAppCommerceVersion} /> : null
                }
                {
                  !validateScreenPermission(FEES)?.noScreen ? <Route exact path={`/${FEES}`} component={validateScreenPermission(FEES).canWrite ? FeesScreen : ReadOnlyFeesScreen} /> : null
                }
                {
                  !validateScreenPermission(SEGMENTATION)?.noScreen ? <Route exact path={`/${SEGMENTATION}`} component={validateScreenPermission(SEGMENTATION).canWrite ? SegmentationScreen : ReadOnlySegmentationScreen} /> : null
                }
                {
                  !validateScreenPermission(REMITTANCES)?.noScreen ? <Route exact path={`/${REMITTANCES}`} component={validateScreenPermission(REMITTANCES).canWrite ? RemittancesScreen : ReadOnlyRemittancesScreen} /> : null
                }
                {
                  !validateScreenPermission(TAXES)?.noScreen ? <Route exact path={`/${TAXES}`} component={validateScreenPermission(TAXES).canWrite ? TaxesScreen : ReadOnlyTaxesScreen} /> : null
                }
                {
                  !validateScreenPermission(PROMO_CATEGORIES)?.noScreen ? <Route exact path={`/${PROMO_CATEGORIES}`} component={validateScreenPermission(PROMO_CATEGORIES).canWrite ? PromoCategories : ReadOnlyPromoCategories} /> : null
                }
                {
                  !validateScreenPermission(PROMOTIONS_APP_SCREEN)?.noScreen ? <Route exact path={`/${PROMOTIONS_APP_SCREEN}`} component={validateScreenPermission(PROMOTIONS_APP_SCREEN).canWrite ? PromotionsAppScreen : ReadOnlyPromotionsAppScreen} /> : null
                }
                {
                  !validateScreenPermission(BANNERS)?.noScreen ? <Route exact path={`/${BANNERS}`} component={validateScreenPermission(BANNERS).canWrite ? BannersScreen : ReadOnlyBannersScreen} /> : null
                }
                {
                  !validateScreenPermission(PERSON_FEATURES_FLAG)?.noScreen ? <Route exact path={`/${PERSON_FEATURES_FLAG}`} component={validateScreenPermission(PERSON_FEATURES_FLAG).canWrite ? PersonFeaturesFlagScreen : ReadOnlyPersonFeaturesFlagScreen} /> : null
                }
                {
                  !validateScreenPermission(UMBRAL_IFO1_FEATURES_FLAG)?.noScreen ? <Route exact path={`/${UMBRAL_IFO1_FEATURES_FLAG}`} component={validateScreenPermission(UMBRAL_IFO1_FEATURES_FLAG).canWrite ? UmbralIF01FeaturesFlagScreen : ReadOnlyUmbralIF01FeaturesFlagScreen} /> : null
                }
                {
                  !validateScreenPermission(COMMERCE_FEATURES_FLAG)?.noScreen ? <Route exact path={`/${COMMERCE_FEATURES_FLAG}`} component={validateScreenPermission(COMMERCE_FEATURES_FLAG).canWrite ? CommerceFeaturesFlagScreen : ReadOnlyCommerceFeaturesFlagScreen} /> : null
                }
                {
                  !validateScreenPermission(COMMERCE_FEATURES_FLAG)?.noScreen ? <Route exact path={`/${COMMERCE_WEB_FEATURES_FLAG}`} component={validateScreenPermission(COMMERCE_FEATURES_FLAG).canWrite ? CommerceFeaturesFlagScreen : ReadOnlyCommerceFeaturesFlagScreen} /> : null
                }
                {
                  !validateScreenPermission(DISTRIBUTION_CENTER)?.noScreen ? <Route exact path={`/${DISTRIBUTION_CENTER}`} component={validateScreenPermission(DISTRIBUTION_CENTER).canWrite ? DistributionCenterScreen : ReadOnlyDistributionCenterScreen} /> : null
                }
                {
                  !validateScreenPermission(AMOUNTS)?.noScreen ? <Route exact path={`/${AMOUNTS}`} component={validateScreenPermission(AMOUNTS).canWrite ? AmountsScreen : ReadOnlyAmountsScreen} /> : null
                }
                {
                  !validateScreenPermission(CARDS)?.noScreen ? <Route exact path={`/${CARDS}`} component={validateScreenPermission(CARDS).canWrite ? CardsScreen : ReadOnlyCardsScreen} /> : null
                }
                {
                  !validateScreenPermission(REFERRALS)?.noScreen ? <Route exact path={`/${REFERRALS}`} component={validateScreenPermission(REFERRALS).canWrite ? ReferralsScreen : ReadOnlyReferralsScreen} /> : null
                }
                {
                  !validateScreenPermission(PAYMENTS_LINK)?.noScreen ? <Route exact path={`/${PAYMENTS_LINK}`} component={validateScreenPermission(PAYMENTS_LINK).canWrite ? PaymentsLinks : ReadOnlyPaymentsLinks} /> : null
                }
                {
                  !validateScreenPermission(TAP_TO_PHONE)?.noScreen ? <Route exact path={`/${TAP_TO_PHONE}`} component={validateScreenPermission(TAP_TO_PHONE).canWrite ? TapToPhone : ReadOnlyTapToPhone} /> : null
                }
                {
                  !validateScreenPermission(ALLIES)?.noScreen ? <Route exact path={`/${ALLIES}`} component={validateScreenPermission(ALLIES).canWrite ? AlliesScreen : ReadOnlyAlliesScreen} /> : null
                }
                {
                  !validateScreenPermission(POS_DEVICE)?.noScreen ? <Route exact path={`/${POS_DEVICE}`} component={validateScreenPermission(POS_DEVICE).canWrite ? DeviceScreen : ReadOnlyDeviceScreen} /> : null
                }
                {
                  !validateScreenPermission(DISTRIBUTION_POS)?.noScreen ? <Route exact path={`/${DISTRIBUTION_POS}`} component={validateScreenPermission(DISTRIBUTION_POS).canWrite ? DistributionPOS : ReadOnlyDistributionPOS} /> : null
                }
                {
                  !validateScreenPermission(LIMIT_REPOS)?.noScreen ? <Route exact path={`/${LIMIT_REPOS}`} component={validateScreenPermission(LIMIT_REPOS).canWrite ? RepositionScreen : ReadOnlyRepositionScreen} /> : null
                }
                {
                  !validateScreenPermission(CARDS_COST_LIMIT)?.noScreen
                    ? (
                      <Route
                        exact
                        path={`/${CARDS_COST_LIMIT}`}
                        render={() => (
                          <CardsCostLimitScreen
                            permissionToEdit={validateScreenPermission(CARDS_COST_LIMIT).canWrite}
                          />
                        )}
                      />
                    ) : null
                }
                {
                  !validateScreenPermission(CARDS_QUERY)?.noScreen ? <Route exact path={`/${CARDS_QUERY}`} component={validateScreenPermission(CARDS_QUERY).canWrite ? CardsQuery : ReadOnlyCardsQuery} /> : null
                }
                {
                  !validateScreenPermission(PROMOTIONS)?.noScreen ? <Route exact path={`/${PROMOTIONS}`} component={validateScreenPermission(PROMOTIONS).canWrite ? PromotionsScreen : ReadOnlyPromotionsScreen} /> : null
                }
                {
                  !validateScreenPermission(TYPE_PROMOTIONS)?.noScreen ? <Route exact path={`/${TYPE_PROMOTIONS}`} component={validateScreenPermission(TYPE_PROMOTIONS).canWrite ? TypePromotionsScreen : ReadOnlyTypePromotionsScreen} /> : null
                }
                {
                  !validateScreenPermission(PROVIDERS_SERVICE_PAY)?.noScreen ? <Route exact path={`/${PROVIDERS_SERVICE_PAY}`} component={validateScreenPermission(PROVIDERS_SERVICE_PAY).canWrite ? ProvidersPaymentScreen : ReadOnlyProvidersPaymentScreen} /> : null
                }
                {
                  !validateScreenPermission(PROVIDERS)?.noScreen ? <Route exact path={`/${PROVIDERS}`} component={validateScreenPermission(PROVIDERS).canWrite ? ProvidersScreen : ReadOnlyProvidersScreen} /> : null
                }
                {
                  !validateScreenPermission(CATEGORIES_SERVICE_PAYMENT)?.noScreen ? <Route exact path={`/${CATEGORIES_SERVICE_PAYMENT}`} component={validateScreenPermission(CATEGORIES_SERVICE_PAYMENT).canWrite ? CategoriesPaymentScreen : ReadOnlyCategoriesPaymentScreen} /> : null
                }
                {
                  !validateScreenPermission(CATEGORIES_SERVICE_RECHARGE)?.noScreen ? <Route exact path={`/${CATEGORIES_SERVICE_RECHARGE}`} component={validateScreenPermission(CATEGORIES_SERVICE_RECHARGE).canWrite ? CategoriesRechargeScreen : ReadOnlyCategoriesRechargeScreen} /> : null
                }
                {
                  !validateScreenPermission(DISTRIBUTION_ZONE)?.noScreen ? <Route exact path={`/${DISTRIBUTION_ZONE}`} render={validateScreenPermission(DISTRIBUTION_ZONE).canWrite ? () => <DistributionPOS type="request-card" /> : () => <ReadOnlyDistributionPOS type="request-card" />} /> : null
                }
                {
                  !validateScreenPermission(FEATURED)?.noScreen ? <Route exact path={`/${FEATURED}`} component={validateScreenPermission(FEATURED).canWrite ? FeaturedScreen : ReadOnlyFeaturedScreen} /> : null
                }
                {
                  !validateScreenPermission(NATIONAL_HOLIDAYS)?.noScreen ? <Route exact path={`/${NATIONAL_HOLIDAYS}`} component={validateScreenPermission(NATIONAL_HOLIDAYS).canWrite ? NationalHolidaysScreen : ReadOnlyNationalHolidaysScreen} /> : null
                }
                {
                  !validateScreenPermission(EXPIRATION_DATE_REMITTANCE)?.noScreen ? <Route exact path={`/${EXPIRATION_DATE_REMITTANCE}`} component={validateScreenPermission(EXPIRATION_DATE_REMITTANCE).canWrite ? RemittanceDateOverdue : ReadOnlyRemittanceDateOverdue} /> : null
                }
                {
                  !validateScreenPermission(EXPIRATION_DATE_REMITTANCE)?.noScreen ? <Route exact path={`/${REMITTANCES_NO_USER}`} component={validateScreenPermission(EXPIRATION_DATE_REMITTANCE).canWrite ? RemittancesNoUser : ReadOnlyRemittancesNoUser} /> : null
                }
                {(screens.length === 0) && <Route path="/" component={ErrorScreenPermission} />}
              </Switch>
            </Grid>
            <Box pt={4}>
              <Version />
            </Box>
          </Container>
        </main>
      </div>
    </UserContext.Provider>
  );
};

MainContainerLayout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MainContainerLayout;
