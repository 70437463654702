/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button,
  IconButton,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import {
  downloadPromotionService,
} from '../../../../services';
import useStyle from './useStyle';
import { CustomTable, Loading, Toaster } from '../../../../components';
import { csvFileToArray, getMoneyFormat, removeFileExtension } from '../../../../utils';
import { ActionPopUp } from '../../../../Layouts';
import UserContext from '../../../../contexts/UserContext';
import approvePromotionService from '../../../../services/promotions/approvePromotionService';

const PromotionDetail = ({
  open,
  data,
  title,
  onClose,
  link,
  fileName,
  status,
  users,
  amount,
  setAlertHome,
  reloadPromotions,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = useStyle();
  const providerRef = React.useRef();
  const [fileNameExtension, setFileNameExtension] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const { userScreens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [promotionsRows, setPromotionsRows] = useState([]);
  const [canApprove, setCanApprove] = useState(false);

  const columns = [
    {
      id: 'documentNumber', label: 'Número de Documento', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'amount', label: 'Monto', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'promoName',
      label: 'Nombre de la promoción',
      paddingLeft: '40px',
      width: 150,
      position: 3,
    },
  ];
  const columnsAll = [
    {
      id: 'documentNumber', label: 'Número de Documento', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'amount', label: 'Monto', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'promoName',
      label: 'Nombre de la promoción',
      paddingLeft: '40px',
      width: 150,
      position: 3,
    },
    {
      id: 'status',
      label: 'Estatus',
      paddingLeft: '40px',
      width: 150,
      position: 4,
    },
    {
      id: 'transactionCode',
      label: 'Número de transacción',
      paddingLeft: '40px',
      width: 150,
      position: 5,
    },
    {
      id: 'description',
      label: 'Descripción',
      paddingLeft: '40px',
      width: 150,
      position: 6,
    },
  ];

  const handleOnclose = () => {
    onClose();
    setDataTable([]);
  };

  const setArray = (string) => {
    const arr = csvFileToArray(string);
    setDataTable(arr);
  };

  const getPromoData = async () => {
    if (link && link !== '') {
      try {
        const res = await downloadPromotionService(link, 'text');
        setArray(res);
      } catch {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar los datos de la promoción' });
      }
    }
  };

  const downloadFile = async () => {
    try {
      if (link && link !== '' && fileName && fileName !== '') {
        const res = await downloadPromotionService(link, `${fileNameExtension}.csv`, 'blob');
        if (res && res.status === 404) {
          setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
        } else {
          setAlert({ status: true, type: 'success', message: 'Archivo de la promoción descargado.' });
        }
      } else {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
    }
  };

  const approveFile = async () => {
    setLoading(true);
    try {
      if (link && link !== '' && canApprove) {
        const res = await approvePromotionService(fileName);
        if (res && res.status === 404) {
          setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al aprobar el archivo.' });
        } else {
          setAlertHome({ status: true, type: 'success', message: 'Archivo de la promoción aprobado exitosamente.' });
          handleOnclose();
          reloadPromotions();
        }
      } else if (!userScreens.description.includes('admin')) {
        setAlert({ status: true, type: 'error', message: 'El archivo sólo puede ser aprobado por un administrador.' });
      } else {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al aprobar el archivo.' });
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al aprobar el archivo.' });
    }
    setLoading(false);
  };

  useEffect(() => {
    getPromoData();
    const newFileName = removeFileExtension(fileName);
    setFileNameExtension(newFileName);
  }, [link, fileName]);

  const mapCodes = () => {
    const promotionsInfo = [];
    dataTable?.forEach((promotion, index) => {
      const amountRow = getMoneyFormat(promotion?.amount);
      if (status !== 'Pendiente de Aprobar') {
        const promotionRow = {
          id: promotion.documentNumber + index,
          documentNumber: promotion.documentNumber,
          amount: amountRow,
          promoName: promotion.promoName,
          status: promotion.status,
          transactionCode: promotion.transactionCode,
          description: promotion.description,
          active: true,
        };
        promotionsInfo.push(promotionRow);
      } else {
        const promotionRow = {
          id: promotion.documentNumber + index,
          documentNumber: promotion.documentNumber,
          amount: amountRow,
          promoName: promotion.promoName,
          active: true,
        };
        promotionsInfo.push(promotionRow);
      }
    });
    setPromotionsRows(promotionsInfo);
  };

  useEffect(() => {
    setPromotionsRows([]);
    mapCodes();
  }, [dataTable]);

  useEffect(() => {
    if (userScreens.description.includes('admin') || userScreens.description.includes('authorize_cashin_promo')) {
      setCanApprove(true);
    }
  }, []);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleOnclose}
        enabledControls
      >
        {loading
        && <Loading />}
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <Grid container>
            <Grid item sm={12}>
              <Box>
                <div>
                  <div className={classes.title}>
                    Información de
                    {' '}
                    {fileNameExtension}
                  </div>
                  <Grid item sm={4}>
                    <div className={classes.subtitle}>
                      Estatus:
                      {' '}
                      {status}
                      {status === 'Pendiente de Aprobar' && (
                        <Tooltip title="Para aprobar es necesario contar con permisos">
                          <IconButton aria-label="info" disableRipple style={{ fontSize: '20px', cursor: 'default' }}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </Grid>

                  <Box className={classes.uploadImage}>
                    <div>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow key="header">
                              <TableCell className={classes.tableCellHead}>Nombre del archivo</TableCell>
                              <TableCell className={classes.tableCellHead}>Número de registros</TableCell>
                              <TableCell className={classes.tableCellHead}>Monto total</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.tableCell}>{fileNameExtension}</TableCell>
                              <TableCell className={classes.tableCell}>{users}</TableCell>
                              <TableCell className={classes.tableCell}>
                                {getMoneyFormat(amount)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Box>

                  <div className={classes.subtitle}>
                    Archivo:
                  </div>

                  <CustomTable
                    data={promotionsRows}
                    columns={status !== 'Pendiente de Aprobar' ? columnsAll : columns}
                    readOnly={false}
                  />

                </div>
                {canApprove && status === 'Pendiente de Aprobar' && (
                  <div className={classes.buttonContainer}>
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImageApprove}
                      endIcon={<CloudDoneIcon htmlColor="#50B940" className={classes.matIcon} />}
                      onClick={() => {
                        approveFile();
                      }}
                    >
                      Aprobar archivo
                    </Button>
                  </div>
                )}
                <div className={classes.buttonContainer}>
                  <Button
                    color="inherit"
                    variant="contained"
                    component="span"
                    className={classes.buttonImage}
                    endIcon={<CloudDownloadIcon htmlColor="#449FDD" className={classes.matIcon} />}
                    onClick={() => {
                      downloadFile();
                    }}
                  >
                    Descargar archivo
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

PromotionDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default PromotionDetail;
