/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckbox = (props) => {
  const {
    name, label, control, error, labelPlacement = 'start', disabled,
  } = props;

  return (
    <Controller
      render={({ value, onChange }) => (
        <FormControlLabel
          control={(
            <Checkbox
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              name={name}
              disabled={disabled}
            />
          )}
          label={label}
          labelPlacement={labelPlacement}
        />
      )}
      name={name}
      control={control}
      error={Boolean(error[name])}
    />
  );
};

export default CustomCheckbox;
