import httpInstance from '../httpInstance';

const useGetProviderById = async (id, type) => {
  let res;
  const endpoint = `/service-payment/v1/beneficiaries/${id}/${type}`;
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default useGetProviderById;
