/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import {
  getProvinceService,
} from '../../services';
import { Toaster, Loading } from '../../components';

const DistributionPOS = (props) => {
  const {
    type,
  } = props;
  const classes = useStyle();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [provincesData, setProvincesData] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getProvince = async () => {
    setLoading(true);
    const res = await getProvinceService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res.data !== undefined) {
      setProvincesData(res.data);
      setChecked(res.data);
    }
    return setProvincesData(res.data);
  };

  const btnSelectAll = () => {
    const typeRequest = checked.filter((i) => (type === 'request-card' ? i.enabled_card_request === true : i.enabled_mpos_request === true));
    return typeRequest.length !== checked.length ? 'Seleccionar todos' : 'Deseleccionar todos';
  };

  useEffect(() => {
    getProvince();
  }, []);

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      {type === 'request-card' && <Typography className={classes.title}>Selecciona la/s provincia/s hábil/es para solicitar tarjetas</Typography>}
      <Paper className={classes.container}>
        {loading
          ? <Loading />
          : (
            <>
              <Grid>
                <Grid container>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell size="small" className={classes.text} align="center">Habilitado</TableCell>
                          <TableCell className={classes.text} align="center">Provincia</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.heightTable}>
                        {provincesData && checked.map((item) => (
                          <TableRow hover key={item.province_id}>
                            {type === 'request-card' ? (
                              <TableCell size="small" className={classes.text} align="center">
                                <Checkbox
                                  checked={item.enabled_card_request}
                                  size="small"
                                  color="primary"
                                  disabled
                                />
                              </TableCell>
                            ) : (
                              <TableCell size="small" className={classes.text} align="center">
                                <Checkbox
                                  checked={item.enabled_mpos_request}
                                  size="small"
                                  color="primary"
                                  disabled
                                />
                              </TableCell>
                            )}
                            <TableCell className={classes.text} align="center">{item.province_name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid style={{ flexDirection: 'row', alignContent: 'space-between', padding: 10 }}>
                  <Button style={{ marginRight: 50 }} variant="outlined" color="primary" disabled>
                    {checked && btnSelectAll()}
                  </Button>
                  <Button variant="contained" color="primary" disabled>
                    {type === 'request-card' ? 'Guardar' : 'Actualizar'}
                  </Button>
                </Grid>
              </Grid>
              <Toaster
                show={alert.status}
                type={alert.type}
                text={alert.message}
                onClose={() => setAlert({ ...alert, status: false })}
              />
            </>
          )}
      </Paper>
    </InternalPage>
  );
};

DistributionPOS.propTypes = {
  type: PropTypes.string,
};

export default DistributionPOS;
