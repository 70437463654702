/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, Loading,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import { updateRemittanceConfig } from '../../../services/remittances/getRemittanceConfig';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  setAlert,
}) => {
  const providerRef = React.useRef();
  const {
    updateRemittance,
    loading,
    error,
  } = updateRemittanceConfig();

  const {
    handleSubmit, control, errors,
  } = useForm();

  const handleOnclose = () => {
    onClose();
  };

  useEffect(() => {
    if (error) setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
  }, [error]);

  const handleSave = async (dataform) => {
    const body = {
      id: data?.id,
      name: data?.id === '1' ? 'ExpiredDays' : 'RemittanceReminderDays',
      value: dataform.value,
    };
    await updateRemittance({ data: body });
    onClose();
    setAlert({ status: true, type: 'success', message: 'Cambio exitoso' });
  };

  const labelText = data?.id === '1' ? 'Días de vencimiento remesas' : 'SMS vencimiento';

  return (
    <SnackbarProvider ref={providerRef}>
      {loading && <Loading />}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <form autoComplete="off">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomInput
                label={labelText}
                name="value"
                control={control}
                rule={Rules.numerico}
                error={errors}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
