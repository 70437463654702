import httpInstance from '../httpInstance';

const getCommercialCategoryService = async (mcc) => {
  let res;
  const endpoint = `/frontlabels/v1/business/details/by-mcc/${mcc}`;
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getCommercialCategoryService;
