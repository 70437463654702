import httpInstance from '../httpInstance';

const getRepositionService = async () => {
  let res;
  const endpoint = '/back-office/v1/replacement-limits/';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const getLabelReposition = async () => {
  let res;
  const endpoint = '/back-office/v1/replacement-limits/labels';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const updateRepositionService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/replacement-limits/';
  const dataSent = { ...dataParam };

  // Data transformation
  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getRepositionService;
