import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify';
import { Version } from '../components';
import { Button } from './styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ onClick }) => {
  const [processing, setProcessing] = useState(false);

  const signOut = async () => {
    try {
      setProcessing(false);
      await Auth.signOut();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    signOut();
  }, []);

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          {!processing && (
            <Button onClick={onClick} type="button">
              AWS Cognito Login
            </Button>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Version />
      </Box>
    </Container>
  );
};

Login.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Login;
