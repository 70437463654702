const COLUMNS = [
  {
    id: 'version', label: 'Versión', paddingLeft: '40px', width: 50, position: 1,
  },
  {
    id: 'title', label: 'Título', paddingLeft: '40px', width: 150, position: 2,
  },
  {
    id: 'author',
    label: 'Autor',
    paddingLeft: '40px',
    width: 150,
    position: 3,
  },
  {
    id: 'description',
    label: 'Descripción',
    paddingLeft: '40px',
    width: 900,
    position: 4,
  },
];

export default COLUMNS;
