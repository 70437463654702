/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

const CustomSelect = (props) => {
  const {
    label, name, className, control, rule, options, error, disabled, variant, InputProps, InputLabelProps, placeholder,
    classNameTextField,
  } = props;

  return (
    <FormControl style={className} fullWidth>
      <Controller
        as={(
          <TextField
            label={label}
            placeholder={placeholder}
            InputLabelProps={InputLabelProps}
            size="small"
            variant={variant || 'outlined'}
            className={classNameTextField}
            select
            InputProps={InputProps}
            disabled={disabled}
            FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
            helperText={error[name] ? error[name].message : null}
          >
            <MenuItem value="">
              <em>Seleccione una opción</em>
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
            )}
        name={name}
        control={control}
        rules={rule}
        fullWidth
        error={Boolean(error[name])}
      />
    </FormControl>
  );
};

export default CustomSelect;
