export const getPaymentLinksRows = (
  paymentLinkParam, createActionsButtons, setRows, setInfoCSV,
) => {
  const paymentLinkInfo = [];
  const paymentLinkCsv = [];

  paymentLinkParam?.forEach(
    (paymentLink) => {
      const paymentLinkRow = {
        active: 1,
        documentNumber: paymentLink.document_number,
        commercialName: paymentLink.commercial_name,
        idCommerce: paymentLink.merchant_id_cardnet,
        actions: createActionsButtons(
          paymentLink.active_payment_links, paymentLink,
        ),
      };

      paymentLinkInfo.push(paymentLinkRow);

      const paymentInfo = {
        RNC: paymentLink.document_number,
        Nombre: paymentLink.commercial_name,
        Id_Aquiriencia: paymentLink.merchant_id_cardnet ?? '',
        ITBIS: paymentLink.itbis === true ? 'SI' : 'NO',
        Estado: paymentLink.active_payment_links === true ? 'Activo' : 'Inactivo',
      };

      paymentLinkCsv.push(paymentInfo);
    },
  );

  setInfoCSV(paymentLinkCsv);

  setRows(paymentLinkInfo);
};

export const getMyPaymentLinksRows = (
  paymentLinkParam, createActionsButtons, setRows, setInfoCSV,
) => {
  const paymentLinkInfo = [];
  const paymentLinkCsv = [];
  paymentLinkParam?.forEach(
    (paymentLink) => {
      const paymentLinkRow = {
        active: paymentLink.active_payment_links,
        documentNumber: paymentLink.document_number,
        commercialName: paymentLink.commercial_name,
        idCommerce: paymentLink.merchant_id_cardnet,
        actions: createActionsButtons(
          paymentLink.active_payment_links, paymentLink,
        ),
      };

      paymentLinkInfo.push(paymentLinkRow);
      const paymentInfo = {
        RNC: paymentLink.document_number,
        Nombre: paymentLink.commercial_name,
        Id_Aquiriencia: paymentLink.merchant_id_cardnet ? paymentLink.merchant_id_cardnet : 'No Configurado',
        Id_Terminal: paymentLink.merchant_id ?? '',
        Categoía_Comercial: paymentLink.mcc_tap_to_phone !== -1 ? paymentLink.mcc_tap_to_phone : 'No Asignado',
        ITBIS: paymentLink.mcc_itbis === true ? 'SI' : 'NO',
        Vigencia_Link_Pago: paymentLink.expiration_days !== -1 ? paymentLink.expiration_days : 'No Configurado',
        Estado: paymentLink.active_payment_links === true ? 'Activo' : 'Inactivo',
      };

      paymentLinkCsv.push(paymentInfo);
    },
  );

  setInfoCSV(paymentLinkCsv);

  setRows(paymentLinkInfo);
};

export const openModalEditPaymentLink = async (
  paymentLinkRow, setDefaultValues, setPopUpAction, getInfo, getMerchant,
) => {
  const commerceResponse = await getInfo(paymentLinkRow.document_number);
  const merchantResponse = await getMerchant(commerceResponse.data.merchant_id);
  const editValues = {
    documentNumber: paymentLinkRow.document_number,
    commercialName: paymentLinkRow.commercial_name,
    idCommerce: paymentLinkRow.merchant_id_cardnet,
    activePaymentLinks: paymentLinkRow.active_payment_links,
    merchantId: paymentLinkRow.merchant_id,
    merchantIdCardnet: paymentLinkRow.merchant_id_cardnet,
    cardnetTerminalId: merchantResponse.data.CardnetTerminalId,
    expirationDays: merchantResponse.data.ExpirationDays,
    mcc: paymentLinkRow.mcc_tap_to_phone,
    checkedItbis: paymentLinkRow.mcc_itbis,
  };

  setDefaultValues(editValues);
  setPopUpAction({
    open: true,
    title: 'Editar comercio Link de pago',
    data: editValues,
  });
};
