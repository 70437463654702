const sortVersions = (list, descending = false) => {
  const compareVersions = (a, b) => {
    const versionA = a.version;
    const versionB = b.version;

    const partsA = versionA.split('.').map(Number);
    const partsB = versionB.split('.').map(Number);

    const multiplier = descending ? -1 : 1;

    return multiplier * partsA.reduce((result, partA, index) => result || partA - partsB[index], 0);
  };

  return list.sort(compareVersions);
};

export default sortVersions;
