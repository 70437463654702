import httpInstance from '../httpInstance';

const createChannelService = async (dataParam) => {
  let res;
  const endpoint = 'back-office/v1/channels';
  const dataSent = {};

  // Data transformation
  dataSent.id = dataParam.idChannel;
  dataSent.name = dataParam.nameChannel;
  dataSent.order = dataParam.orderChanel;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const updateChannelService = async (dataParam) => {
  let res;
  const endpoint = 'back-office/v1/channels';
  const dataSent = {};

  // Data transformation
  dataSent.id = dataParam.id;
  dataSent.name = dataParam.name;
  dataSent.order = dataParam.id;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const deleteChannelService = async (dataParam) => {
  let res;
  const endpoint = `back-office/v1/channels/${dataParam}`;

  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const getChannelService = async () => {
  let res;
  const endpoint = 'back-office/v1/channels';

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

const getChannelByIdService = async (dataParam) => {
  let res;
  const endpoint = `back-office/v1/channels${dataParam.id}`;

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export {
  createChannelService,
  updateChannelService,
  deleteChannelService,
  getChannelService,
  getChannelByIdService,
};
