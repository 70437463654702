import styled from 'styled-components';

export const ButtonVersion = styled.button`
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 4px;
    text-transform: uppercase;
    padding: 12px;
    line-height: 1.75;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.02857em;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    margin-left: 12px;
    width: 96%;
`;

export default ButtonVersion;
