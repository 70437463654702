import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, WrapperButton, Card } from '../Taxes/styles';
import ActionForm from './partials/ActionForm/ActionForm';
import Table from './partials/Table/Table';
import { Loading, Toaster } from '../../components';
import { CenteredText } from './styles';
import getTypePromotionsService from '../../services/typePromotions/getTypePromotions';

const TypePromotionsScreen = () => {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    new: false,
    title: 'Crear nuevo tipo de promoción',
    data: null,
  });

  const onClose = (info) => {
    if (info?.action === 'CREATED') setPromotions((prev) => ([...prev, info.res]));
    if (info?.action === 'DELETED') {
      const newPromotions = promotions.filter((promotion) => promotion.id !== info.res.id);
      setPromotions(newPromotions);
    }
    if (info?.action === 'UPDATED') {
      const promotionsUpdated = promotions.map((promotion) => (
        promotion.id === info.res.id
          ? info.res
          : promotion
      ));
      setPromotions(promotionsUpdated);
    }

    setPopUpAction({
      open: false,
      new: false,
      title: 'Crear nuevo tipo de promoción',
      data: null,
    });
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const res = await getTypePromotionsService();
      setLoading(false);
      if (res) setPromotions(res);
    };

    initData();
  }, []);

  if (loading) return <Loading />;

  return (
    <Card>
      {promotions.length > 0
        ? (
          <Table
            data={promotions}
            onRowClick={() => {}}
            onDelete={() => {}}
            actionsDisabled
          />
        )
        : <CenteredText>Aun no hay registros</CenteredText>}
      <WrapperButton>
        <Button color="primary" aria-label="add" disabled>
          <AddIcon />
        </Button>
      </WrapperButton>
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        newPromotion={popUpAction.new}
        onClose={onClose}
        setAlert={setAlert}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </Card>
  );
};

export default TypePromotionsScreen;
