import httpInstanceCommerce from '../httpInstanceCommerce';

const putToggleTapToPhoneService = async (commerceId, newValue) => {
  let res;

  const endpoint = `/tap-to-phone/v1/phos-commerce/merchant-status?newValue=${newValue}&commerceId=${commerceId}`;

  await httpInstanceCommerce.put(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default putToggleTapToPhoneService;
