/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import SVG from 'react-inlinesvg';
import { CustomTable, Toaster } from '../../../components';
import tableStyle, { DivImage } from './tableStyle';
import { PRIMARY } from '../../../theme/colors';

const CategoriesTable = (props) => {
  const providerRef = React.useRef();
  const {
    categories, actionsDisabled, setDefaultValues, setPopUpAction,
  } = props;
  const [categoriesRows, setCategoriesRows] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = tableStyle();

  const openModalEdit = (categoryIdSelected) => {
    const categoryEdit = categories.find((categoryRow) => categoryRow.id === categoryIdSelected);
    setDefaultValues({
      nameCategory: categoryEdit.nameCategory || ' ',
      order: categoryEdit.order || ' ',
      icon_base64: '',
      icon: categoryEdit.icon || ' ',
    });
    setPopUpAction({
      open: true,
      title: 'Categoria - Solo lectura',
      data: categoryEdit,
    });
  };

  function createActions(categoryId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEdit(categoryId)}
          style={{ marginLeft: 0 }}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <EyeIcon style={{ fill: PRIMARY }} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          disabled
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createImageSvg(strSvg) {
    return (
      <DivImage>
        <SVG src={strSvg} fill="#2D3134" />
      </DivImage>
    );
  }

  function mapCodes(categoriesParam) {
    const categoriesInfo = [];
    categoriesParam?.forEach((category, index) => {
      const categoryRow = {
        // eslint-disable-next-line prefer-template
        id: 'CAT' + (index + 1),
        nameCategory: category.nameCategory,
        order: category.order,
        icon: createImageSvg(category.icon),
        actions: createActions(category.id),
        active: !actionsDisabled,
      };
      categoriesInfo.push(categoryRow);
    });
    setCategoriesRows(categoriesInfo);
  }

  useEffect(() => {
    setCategoriesRows([]);
    mapCodes(categories);
  }, [categories]);

  const columns = [
    {
      id: 'id',
      label: 'ID',
      position: 1,
    },
    {
      id: 'nameCategory',
      label: 'Nombre categoria',
      position: 2,
    },
    {
      id: 'order',
      label: 'Orden',
      position: 3,
    },
    {
      id: 'icon',
      label: 'Preview icono',
      position: 4,
    },
    {
      id: 'actions',
      label: 'Acciones',
      position: 9,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={categoriesRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
        readOnly
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default CategoriesTable;
