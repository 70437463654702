export const getPaymentLinksRows = (
  paymentLinkParam, createActionsButtons, setRows,
) => {
  const paymentLinkInfo = [];
  paymentLinkParam?.forEach(
    (paymentLink) => {
      const paymentLinkRow = {
        active: 1,
        documentNumber: paymentLink.document_number,
        commercialName: paymentLink.commercial_name,
        idCommerce: paymentLink.merchant_id_cardnet,
        actions: createActionsButtons(
          paymentLink.active_payment_links, paymentLink,
        ),
      };
      paymentLinkInfo.push(paymentLinkRow);
    },
  );
  setRows(paymentLinkInfo);
};

export const openModalEditPaymentLink = async (
  paymentLinkRow, setDefaultValues, setPopUpAction, getInfo, getMerchant,
) => {
  const commerceResponse = await getInfo(paymentLinkRow.document_number);
  const merchantResponse = await getMerchant(commerceResponse.data.merchant_id);
  const editValues = {
    documentNumber: paymentLinkRow.document_number,
    commercialName: paymentLinkRow.commercial_name,
    idCommerce: paymentLinkRow.merchant_id_cardnet,
    activePaymentLinks: paymentLinkRow.active_payment_links,
    merchantId: paymentLinkRow.merchant_id,
    merchantIdCardnet: paymentLinkRow.merchant_id_cardnet,
    cardnetTerminalId: merchantResponse.data.CardnetTerminalId,
    expirationDays: merchantResponse.data.ExpirationDays,
  };
  setDefaultValues(editValues);
  setPopUpAction({
    open: true,
    title: 'Editar comercio Link de pago',
    data: editValues,
  });
};
