import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyle = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.grey[700],
  },
  disabled: {
    opacity: 0.5,
  },
}));

export const Image = styled.img`
  ${({ url }) => (url ? `
    width: 40px;
    height: 40px;`
    : `display: flex;
    text-align: center;
    justify-content: center;`)}
  
`;

export default useStyle;
