/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Typography,
} from '@material-ui/core';
import { TableRowLimits } from '../index';
import { ButtonStyled } from './styles';
import { NAME_OPERATIONS, SPECIAL_OPERATIONS } from '../../constants';

const DataTable = ({
  data,
  index,
  newSegment,
}) => {
  let i = 0;
  const [segmentation, setSegmentation] = useState(JSON.parse(data.limits));

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={4}>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography variant="h5" component="h5">{`${data.description} - ${data.segment}`}</Typography>
        </Box>
        <Box>
          {!newSegment && (
            <ButtonStyled variant="contained" disabled>
              Editar
            </ButtonStyled>
          )}
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">No.</TableCell>
              <TableCell align="center">Operaciones</TableCell>
              <TableCell align="center">Tipo de transacción</TableCell>
              <TableCell align="center">Limites diarios</TableCell>
              <TableCell align="center">Limites semanales</TableCell>
              <TableCell align="center">Limites mensuales</TableCell>
              <TableCell align="center">Número de trx diarias</TableCell>
              <TableCell align="center">Número de trx semanales</TableCell>
              <TableCell align="center">Número de trx mensuales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(segmentation).map(([key]) => {
              i += 1;
              if (SPECIAL_OPERATIONS.includes(key)) {
                i += 1;
                return (
                  <Fragment key={`${key}-${index}.${i}`}>
                    <TableRowLimits
                      setSegmentation={setSegmentation}
                      segmentation={segmentation}
                      position={i - 1}
                      operation={key}
                      nameOperation={`${NAME_OPERATIONS[key]} - Origen`}
                      keyObject={key}
                      out
                    />
                    <TableRowLimits
                      setSegmentation={setSegmentation}
                      segmentation={segmentation}
                      position={i}
                      operation={key}
                      nameOperation={`${NAME_OPERATIONS[key]} - Destino`}
                      keyObject={key}
                    />
                  </Fragment>
                );
              }
              return (
                <TableRowLimits
                  key={`${key}-${index}.${i}`}
                  setSegmentation={setSegmentation}
                  segmentation={segmentation}
                  position={i}
                  operation={key}
                  nameOperation={NAME_OPERATIONS[key]}
                  keyObject={key}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

DataTable.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  newSegment: PropTypes.bool,
};

export default DataTable;
