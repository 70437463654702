import httpInstance from '../../httpInstance';

const updateReferralService = async (dataParam) => {
  let res;
  const endpoint = '/referrals/v1/promos';
  const dataSent = dataParam;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateReferralService;
