/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  updateFeaturesFlagService,
  deleteFeaturesFlagService,
} from '../../../../services';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';

const FeaturesFlagTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction, enteredFilter, featuresFlag, setFeaturesFlag, setDefaultValues,
  } = props;
  const [featuresFlagRows, setFeaturesFlagRows] = React.useState([]);
  const [featuresFlagListCopy, setFeaturesFlagListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    id: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const featuresFlagList = codesMapped != null ? codesMapped : featuresFlagListCopy;
    setFeaturesFlagRows(featuresFlagList.filter(
      (featuresFlagParam) => featuresFlagParam.name
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || featuresFlagParam.code
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || featuresFlagParam.description
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleChangeState = (event) => {
    setGeneralInfo({
      id: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const handleDeleteFeatureFlag = (bannerId) => {
    setGeneralInfo({
      id: bannerId,
      checked: false,
    });
    setOpenAlertConfirmDelete(true);
  };

  const openModalEditFeatureFlag = (bannerIdSelected) => {
    const featureFlagEdit = featuresFlag.find((bannerRow) => bannerRow.id === bannerIdSelected);
    setDefaultValues({
      name: featureFlagEdit.name,
      description: featureFlagEdit.description,
      code: featureFlagEdit.code,
      url: featureFlagEdit.url,
    });
    setPopUpAction({
      open: true,
      title: 'Editar feature flag',
      data: featureFlagEdit,
    });
  };

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele.id !== value);
  }

  const deleteFeatureFlag = async () => {
    const res = await deleteFeaturesFlagService(generalInfo.id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    providerRef.current.enqueueSnackbar('Se ha eliminado el feature flag exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    const bannersLocal = arrayRemove(featuresFlag, generalInfo.id);
    setFeaturesFlag(bannersLocal);
    setOpenAlertConfirmDelete(false);
  };

  function createActions(active, featureFlagId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              onChange={(e) => handleChangeState(e)}
              value={featureFlagId}
              name="active"
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditFeatureFlag(featureFlagId)}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeleteFeatureFlag(featureFlagId)}
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(featureFlagParam) {
    const featuresFlagInfo = [];
    featureFlagParam?.forEach((featureFlag) => {
      const featureFlagRow = {
        name: featureFlag.name,
        description: featureFlag.description,
        code: featureFlag.code,
        url: featureFlag.url,
        actions: createActions(featureFlag.active, featureFlag.id),
        id: featureFlag.id,
        active: 1,
      };
      featuresFlagInfo.push(featureFlagRow);
    });
    setFeaturesFlagRows(featuresFlagInfo);
    setFeaturesFlagListCopy(featuresFlagInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, featuresFlagInfo);
  }

  useEffect(() => {
    setFeaturesFlagRows([]);
    mapCodes(featuresFlag);
  }, [featuresFlag]);

  const columns = [
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'description', label: 'Descripción', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'code',
      label: 'Código',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'url',
      label: 'Url',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 4,
    },
  ];

  const updateFeatureFlag = async (featureFlag) => {
    const res = await updateFeaturesFlagService(featureFlag)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return true;
  };

  const handleConfirmChecked = () => {
    const featureFlagSelected = featuresFlag
      .find((featureFlag) => featureFlag.id === generalInfo.id);
    featureFlagSelected.active = generalInfo.checked ? 1 : 0;
    mapCodes(featuresFlag);
    if (updateFeatureFlag(featureFlagSelected)) {
      setGeneralInfo({
        id: '',
        checked: false,
      });
      setOpenAlertConfirm(false);
      providerRef.current.enqueueSnackbar(`Se ha ${activeCode ? 'activado' : 'inactivado'} el feature flag exitosamente`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={featuresFlagRows}
        columns={columns}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${activeCode ? 'activar' : 'inactivar'
        } este feature flag?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />

      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere eliminar este feature flag?"
        handleAccept={() => deleteFeatureFlag()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default FeaturesFlagTable;
