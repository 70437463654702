import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toaster = ({
  show,
  type,
  text,
  onClose,
}) => (
  <Snackbar
    autoHideDuration={3000}
    open={show}
    onClose={onClose}
  >
    <Alert severity={type}>{text}</Alert>
  </Snackbar>
);

Toaster.propTypes = {
  show: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Toaster;
