/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import useStyle from './useStyle';
import {
  getCategoriesPaymentService,
  updateCategoriesPaymentService,
} from '../../services';
import CategoriesPaymentTable from './components/DataTable/CategoriesPaymentTable';
import ActionForm from './components/ActionForm';
import { Toaster, Loading } from '../../components';

const CategoriesPaymentScreen = () => {
  const classes = useStyle();
  const [categoriesPayment, setCategoriesPayment] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [loading, setLoading] = useState(false);
  const defaultData = {
    nameCategory: '',
    icon: '',
    order: 1,
    status: 'true',
    vendor: '',
  };

  const vendor = [
    {
      label: 'PagaTodo',
      value: 'PagaTodo',
    },
    {
      label: 'BHDL',
      value: 'BHDL',
    },
  ];
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getCategories = async () => {
    if (categoriesPayment.length === 0) {
      setLoading(true);
    }
    const res = await getCategoriesPaymentService();
    setLoading(false);
    return setCategoriesPayment(res?.data);
  };

  const updateCategory = async (category) => {
    const res = await updateCategoriesPaymentService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const openCategoryForm = () => {
    let initialOrderValue = 1;
    if (categoriesPayment.length > 0) {
      for (let v = 1; v < 100; v += 1) {
        const usedOrders = categoriesPayment.find((category) => category.order === v);
        if (usedOrders === undefined) {
          initialOrderValue = v;
          break;
        }
      }
      setDefaultValues({ ...defaultData, order: initialOrderValue });
    }
    setPopUpAction({
      open: true,
      title: 'Crear nueva categoria',
      data: null,
    });
  };

  useEffect(async () => {
    await getCategories();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Tooltip title="Refrescar página" aria-label="Refrescar página">
        <IconButton
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
          disabled
        >
          <img src={reload} alt="reload" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
        <IconButton
          onClick={openCategoryForm}
          style={{ fontSize: '20px', float: 'right' }}
          edge="end"
          size="small"
        >
          <img src={newButton} alt="newButton" />
        </IconButton>
      </Tooltip>
      <div className={classes.root}>
        <CategoriesPaymentTable
          setCategoriesPayment={setCategoriesPayment}
          categoriesData={categoriesPayment}
          setDefaultValues={setDefaultValues}
          setPopUpAction={setPopUpAction}
          updateCategory={updateCategory}
          getCategories={getCategories}
        />
        <ActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          categories={categoriesPayment}
          title={popUpAction.title}
          setDefaultValues={setDefaultValues}
          defaultValues={defaultValues}
          getCategories={getCategories}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
          }}
          setAlertHome={setAlert}
          updateCategory={updateCategory}
          vendorOptions={vendor}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </div>
    </>
  );
};

export default CategoriesPaymentScreen;
