/* eslint-disable import/prefer-default-export */
import { withStyles } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import { PRIMARY, WHITE } from '../../../theme/colors';

export const TableHead = withStyles(() => ({
  root: {
    backgroundColor: PRIMARY,
    color: WHITE,
  },
}))(MuiTableHead);

export const TableHeaderCell = withStyles(() => ({
  root: {
    color: 'white',
  },
}))(TableCell);
