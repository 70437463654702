/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-dynamic-require
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import DivImage, { IconWrapper } from '../tableStyle';

const CategoriesRechargeTable = (props) => {
  const classes = useStyle();
  const { categories } = props;
  const [categoriesRows, setCategoriesRows] = React.useState([]);
  const providerRef = React.useRef();

  function createActions(active) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              name="active"
              disabled
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          disabled
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          disabled
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createImage(componentParam) {
    return (
      <DivImage>
        <IconWrapper>
          {componentParam}
        </IconWrapper>
      </DivImage>
    );
  }

  const mapCodes = async (categoriesParam) => {
    let categoryInfo = [];
    if (categoriesParam) {
      categoryInfo = await Promise.all(
        categoriesParam.map(async (category) => ({
          id: category.categoryId,
          name: category.name,
          order: category.order,
          icon: createImage(category.url),
          actions: createActions(category.active),
          state: 'Activo',
          active: 1,
        })),
      );
    }
    setCategoriesRows(categoryInfo);
  };

  useEffect(() => {
    mapCodes(categories);
  }, [categories]);

  const columns = [
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'icon', label: 'Icono', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'order', label: 'Orden', paddingLeft: '40px', width: 150, position: 3,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 4,
    },
    {
      id: 'state',
      paddingLeft: '',
      label: 'Estado',
      width: 5,
      position: 5,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={categoriesRows}
        columns={columns}
        readOnly
      />
    </SnackbarProvider>
  );
};

export default CategoriesRechargeTable;
