import { useState } from 'react';
import { useAxiosInstance } from '../httpInstance';

export const useRegisteredRoles = () => {
  const [{ loading, data, error }, getAllRoles] = useAxiosInstance(
    // request all registered screens
    {
      url: 'back-office/v1/roles',
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    getAllRoles,
  };
};

export const useDeleteRolById = () => {
  const [rolId, setRolId] = useState();
  const [{ loading, data, error }, deleteRolById] = useAxiosInstance(
    // delete rol
    {
      url: `back-office/v1/roles/${rolId}`,
      method: 'DELETE',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    deleteRolById,
    rolId,
    setRolId,
  };
};

export const useRegisterRol = () => {
  const [{ loading, data, error }, registerRol] = useAxiosInstance(
    // register rol
    {
      url: 'back-office/v1/roles',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    registerRol,
  };
};

export const useUpdateRol = () => {
  const [{ loading, data, error }, updateRol] = useAxiosInstance(
    // register rol
    {
      url: 'back-office/v1/roles',
      method: 'PUT',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    updateRol,
  };
};

export default {
  useRegisteredRoles,
  useDeleteRolById,
  useRegisterRol,
  useUpdateRol,
};
