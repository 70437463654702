import httpInstance from '../httpInstance';

const getCategoriesPaymentService = async () => {
  let res;
  const endpoint = '/back-office/v1/beneficiary-catalog';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getCategoriesPaymentService;
