/* eslint-disable import/prefer-default-export */
import { GridToolbarExport } from '@material-ui/data-grid';
import styled from 'styled-components';

export const GridToolbarExportStyled = styled(GridToolbarExport)`
  color: ${({ theme }) => theme.primary};
`;

export const Box = styled.div`
  width: 100%;
  height: 400px;
`;
