import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  editIcon: {
    color: theme.palette.grey[700],
  },
  dataGridDiv: {
    height: 400,
    width: '100%',
  },
  dataGridComponent: {
    textAlign: 'center',
    '& .styledrows': {
      '& .MuiDataGrid-root, .MuiDataGrid-cell--textLeft': {
        textAlign: 'center',
      },
    },
  },
}));

export default useStyle;
