/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Box,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import {
  getTransactionTypesService,
  createTaxService,
  updateTaxService,
  deleteTaxService,
} from '../../../../services';
import {
  Toaster, Loading, CustomDialog, CustomSwitch,
} from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';

const taxModel = {
  tax_description: '',
  tax_id: '',
  transaction_code: '',
  transaction_name: '',
  state: false,
  channel: '',
  taxes: [
    {
      amount: '',
      description: '',
      id_tax: '',
      name: '',
      percent: '',
    },
  ],
};

const ActionForm = ({
  open, data, title, onClose,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [tax, setTax] = useState(taxModel);
  const [active, setActive] = React.useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const getTransactionTypes = async () => {
    setLoading(true);
    const res = await getTransactionTypesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setTransactionTypes(res.data);
  };

  const createTax = async () => {
    setLoading(true);
    const res = await createTaxService(tax)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 201) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 201) return setAlert({ status: true, type: 'success', message: 'Impuesto creado correctamente' });
    return true;
  };

  const updateTax = async () => {
    setLoading(true);
    const res = await updateTaxService(tax)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'El impuesto ha sido modificado' });
    return true;
  };

  const deleteTax = async () => {
    setLoading(true);
    const res = await deleteTaxService(tax.tax_id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'El impuesto ha sido eliminado' });
    return true;
  };

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  const handleSave = () => {
    tax.state = active;
    if (data) {
      updateTax();
    } else {
      createTax();
    }
  };

  useEffect(() => {
    if (open) getTransactionTypes();
  }, [open]);

  useEffect(() => {
    if (data) {
      setActive(data.state);
      return setTax(data);
    }
    return setTax(taxModel);
  }, [data]);

  const selectorChangeHandler = (e) => {
    setTax({
      ...tax,
      transaction_code: e.target.value,
      transaction_name: e.nativeEvent.target.innerText,
    });
  };

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canEdit}
    >
      {loading
        ? <Loading />
        : (
          <>
            {!canEdit && <Button variant="outlined" color="primary" onClick={() => setCanEdit(true)}>Habilitar edición</Button>}
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            <form noValidate autoComplete="off">
              <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={1}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item md>
                    {transactionTypes && (
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          disabled={!canEdit}
                          id="outlined-select-tax-type"
                          label="Tipo de transacción"
                          select
                          value={tax.transaction_code}
                          onChange={selectorChangeHandler}
                          variant="outlined"
                        >
                          {transactionTypes.map((option) => (
                            <MenuItem key={option.transaction_code} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="name">Nombre</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        value={tax?.taxes[0]?.name || ''}
                        onChange={(e) => setTax({
                          ...tax,
                          taxes: [{
                            ...tax.taxes[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="amount">Monto $</InputLabel>
                      <OutlinedInput
                        id="amount"
                        name="amount"
                        type="number"
                        value={tax?.taxes[0]?.amount || ''}
                        onChange={(e) => setTax({
                          ...tax,
                          taxes: [{
                            ...tax.taxes[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Monto $"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="percent">Porcentaje %</InputLabel>
                      <OutlinedInput
                        id="percent"
                        name="percent"
                        type="number"
                        value={tax?.taxes[0]?.percent || ''}
                        onChange={(e) => setTax({
                          ...tax,
                          taxes: [{
                            ...tax.taxes[0],
                            [e.target.name]: e.target.value,
                          }],
                        })}
                        label="Porcentaje %"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
                <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                  <InputLabel htmlFor="description">Descripción</InputLabel>
                  <OutlinedInput
                    id="description"
                    name="description"
                    value={tax?.taxes[0]?.description || ''}
                    onChange={(e) => setTax({
                      ...tax,
                      taxes: [{
                        ...tax.taxes[0],
                        [e.target.name]: e.target.value,
                      }],
                    })}
                    label="Descripción"
                  />
                </FormControl>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item md>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="channel">Canal</InputLabel>
                      <OutlinedInput
                        id="channel"
                        name="channel"
                        value={tax?.channel || ''}
                        onChange={(e) => setTax({
                          ...tax,
                          [e.target.name]: e.target.value,
                        })}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md>
                    <Paper variant="outlined" style={{ height: 55, width: 155 }}>
                      <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                        <FormControlLabel
                          disabled={!canEdit}
                          control={(
                            <CustomSwitch
                              checked={active}
                              onChange={(e) => setActive(e.target.checked)}
                              name="active"
                              color="primary"
                            />
                    )}
                          label="Estado"
                          labelPlacement="start"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item md />
                  <Grid item md />
                </Grid>

              </Grid>
            </form>
            {data && (
              <div style={{ marginTop: 40, textAlign: 'right' }}>
                {canEdit && <Button variant="contained" color="secondary" onClick={() => setDeleteDialog(true)}>Borrar registro</Button>}
              </div>
            )}
          </>
        )}
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Eliminar impuesto"
        text={`Se eliminará el impuesto ${tax.transaction_name}`}
        okFunction={deleteTax}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
