/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useStyle from './useStyle';

const DataTable = ({ data, onRowClick, actionsDisabled }) => {
  const classes = useStyle;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Transacción</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Canal</TableCell>
            <TableCell align="right">Comisión</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow hover={!actionsDisabled} key={row.id} onClick={() => onRowClick(index)}>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.channel}</TableCell>
              <TableCell align="right">{row.amount ? `RD$ ${row.amount}` : `${row.percentage}%` }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool,
};

export default DataTable;
