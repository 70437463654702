import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { Button, WrapperButton, Card } from '../Taxes/styles';
import ActionForm from './partials/ActionForm/ActionForm';
import Table from './partials/Table/Table';
import { Loading, CustomDialog, Toaster } from '../../components';
import { CenteredText } from './styles';
import AlertConfirm from '../../components/Crosscutting/AlertConfirm';
import getTypePromotionsService from '../../services/typePromotions/getTypePromotions';
import deleteTypePromotion from '../../services/typePromotions/deleteTypePromotion';

const TypePromotionsScreen = () => {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dataDelete, setDataDelete] = useState();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [alertPromos, setAlertPromos] = useState(false);
  const [messageRelatedPromos, setMessageRelatedPromos] = useState([]);
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    new: false,
    title: 'Crear nuevo tipo de promoción',
    data: null,
  });

  const onRowClick = (id) => {
    const promotion = promotions.find((el) => el.id === id);
    setPopUpAction({
      title: 'Editar tipo de promoción',
      data: promotion,
      open: true,
      new: false,
    });
  };

  const onClose = (info) => {
    if (info?.action === 'CREATED') setPromotions((prev) => ([...prev, info.res]));
    if (info?.action === 'DELETED') {
      const newPromotions = promotions.filter((promotion) => promotion.id !== info.res.id);
      setPromotions(newPromotions);
    }
    if (info?.action === 'UPDATED') {
      const promotionsUpdated = promotions.map((promotion) => (
        promotion.id === info.res.id
          ? info.res
          : promotion
      ));
      setPromotions(promotionsUpdated);
    }

    setPopUpAction({
      open: false,
      new: false,
      title: 'Crear nuevo tipo de promoción',
      data: null,
    });
  };

  const messegePromosRelated = () => {
    if (messageRelatedPromos?.length > 0) {
      const tagsPromos = messageRelatedPromos.map((item) => (
        <Typography>
          {`id: ${item.id.split('-')[0]} - promoción: ${item.promotionName}`}
        </Typography>
      ));
      return (
        <div>
          <Typography>Aún hay promociones ligadas a este registro:</Typography>
          {tagsPromos}
        </div>
      );
    }
    return <div />;
  };

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteTypePromotion(dataDelete.id);
    setLoading(false);
    if (res?.status === 200) {
      onClose({ action: 'DELETED', res: { id: dataDelete.id } });
      setAlert({ status: true, type: 'success', message: 'La tipo de promoción ha sido eliminada' });
    } else {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      if (res?.data.details[0]?.error_code === 'type-use-in-a-promotion') {
        setMessageRelatedPromos(res?.data.details[0]?.error_detail);
        setAlertPromos(true);
      }
    }
    setDataDelete(undefined);
  };

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const res = await getTypePromotionsService();
      setLoading(false);
      if (res) setPromotions(res);
    };

    initData();
  }, []);

  if (loading) return <Loading />;

  return (
    <Card>
      {promotions.length > 0
        ? (
          <Table
            data={promotions}
            onRowClick={onRowClick}
            onDelete={(promotion) => {
              setDeleteDialog(true);
              setDataDelete(promotion);
            }}
          />
        )
        : <CenteredText>Aun no hay registros</CenteredText>}
      <WrapperButton>
        <Button
          color="primary"
          aria-label="add"
          onClick={() => setPopUpAction((prev) => ({
            ...prev,
            data: null,
            open: true,
            new: true,
          }))}
        >
          <AddIcon />
        </Button>
      </WrapperButton>
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Confirmación"
        text={`Se eliminará el tipo de promoción: ${dataDelete?.promotionTypeName}`}
        okFunction={onDelete}
        notOkFunction={() => { }}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        newPromotion={popUpAction.new}
        onClose={onClose}
        setAlert={setAlert}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
      <AlertConfirm
        isOpen={alertPromos}
        tituloModal="Error al eliminar el tipo"
        mensajeModal={messegePromosRelated()}
        onClose={() => setAlertPromos(false)}
        handleAccept={() => setAlertPromos(false)}
        textButtonAcept="Entendido"
      />
    </Card>
  );
};

export default TypePromotionsScreen;
