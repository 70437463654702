import httpInstance from '../httpInstance';

const sendDecodeCardId = async (cardId) => {
  let res;
  const endpoint = '/coder/v1/decode/card-id';
  const data = { cardId };
  const headers = { 'Content-Type': 'application/json' };
  await httpInstance.post(endpoint, data, { headers }).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default sendDecodeCardId;
