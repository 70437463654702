// eslint-disable-next-line import/no-extraneous-dependencies
import httpInstance from '../httpInstance';

export const createCategoryService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/categories';
  const dataSent = dataParam;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const updateCategoryService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/categories';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const getCategoryService = async () => {
  let res;
  const endpoint = '/back-office/v1/categories';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const getCategoryByIdService = async (id) => {
  let res;
  const endpoint = `/back-office/v1/categories/${id}`;

  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export const deleteCategoryByIdService = async (id) => {
  let res;
  const endpoint = `/back-office/v1/categories/${id}`;

  httpInstance.head();
  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default {
  getCategoryService,
  deleteCategoryByIdService,
  getCategoryByIdService,
  updateCategoryService,
  createCategoryService,
};
