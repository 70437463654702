import React from 'react';
import { Backdrop } from '@material-ui/core';
import { CircularProgressStyled, TypographyStyled } from './styles';

const Loading = () => (
  <Backdrop open style={{ backgroundColor: '#FFFFFF99', zIndex: 1000000 }}>
    <CircularProgressStyled />
    <TypographyStyled>
      Procesando...
    </TypographyStyled>
  </Backdrop>
);

export default Loading;
