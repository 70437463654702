import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    borderRadius: 10,
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: '504px',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
  },
  disabled: {
    opacity: 0.5,
  },
}));

export default useStyle;
