/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 16px;
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  flex-direction: column;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`;

export const Button = styled.button`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.white};
  border: none;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
  &:active {
    background-color: ${({ theme }) => theme.primary80};
    background-size: 100%;
    transition: background 0s;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.black10};
    cursor: auto;
    color: ${({ theme }) => theme.black20};
    box-shadow: none;
    opacity: 0.5;
  }
`;
