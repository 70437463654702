import httpInstance from '../httpInstance';

const deleteDeviceService = async (deviceId) => {
  let res;
  const endpoint = `/card-acceptance/v1/devices/${deviceId}`;

  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default deleteDeviceService;
