const removeFileExtension = (file) => {
  if (file.includes('PENDING')) {
    return file.split('_PENDING')[0];
  }
  if (file.includes('COMPLETED')) {
    return file.split('_COMPLETED')[0];
  }
  if (file.includes('APPROVED')) {
    return file.split('_APPROVED')[0];
  }
  return file;
};

export default removeFileExtension;
