import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { getTaxesService } from '../../services';
import { Toaster } from '../../components';
import { ReadOnlyActionForm, ReadOnlyDataTable } from './components';
import { InternalPage } from '../../Layouts';
import { Button, WrapperButton, Card } from './styles';

const TaxesScreen = () => {
  const [taxesList, setTaxesList] = useState();
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(true);

  const getTaxesList = async () => {
    setLoading(true);
    const res = await getTaxesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setTaxesList(res.data);
  };

  const tableRowClickHandler = (index) => {
    setPopUpAction({ open: true, title: 'Impuesto - Solo lectura', data: taxesList[index] });
  };

  useEffect(() => {
    getTaxesList();
  }, []);

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Card>
        {taxesList && (
          <ReadOnlyDataTable data={taxesList} onRowClick={tableRowClickHandler} />
        )}
        <WrapperButton>
          <Button color="primary" aria-label="add" disabled>
            <AddIcon />
          </Button>
        </WrapperButton>
        <ReadOnlyActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          title={popUpAction.title}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
            getTaxesList();
          }}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Card>
    </InternalPage>
  );
};

export default TaxesScreen;
