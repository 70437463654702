/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useState, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Button,
  Paper,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PatternFormat from 'react-number-format';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import moment from 'moment';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import { SnackbarProvider } from 'notistack';
import { imageProps } from './constants';
import formStyle, { WrapContainer, StyledImage, MessageRequired } from './formStyle';
import { Rules } from '../../../helpers/RHFRules';
import {
  Toaster,
  CustomInput,
  CustomSwitch,
  CustomSelect,
} from '../../../components';
import { getLocationAddress } from '../../../services';
import { ActionPopUp } from '../../../Layouts';

const ActionForm = ({
  open,
  data,
  title,
  defaultValues,
  setDefaultValues,
  onClose,
  categories,
  typePromotions,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [canEdit, setCanEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('MM/DD/yyyy'));
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [endDate, setEndDate] = useState(moment().add(1, 'day').format('MM/DD/yyyy'));
  const [active, setActive] = React.useState(false);
  const [isVirtual, setIsVirtual] = React.useState(false);
  const [images, updateImages] = useReducer((prev, next) => {
    const newImages = { ...prev, ...next };
    return newImages;
  }, {
    imageLogo: { ...imageProps }, imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageImportant: { ...imageProps },
  });

  const classes = formStyle();
  const providerRef = React.useRef();

  const {
    control, errors, reset, watch,
  } = useForm({ defaultValues });

  const {
    lat,
    long,
  } = watch(['lat', 'long']);

  const resetFields = () => {
    setCanEdit(false);
    setActive(false);
    updateImages({
      imageLogo: { ...imageProps }, imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageImportant: { ...imageProps },
    });

    setDefaultValues({
      highlight: '',
      categoryOrder: 1,
      featureOrder: 1,
      promotionName: '',
      commerceName: '',
      commerceLogo: '',
      description: '',
      startDate: '',
      endDate: '',
      details: '',
      imageBig_base64: '',
      imageLit_base64: '',
      featureImage_base64: '',
      category: '',
      promotionType: '',
      commerceLocation: {
        address: '',
        geolocation: '',
        virtualCommerce: false,
      },
      address: '',
      lat: '',
      long: '',
      contactInformation: {
        socialMedia: '',
        phone: '',
        whatsapp: '',
        webPage: '',
      },
      phone: '',
      weburl: '',
      whatsapp: '',
      facebook: '',
      instagram: '',
      status: '',
      featurePromotion: false,
    });
  };

  const handleAddress = async (_address) => {
    if (_address !== '') {
      await getLocationAddress(_address).then((result) => {
        setLongitude(result.location.lng);
        setLatitude(result.location.lat);
      }).catch((err) => {
        setAlert({ status: true, type: 'warning', message: err.message });
      });
    }
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };

  const onFileUploadIcon = (prop) => (event) => {
    event.preventDefault();
    // Get the file Id
    const { id } = event.target;
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
      updateImages({ ...images, [prop]: { ...imageProps, invalidImage: 'Debe cargar un archivo valido (.jpg, .jpeg, png, pdf).' } });
      file_reader.abort();
      return false;
    }
    if (file.size > 240000 && prop === 'imageLit') {
      updateImages({ ...images, [prop]: { ...imageProps, invalidImage: 'El tamaño del archivo supera el máximo permitido (30KB).' } });
      file_reader.abort();
      return false;
    }

    if (file.size > 240000 && prop === 'imageLogo') {
      updateImages({ ...images, [prop]: { ...imageProps, invalidImage: 'El tamaño del archivo supera el máximo permitido (30KB).' } });
      file_reader.abort();
      return false;
    }

    if (file.size > 2728000 && prop === 'imagebig') {
      updateImages({ ...images, [prop]: { ...imageProps, invalidImage: 'El tamaño del archivo supera el máximo permitido (341KB).' } });
      file_reader.abort();
      return false;
    }

    if (file.size > 2728000 && prop === 'imageImportant') {
      updateImages({ ...images, [prop]: { ...imageProps, invalidImage: 'El tamaño del archivo supera el máximo permitido (341KB).' } });
      file_reader.abort();
      return false;
    }

    file_reader.onload = () => {
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
      updateImages({ ...images, [prop]: { ...imageProps, b64: file_reader.result, name: file !== null && file.name !== null ? file.name : '' } });
    };

    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, value, defaultValue, ...other
    } = props;
    return (
      <PatternFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        isNumericString
        value={value}
        defaultValue={(defaultValue && defaultValue.target) ? defaultValue.target.value : defaultValue}
        format="(###) ### ####"
      />
    );
  }

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(async () => {
    // Set date for edition
    if (defaultValues.startDate !== '' && defaultValues.endDate !== '') {
      setStartDate(moment(defaultValues.startDate).format('MM/DD/yyyy'));
      setEndDate(moment(defaultValues.endDate).format('MM/DD/yyyy'));
    }
    // Set Images for edition
    if (defaultValues.imageBig_base64 !== ''
      && defaultValues.imageLit_base64 !== ''
      && defaultValues.featureImage_base64 !== '') {
      updateImages({
        ...images,
        imageBig: { ...imageProps, urlImage: defaultValues.imageBig_base64 },
        imageLit: { ...imageProps, urlImage: defaultValues.imageLit_base64 },
        imageImportant: { ...imageProps, urlImage: defaultValues.featureImage_base64 },
        imageLogo: { ...imageProps, urlImage: defaultValues.commerceLogo },
      });
    }
    // Set location points for edition
    if (defaultValues.latitude !== 0 && defaultValues.longitude !== 0) {
      setLatitude(defaultValues.lat);
      setLongitude(defaultValues.long);
    }
    // Set active check edition
    if (defaultValues.status === 'activo') {
      setActive(true);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data !== null) setCanEdit(true);
  }, [data]);

  useEffect(() => {
    setLatitude(lat);
  }, [lat]);

  useEffect(() => {
    setLongitude(long);
  }, [long]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlert({ status: true, type: 'warning', message: 'campos obligatorios faltantes' });
    }
  }, [errors]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        withoutSaveButton
        readOnly
      >
        <>
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off" className={classes.formContainer}>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  Estatus de la promoción:
                </Typography>
              </Grid>
              {/* Estatus */}
              <Grid item xs={12} sm={12} md lg>
                <Paper variant="outlined" className={classes.paperbox}>
                  <FormControlLabel
                    className={classes.labelSwitch}
                    control={(
                      <CustomSwitch
                        checked={active}
                        name="active"
                        color="primary"
                      />
                    )}
                    disabled
                    label={`Estatus de la promoción: ${active ? 'Activo' : 'Inactivo'}`}
                    labelPlacement="start"
                  />
                </Paper>
                {(active && data !== null && (moment() > moment(data.endDate || moment() > moment(data.startDate))))
                  && (
                    <Typography variant="overline">
                      <b>La promoción no sera visible en la aplicacion ya que las fechas de vigencia aun no inicia o ya ha expirado.</b>
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} />
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Datos generales de la promoción:
                </Typography>
              </Grid>
              {/* @#promocion */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre promoción*"
                  name="promotionName"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 48,
                  }}
                  disabled={canEdit}
                  helperText="48 caracteres"
                />
              </Grid>
              {/* @#comercio */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre comercio*"
                  name="commerceName"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 31,
                  }}
                  disabled={canEdit}
                  helperText="31 caracteres"
                />
              </Grid>
              {/* @#descripcion */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción corta promoción*"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  multiline
                  inputProps={{
                    maxLength: 48,
                  }}
                  rows={3}
                  disabled={canEdit}
                  helperText="48 caracteres"
                />
              </Grid>
              {/* @#Detalles */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Detalles de la promoción*"
                  name="details"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  multiline
                  inputProps={{
                    maxLength: 272,
                  }}
                  rows={3}
                  disabled={canEdit}
                  helperText="272 caracteres"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} />
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <CustomInput
                  label="Texto destacado (highligth)*"
                  name="highlight"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  multiline
                  inputProps={{
                    maxLength: 17,
                  }}
                  disabled={canEdit}
                  helperText="17 caracteres"
                />
              </Grid>
              {/* @#Fecha inicio */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl>
                  <Controller
                    render={() => (
                      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.dateCustom}
                          id="startDate"
                          variant="inline"
                          inputVariant="outlined"
                          label="Inicio vigencia *"
                          format="dd/MM/yyyy"
                          size="small"
                          minDate={moment().format('MM/DD/yyyy')}
                          FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                          onChange={(e) => setStartDate(e)}
                          value={startDate}
                          disablePast
                          minDateMessage="La fecha de inicio no puede ser menor a la actual."
                          disabled={canEdit}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="startDate"
                    control={control}
                    error={errors.startDate}
                  />
                </FormControl>
              </Grid>
              {/* @#Fecha Final */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl>
                  <Controller
                    render={() => (
                      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.dateCustom}
                          id="endDate"
                          variant="inline"
                          inputVariant="outlined"
                          label="Fin vigencia *"
                          format="dd/MM/yyyy"
                          size="small"
                          FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                          onChange={(e) => setEndDate(e)}
                          value={endDate}
                          minDate={moment().add(1, 'day').format('MM/DD/yyyy')}
                          disablePast
                          minDateMessage="La fecha de final no puede ser menor a la inicial."
                          disabled={canEdit}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                    error={errors}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Imagenes promoción:
                </Typography>
              </Grid>
              {/* @#imagen logo */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="imageLogo">
                    <input
                      onChange={onFileUploadIcon('imageLogo')}
                      id="imageLogo"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled={canEdit}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled={canEdit}
                    >
                      IMAGEN LOGO (80 x 80 px)
                    </Button>
                    {images.imageLogo.invalidImage !== '' && (
                      <Typography className={classes.formErrors}>
                        {images.imageLogo.invalidImage}
                      </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageLogo.b64 !== '' || images.imageLogo.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewLogo"
                      src={images.imageLogo.urlImage !== '' ? images.imageLogo.urlImage : images.imageLogo.b64}
                      width={60}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageLogo.name}
                    </Typography>
                  </Grid>
                )}
                {
                  Object.keys(errors).length > 0 && images.imageLogo.b64 === '' && data === null
                  && (
                  <>
                    <MessageRequired>Campo obligatorio</MessageRequired>
                  </>
                  )
                }
              </Grid>
              {/* @#imagen grande */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="imageBig">
                    <input
                      onChange={onFileUploadIcon('imageBig')}
                      id="imageBig"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled={canEdit}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled={canEdit}
                    >
                      IMAGEN GRANDE (335 x 130 px)
                    </Button>

                    {images.imageBig.invalidImage !== '' && (
                      <Typography className={classes.formErrors}>
                        {images.imageBig.invalidImage}
                      </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageBig.b64 !== '' || images.imageBig.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewGrande"
                      src={images.imageBig.urlImage ? images.imageBig.urlImage : images.imageBig.b64}
                      width={290}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageBig.name}
                    </Typography>
                  </Grid>
                )}
                {
                  Object.keys(errors).length > 0 && images.imageBig.b64 === '' && data === null
                  && (
                  <>
                    <MessageRequired>Campo obligatorio</MessageRequired>
                  </>
                  )
                }
              </Grid>
              {/* @#imagen pequeña */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="imageLit">
                    <input
                      onChange={onFileUploadIcon('imageLit')}
                      id="imageLit"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled={canEdit}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled={canEdit}
                    >
                      IMAGEN PEQUEÑA (102 x 158 px)
                    </Button>

                    {images.imageLit.invalidImage !== '' && (
                      <Typography className={classes.formErrors}>
                        {images.imageLit.invalidImage}
                      </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageLit.b64 !== '' || images.imageLit.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewPeq"
                      src={images.imageLit.urlImage ? images.imageLit.urlImage : images.imageLit.b64}
                      width={120}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageLit.name}
                    </Typography>
                  </Grid>
                )}
                {
                  Object.keys(errors).length > 0 && images.imageLit.b64 === '' && data === null
                  && (
                  <>
                    <MessageRequired>Campo obligatorio</MessageRequired>
                  </>
                  )
                }
              </Grid>
              {/* @#imagen destacada */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="imageImportant">
                    <input
                      onChange={onFileUploadIcon('imageImportant')}
                      id="imageImportant"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled={canEdit}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImageLarge}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled={canEdit}
                    >
                      IMAGEN DESTACADA (161 x 241 px)
                    </Button>

                    {images.imageImportant.invalidImage !== '' && (
                      <Typography className={classes.formErrors}>
                        {images.imageImportant.invalidImage}
                      </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageImportant.b64 !== '' || images.imageImportant.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewDest"
                      width={180}
                      src={images.imageImportant.urlImage ? images.imageImportant.urlImage : images.imageImportant.b64}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageImportant.name}
                    </Typography>
                  </Grid>
                )}
                {
                  Object.keys(errors).length > 0 && images.imageImportant.b64 === '' && data === null
                  && (
                  <>
                    <MessageRequired>Campo obligatorio</MessageRequired>
                  </>
                  )
                }
              </Grid>
              <Grid item xs sm md lg />
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Categoría y tipo de promoción:
                </Typography>
              </Grid>
              {/* @#categoria promocion */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Categorias*"
                  name="category"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  disabled={canEdit}
                  options={categories}
                />
              </Grid>
              {/* @#Tipo promocion */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Tipo promoción*"
                  name="promotionType"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  disabled={canEdit}
                  options={typePromotions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md lg />
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Información de contacto del negocio:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper variant="outlined" className={classes.paperbox}>
                  <FormControlLabel
                    className={classes.labelSwitch}
                    disabled={canEdit}
                    control={(
                      <CustomSwitch
                        checked={isVirtual}
                        onChange={(e) => setIsVirtual(e.target.checked)}
                        name="virtual"
                        color="primary"
                      />
                    )}
                    label="¿Es tienda virtual?"
                    labelPlacement="start"
                  />
                </Paper>
              </Grid>
              {/* @#Direccion */}
              { !isVirtual && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <FormControl fullWidth>
                      <Controller
                        render={({ value, onChange }) => (
                          <TextField
                            label="Dirección*"
                            id="address"
                            size="small"
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              maxLength: 150,
                            }}
                            value={value}
                            disabled={canEdit || isVirtual}
                            helperText="150 caracteres"
                            onChange={onChange}
                            onBlur={(e) => handleAddress(e.target.value)}
                          />
                        )}
                        name="address"
                        control={control}
                        error={errors.address}
                        rule={isVirtual ? null : Rules.required}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <Controller
                        render={({ value, onChange }) => (
                          <TextField
                            label="Latitud*"
                            id="lat"
                            size="small"
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              maxLength: 20,
                            }}
                            onChange={(e) => onChange(e)}
                            value={latitude}
                            defaultValue={value !== '' ? value : 0}
                            disabled={canEdit}
                          />
                        )}
                        name="lat"
                        control={control}
                        error={errors.lat}
                        rule={isVirtual ? null : Rules.required}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                      <Controller
                        render={({ value, onChange }) => (
                          <TextField
                            label="Longitud*"
                            id="long"
                            size="small"
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              maxLength: 20,
                            }}
                            onChange={onChange}
                            value={longitude}
                            defaultValue={value !== '' ? value : 0}
                            disabled={canEdit}
                          />
                        )}
                        name="long"
                        control={control}
                        error={errors.long}
                        rule={isVirtual ? null : Rules.required}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {/* @#pagina web */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Página Web"
                  name="weburl"
                  control={control}
                  rule={Rules.urlValidate}
                  error={errors}
                  disabled={canEdit}
                  helperText="formato: www.mitienda.com"
                />
              </Grid>
              {/* @#Telefono */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth>
                  <Controller
                    render={({ value, onChange }) => (
                      <TextField
                        id="phone"
                        size="small"
                        variant="outlined"
                        label="Teléfono"
                        onChange={(e) => onChange(e)}
                        defaultValue={value}
                        fullWidth
                        disabled={canEdit}
                        InputProps={value !== ' ' && {
                          inputComponent: NumberFormatCustom,
                        }}
                        helperText="Teléfono a 10 digitos"
                      />
                    )}
                    name="phone"
                    control={control}
                    error={errors.phone}
                    rule={{ pattern: { ...Rules.phone.pattern } }}
                  />
                </FormControl>
              </Grid>
              {/* @#Whatsapp */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl fullWidth>
                  <Controller
                    render={({ value, onChange }) => (
                      <TextField
                        id="whatsapp"
                        size="small"
                        variant="outlined"
                        label="WhatsApp"
                        onChange={(e) => onChange(e)}
                        defaultValue={value}
                        fullWidth
                        disabled={canEdit}
                        InputProps={value !== ' ' && {
                          inputComponent: NumberFormatCustom,
                        }}
                        helperText="Teléfono a 10 digitos"
                      />
                    )}
                    name="whatsapp"
                    control={control}
                    error={errors.phone}
                    rule={{ pattern: { ...Rules.phone.pattern } }}
                  />
                </FormControl>
              </Grid>
              {/* @#Facebook */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Facebook"
                  name="facebook"
                  control={control}
                  rule={Rules.urlFacebook}
                  error={errors}
                  inputProps={{
                    maxLength: 150,
                  }}
                  disabled={canEdit}
                  helperText="formato: www.facebook.com/#identificadorTienda#/"
                />
              </Grid>
              {/* @#Instragram */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  label="Instragram"
                  name="instagram"
                  control={control}
                  rule={Rules.urlInstagram}
                  error={errors}
                  inputProps={{
                    maxLength: 150,
                  }}
                  disabled={canEdit}
                  helperText="formato: www.instagram.com/#identificadorTienda#/"
                />
              </Grid>
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
