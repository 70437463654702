// eslint-disable-next-line import/prefer-default-export
export const INIT = [
  {
    description: '',
    limits: JSON.stringify({
      p2pTransfer: {
        in: {
          countLimit: {
            daily: '50',
            weekly: '150',
            monthly: '250',
          },
          amountLimit: {
            daily: '65100',
            weekly: '65100',
            monthly: '65100',
          },
          active: true,
        },
        out: {
          countLimit: {
            daily: '100',
            weekly: '300',
            monthly: '500',
          },
          amountLimit: {
            daily: '65100',
            weekly: '65100',
            monthly: '65100',
          },
          active: true,
        },
      },
      atmWithdrawals: {
        countLimit: {
          daily: '5',
          weekly: '25',
          monthly: '60',
        },
        amountLimit: {
          daily: '65100',
          weekly: '65100',
          monthly: '65100',
        },
        active: true,
      },
      cashin: {
        countLimit: {
          daily: '100',
          weekly: '300',
          monthly: '500',
        },
        amountLimit: {
          daily: '65100',
          weekly: '65100',
          monthly: '65100',
        },
        active: true,
      },
      ecommerce: {
        countLimit: {
          daily: '5',
          weekly: '25',
          monthly: '60',
        },
        amountLimit: {
          daily: '65100',
          weekly: '65100',
          monthly: '65100',
        },
        active: true,
      },
      posPurchases: {
        countLimit: {
          daily: '10',
          weekly: '70',
          monthly: '300',
        },
        amountLimit: {
          daily: '65100',
          weekly: '65100',
          monthly: '65100',
        },
        active: true,
      },
      p2mTransfer: {
        in: {
          countLimit: {
            daily: '50',
            weekly: '150',
            monthly: '250',
          },
          amountLimit: {
            daily: '65100',
            weekly: '65100',
            monthly: '65100',
          },
          active: true,
        },
        out: {
          countLimit: {
            daily: '100',
            weekly: '300',
            monthly: '500',
          },
          amountLimit: {
            daily: '65100',
            weekly: '65100',
            monthly: '65100',
          },
          active: true,
        },
      },
      cashout: {
        countLimit: {
          daily: '50',
          weekly: '150',
          monthly: '250',
        },
        amountLimit: {
          daily: '65100',
          weekly: '65100',
          monthly: '65100',
        },
        active: true,
      },
    }),
    segment: 'S1',
  },
];

export const NAME_OPERATIONS = {
  p2pTransfer: 'P2P',
  atmWithdrawals: 'Retiro ATM',
  cashin: 'Cashin - API',
  ecommerce: 'Compra Ecommerce',
  posPurchases: 'Compra Pos',
  p2mTransfer: 'P2M',
  cashout: 'Cashout - API',
};

export const TYPE_TRANSACTION = {
  p2pTransfer_in: 'Crédito',
  p2pTransfer_out: 'Débito',
  atmWithdrawals_in: 'Débito',
  cashin_in: 'Crédito',
  ecommerce_in: 'Débito',
  posPurchases_in: 'Débito',
  p2mTransfer_in: 'Crédito',
  p2mTransfer_out: 'Débito',
  cashout_in: 'Débito',
};

export const SPECIAL_OPERATIONS = ['p2pTransfer', 'p2mTransfer'];
