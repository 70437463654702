/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller } from 'react-hook-form';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { getRemittancesService } from '../../services';
import { Toaster, Loading } from '../../components';
import RemittancesTable from './components/DataTable/RemittancesTable';
import {
  Box,
  Button,
  ButtonContainer,
  Card,
} from './styles';

const RemittancesScreen = () => {
  const classes = useStyle();

  const [loading, setLoading] = useState(false);
  const [creationAction, setCreationAction] = useState(true);
  const [remittences, setRemittences] = useState();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const {
    control, errors, setValue,
  } = useForm({ casham_day_rate: '', banks_average_rate: '' });

  const getRemittences = async () => {
    setLoading(true);
    const res = await getRemittancesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res.data[0] !== undefined) {
      setValue('banks_average_rate', res.data[0]?.banks_average_rate);
      setValue('casham_day_rate', res.data[0]?.casham_day_rate);
      setCreationAction(false);
    }
    return setRemittences(res.data);
  };

  useEffect(() => {
    getRemittences();
  }, []);

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Card>
        {loading
          ? <Loading />
          : (
            <>
              <form noValidate autoComplete="off">
                <Box>
                  <Box>
                    <InputLabel>Tasa del Dia CASHAM</InputLabel>
                  </Box>
                  <Box>
                    <FormControl>
                      <Controller
                        as={(
                          <FormControlLabel
                            control={(
                              <TextField
                                disabled
                                size="small"
                                className={classes.textFieldShape}
                                variant="outlined"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                onChange={(e) => setValue('casham_day_rate', e.target.value)}
                                color="primary"
                                FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                                helperText={errors.casham_day_rate ? errors.casham_day_rate.message : ''}
                                inputProps={{
                                  maxLength: 15,
                                }}
                              />
                            )}
                            labelPlacement="start"
                          />
                        )}
                        name="casham_day_rate"
                        control={control}
                        error={errors.casham_day_rate}
                        rules={{
                          required: 'Campo obligatorio',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box marginTop={20}>
                  <Box>
                    <InputLabel>Tasa Promedio Bancos RD</InputLabel>
                  </Box>
                  <Box>
                    <FormControl>
                      <Controller
                        as={(
                          <FormControlLabel
                            control={(
                              <TextField
                                disabled
                                size="small"
                                className={classes.textFieldShape}
                                variant="outlined"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                required
                                onChange={(e) => setValue('banks_average_rate', e.target.value)}
                                color="primary"
                                FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                                helperText={errors.banks_average_rate ? errors.banks_average_rate.message : ''}
                                inputProps={{
                                  maxLength: 15,
                                }}
                              />
                            )}
                            labelPlacement="start"
                          />
                        )}
                        name="banks_average_rate"
                        control={control}
                        error={errors.banks_average_rate}
                        rules={{
                          required: 'Campo obligatorio',
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <ButtonContainer>
                  <Button type="submit" onClick={(e) => e.preventDefault()} disabled>
                    {creationAction ? 'Crear' : 'Editar'}
                  </Button>
                </ButtonContainer>
              </form>
              <Box paddingTop={10}>
                <RemittancesTable remittances={remittences} />
              </Box>
              <Toaster
                show={alert.status}
                type={alert.type}
                text={alert.message}
                onClose={() => setAlert({ ...alert, status: false })}
              />
            </>
          )}
      </Card>
    </InternalPage>
  );
};

export default RemittancesScreen;
