import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import PaymentLinksTable from './components/DataTable/PaymentLinksTable.readonly';
import SearchBox from '../../components/SearchBox/SearchBox';
import newButton from '../../assets/img/iconos/newButton.svg';
import ActionForm from './components/ActionForm/ActionForm';
import { FILTER_OPTIONS } from '../../components/SearchBox/constants';
import defaultData from './constants';
import { Loading, Toaster } from '../../components';
import getPaymentLinksService from '../../services/paymentsLink/getPaymentLinksService';

const PaymentsLinkScreen = () => {
  const classes = useStyle();
  const chunkSize = 80;
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [keyFilter, setKeyFilter] = useState(FILTER_OPTIONS.COMMERCE_ID);
  const [showAlert, setShowAlert] = useState(false);
  const [totalElementsData, setTotalElementsData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentChunk, setCurrentChunk] = useState(0);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    title: '',
    data: null,
  });

  const filters = {
    'RNC/Cédula': 'documentNumber',
    'Nombre del comercio': 'commercialName',
    'ID del comercio': 'merchantIdCardnet',
  };

  const createCommercePaymentLink = () => {
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: 'Configurar comercio Link de pago',
      data: null,
    });
  };

  const getPaymentLinksData = async () => {
    try {
      const response = await getPaymentLinksService({
        pageSize: chunkSize, pageNumber: currentChunk, keyFilter: filters[keyFilter], searchQuery,
      });
      setPaymentLinks((paymentLinksData) => [
        ...paymentLinksData, ...response.data.list_of_commerces,
      ]);
      setLoading(false);
      setTotalElementsData(response.data.total_elements);
    } catch (error) {
      setShowAlert(true);
    }
  };

  const handleSearchPress = () => {
    if (searchQuery) {
      setLoading(true);
      setPaymentLinks([]);
      getPaymentLinksData();
    }
  };

  const handleNext = () => {
    if (paymentLinks.length === totalElementsData) return;
    if (
      paymentLinks.length < (currentPage + 2) * currentPageSize
    ) {
      setCurrentChunk((oldChunk) => oldChunk + 1);
    }
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handleNewPageSize = (newPageSize) => {
    setCurrentPageSize(Number(newPageSize));
    setCurrentPage(0);
  };

  useEffect(() => {
    getPaymentLinksData();
  }, [currentChunk, chunkSize]);

  return (
    <Paper className={classes.paper}>
      <>
        <div className={classes.root}>
          {loading ? <Loading /> : null}
          <div>
            <SearchBox
              handleSearchPress={handleSearchPress}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setKeyFilter={setKeyFilter}
            />
            <Tooltip title="Configurar comercio" aria-label="Nuevo comercio">
              <IconButton
                onClick={createCommercePaymentLink}
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
                disabled
              >
                <img src={newButton} alt="newButton" className={classes.imgColorReadOnly} />
              </IconButton>
            </Tooltip>
          </div>
          <PaymentLinksTable
            paymentLinks={paymentLinks}
            enteredFilter={searchQuery}
            setPopUpAction={setPopUpAction}
            defaultValues={defaultValues}
            setDefaultValues={setDefaultValues}
            totalElementsData={totalElementsData}
            onNext={handleNext}
            onSelectPageSize={handleNewPageSize}
            searchQuery={searchQuery}
            loading={loading}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            title={popUpAction.title}
            defaultValues={defaultValues}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
              setPaymentLinks([]);
              getPaymentLinksData();
            }}
          />
        </div>
      </>
      <Toaster
        show={showAlert}
        type="error"
        text="Ha ocurrido un error, intente nuevamente."
        onClose={() => setShowAlert(false)}
      />
    </Paper>
  );
};
export default PaymentsLinkScreen;
