/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, Toaster, CustomSwitch,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import {
  createFeaturesFlagCommerceService,
  updateFeaturesFlagCommerceService,
} from '../../../../services';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getFeaturesFlag,
  defaultValues,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = React.useState(true);
  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset,
  } = useForm({ defaultValues });

  const createFeatureFlag = async (featureFlag) => {
    try {
      const res = await createFeaturesFlagCommerceService(featureFlag);
      if (res?.status !== 201) {
        return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
      return true;
    } catch (err) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  const updateFeatureFlag = async (featureFlag) => {
    try {
      const res = await updateFeaturesFlagCommerceService(featureFlag);
      if (res?.status !== 200) {
        return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
      return true;
    } catch (error) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  const handleOnclose = () => {
    onClose();
  };
  const handleSave = async (dataParam) => {
    const body = {
      code: dataParam.code,
      description: dataParam.description,
      name: dataParam.name,
      active: active ? 1 : 0,
    };
    let isSavedorUpdate = false;
    let message;
    if (data) {
      body.id = data.id;
      isSavedorUpdate = await updateFeatureFlag(body);
      message = 'Se ha editado el feature flag comercio exitosamente';
    } else {
      isSavedorUpdate = await createFeatureFlag(body);
      message = 'Se ha creado el feature flag comercio exitosamente';
    }
    if (isSavedorUpdate) {
      await getFeaturesFlag();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      onClose();
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.active);
    }
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >

        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justify="space-evenly"
              alignItems="stretch"
              spacing={2}
            >

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre *"
                  name="name"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Código *"
                  name="code"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                          name="active"
                          color="black"
                        />
                    )}
                      label="Estado"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} />

            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
