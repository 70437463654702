import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  seeIcon: {
    color: '#50B940',
  },
  downloadIcon: {
    color: '#449FDD',
  },
}));

export default useStyle;
