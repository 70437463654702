import httpInstancePaymentLink from '../httpInstancePaymentLink';

const putMerchantService = async (dataParam) => {
  let res;
  const endpoint = 'api/v1/merchants';
  const dataSent = dataParam;

  await httpInstancePaymentLink.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default putMerchantService;
