import httpInstance from './httpInstance';

const getTransactionTypesService = async () => {
  let res;
  const endpoint = '/fares/v1/types_tx_codes';
  await httpInstance.get(endpoint).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getTransactionTypesService;
