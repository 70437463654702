import httpInstance from '../httpInstance';

const updateProviderPaymentService = async (dataParam) => {
  let res;
  const endpoint = '/service-payment/v1/beneficiaries/update-beneficiary';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateProviderPaymentService;
