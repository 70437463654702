/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import NationalHolidaysTable from './components/DataTable/NationalHolidaysTable';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import ActionForm from './components/ActionForm/ActionForm';
import { getNationalHolidaysService } from '../../services';

const NationalHolidaysScreen = () => {
  const classes = useStyle();
  const [nationalHoliday, setNationalHoliday] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState('');
  const defaultData = {
    holiday: '',
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });

  const getNationalHolidays = async () => {
    const res = await getNationalHolidaysService();
    return setNationalHoliday(res?.data);
  };

  const createNationalHoliday = () => {
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: 'Crear día feriado',
      data: null,
    });
  };

  useEffect(() => {
    getNationalHolidays();
  }, []);

  return (
    <Paper className={classes.paper}>
      <>
        <div className={classes.root}>
          <div>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              placeholder="Buscar"
            />
            <Tooltip title="Crear día feriado" aria-label="Nuevo día feriado">
              <IconButton
                onClick={createNationalHoliday}
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
                classes={{ disabled: classes.disabled }}
              >
                <img src={newButton} alt="newButton" />
              </IconButton>
            </Tooltip>
          </div>
          <NationalHolidaysTable
            setNationalHoliday={setNationalHoliday}
            nationalHoliday={nationalHoliday}
            enteredFilter={enteredFilter}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            title={popUpAction.title}
            defaultValues={defaultValues}
            getNationalHolidays={getNationalHolidays}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
            }}
          />
        </div>
      </>
    </Paper>
  );
};

export default NationalHolidaysScreen;
