import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import useStyle from './useStyle';
import ProvidersTable from './components/DataTable/ProvidersRechargeTable.readonly';
import {
  getProvidersService,
} from '../../services';
import { Loading } from '../../components';

const ProvidersRechargeScreen = () => {
  const classes = useStyle();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProviders = async () => {
    if (providers.length === 0) {
      setLoading(true);
    }
    const res = await getProvidersService();
    setLoading(false);
    return setProviders(res?.data);
  };

  useEffect(() => {
    getProviders();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <div className={classes.root}>
          <ProvidersTable
            setProviders={setProviders}
            providers={providers.data}
          />
        </div>
      </>
    </Paper>
  );
};

export default ProvidersRechargeScreen;
