/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  downloadPromotionService,
} from '../../../../services';
import useStyle from './useStyle';
import { Toaster } from '../../../../components';
import { csvFileToArray, removeFileExtension } from '../../../../utils';
import { ActionPopUp } from '../../../../Layouts';

const PromotionDetail = ({
  open,
  data,
  title,
  onClose,
  link,
  fileName,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = useStyle();
  const providerRef = React.useRef();
  const [fileNameExtension, setFileNameExtension] = useState('');
  const [dataTable, setDataTable] = useState([]);

  const handleOnclose = () => {
    onClose();
    setDataTable([]);
  };

  const setArray = (string) => {
    const arr = csvFileToArray(string);
    setDataTable(arr);
  };

  const getPromoData = async () => {
    if (link && link !== '') {
      try {
        const res = await downloadPromotionService(link, 'text');
        setArray(res);
      } catch {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar los datos de la promoción' });
      }
    }
  };

  const downloadFile = async () => {
    try {
      if (link && link !== '' && fileName && fileName !== '') {
        const res = await downloadPromotionService(link, `${fileNameExtension}.csv`, 'blob');
        if (res && res.status === 404) {
          setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
        } else {
          setAlert({ status: true, type: 'success', message: 'Archivo de la promoción descargado.' });
        }
      } else {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
    }
  };

  useEffect(() => {
    getPromoData();
    const newFileName = removeFileExtension(fileName);
    setFileNameExtension(newFileName);
  }, [link, fileName]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleOnclose}
        withoutSaveButton
        readOnly
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <Grid container>
            <Grid item sm={12}>
              <Box>
                <div>
                  <div className={classes.title}>
                    Información de
                    {' '}
                    {fileNameExtension}
                  </div>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow key="header">
                          <TableCell>Número de Documento</TableCell>
                          <TableCell>Monto</TableCell>
                          <TableCell>Nombre de la promoción</TableCell>
                          <TableCell>Estatus</TableCell>
                          <TableCell>Número de transacción</TableCell>
                          <TableCell>Descripción</TableCell>
                        </TableRow>
                      </TableHead>
                      {dataTable.length > 0 && (
                        <TableBody>
                          {dataTable.map((row) => (
                            <TableRow key={`${row.documentNumber}-${row.transactionCode}`}>
                              <TableCell>{row.documentNumber}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                              <TableCell>{row.promoName}</TableCell>
                              <TableCell>{row.status}</TableCell>
                              <TableCell>{row.transactionCode}</TableCell>
                              <TableCell>{row.description}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    color="inherit"
                    variant="contained"
                    component="span"
                    className={classes.buttonImage}
                    endIcon={<CloudDownloadIcon htmlColor="#449FDD" className={classes.matIcon} />}
                    onClick={() => {
                      downloadFile();
                    }}
                  >
                    Descargar archivo
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

PromotionDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default PromotionDetail;
