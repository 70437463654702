import httpInstance from '../httpInstance';

const getFeesService = async () => {
  let res;
  const endpoint = '/fares/v1/fees/all';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getFeesService;
