/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import {
  useGetProviderById,
} from '../../../../services';

const ProvidersRechargeTable = (props) => {
  const classes = useStyle();
  const { providers } = props;
  const [providersRows, setProvidersRows] = React.useState([]);
  const providerRef = React.useRef();

  function createActions(active) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              name="active"
              disabled
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          disabled
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          disabled
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  const getProviderById = async (id) => {
    const res = await useGetProviderById(id);
    return res?.data;
  };

  const mapCodes = async (providerParam) => {
    let providerInfo = [];
    if (providerParam) {
      providerInfo = await Promise.all(
        providerParam.map(async (provider) => {
          const providerData = await getProviderById(provider.beneficiaryId);
          return {
            id: provider.beneficiaryId,
            name: provider.reference,
            category: providerData?.data?.creditorAgent,
            pay_method: providerData?.data?.supplementaryData[0]?.paymentMethod,
            payment: providerData?.data?.supplementaryData[0]?.instructedAmount.hasPartialPayment ? 'Si' : 'No',
            actions: createActions(provider.active, provider.beneficiaryId),
            state: 'Activo',
            active: 1,
          };
        }),
      );
    }
    setProvidersRows(providerInfo);
  };

  useEffect(() => {
    mapCodes(providers);
  }, [providers]);

  const columns = [
    {
      id: 'id', label: 'ID', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'category', label: 'Categoría', paddingLeft: '40px', width: 150, position: 3,
    },
    {
      id: 'pay_method',
      label: 'Método de pago',
      paddingLeft: '40px',
      width: 10,
      position: 4,
    },
    {
      id: 'payment',
      label: 'Abono',
      paddingLeft: '40px',
      width: 10,
      position: 6,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 7,
    },
    {
      id: 'state',
      paddingLeft: '',
      label: 'Estado',
      width: 5,
      position: 7,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={providersRows}
        columns={columns}
        readOnly
      />
    </SnackbarProvider>
  );
};

export default ProvidersRechargeTable;
