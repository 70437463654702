/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import {
  deleteAmountService,
  getChannelService,
  createChannelService,
  createReplacementService,
} from '../../../../services';
import { Toaster, Loading, CustomDialog } from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';

const amountModel = {
  minimum_amount: '',
  maximum_amount: '',
  amount_name: '',
  amount_id: '',
  transaction_code: '',
  transaction_name: '',
};

const costModel = {
  cost: 0,
  costName: '',
  costChannel: '',
  idCost: '',
};

const channelModel = {
  idChannel: '',
  nameChannel: '',
  orderChanel: '',
};

const ActionForm = ({
  open,
  data,
  title,
  onClose,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [amount, setAmount] = useState(amountModel);
  const [cost, setCost] = useState(costModel);
  const [channel, setChannel] = useState(channelModel);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [deleteDialog, setDeleteDialog] = useState(false);

  const getTransactionTypes = async () => {
    setLoading(true);
    await getChannelService().then((res) => {
      setLoading(false);
      setCanEdit(true);
      if (res && res.data) {
        setTransactionTypes(res.data);
      }
    });
  };

  const createChannel = async () => {
    setLoading(true);
    const res = await createChannelService(channel)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    let messageErr = 'Ha ocurrido un error, intente nuevamente.';
    if (res?.status !== 201) {
      if (res.data && res.data.details) {
        const errors = res.data.details.map((detail) => detail.error_message);
        messageErr = errors.join(' ');
      }
      return setAlert({ status: true, type: 'error', message: messageErr });
    }
    if (res?.status === 201) {
      setAlert({ status: true, type: 'success', message: 'Monto creado correctamente' });
      setCanEdit(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
    return true;
  };

  const createReplacement = async () => {
    setLoading(true);
    const res = await createReplacementService(cost)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    let messageErr = 'Ha ocurrido un error, intente nuevamente.';
    if (res?.status !== 201) {
      if (res.data && res.data.details) {
        const errors = res.data.details.map((detail) => detail.error_message);
        messageErr = errors.join(' ');
      }
      return setAlert({ status: true, type: 'error', message: messageErr });
    }
    if (res?.status === 201) {
      setAlert({ status: true, type: 'success', message: 'Monto creado correctamente' });
      setCanEdit(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
    return true;
  };

  // const updateAmount = async () => {
  //   setLoading(true);
  //   const res = await updateAmountService(amount)
  //     .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
  //   setLoading(false);
  //   if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
  //   if (res?.status === 200) setAlert({ status: true, type: 'success', message: 'El monto ha sido modificado' });
  //   return true;
  // };

  const deleteFee = async () => {
    setLoading(true);
    const res = await deleteAmountService(amount.amount_id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    if (res?.status === 200) {
      setAlert({ status: true, type: 'success', message: 'El monto ha sido eliminado' });
      setCanEdit(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
    return true;
  };

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  useEffect(() => {
    if (open && data && data.action === 'newCost') getTransactionTypes();
  }, [open]);

  useEffect(() => {
    if (data && data != null && data.action === 'newCost') {
      setCanEdit(false);
      setCost({
        ...cost,
        idCost: (data.lastIdReplacement + 1),
      });
    } else if (data && data != null && data.action === 'newChannel') {
      setChannel({
        ...channel,
        idChannel: (data.lastIdChannel + 1),
        orderChanel: (data.lastIdOrder + 1),
      });
    }
    setCanEdit(true);
    return setAmount(amountModel);
  }, [data]);

  const selectorChangeHandler = (e) => {
    setCost({
      ...cost,
      costChannel: e.target.value,
    });
  };

  const handleSave = () => {
    if (data && data.action === 'newChannel') {
      createChannel();
    } else {
      createReplacement();
    }
    return 1;
  };

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      onSave={handleSave}
      enabledControls={canEdit}
    >
      {loading
        ? <Loading />
        : (
          <>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            {data && data.action === 'newChannel' ? (
              <form noValidate autoComplete="off">
                <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item md>
                      <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                        <InputLabel htmlFor="name">Nombre canal</InputLabel>
                        <OutlinedInput
                          id="name"
                          name="name"
                          value={channel?.nameChannel || ''}
                          onChange={(e) => setChannel({
                            ...channel,
                            nameChannel: e.target.value,
                          })}
                          label="Name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md>
                      <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                        <InputLabel htmlFor="amount">identificador (ID)</InputLabel>
                        <OutlinedInput
                          id="amountout"
                          name="amountout"
                          type="number"
                          disabled
                          value={channel?.idChannel || ''}
                          onChange={(e) => setChannel({
                            ...channel,
                            idChannel: (e.target.value).replace(/\s/g, ''),
                          })}
                          label="Monto $"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
              </form>
            ) : (
              <form noValidate autoComplete="off">
                <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={1}>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item md>
                      {(transactionTypes && channel) && (
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            disabled={!canEdit}
                            id="outlined-select-fee-type"
                            select
                            label="Canal"
                            value={cost?.costChannel || ''}
                            sub={cost?.costChannel || ''}
                            onChange={selectorChangeHandler}
                            variant="outlined"
                          >
                            {transactionTypes.map((option, index) => (
                              <MenuItem key={`opt_${option.name}_${index + 1}`} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item md>
                      <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                        <InputLabel htmlFor="cost">Nombre costo</InputLabel>
                        <OutlinedInput
                          id="cost"
                          name="cost"
                          value={cost?.costName || ''}
                          onChange={(e) => setCost({
                            ...cost,
                            costName: e.target.value,
                          })}
                          label="Nombre costo"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md>
                      <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                        <InputLabel htmlFor="amount">Costo</InputLabel>
                        <OutlinedInput
                          id="amountout"
                          name="amountout"
                          type="number"
                          value={cost.cost || ''}
                          onChange={(e) => setCost({
                            ...cost,
                            cost: e.target.value,
                          })}
                          label="Costo"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
              </form>
            )}
          </>
        )}
      <CustomDialog
        isOpen={deleteDialog}
        openCloseFx={setDeleteDialog}
        title="Eliminar limite"
        text={`Se eliminará el limite ${amount.transaction_name}`}
        okFunction={deleteFee}
        notOkFunction={() => {}}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
