import React, { useState, useEffect } from 'react';
import {
  Paper,
  Tooltip,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import PromotionTable from './components/DataTable/PromotionTable.readonly';
import ActionForm from './components/ActionForm/ActionForm';
import PromotionDetail from './components/PromotionDetail/PromotionDetail.readonly';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import reload from '../../assets/img/iconos/reload.svg';
import {
  getPromotionService,
} from '../../services';
import { Loading, Toaster } from '../../components';
import useStyle from './useStyle';

const PromotionsScreen = () => {
  const classes = useStyle();
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultData = {
    file: null,
    fileName: '',
    eventName: '',
    registers: 0,
    totalAmount: 0,
  };
  const [enteredFilter, setEnteredFilter] = useState('');
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [popUpActionDetail, setPopUpActionDetail] = useState({
    open: false, title: '', data: null, link: '', fileName: '',
  });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getPromotions = async () => {
    if (promotions.length === 0) {
      setLoading(true);
    }
    try {
      const res = await getPromotionService();
      if (res.status >= 300) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
      } else {
        setPromotions(res?.data.summary);
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
    }
    setLoading(false);
  };

  const getPromotionsRefresh = async () => {
    setLoading(true);
    try {
      const res = await getPromotionService();
      if (res.status >= 300) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
      } else {
        setPromotions(res?.data.summary);
        setAlert({ status: true, type: 'info', message: 'Las promociones se han actualizado' });
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
    }
    setLoading(false);
  };

  const detailCsv = (link, fileName) => {
    setPopUpActionDetail({
      open: true,
      data: null,
      title: 'Ver Promoción - Solo lectura',
      link,
      fileName,
    });
  };

  useEffect(() => {
    getPromotions();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <div className={classes.root}>
          <div>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              placeholder="Buscar"
            />
            <Tooltip title="Refrescar página" aria-label="Refrescar página">
              <IconButton
                onClick={getPromotionsRefresh}
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
              >
                <img src={reload} alt="reload" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cargar archivo csv" aria-label="Cargar archivo csv">
              <IconButton
                style={{ fontSize: '20px', float: 'right', opacity: 0.4 }}
                edge="end"
                size="small"
                disabled
              >
                <img src={newButton} alt="newButton" />
              </IconButton>
            </Tooltip>
          </div>

          <PromotionTable
            promotions={promotions}
            setPromotions={setPromotions}
            detailCsv={detailCsv}
            setAlert={setAlert}
            enteredFilter={enteredFilter}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            title={popUpAction.title}
            defaultValues={
              {
                ...defaultData,
              }
            }
            getPromotions={getPromotions}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
              setEnteredFilter('');
              setTimeout(() => {
                getPromotions();
              }, 3000);
            }}
            setAlertHome={setAlert}
          />
          <PromotionDetail
            open={popUpActionDetail.open}
            data={popUpActionDetail.data}
            title={popUpActionDetail.title}
            getPromotions={getPromotions}
            link={popUpActionDetail.link}
            fileName={popUpActionDetail.fileName}
            onClose={() => {
              setPopUpActionDetail({
                open: false, title: '', data: null, link: '', fileName: '',
              });
            }}
          />
        </div>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </>
    </Paper>
  );
};

export default PromotionsScreen;
