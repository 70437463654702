import { makeStyles } from '@material-ui/core/styles';
import { BLACK_20, PRIMARY } from '../../../../theme/colors';

const useStyle = makeStyles(() => ({
  editIcon: {
    color: PRIMARY,
  },
  editIconReadOnly: {
    color: BLACK_20,
  },
}));

export default useStyle;
