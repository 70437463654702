import httpInstance from '../httpInstance';

const deleteTypePromotionService = async (id) => {
  let res;
  const endpoint = `/back-office/v1/promotions-type/${id}`;
  await httpInstance.delete(endpoint)
    .then((data) => {
      res = data;
    }).catch((err) => {
      res = err.response;
    });
  return res;
};

export default deleteTypePromotionService;
