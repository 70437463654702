import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const WrapContainer = styled.div`
  margin-top: ${({ marginTop }) => marginTop || 0};
`;

export const MessageRequired = styled.div`
  color: #d61103;
  margin: 0 0 0 5px;
  font-size: 0.6964285714285714rem;
`;

export const StyledImage = styled.img`
  opacity: .5;
`;

const formStyle = makeStyles(() => ({
  uploadImage: {
    paddingBottom: 10,
  },
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#50B940',
    },
    left: 0,
  },
  buttonImage: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #50B940',
    borderRadius: '5px',
    opacity: 1,
    width: '300px',
    height: '33px',
    letterSpacing: '0px',
    color: '#50B940',
    textTransform: 'initial',
  },
  buttonImageLarge: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #50B940',
    borderRadius: '5px',
    opacity: 1,
    width: '300px',
    height: '33px',
    letterSpacing: '0px',
    color: '#50B940',
    textTransform: 'initial',
  },
  formLabel: {
    color: 'var(--unnamed-color-575756)',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: 1,
  },
  mat_icon: {
    height: '15px',
    width: '15px',
  },
  paperbox: {
    height: '40px',
    width: 'fit-content',
  },
  labelSwitch: {
    paddingTop: '8px',
  },
  formContainer: {
    overflow: 'scroll',
  },
  formErrors: {
    color: '#F53D3D',
  },
  subInfoLabel: {
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  errorSubText: {
    color: '#d61103',
    margin: '0 0 0 5px',
    fontSize: '0.7rem',
  },
}));

export default formStyle;
