/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Box,
  FormControlLabel,
} from '@material-ui/core';
import { getTransactionTypesService } from '../../../../services';
import {
  Toaster, Loading, CustomSwitch,
} from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';

const commissionModel = {
  type: '',
  channel: '',
  name: '',
  description: '',
  amount: null,
  percentage: null,
  active: false,
};

const ActionForm = ({
  open, data, title, onClose,
}) => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [commission, setCommission] = useState(commissionModel);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const handleOnclose = () => {
    setCanEdit(false);
    onClose();
  };

  const getTransactionTypes = async () => {
    setLoading(true);
    const res = await getTransactionTypesService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setTransactionTypes(res.data);
  };

  useEffect(() => {
    if (open) {
      getTransactionTypes();
      if (typeof (data?.type) === 'undefined') {
        setCanEdit(true);
      }
    }
  }, [open]);

  useEffect(() => {
    if (data) return setCommission(data);
    return setCommission(commissionModel);
  }, [data]);

  return (
    <ActionPopUp
      open={open}
      data={data}
      title={title}
      onClose={handleOnclose}
      withoutSaveButton
      readOnly
    >
      {loading
        ? <Loading />
        : (
          <>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
            <form noValidate autoComplete="off">
              <Grid
                container
                justify="space-evenly"
                alignItems="stretch"
                spacing={2}
              >
                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {transactionTypes && (
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          disabled={!canEdit || !!data}
                          id="outlined-select-commission-type"
                          select
                          label="Tipo de Transaccion"
                          value={commission.type || ' '}
                          variant="outlined"
                        >
                          {transactionTypes.map((option) => (
                            <MenuItem key={option.transaction_code} value={option.transaction_code}>
                              {option.transaction_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="channel">Canal</InputLabel>
                      <OutlinedInput
                        id="channel"
                        name="channel"
                        value={commission?.channel || ' '}
                        label="Channel"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="name">Nombre</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        value={commission?.name || ' '}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 15 }}>&nbsp;</div>
                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="amount">Monto $</InputLabel>
                      <OutlinedInput
                        id="amount"
                        name="amount"
                        type={commission?.amount && 'number'}
                        value={commission?.amount || ' '}
                        label="Monto $"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                      <InputLabel htmlFor="percentage">Porcentaje %</InputLabel>
                      <OutlinedInput
                        id="percentage"
                        name="percentage"
                        type={commission?.percentage && 'number'}
                        value={commission?.percentage || ' '}
                        label="Porcentaje %"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                      <FormControlLabel
                        disabled={!canEdit}
                        control={(
                          <CustomSwitch
                            checked={commission?.active || false}
                            name="active"
                            color="primary"
                          />
                          )}
                        label="Estado"
                        labelPlacement="start"
                      />
                    </Box>

                  </Grid>
                </Grid>
              </Grid>
              <div style={{ marginTop: 15 }}>&nbsp;</div>
              <FormControl fullWidth variant="outlined" disabled={!canEdit}>
                <InputLabel htmlFor="description">Descripción</InputLabel>
                <OutlinedInput
                  id="description"
                  name="description"
                  value={commission?.description || ' '}
                  label="Descripción"
                />
              </FormControl>
            </form>
          </>
        )}
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
