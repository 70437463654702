import httpInstance from '../httpInstance';

const createCommissionService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/commissions';
  const dataSent = dataParam;
  console.log(dataSent);

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default createCommissionService;
