import httpInstance from '../httpInstance';

const deleteTaxService = async (taxId) => {
  let res;
  const endpoint = `/fares/v1/taxes/id/${taxId}`;

  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default deleteTaxService;
