/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectInput from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import useStyle from './useStyle';
import { Button } from './styles';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import { FILTER_OPTIONS_BUTTONS } from './constants';

const SearchBox = (props) => {
  const classes = useStyle();
  const {
    handleSearchPress,
    searchQuery,
    setSearchQuery,
    setKeyFilter,
    options,
    placeholder,
  } = props;
  const [selectedOption, setSelectedOption] = useState(options ? options[0].value : 'ID del comercio');

  const dropdownOptions = options || FILTER_OPTIONS_BUTTONS;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setKeyFilter(event.target.value);
  };
  return (
    <>
      <TextField
        className={classes.searchTextField}
        value={searchQuery}
        onChange={(e) => { setSearchQuery(e.target.value); }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        placeholder={placeholder || 'Buscar comercio'}
      />
      <SelectInput
        className={classes.selectTextField}
        value={selectedOption}
        onChange={handleOptionChange}
        variant="outlined"
      >
        {dropdownOptions.map((item) => (
          <MenuItem
            index={item.value}
            value={item.value}
          >
            {item.value}
          </MenuItem>

        ))}
      </SelectInput>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleSearchPress}
        className={classes.button}
      >
        Buscar
      </Button>
    </>
  );
};

export default SearchBox;
