/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Alert, Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { getVersionsService, getCurrentVersionService } from '../../services';
import { Toaster, Loading } from '../../components';
import VersionTable from './components/DataTable/VersionsTable';

const AppVersionScreen = () => {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState();
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const filter = (v1, v2) => {
    if (v1.latest > v2.latest) {
      return -1;
    }
    if (v1.latest < v2.latest) {
      return 1;
    }
    return 0;
  };

  const sortData = (arrVersion) => {
    const dataTable = arrVersion.sort(filter);
    return dataTable
      .map((res, index) => ({
        id: index,
        version: res.version,
        title: res.title,
        author: res.author,
        description: res.description,
        latest: res.latest,
      }));
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([Auth.currentAuthenticatedUser(), getVersionsService(), getCurrentVersionService()])
      .then(([, version, currVersion]) => {
        setVersions(sortData(version.data));
        setCurrentVersion(currVersion.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const alertMessage = () => {
    if (currentVersion) {
      const { version, updatedBy, dateModified } = currentVersion;
      const date = new Date(0);
      date.setUTCSeconds(dateModified);
      const dateFormated = format(date, 'dd/MM/yyyy h:m');
      return (
        <Alert severity="info">
          {'Version mínima requerida: '}
          <b>{version}</b>
          {` actualizada por ${updatedBy} el ${dateFormated}`}
        </Alert>
      );
    }
    return null;
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        {loading
          ? <Loading />
          : (
            <>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12}>
                  <Box mb={1}>
                    {alertMessage()}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    disablePortal
                    options={versions}
                    getOptionLabel={(row) => `${row.version} - ${row.title}`}
                    renderInput={(params) => <TextField {...params} label="Seleccionar versión" />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    size="large"
                    fullWidth
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  {versions.length > 0 && (<VersionTable versions={versions} />)}
                </Box>
              </Grid>
              <Toaster
                show={alert.status}
                type={alert.type}
                text={alert.message}
                onClose={() => setAlert({ ...alert, status: false })}
              />
            </>
          )}
      </Paper>
    </InternalPage>
  );
};

export default AppVersionScreen;
