import React, { useState, useEffect } from 'react';
import {
  Paper,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import TapToPhoneTable from './components/DataTable/TapToPhoneTable.readonly';
import SearchBox from '../../components/SearchBox/SearchBox';
import newButton from '../../assets/img/iconos/newButton.svg';
import {
  chunkSize, filters, FILTER_OPTIONS_TAP_TO_PHONE,
} from './constants';
import { Loading, Toaster } from '../../components';
import getTapToPhoneService from '../../services/tapToPhone/getTapToPhoneService';

const TapOnPhoneScreen = () => {
  const classes = useStyle();
  const [tapToPhone, setTapToPhone] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [keyFilter, setKeyFilter] = useState(FILTER_OPTIONS_TAP_TO_PHONE[0].value);
  const [showAlert, setShowAlert] = useState(false);
  const [totalElementsData, setTotalElementsData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentChunk, setCurrentChunk] = useState(0);
  const [loading, setLoading] = useState(false);

  const getTapOnPhoneData = async () => {
    try {
      const response = await getTapToPhoneService({
        pageSize: chunkSize, pageNumber: currentChunk, keyFilter: filters[keyFilter], searchQuery,
      });
      setTapToPhone((tapToPhoneData) => [
        ...tapToPhoneData, ...response.data.list_of_commerces,
      ]);
      setLoading(false);
      setTotalElementsData(response.data.total_elements);
    } catch (error) {
      setShowAlert(true);
    }
  };

  const handleSearchPress = () => {
    if (searchQuery) {
      setLoading(true);
      setTapToPhone([]);
      getTapOnPhoneData();
    }
  };

  const handleNext = () => {
    if (tapToPhone.length === totalElementsData) return;
    if (
      tapToPhone.length < (currentPage + 2) * currentPageSize
    ) {
      setCurrentChunk((oldChunk) => oldChunk + 1);
    }
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handleNewPageSize = (newPageSize) => {
    setCurrentPageSize(Number(newPageSize));
    setCurrentPage(0);
  };

  useEffect(() => {
    getTapOnPhoneData();
  }, [currentChunk, chunkSize]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {loading ? <Loading /> : null}
        <div>
          <SearchBox
            options={FILTER_OPTIONS_TAP_TO_PHONE}
            handleSearchPress={handleSearchPress}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setKeyFilter={setKeyFilter}
          />
          <Tooltip title="Crear comercio" aria-label="Nuevo comercio">
            <IconButton
              style={{ fontSize: '20px', float: 'right' }}
              edge="end"
              size="small"
              disabled
            >
              <img src={newButton} alt="newButton" className={classes.imgColorReadOnly} />
            </IconButton>
          </Tooltip>
        </div>
        <TapToPhoneTable
          tapToPhone={tapToPhone}
          enteredFilter={searchQuery}
          totalElementsData={totalElementsData}
          onNext={handleNext}
          onSelectPageSize={handleNewPageSize}
          searchQuery={searchQuery}
          loading={loading}
        />
      </div>
      <Toaster
        show={showAlert}
        type="error"
        text="Ha ocurrido un error, intente nuevamente."
        onClose={() => setShowAlert(false)}
      />
    </Paper>
  );
};
export default TapOnPhoneScreen;
