import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import useStyle from './useStyle';
import DistributionCenterTable from './components/DataTable/DistributionCenterTable';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import ActionForm from './components/ActionForm/ActionForm';
import {
  getDistributionCenterService,
} from '../../services';
import { Card, CardOptions, IconButtonAdd } from './styles';

const DistributionCenterScreen = () => {
  const classes = useStyle();
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState('');
  const defaultData = {
    name: '',
    description: '',
    streetAddress: '',
    postalCode: '',
    phone: '',
    phoneExtension: '',
    city: '',
    country: '',
    latitude: '',
    longitude: '',
    openingTime: '',
    closingTime: '',
    observations: '',
    email: '',
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const getDistributionCenter = async () => {
    const res = await getDistributionCenterService();
    return setDistributionCenters(res?.data);
  };

  const createDistributionCenter = () => {
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: 'Crear Distribution Center',
      data: null,
    });
  };

  useEffect(() => {
    getDistributionCenter();
  }, []);

  return (
    <Card>
      <CardOptions>
        <TextField
          className={classes.searchTextField}
          value={enteredFilter}
          onChange={(e) => setEnteredFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          placeholder="Buscar"
        />
        <Tooltip title="Crear Distribution Center" aria-label="Nuevo Distribution Center">
          <IconButtonAdd onClick={createDistributionCenter}>
            <img src={newButton} alt="newButton" />
          </IconButtonAdd>
        </Tooltip>
      </CardOptions>
      <DistributionCenterTable
        setDistributionCenters={setDistributionCenters}
        distributionCenters={distributionCenters}
        setDefaultValues={setDefaultValues}
        enteredFilter={enteredFilter}
        setPopUpAction={setPopUpAction}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        defaultValues={defaultValues}
        getDistributionCenter={getDistributionCenter}
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
        }}
      />
    </Card>
  );
};

export default DistributionCenterScreen;
