import httpInstancePaymentLink from '../httpInstancePaymentLink';

const getMerchantService = async (merchantId) => {
  let res;
  const endpoint = `/api/v1/merchants/${merchantId}`;
  await httpInstancePaymentLink.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getMerchantService;
