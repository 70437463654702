/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-computed-key */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  getChannelService,
  getReplacementByIdChannelService,
  getReplacementService,
  updateReplacementService,
  createReplacementService,
} from '../../services';
import { Toaster, CustomDialog } from '../../components';
import { ActionForm } from './components';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import UserContext from '../../contexts/UserContext';
import { ButtonStyled } from './styles';

const CardsScreen = () => {
  const [channelsList, setChannelsList] = useState([]);
  const [channelsListCopy, setChannelsListCopy] = useState([]);
  const [allReplacement, setAllReplacement] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const classes = useStyle();

  const handlechangeappBlur = (indexc, indexItem) => (event) => {
    setChannelsListCopy((prev) => {
      let copyPrev = [...prev];
      copyPrev[indexc].replacements[indexItem] = { ...copyPrev[indexc].replacements[indexItem], cost: parseFloat((event.target.value).replace(/,/g, '')), change: 1 };
      return copyPrev;
    });
  };

  const getSections = async () => {
    let baseData = [];
    const channels = await getChannelService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente (secciones).' }));
    if (channels?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente (secciones).' });
    let apidata = channels.data.reduce((accumulatorPromise, nextID) => {
      return accumulatorPromise.then(() => {
        return getReplacementByIdChannelService(nextID.id).then((res) => {
          baseData.push({ ...nextID, replacements: res.data.replacements });
        });
      });
    }, Promise.resolve());
    apidata.then((e) => {
      return setChannelsList(baseData);
    });
    return 1;
  };

  const getAll = async () => {
    setLoading(true);
    const res = await getReplacementService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    const lastOrder = Math.max(...res.data.map((o) => parseInt(o.order, 12)));
    const lastChannel = Math.max(...res.data.map((o) => parseInt(o.channel_id, 12)));
    const lastReplacement = Math.max(...res.data.map((o) => parseInt(o.id, 12)));
    return setAllReplacement({
      lastOrder,
      lastChannel,
      lastReplacement,
    });
  };

  useEffect(() => {
    getSections();
  }, []);

  useEffect(() => {
    const filteredChannels = [...channelsList];
    const deliveryGroup = [];
    let pickupGroup = [];
    if (filteredChannels.length > 0) {
      filteredChannels[0].replacements.forEach((replacement) => {
        if (replacement.delivery_type === null || replacement.delivery_type === 'delivery') {
          deliveryGroup.push(replacement);
          if (replacement.delivery_type === null) {
            // eslint-disable-next-line no-param-reassign
            replacement.delivery_type = 'delivery';
            updateReplacementService({
              ...replacement,
            });
          }
        } else if (replacement.delivery_type === 'pickup') {
          pickupGroup.push(replacement);
        }
      });
      if (pickupGroup.length === 0) {
        pickupGroup = JSON.parse(JSON.stringify(deliveryGroup));
        pickupGroup.forEach((_, index) => {
          pickupGroup[index].delivery_type = 'pickup';
          pickupGroup[index].cost = 0;
          pickupGroup[index].id = String(Number(deliveryGroup[index].id) + 8);
          createReplacementService({
            ...pickupGroup[index],
          });
        });
      }
      const dataPickup = {
        id: '3',
        name: 'App Pickup',
        order: 3,
        replacements: pickupGroup,
      };
      filteredChannels[0].replacements = deliveryGroup;
      filteredChannels[0].name = 'App Delivery';
      filteredChannels.splice(1, 0, dataPickup);
    }
    setChannelsListCopy(filteredChannels);
    getAll();
  }, [channelsList]);

  const handleedit = () => {
    setCanEdit((prev) => !prev);
  };

  const handleSave = async () => {
    const copyOriginal = [...channelsListCopy];
    let toupdate = [];
    for (let index = 0; index < copyOriginal.length; index++) {
      const element = copyOriginal[index].replacements;
      for (let n = 0; n < element.length; n++) {
        const elementInner = element[n];
        if (elementInner.change) {
          toupdate.push(elementInner);
        }
      }
    }
    let req = [];
    toupdate.forEach((itemUpdate) => {
      let doupdate = updateReplacementService({
        ...itemUpdate,
      });
      req.push(doupdate);
    });
    Promise.all(req).then(() => {
      setAlert({ status: true, type: 'success', message: 'Guardado correctamente' });
      getSections();
    }).catch(() => {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    });
    handleedit();
  };

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            {(channelsListCopy && channelsListCopy.length > 0) && (
              <Grid container spacing={3}>
                <Grid container justifyContent="flex-end" style={{ marginRight: 10, marginTop: 10 }}>
                  <ButtonStyled variant={canEdit ? 'outlined' : 'contained'} onClick={handleedit}>
                    Editar
                  </ButtonStyled>
                </Grid>
              </Grid>
            )}
            {channelsListCopy && channelsListCopy.map((itemc, indexc) => {
              return (
                <>
                  <Grid container spacing={3} style={{ marginTop: '10px' }} key={`${(indexc + 1)}_seccioncont`}>
                    <Grid container justifyContent="space-between" style={{ marginTop: '10px' }} key={`${(indexc + 1)}_conttitle`}>
                      <Grid container xs={3} md={3} key={`${(indexc + 1)}_contitem_del`} alignItems="center" justifyContent="space-between">
                        <Typography variant="h4" gutterBottom style={{ marginLeft: '10px' }} key={`${(indexc + 1)}_co`}>{itemc.name}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} key={`${(indexc + 1)}_conttypotitle`}>
                      <Typography>Tipos de cobro</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} key={`${(indexc + 1)}_conttypoCost`}>
                      <Typography>Costo</Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: '20px' }} key={`${(indexc + 1)}_seccioncontDivider`} />
                  <Grid container spacing={3} style={{ marginTop: 20 }} key={`${(indexc + 1)}_seccionInnercontSec`}>
                    {itemc.replacements && itemc.replacements.map((item, index) => {
                      return (
                        <Grid container key={`${(index + 1)}_contitemcostapp${itemc + 1}`} style={{ marginTop: 10, marginLeft: 10 }}>
                          <Grid item xs={12} md={3} key={`${(index + 1)}_contitemapp_text${indexc + 1}`}>
                            <Typography key={`${(index + 1)}_contitemapp_typo${indexc + 1}`}>{item?.name}</Typography>
                          </Grid>
                          <Grid item xs={12} md={2} key={`${(index + 1)}_contcostoappfield${indexc + 1}`}>
                            <TextField
                              id={`${indexc + 1}amountmaxapp${(index + 1)}`}
                              key={`${(index + 1)}_amountInputapp${indexc + 1}`}
                              variant="outlined"
                              value={item?.cost}
                              onChange={() => { }}
                              onBlur={handlechangeappBlur(indexc, index)}
                              name="numberformat"
                              disabled={!canEdit}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              placeholder="0"
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              );
            })}
            {canEdit && (
              <Grid container justifyContent="flex-end" className={classes.seccionButton}>
                <ButtonStyled variant="contained" onClick={() => setSaveDialog(true)}>
                  guardar
                </ButtonStyled>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ActionForm
          open={popUpAction.open}
          data={popUpAction.data}
          title={popUpAction.title}
          actualCreated={[]}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
            getSections();
          }}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Paper>
      <CustomDialog
        isOpen={saveDialog}
        openCloseFx={setSaveDialog}
        title="Guardar cambios"
        text="Se guardaran los cambios a los montos"
        okFunction={handleSave}
        notOkFunction={() => { }}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default CardsScreen;
