export const categoryOptions = [
  { value: 'APP', label: 'App' },
  { value: 'PROMO', label: 'Promo' },
];

export const redirectionTypeOptions = [
  { value: 'PROMO', label: 'PROMO' },
  { value: 'APP', label: 'APP' },
];

export const orderValues = [
  { value: '0', label: 'Sin orden' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];

export const targetoptions = [
  { value: '1', label: 'Personas' },
  { value: '2', label: 'Negocios' },
  { value: '3', label: 'Ambos (Personas/Negocios)' },
];

export const columns = [
  {
    id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
  },
  {
    id: 'description', label: 'Descripción', paddingLeft: '40px', width: 150, position: 2,
  },
  {
    id: 'category',
    label: 'Categoría',
    paddingLeft: '40px',
    width: 10,
    position: 3,
  },
  {
    id: 'order',
    label: 'Orden',
    paddingLeft: '',
    width: 10,
    position: 4,
  },
  {
    id: 'target',
    label: 'Target',
    paddingLeft: '40px',
    width: 165,
    position: 5,
  },
  {
    id: 'startDate',
    label: 'Inicio vigencia',
    paddingLeft: '',
    width: 165,
    position: 6,
  },
  {
    id: 'endDate',
    label: 'Fin vigencia',
    paddingLeft: '',
    width: 165,
    position: 7,
  },
  {
    id: 'image',
    label: 'Imagen',
    paddingLeft: '',
    width: 250,
    position: 8,
  },
  {
    id: 'status',
    label: 'Estatus',
    paddingLeft: '',
    width: 100,
    position: 9,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 10,
  },
];

export const imageProps = {
  name: '', b64: '', invalidImage: '', urlImage: '',
};
