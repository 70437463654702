import styled from 'styled-components';

const DivImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #50b93f;
  border-radius: 20%;
  width: 50px;
  height: 50px;
`;

export const Img = styled.img`
  width: 25px;
  height: 25px;
  fill: white;
  filter: brightness(0) invert(1);
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  color: white;
`;

export default DivImage;
