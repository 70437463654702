import httpInstance from '../httpInstance';

const updateFeaturesFlagService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/features-flag';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateFeaturesFlagService;
