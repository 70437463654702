/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Image from 'material-ui-image';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomTable } from '../../../../components';
import { columns, targetoptions, categoryOptions } from '../constants';
import useStyle from './useStyle';

const BanerTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction, enteredFilter, banners, setDefaultValues,
  } = props;
  const [bannersRows, setBannersRows] = React.useState([]);
  const [bannersListCopy, setBannersListCopy] = React.useState([]);
  const targets = [...targetoptions];
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const bannersList = codesMapped != null ? codesMapped : bannersListCopy;
    setBannersRows(bannersList.filter(
      (banner) => banner.name
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.target
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.category
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.order
          .indexOf(enteredFilterParam) >= 0
        || banner.startDate
          .indexOf(enteredFilterParam) >= 0
        || banner.endDate
          .indexOf(enteredFilterParam) >= 0
        || banner.status
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
      ,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const openModalEditPromo = (bannerIdSelected) => {
    const bannerEdit = banners.find((bannerRow) => bannerRow.id === bannerIdSelected);
    const redirectionTypeLocal = bannerEdit.application_path !== null
      ? categoryOptions[0].value : categoryOptions[1].value;
    setDefaultValues({
      name: bannerEdit.name || ' ',
      description: bannerEdit.description || ' ',
      category: bannerEdit.category,
      redirectionType: redirectionTypeLocal,
      redirection: redirectionTypeLocal === 'APP' ? bannerEdit.application_path : bannerEdit.destination_url,
      startDate: bannerEdit.date_start.split('T')[0].replaceAll('-', '/'),
      endDate: bannerEdit.date_end.split('T')[0].replaceAll('-', '/'),
      target: bannerEdit.target || ' ',
      promotion: bannerEdit.id_promotion || ' ',
      image_source_url: bannerEdit.image_source_url,
      order: bannerEdit.order || ' ',
    });
    setPopUpAction({
      open: true,
      title: 'Editar banner - Solo lectura',
      data: bannerEdit,
    });
  };

  function createActions(bannerId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEditPromo(bannerId)}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Ver" aria-label="edit" placement="top">
            <VisibilityIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          style={{ paddingLeft: 10 }}
          disabled
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIconReadOnly} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createImage(url) {
    return (
      <Image
        width={120}
        aspectRatio={(40 / 15)}
        src={url}
      />
    );
  }

  function mapCodes(bannersParam) {
    const bannersInfo = [];
    bannersParam?.forEach((banner) => {
      const targetLabel = targets.filter((item) => item.value === banner.target)[0]?.label || '';
      const bannerRow = {
        name: banner.name,
        description: banner.description,
        category: banner.category === 'APP' ? 'App banner' : 'Promo banner',
        order: banner.order === 0 ? 'Sin orden' : banner.order.toString(),
        target: targetLabel,
        startDate: banner.date_start.split('T')[0].replaceAll('-', '/'),
        endDate: banner.date_end.split('T')[0].replaceAll('-', '/'),
        state: true,
        image: createImage(banner.image_source_url.big),
        actions: createActions(banner.id),
        id: banner.id,
        status: banner.state ? 'Activo' : 'Inactivo',
        active: true,
      };
      bannersInfo.push(bannerRow);
    });
    setBannersRows(bannersInfo);
    setBannersListCopy(bannersInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, bannersInfo);
  }

  useEffect(() => {
    setBannersRows([]);
    if (banners.length > 0 && targetoptions.length > 0) mapCodes(banners);
  }, [banners, targetoptions]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={bannersRows}
        columns={columns}
        readOnly
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default BanerTable;
