/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import MaterialImage from 'material-ui-image';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import formStyle, { WrapContainer, MessageRequired } from './formStyle';
import {
  CustomInput, Toaster, CustomSelect, ProgressBar,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import {
  createCategoryService,
} from '../../../services';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getProviders,
  defaultValues,
  setDefaultValues,
  providers,
  categoriesOptions,
  categories,
  updateProvider,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [imagePreview, setImagePreview] = useState(null);
  const providerRef = React.useRef();
  const [invalidImageLit, setInvalidImageLit] = useState('');
  const [fileNameLit, setFileNameLit] = useState('');
  const [startBarProgressLit, setStartBarProgressLit] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  const [invalidImg, setInvalidImg] = useState(false);
  const classes = formStyle();

  const {
    handleSubmit, control, errors, reset, setError, clearErrors,
  } = useForm({ defaultValues });

  const resetFields = () => {
    setDefaultValues({
      nameCategory: '',
      icon: '',
      order: '',
    });
  };

  const createCategory = async (category) => {
    const res = await createCategoryService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    resetFields();
    return true;
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
    setImagePreview(null);
  };

  const getHeightAndWidthFromDataUrl = (dataURL) => new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

  const handleSave = async (dataParam) => {
    if (fileNameLit === '' && data === null) {
      setError('imageLit', 'Campo obligatorio');
      return;
    }
    if (!dataParam.reference.replace(/\s/g, '').length || dataParam.reference === '') {
      setAlert({ status: true, type: 'error', message: 'El nombre de la categoría es inválido' });
      return;
    }
    let imagesBase64 = (data !== null && data.icon_base64) ? data.icon_base64 : defaultValues.beneficiaryUrlImg;
    files.forEach((file) => {
      const uploadedFile = file.uploaded_file;
      const imageBase64 = uploadedFile;
      if (file.file_id === 'fileIcon') {
        imagesBase64 = imageBase64 !== undefined ? imageBase64.split(',')[1] : defaultValues.beneficiaryUrlImg;
      }
    });
    if (Number(dataParam.minAmount) >= Number(dataParam.maxAmount)) {
      setAlert({ status: true, type: 'error', message: 'Ingrese montos válidos' });
      return;
    }
    if (invalidImg) {
      setAlert({ status: true, type: 'error', message: 'Ingrese imagen válida' });
      return;
    }
    const categoryId = categories.find((category) => category.categoryId === dataParam.creditorAgent);
    const body = {
      id: defaultValues.id,
      beneficiaryId: defaultValues.beneficiaryId,
      beneficiaryType: defaultValues.beneficiaryType,
      reference: dataParam.reference,
      creditorAgent: categoryId.creditorAgent,
      creditorAgentUrlImage: null,
      beneficiaryUrlImg: imagesBase64,
      categoryId: categoryId.categoryId,
      maxAmount: dataParam.maxAmount,
      minAmount: dataParam.minAmount,
      status: defaultValues.status,
    };

    let isSavedorUpdate = false;
    let message;
    if (data) {
      isSavedorUpdate = await updateProvider(body);
      resetFields();
      message = 'Se ha editado el proveedor exitosamente';
    } else {
      const categoryOrder = providers.find((categoryRow) => categoryRow.order === parseInt(dataParam.order, 10));
      if (categoryOrder !== undefined || categoryOrder === false) {
        setAlert({ status: true, type: 'error', message: 'El número de orden ya ha sido usado. El numero de orden debe estar entre 1 y 99' });
        return;
      }
      isSavedorUpdate = await createCategory(body);
      message = 'Se ha creado la categoria exitosamente';
    }
    if (isSavedorUpdate) {
      await getProviders();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      resetFields();
      onClose();
      setImagePreview(null);
    }
  };

  const priceValidator = (price) => {
    let errorString = '';
    if (price) {
      const digits = price.toString().split('.')?.[0]?.length || 0;
      if (digits > 6) {
        errorString += 'Solo se aceptan 6 digitos';
      }
      const decimals = price.toString().split('.')?.[1]?.length || 0;
      if (decimals > 2) {
        if (errorString) {
          errorString += 'y 2 decimales';
        } else {
          errorString += 'Solo se aceptan 2 decimales';
        }
      }
      return errorString || true;
    }
    return true;
  };

  const validateAmount = (type) => {
    switch (type) {
      case 'min':
        return 'El valor debe ser mayor a 0';
      case 'required':
        return 'Campo obligatorio';
      default:
        return '';
    }
  };

  const onFileUploadIcon = async (event) => {
    event.preventDefault();
    if (!event.target.files || !event.target.files[0]) return;
    setInvalidImageLit('');
    setInvalidImg(false);
    // Get the file Id
    const { id } = event.target;
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(png)$/)) {
      setInvalidImageLit('Debe cargar un archivo válido (png).');
      return false;
    }
    setImgUrl((window.URL ? URL : window.webkitURL).createObjectURL(event.target.files[0]));
    getHeightAndWidthFromDataUrl(imgUrl).then((value) => {
      if (value.width > 40 || value.height > 40) {
        setInvalidImageLit('El tamaño del archivo supera el máximo permitido 40px x 40px.');
        setInvalidImg(true);
        return false;
      }
    });
    setStartBarProgressLit(true);
    file_reader.onload = () => {
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
      setImagePreview(file_reader.result);
    };
    setFileNameLit(file?.name ?? '');
    setTimeout(() => {
      if (file && file.name !== '') {
        setStartBarProgressLit(false);
      }
    }, 2000);
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (fileNameLit !== '') {
      clearErrors('imageLit');
    }
  }, [fileNameLit]);

  useEffect(() => {
  }, [imagePreview]);

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >

        <>
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre proveeedor*"
                  name="reference"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Asignar categoría*"
                  name="creditorAgent"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={categoriesOptions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Monto mínimo *"
                  name="minAmount"
                  inputMode="numeric"
                  type="number"
                  control={control}
                  rule={{
                    required: 'Campo obligatorio',
                    min: 0.01,
                    validate: priceValidator,
                  }}
                  error={errors}
                  helperText={
                    validateAmount(errors.amount
                      ? errors.amount.type
                      : '')
                  }
                  InputPropsParam={{
                    inputProps: { type: 'number', min: 1, max: 999999.999 },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Monto máximo *"
                  name="maxAmount"
                  inputMode="numeric"
                  type="number"
                  control={control}
                  rule={{
                    required: 'Campo obligatorio',
                    min: 0.01,
                    validate: priceValidator,
                  }}
                  error={errors}
                  helperText={
                    validateAmount(errors.amount
                      ? errors.amount.type
                      : '')
                  }
                  InputPropsParam={{
                    inputProps: { type: 'number', min: 1, max: 999999.999 },
                  }}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={4} lg={4} justifyContent="center" alignItems="center" direction="column">
                <Box display="flex" className={classes.uploadImage}>
                  <label htmlFor="fileIcon">
                    <input
                      onChange={onFileUploadIcon}
                      id="fileIcon"
                      accept=".png"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                    >
                      IMAGEN ICONO (40 x 40 px)
                    </Button>

                    {invalidImageLit && (
                    <Typography className={classes.formErrors}>
                      {invalidImageLit}
                    </Typography>
                    )}
                  </label>
                  <Box style={{ width: '100%', paddingLeft: 15 }}>
                    <Typography className={classes.formLabel}>
                      {fileNameLit}
                    </Typography>
                    <Box style={{ width: '70%' }}>
                      <ProgressBar
                        startBarProgress={startBarProgressLit}
                      />
                    </Box>
                  </Box>
                </Box>
                {(data !== null && data.icon_base64 && imagePreview === null) && (
                  <>
                    <MaterialImage
                      name="imageActual"
                      src={data.icon_base64}
                      style={{ width: 60 }}
                      aspectRatio={(60 / 10)}
                    />
                    <span htmlFor="imageActual">Imagen actual</span>
                  </>
                )}
                {(imagePreview !== null) && (
                  <>
                    <MaterialImage
                      name="imagepreview"
                      src={imagePreview}
                      style={{ width: 60 }}
                      aspectRatio={(60 / 10)}
                    />
                    <span htmlFor="imagepreview">Nueva Imagen</span>
                  </>
                )}
                {
                Object.keys(errors).length > 0 && fileNameLit === '' && data === null
                && (
                <>
                  <MessageRequired>Campo obligatorio</MessageRequired>
                </>
                )
              }
              </Grid>
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
