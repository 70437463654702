import httpInstance from '../httpInstance';

const getBannersService = async () => {
  let res;
  const endpoint = '/frontlabels/v1/promos/WEB_ALL';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getBannersService;
