/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useForm } from 'react-hook-form';
import { CustomSelect, Loading } from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { getFeaturedPromotion } from '../../../services';
import { onAddFeaturedPromotion } from '../utils/utils';
import {
  BoxButton,
  BoxInputs,
  BoxOrder,
  BoxRow,
  BoxSelect,
} from './styles';

const ListPromotions = ({
  currentPromotions,
  canEdit,
  setAlert,
  onClose,
}) => {
  const [promotions, setPromotions] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsOrder, setOptionsOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    control, errors, handleSubmit,
  } = useForm({});

  const handleSave = async (dataForm) => {
    setLoading(true);
    const dataAlert = await onAddFeaturedPromotion({ dataForm, promotions });
    setLoading(false);
    if (dataAlert.type === 'success') onClose(true);
    setAlert({ ...dataAlert, status: true });
  };

  const initData = async () => {
    setLoading(true);
    const res = await getFeaturedPromotion('false');
    setLoading(false);
    if (res?.status === 200) {
      const activePromotions = res.data.filter((e) => e.status === 'activo');
      const optionsBuilded = activePromotions.map((promotion) => ({
        value: promotion.id,
        label: promotion.promotionName,
      }));

      const currentOrder = currentPromotions.map((promotion) => promotion.featureOrder);
      const initOptionsOrder = [];
      for (let i = 1; i <= 3; i++) {
        const value = i.toString();
        if (currentOrder.indexOf(value) === -1) {
          initOptionsOrder.push({ value, label: value });
        }
      }

      setOptionsOrder(initOptionsOrder);
      setOptions(optionsBuilded);
      setPromotions(res.data);
    } else {
      setPromotions([]);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (currentPromotions) initData();
  }, [currentPromotions]);

  return (
    <Box>
      {loading && <Loading />}
      <BoxRow>
        <h2>Agregar promoción a destacadas</h2>
        <Tooltip title="Podras agregar una promoción destacada siempre y cuando no tengas más de tres." aria-label="help" placement="bottom">
          <HelpIcon />
        </Tooltip>
      </BoxRow>
      {!loading && options.length === 0
        ? <h3>No hay promociones para destacar con estatus Activa</h3>
        : (
          <form noValidate autoComplete="off">
            {optionsOrder.length === 0
              ? <h4>Ya cuentas con tres promociones destacadas.</h4>
              : (
                <>
                  {optionsOrder.map((e, i) => (
                    <BoxInputs>
                      <BoxSelect>
                        <CustomSelect
                          label={`Promociones${i === 0 ? '*' : ''}`}
                          name={`promotion-${i}`}
                          control={control}
                          rule={i === 0 && Rules.required}
                          error={errors}
                          options={options}
                          disabled={!canEdit}
                        />
                      </BoxSelect>
                      <BoxOrder>
                        <CustomSelect
                          label={`Orden${i === 0 ? '*' : ''}`}
                          name={`order-${i}`}
                          control={control}
                          rule={i === 0 && Rules.required}
                          error={errors}
                          options={optionsOrder}
                          disabled={!canEdit}
                        />
                      </BoxOrder>
                    </BoxInputs>
                  ))}
                  <BoxButton>
                    <Button variant="outlined" disabled={!canEdit} onClick={handleSubmit(handleSave)}>
                      Agregar
                    </Button>
                  </BoxButton>
                </>
              )}
          </form>
        )}
    </Box>
  );
};

ListPromotions.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  setAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentPromotions: PropTypes.array.isRequired,
};

export default ListPromotions;
