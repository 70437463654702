/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { sortVersions } from '../../../utils';
import useStyle from './useStyle';

const CustomTable = (props) => {
  const classes = useStyle();
  const {
    data,
    columns,
    readOnly,
    totalElementsData,
    onNext,
    onSelectPageSize,
    searchQuery,
    loading,
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countTablePagination, setCountTablePagination] = React.useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');

  useEffect(() => {
    if (totalElementsData) {
      setCountTablePagination(totalElementsData);
      setPage(0);
    }
  }, [totalElementsData]);

  useEffect(() => {
    if (!totalElementsData || searchQuery) {
      setCountTablePagination(data.length);
      setPage(0);
    }
  }, [data, searchQuery]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function stableSort(array, comparator, orderByParam = '', order = '') {
    if (orderByParam === 'version') {
      const stabilizedThis = sortVersions(array, order === 'asc');
      return stabilizedThis;
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderComparator = comparator(a[0], b[0]);
      if (orderComparator !== 0) return orderComparator;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderByParam) {
    if (b[orderByParam] < a[orderByParam]) {
      return -1;
    }
    if (b[orderByParam] > a[orderByParam]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderParam, orderByParam) {
    return orderParam === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByParam)
      : (a, b) => -descendingComparator(a, b, orderByParam);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (onNext) onNext();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    if (onSelectPageSize) onSelectPageSize(+event.target.value);
    setPage(0);
  };

  function EnhancedTableHead(props) {
    const {
      classes, order, orderBy, onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              className={`${classes.tableCellHead} ${readOnly && classes.readOnly}`}
              key={headCell.id}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                classes={{
                  root: classes.tableSortLabel,
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={countTablePagination} // {rows.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy), orderBy, order)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (

                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={row.active ? '' : classes.disabledRow}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={classes.tableCell}
                        key={column.id}
                        align={column.align}
                        size="small"
                        variant="body"
                      >
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {
          countTablePagination === 0 && !loading
            ? (
              <Grid container style={{ justifyContent: 'center' }}>
                <Typography>
                  No existe información correspondiente a los criterios de búsqueda
                </Typography>
              </Grid>
            )
            : ''
        }

      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={countTablePagination}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Registros por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        classes={{
          actions: classes.actionsPaginTable,
          selectIcon: classes.actionsPaginTable,
          caption: classes.tablePagination,
        }}
      />
    </>
  );
};

export default CustomTable;
