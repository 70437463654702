import httpInstance from '../httpInstance';

const updatePromotionService = async (dataParam) => {
  let res;
  const endpoint = '/files/v1/files/cashin-promo';
  const dataSent = dataParam;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updatePromotionService;
