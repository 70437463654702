import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

const ErrorScreenPermission = () => (
  <Container>
    <Grid container alignContent="center" alignItems="center">
      <Typography variant="body1">
        No fue posible validar los permisos del usuario o
        no cuenta con permisos suficientes para visualizar esta sección.
      </Typography>
    </Grid>
  </Container>
);

export default ErrorScreenPermission;
