/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import SVG from 'react-inlinesvg';
import formStyle, { WrapContainer } from './formStyle';
import {
  CustomInput, Toaster,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
  setDefaultValues,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [invalidImageLit, setInvalidImageLit] = useState('');
  const [fileNameLit, setFileNameLit] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [files, setFiles] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const providerRef = React.useRef();
  const classes = formStyle();

  const {
    control, errors, reset, clearErrors, watch, setValue,
  } = useForm({ defaultValues });

  const {
    icon,
  } = watch(['icon']);

  const resetFields = () => {
    setFiles([]);
    setCanEdit(false);
    setInvalidImageLit('');
    setFileNameLit('');
    setDefaultValues({
      nameCategory: '',
      icon: '',
      order: '',
    });
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
    setImagePreview(null);
  };

  useEffect(() => {
    if (fileNameLit !== '') {
      clearErrors('imageLit');
    }
  }, [fileNameLit]);

  useEffect(() => {
  }, [imagePreview]);

  useEffect(() => {
    setImagePreview(icon);
  }, [icon]);

  const onFileUploadIcon = (event) => {
    event.preventDefault();
    setInvalidImageLit('');
    // Get the file Id
    const { id } = event.target;
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(svg)$/)) {
      setInvalidImageLit('Debe cargar un archivo valido (.svg).');
      return false;
    }
    file_reader.readAsText(file, 'UTF-8');
    file_reader.onload = () => {
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
      setImagePreview(file_reader.result);
      setValue('icon', file_reader.result);
    };
    setFileNameLit(file !== null && file.name !== null ? file.name : '');
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data !== null) setCanEdit(true);
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        withoutSaveButton
        readOnly
      >

        <>
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={2} lg={2} />
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre categoria*"
                  name="nameCategory"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled={canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Orden *"
                  name="order"
                  control={control}
                  inputMode="numeric"
                  pattern="[1-9]*'"
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 2,
                    max: 99,
                    min: 1,
                  }}
                  disabled={canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} />
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container alignItems="center" alignContent="center" direction="column">
                  {imagePreview && (
                    <>
                      <span htmlFor="imagepreview">Preview icono</span>
                      <SVG src={imagePreview} fill="#2D3134" />
                    </>
                  )}
                  <CustomInput
                    label="Icono svg xml*"
                    name="icon"
                    control={control}
                    rule={Rules.required}
                    error={errors}
                    multiline
                    rows={3}
                    disabled={canEdit}
                    helperText="svg xml"
                  />
                </Grid>
                <label htmlFor="fileIcon">
                  <input
                    onChange={onFileUploadIcon}
                    id="fileIcon"
                    accept=".svg"
                    type="file"
                    style={{ display: 'none' }}
                    disabled={canEdit}
                  />
                  <Button
                    color="inherit"
                    variant="contained"
                    component="span"
                    className={classes.buttonImage}
                    endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                    disabled={canEdit}
                  >
                    Cargar desde archivo.svg
                  </Button>

                  {invalidImageLit && (
                  <Typography className={classes.formErrors}>
                    {invalidImageLit}
                  </Typography>
                  )}
                </label>
              </Grid>
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  setDefaultValues: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
