export const getTapToPhoneRows = (
  tapOnPhoneParam, createActionsButtons, setRows, setInfoCSV,
) => {
  const tapOnPhoneInfo = [];
  const tapOnPhoneCsv = [];

  tapOnPhoneParam?.forEach(
    (tapOnPhone) => {
      const tapOnPhoneRow = {
        active: 1,
        documentNumber: tapOnPhone.document_number,
        commercialName: tapOnPhone.commercial_name,
        commerceId: tapOnPhone.commerce_id,
        acquiringId: tapOnPhone.acquiring_id_tap_to_phone,
        actions: createActionsButtons(
          tapOnPhone.active_tap_to_phone, tapOnPhone,
        ),
      };
      tapOnPhoneInfo.push(tapOnPhoneRow);

      const tapPhoneInfo = {
        RNC: tapOnPhone.document_number,
        Nombre: tapOnPhone.commercial_name,
        Id_Aquiriencia: tapOnPhone.acquiring_id_tap_to_phone,
        Id_Terminal: tapOnPhone.terminal_id_tap_to_phone,
        Categoria: tapOnPhone.mcc_tap_to_phone,
        ITBIS: tapOnPhone.itbis === true ? 'SI' : 'NO',
        Estado: tapOnPhone.active_tap_to_phone === true ? 'Activo' : 'Inactivo',
      };

      tapOnPhoneCsv.push(tapPhoneInfo);
    },
  );

  setInfoCSV(tapOnPhoneCsv);
  setRows(tapOnPhoneInfo);
};

export const openModalEditTapToPhone = async (
  tapOnPhoneRow, setDefaultValues, setPopUpAction,
) => {
  const editValues = {
    acquiringId: tapOnPhoneRow.acquiring_id_tap_to_phone,
    activeTapOnPhone: tapOnPhoneRow.active_tap_to_phone,
    commercialName: tapOnPhoneRow.commercial_name,
    documentNumber: tapOnPhoneRow.document_number,
    mcc: tapOnPhoneRow.mcc_tap_to_phone,
    terminalId: tapOnPhoneRow.terminal_id_tap_to_phone,
    checkedItbis: false,
  };
  setDefaultValues(editValues);
  setPopUpAction({
    open: true,
    title: 'Editar comercio Tap to Phone',
    data: editValues,
  });
};
