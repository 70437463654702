import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  uploadImage: {
    paddingBottom: 10,
  },
  dateCustom: {
    width: '270px',
    '& .MuiIconButton-root': {
      color: '#50B940',
    },
  },
  buttonImage: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #50B940',
    borderRadius: '5px',
    opacity: 1,
    width: '156px',
    height: '33px',
    letterSpacing: '0px',
    color: '#50B940',
  },
  formLabel: {
    color: 'var(--unnamed-color-575756)',
    font: 'normal normal normal 14px/17px Muli',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: 1,
  },
  mat_icon: {
    height: '15px',
    width: '15px',
  },
}));

export default useStyle;
