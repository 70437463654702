/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
// import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Image from 'material-ui-image';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  deleteBannerService,
} from '../../../../services';
import { Toaster, CustomTable } from '../../../../components';
import { columns, targetoptions, categoryOptions } from '../constants';
import useStyle from './useStyle';

const BanerTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction, enteredFilter, banners, setBanners, setDefaultValues, actionsDisabled,
  } = props;
  const [bannersRows, setBannersRows] = React.useState([]);
  const [bannersListCopy, setBannersListCopy] = React.useState([]);
  const targets = [...targetoptions];
  const [generalInfo, setGeneralInfo] = useState({
    code: '',
    checked: false,
  });
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const bannersList = codesMapped != null ? codesMapped : bannersListCopy;
    setBannersRows(bannersList.filter(
      (banner) => banner.name
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.target
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.category
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || banner.order
          .indexOf(enteredFilterParam) >= 0
        || banner.startDate
          .indexOf(enteredFilterParam) >= 0
        || banner.endDate
          .indexOf(enteredFilterParam) >= 0
        || banner.status
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
      ,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleDeleteBanner = (bannerId) => {
    setGeneralInfo({
      id: bannerId,
      checked: false,
    });
    setOpenAlertConfirmDelete(true);
  };

  const openModalEditPromo = (bannerIdSelected) => {
    const bannerEdit = banners.find((bannerRow) => bannerRow.id === bannerIdSelected);
    const redirectionTypeLocal = bannerEdit.application_path !== null
      ? categoryOptions[0].value : categoryOptions[1].value;
    setDefaultValues({
      name: bannerEdit.name,
      description: bannerEdit.description,
      category: bannerEdit.category,
      // redirectionType: redirectionTypeLocal,
      redirection: (redirectionTypeLocal === 'APP' ? bannerEdit.application_path : bannerEdit.destination_url) || '',
      startDate: bannerEdit.date_start.split('T')[0].replaceAll('-', '/'),
      endDate: bannerEdit.date_end.split('T')[0].replaceAll('-', '/'),
      target: bannerEdit.target,
      promotion: bannerEdit.id_promotion,
      image_source_url: bannerEdit.image_source_url,
      order: bannerEdit.order,
    });
    setPopUpAction({
      open: true,
      title: 'Editar banner',
      data: bannerEdit,
    });
  };

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele.id !== value);
  }

  const deletePromo = async () => {
    const res = await deleteBannerService(generalInfo.id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status > 201) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    providerRef.current.enqueueSnackbar('Se ha eliminado el banner exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    const bannersLocal = arrayRemove(banners, generalInfo.id);
    setBanners(bannersLocal);
    setOpenAlertConfirmDelete(false);
  };

  function createActions(active, bannerId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditPromo(bannerId)}
          style={{ paddingLeft: 10 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeleteBanner(bannerId)}
          disabled={actionsDisabled}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createImage(url) {
    return (
      <Image
        width={120}
        aspectRatio={(40 / 15)}
        src={url}
      />
    );
  }

  function mapCodes(bannersParam) {
    const bannersInfo = [];
    bannersParam?.forEach((banner) => {
      const targetLabel = targets.filter((item) => item.value === banner.target)[0]?.label || '';
      const bannerRow = {
        name: banner.name,
        description: banner.description,
        category: banner.category === 'APP' ? 'App banner' : 'Promo banner',
        order: banner.order === 0 ? 'Sin orden' : banner.order.toString(),
        target: targetLabel,
        startDate: banner.date_start.split('T')[0].replaceAll('-', '/'),
        endDate: banner.date_end.split('T')[0].replaceAll('-', '/'),
        state: true,
        image: createImage(banner.image_source_url.big),
        actions: createActions(banner.state, banner.id),
        id: banner.id,
        status: banner.state ? 'Activo' : 'Inactivo',
        active: !actionsDisabled,
      };
      bannersInfo.push(bannerRow);
    });
    setBannersRows(bannersInfo);
    setBannersListCopy(bannersInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, bannersInfo);
  }

  useEffect(() => {
    setBannersRows([]);
    if (banners.length > 0 && targetoptions.length > 0) mapCodes(banners);
  }, [banners, targetoptions]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={bannersRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />

      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere eliminar este banner?"
        handleAccept={() => deletePromo()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default BanerTable;
