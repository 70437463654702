import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const getDisabledButton = ({ disabled, theme }) => disabled && `
  border: 1px solid ${theme.primary10};
  color: ${theme.black10};
  cursor: auto;
  border: 1px solid ${theme.black10};
  box-shadow: none;
`;

export const ButtonStyled = styled.button`
  align-items: center;
  justify-content: center;
  min-width: 64px;
  height: 40px;
  margin: 0 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  width: 110px;
  display: flex;
  alignt-items: center;
  ${({ theme }) => `
    background-color: ${theme.white};
    color: ${theme.primary};
    box-shadow: none;
  `}
  ${getDisabledButton}
`;

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default useStyle;
