import React, { useState, useEffect } from 'react';
import {
  Paper,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import FeaturesFlagTable from './components/DataTable/FeaturesFlagTable.readonly';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import {
  getFeaturesFlagService,
} from '../../services';

const PersonFeaturesFlagScreen = () => {
  const classes = useStyle();
  const [featuresFlag, setFeaturesFlag] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState('');
  const getFeaturesFlag = async () => {
    const res = await getFeaturesFlagService();
    return setFeaturesFlag(res?.data);
  };

  useEffect(() => {
    getFeaturesFlag();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        <div className={classes.root}>
          <div>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              placeholder="Buscar"
            />
            <Tooltip title="Crear Feature Flag" aria-label="Nuevo Feature Flag">
              <IconButton
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
                classes={{ disabled: classes.disabled }}
                disabled
              >
                <img src={newButton} alt="newButton" />
              </IconButton>
            </Tooltip>
          </div>
          <FeaturesFlagTable
            featuresFlag={featuresFlag}
            enteredFilter={enteredFilter}
          />
        </div>
      </>
    </Paper>
  );
};

export default PersonFeaturesFlagScreen;
