/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { SnackbarProvider } from 'notistack';
import formStyle, {
  WrapContainer, ListContainer, LabelPermissions, DeleteButton,
} from './formStyle';
import { Rules } from '../../../helpers/RHFRules';
import {
  Loading,
  Toaster,
  CustomInput,
  CustomSwitch,
  CustomSelect,
} from '../../../components';
import {
  useRegisterRol,
  useUpdateRol,
} from '../../../services';
import { ActionPopUp } from '../../../Layouts';

const ActionForm = ({
  open,
  data,
  title,
  defaultValues,
  setDefaultValues,
  onClose,
  getAllRoles,
  screensData,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [canEdit, setCanEdit] = useState(false);
  const [active, setActive] = React.useState(true);
  const [screensCopy, setCopyScreens] = useState([]);
  const [sectionsSelected, setSectionsSelected] = useState([]);

  useEffect(() => {
    setCopyScreens([...screensData]);
  }, [screensData]);

  const {
    registerRol,
    loading,
    error: errorResponse,
  } = useRegisterRol();

  const {
    updateRol,
    loading: loadingUpdate,
    error: errorUpdate,
  } = useUpdateRol();

  const classes = formStyle();
  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset, clearErrors, watch, setValue,
  } = useForm({ defaultValues });

  const {
    screens,
  } = watch(['screens']);

  useEffect(() => {
    if (screensData && screensData.length > 0) {
      const arrayOriginal = [...screensData];
      const thirdArray = arrayOriginal.filter((arrayOr) => !sectionsSelected.find((item) => item.screenName === arrayOr.value));
      setCopyScreens(thirdArray);
    }
  }, [sectionsSelected]);

  const resetFields = () => {
    setCanEdit(false);
    setSectionsSelected([]);
    setDefaultValues({
      id: '',
      description: '',
      active: true,
      permissions: [],
      screens: '',
    });
  };

  const enableEdition = () => {
    setCanEdit((prev) => !prev);
  };

  const addSections = () => {
    setSectionsSelected((prev) => {
      const added = prev.find((item) => item.screenName === screens);
      if (added || screens === '') return prev;
      const dataUpdate = [{ screenName: screens, canWrite: false, canRead: true }, ...prev];
      return dataUpdate;
    });
    setValue('screens', '');
  };

  const deleteSections = (screenDelete) => {
    if (screensData && screensData.length > 0) {
      const oldSelected = [...sectionsSelected];
      const newSelected = oldSelected.filter((old) => old.screenName !== screenDelete);
      setSectionsSelected(newSelected);
    }
  };

  const handleChangeState = (event, type) => {
    const cpyarray = [...sectionsSelected];
    const index = cpyarray.findIndex((item) => item.screenName === event.target.value);
    if (index !== -1) {
      cpyarray[index][type] = event.target.checked;
    }
    setSectionsSelected(cpyarray);
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };

  const handleSave = async (dataParam) => {
    clearErrors();
    const body = {
      description: dataParam.description,
      active,
      permissions: sectionsSelected,
    };
    let message;
    let response;
    if (data) {
      body.id = data.id;
      response = await updateRol({ data: body });
      message = 'Se ha editado el rol exitosamente';
    } else {
      response = await registerRol({ data: body });
      message = 'Se ha creado el rol exitosamente';
    }
    if (response && [200, 201].includes(response.status)) {
      getAllRoles();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      resetFields();
      setCanEdit(false);
      onClose();
    }
  };

  useEffect(() => {
    if (errorResponse) {
      const message = `Ocurrio un error al crear el rol. Intentelo nuevamente. ${errorResponse.message || ''}`;
      providerRef.current.enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    if (errorUpdate) {
      const message = `Ocurrio un error al actualizar e rol. Intentelo nuevamente. ${errorUpdate.message || ''}`;
      providerRef.current.enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }, [errorResponse, errorUpdate]);

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data !== null) setCanEdit(true);
  }, [data]);

  useEffect(() => {
    // Set date for edition
    if (defaultValues.permissions) {
      setSectionsSelected(defaultValues.permissions);
    }
    setActive(defaultValues?.active || false);
  }, [defaultValues]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlert({ status: true, type: 'warning', message: 'campos obligatorios faltantes' });
    }
  }, [errors]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          {data !== null && <Button variant="outlined" color="primary" onClick={enableEdition}>Habilitar edición</Button>}
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off" className={classes.formContainer}>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  Registrar nuevo rol:
                </Typography>
              </Grid>
              {/* @#Rol */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Nombre del rol *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 48,
                  }}
                  disabled={canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} />
              {/* Estatus */}
              <Grid item xs={12} sm={12} md lg={12}>
                <Paper variant="outlined" className={classes.paperbox}>
                  <FormControlLabel
                    className={classes.labelSwitch}
                    control={(
                      <CustomSwitch
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                        name="active"
                        color="primary"
                      />
                    )}
                    disabled={canEdit}
                    label={`Estatus: ${active ? 'Activo' : 'Inactivo'}`}
                    labelPlacement="start"
                  />
                </Paper>
                {!active && <MuiAlert elevation={6} variant="filled" severity="warning" style={{ width: '50%' }}>Inactivar el rol deshabilitará el acceso a todas las pantallas de los usuarios que tengan este rol asignado</MuiAlert>}
              </Grid>
              {/* @#Secciones */}
              <Grid item xs={12}>
                <Typography variant="body1">
                  Elegir secciones del rol:
                </Typography>
              </Grid>
              {/* @#agregar secciones */}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomSelect
                  label="Elegir sección*"
                  name="screens"
                  control={control}
                  error={errors}
                  disabled={canEdit}
                  options={screensCopy || []}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Button variant="text" color="primary" size="small" onClick={addSections} disabled={canEdit}> Agregar sección +</Button>
              </Grid>
              {
                sectionsSelected.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <LabelPermissions variant="body1">
                        Editar permisos de secciones:
                      </LabelPermissions>
                    </Grid>
                    <ListContainer>
                      {
                        sectionsSelected.map((item) => (
                          <Grid item xs={12} sm={12} md={12} lg={12} key={`gr-${item.screenName}`}>
                            <Paper variant="outlined" className={classes.paperRow} key={`pr-${item.screenName}`}>
                              <Grid item xs={12} sm={12} md={11} lg={11} key={`grinner-${item.screenName}`}>
                                <Typography variant="subtitle2">
                                  {`${item.screenName} ${!item.canWrite ? '(solo lectura)' : ''}`}
                                </Typography>
                                <FormControlLabel
                                  style={{ marginLeft: 0 }}
                                  control={(
                                    <CustomSwitch
                                      checked={item.canWrite}
                                      onChange={(e) => handleChangeState(e, 'canWrite')}
                                      value={item.screenName}
                                      name="write"
                                      color="primary"
                                    />
                                  )}
                                  disabled={canEdit}
                                  label="Escritura"
                                  labelPlacement="start"
                                  key={`frswitchw-${item.screenName}`}
                                />
                                <Tooltip title="Quitar" aria-label="delete" placement="top">
                                  <DeleteButton className={classes.editIcon} onClick={() => deleteSections(item.screenName)} />
                                </Tooltip>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))
                      }
                    </ListContainer>
                  </>
                )
              }
              <Grid item xs={12} sm={12} md={8} lg={8} />
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>
        {(loading || loadingUpdate) && <Loading />}
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
