/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControlLabel,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput,
  Toaster,
  CustomSwitch,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import useStyle from './useStyle';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
}) => {
  const classes = useStyle();

  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = useState(true);
  const [selected, setSelected] = useState([]);

  const providerRef = useRef();

  const {
    control, errors, reset,
  } = useForm({ defaultValues });

  const redirectionTypeOptions = ['delivery', 'pickup'];

  const handleOnclose = () => {
    onClose();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.active);
      setSelected(data?.deliveryTypes);
    }
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        withoutSaveButton
        readOnly
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Nombre *"
                  name="name"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Dirección *"
                  name="streetAddress"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 200,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Código Postal"
                  name="postalCode"
                  variant="standard"
                  type="number"
                  control={control}
                  error={errors}
                  inputProps={{
                    maxLength: 10,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Extensión"
                  name="phoneExtension"
                  variant="standard"
                  type="number"
                  rule={Rules.phoneExtension}
                  control={control}
                  error={errors}
                  maxlength="5"
                  inputProps={{ maxLength: 3 }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Teléfono *"
                  name="phone"
                  variant="standard"
                  type="number"
                  control={control}
                  rule={Rules.phone}
                  error={errors}
                  inputProps={{
                    maxLength: 10,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Ciudad *"
                  name="city"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 100,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="País *"
                  name="country"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Latitud *"
                  name="latitude"
                  variant="standard"
                  type="number"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 20,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Longitud *"
                  name="longitude"
                  variant="standard"
                  type="number"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 20,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Hora que abre *"
                  name="openingTime"
                  variant="standard"
                  control={control}
                  type="datetime-local"
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 20,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Hora que cierra *"
                  name="closingTime"
                  variant="standard"
                  control={control}
                  type="datetime-local"
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 20,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Correo electronico *"
                  name="email"
                  variant="standard"
                  type="email"
                  control={control}
                  rule={Rules.emailDC}
                  error={errors}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CustomInput
                  label="Observaciones *"
                  name="observations"
                  variant="standard"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 200,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                >
                  <InputLabel
                    variant="outlined"
                    id="demo-mutiple-checkbox-label"
                  >
                    Tipo de entrega
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={selected}
                    input={<Input />}
                    renderValue={(values) => values.join(', ')}
                    disabled
                  >
                    {redirectionTypeOptions.map((opt) => (
                      <MenuItem key={opt} value={opt}>
                        <Checkbox checked={selected.indexOf(opt) > -1} />
                        <ListItemText primary={opt} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Paper style={{ height: 50 }}>
                  <Box
                    display="flex"
                    style={{ paddingTop: 10 }}
                    justifyContent="center"
                  >
                    <FormControlLabel
                      className={classes.formControl}
                      control={(
                        <CustomSwitch
                          checked={active}
                          name="active"
                          color="primary"
                          disabled
                        />
                      )}
                      label="Estado"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default ActionForm;
