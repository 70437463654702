import httpInstance from '../httpInstance';

const deleteFeaturesFlagCommerceService = async (FFId) => {
  let res;
  const endpoint = `/back-office/v1/features-flag-commerce/${FFId}`;

  await httpInstance.delete(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default deleteFeaturesFlagCommerceService;
