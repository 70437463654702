import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  textFieldShape: {
    width: '100px',
    marginLeft: '3px',
  },
  searchTextField: {
    boxShadow: '0px 1px #00000026',
    width: '50%',
    borderRadius: '3px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    opacity: 1,
    height: '33px',
  },
  selectTextField: {
    width: '30%',
    opacity: 1,
    height: '33px',
    marginLeft: '5px',
  },
  imgColorReadOnly: {
    opacity: 0.4,
  },
}));

export default useStyle;
