/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const getVariant = ({ variant, theme }) => {
  if (variant === 'outlined') {
    return `
      background-color: ${theme.white};
      color: ${theme.primary};
      box-shadow: none;
    `;
  }

  return `
    background-color: ${theme.primary};
    color: ${theme.white};
  `;
};

const getDisabledButton = ({ disabled, theme }) => disabled && `
    background-color: ${theme.black10};
    color: ${theme.black20};
    cursor: auto;
    border: 1px solid ${theme.black10};
    box-shadow: none;
  `;

export const ButtonStyled = styled.button`
  align-items: center;
  justify-content: center;
  min-width: 64px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  text-transform: uppercase;
  padding: 6px 16px;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-left: ${({ marginLeft }) => marginLeft}px;
  ${getVariant}
  ${getDisabledButton}
`;
