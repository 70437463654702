/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const BoxSelect = styled.div`
  width: 400px;
`;

export const BoxOrder = styled.div`
  margin-left: 20px;
  width: 120px;
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ withSpace }) => withSpace && 'margin-top: 10px;'}
`;

export const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BoxInputs = styled(BoxRow)`
  margin: 10px 0;
`;
