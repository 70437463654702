const columns = [
  {
    id: 'name',
    label: 'Nombre',
    paddingLeft: '40px',
    width: 150,
    position: 1,
  },
  {
    id: 'description',
    label: 'Descripción',
    paddingLeft: '40px',
    width: 150,
    position: 2,
  },
  {
    id: 'code',
    label: 'Código',
    paddingLeft: '40px',
    width: 10,
    position: 3,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 4,
  },
];

export default columns;
