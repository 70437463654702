const COLUMNS = [
  {
    id: 'origin_sequence', label: 'Referencia', paddingLeft: '40px', width: 10, position: 1,
  },
  {
    id: 'beneficiary_name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 2,
  },
  {
    id: 'amount', label: 'Monto', paddingLeft: '40px', width: 150, position: 3,
  },
  {
    id: 'date',
    label: 'Fecha',
    paddingLeft: '40px',
    width: 10,
    position: 4,
  },
  {
    id: 'phone_number',
    paddingLeft: '',
    label: '#Telefónico',
    width: 10,
    position: 5,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 10,
    position: 6,
  },
];

export default COLUMNS;
