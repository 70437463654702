/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import {
  useGetProviderById,
} from '../../../../services';

const ProvidersRechargeTable = (props) => {
  const classes = useStyle();
  const {
    providers, setPopUpAction, setDefaultValues, getProviders, updateProvider,
  } = props;
  const [providersRows, setProvidersRows] = React.useState([]);
  const providerRef = React.useRef();

  const openModalEditProvider = async (bannerId) => {
    const providerEdit = providers.find((bannerRow) => bannerRow.beneficiaryId === bannerId);
    setDefaultValues({
      id: providerEdit.id,
      beneficiaryId: providerEdit.beneficiaryId,
      reference: providerEdit.reference,
      beneficiaryType: providerEdit.beneficiaryType,
      creditorAgent: providerEdit.categoryId,
      beneficiaryUrlImg: providerEdit.beneficiaryUrlImg,
      status: providerEdit.status,
      maxAmount: providerEdit.maxAmount,
      minAmount: providerEdit.minAmount,
    });

    setPopUpAction({
      open: true,
      title: 'Editar proveedor',
      data: providerEdit,
    });
  };

  function createActions(active, providerId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditProvider(providerId)}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          disabled
          style={{ paddingLeft: 10 }}
          classes={{ disabled: classes.disabled }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  const updateStatus = async (item) => {
    const body = {
      id: item.id,
      beneficiaryId: item.beneficiaryId,
      status: item.status ? 0 : 1,
    };
    await updateProvider(body);
    await getProviders();
  };

  const createStatusSwitch = (providerParam) => (
    <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
      <Box alignSelf="center">
        <CustomSwitch
          checked={providerParam.status}
          handleChange={() => {}}
          onChange={() => updateStatus(providerParam)}
          name="active"
        />
      </Box>
    </Tooltip>
  );

  const getProviderById = async (id, type) => {
    const res = await useGetProviderById(id, type);
    const normalizedData = {
      data: res?.data?.data[0],
    };
    return normalizedData;
  };

  function createImage(url) {
    return (
      <div>
        {url && <img src={url} alt="" width={40} height={40} />}
      </div>
    );
  }

  const mapCodes = async (providerParam) => {
    let providerInfo = [];
    if (providerParam) {
      providerInfo = await Promise.all(
        providerParam.map(async (provider) => {
          const providerData = await getProviderById(provider.beneficiaryId, provider.serviceType);
          return {
            id: provider?.beneficiaryId,
            name: provider?.reference,
            icon: createImage(provider.beneficiaryUrlImg),
            category: providerData?.data?.creditorAgent,
            min: provider?.minAmount,
            max: provider?.maxAmount,
            pay_method: providerData?.data?.supplementaryData[0]?.paymentMethod,
            payment: providerData?.data?.supplementaryData[0]?.instructedAmount.hasPartialPayment ? 'Si' : 'No',
            actions: createActions(provider?.active, provider?.beneficiaryId),
            state: createStatusSwitch(providerData?.data),
            active: 1,
            vendor: providerData?.data?.supplementaryData[0]?.vendor,
          };
        }),
      );
    }
    setProvidersRows(providerInfo);
  };

  useEffect(() => {
    mapCodes(providers);
  }, [providers]);

  const columns = [
    {
      id: 'id', label: 'ID', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'icon', label: 'Icono', paddingLeft: '40px', width: 150, position: 3,
    },
    {
      id: 'category', label: 'Categoría', paddingLeft: '40px', width: 150, position: 4,
    },
    {
      id: 'min', label: 'Monto mínimo', paddingLeft: '40px', width: 150, position: 4,
    },
    {
      id: 'max', label: 'Monto máximo', paddingLeft: '40px', width: 150, position: 4,
    },
    {
      id: 'pay_method',
      label: 'Método de pago',
      paddingLeft: '40px',
      width: 10,
      position: 5,
    },
    {
      id: 'vendor',
      label: 'Agregador',
      paddingLeft: '40px',
      width: 10,
      position: 4,
    },
    {
      id: 'payment',
      label: 'Abono',
      paddingLeft: '40px',
      width: 10,
      position: 6,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 7,
    },
    {
      id: 'state',
      paddingLeft: '',
      label: 'Estado',
      width: 5,
      position: 7,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={providersRows}
        columns={columns}
      />
    </SnackbarProvider>
  );
};

export default ProvidersRechargeTable;
