import { useState } from 'react';
import { useAxiosInstance } from '../httpInstance';

export const useRegisteredScreens = () => {
  const [{ loading, data, error }, getScreens] = useAxiosInstance(
    // request all registered screens
    {
      url: 'back-office/v1/screen-names',
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    getScreens,
  };
};

export const useDeleteScreenById = () => {
  const [screenId, setScreenId] = useState();
  const [{ loading, data, error }, deleteScreenById] = useAxiosInstance(
    // request all registered screens
    {
      url: `back-office/v1/screen-names/${screenId}`,
      method: 'DELETE',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    deleteScreenById,
    screenId,
    setScreenId,
  };
};

export const useRegisterScreen = () => {
  const [method, setMethod] = useState('POST');
  const [{ loading, data, error }, registerScreen] = useAxiosInstance(
    // request all registered screens
    {
      url: 'back-office/v1/screen-names',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    registerScreen,
    setMethod,
    method,
  };
};

export const useUpdateScreen = () => {
  const [{ loading, data, error }, updateScreen] = useAxiosInstance(
    // request all registered screens
    {
      url: 'back-office/v1/screen-names',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    updateScreen,
  };
};

export default {
  useRegisteredScreens,
  useDeleteScreenById,
  useRegisterScreen,
  useUpdateScreen,
};
