import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  title: {
    marginLeft: '20px',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subtitle: {
    marginLeft: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '25px',
  },
  buttonImage: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #449FDD',
    borderRadius: '5px',
    opacity: 1,
    width: '256px',
    height: '50px',
    letterSpacing: '0px',
    color: '#449FDD',
    marginTop: '20px',
    fled: 'end',
    fontWeight: 'bold',
  },
  buttonImageApprove: {
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #50B940',
    borderRadius: '5px',
    opacity: 1,
    width: '256px',
    height: '50px',
    letterSpacing: '0px',
    color: '#50B940',
    marginTop: '20px',
    fled: 'end',
    fontWeight: 'bold',
  },
  matIcon: {
    height: '40px',
    width: '40px',
    marginLeft: '15px',
  },
  buttonContainer: {
    float: 'right',
    marginLeft: '15px',
  },
  uploadImage: {
    marginTop: '15px',
    marginBottom: '35px',
  },
  tableCellHead: {
    color: '#FFF',
    backgroundColor: '#50B940',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
    textAlign: 'center',
  },
  tableCell: {
    textAlign: 'center',
  },
}));

export default useStyle;
