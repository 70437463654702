/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Slide,
  Paper,
  Container,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from './useStyle';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ActionPopUp = ({
  children,
  open,
  title,
  onClose,
  onSave,
  enabledControls,
  withoutSaveButton = false,
  readOnly = false,
}) => {
  const classes = useStyle();

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={(readOnly && classes.appBarReadOnly) || classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {!withoutSaveButton && (
            <Button autoFocus color="inherit" onClick={handleSave} disabled={!enabledControls}>
              Guardar
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {children}
          </Paper>
        </Grid>
      </Container>
    </Dialog>
  );
};

ActionPopUp.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  enabledControls: PropTypes.bool.isRequired,
  withoutSaveButton: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default ActionPopUp;
