import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Tooltip,
} from '@material-ui/core';
import { getFeaturedPromotion } from '../../services';
import useStyle, { ButtonStyled } from './useStyle';
import ActionForm from './components/ActionForm';
import DataTable from './components/DataTable';
import { Loading, Toaster } from '../../components';

const FeaturedScreen = () => {
  const classes = useStyle();
  const POPUP_VALUES = {
    open: false,
    title: '',
    data: [],
    defaultValues: {},
  };
  const [featuredPromotions, setFeaturedPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState(POPUP_VALUES);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const onBuildOptions = (dataPromotions) => {
    const buildDefaultValues = {};
    dataPromotions.forEach((e) => {
      buildDefaultValues[`order/${e.id}`] = e.featureOrder;
    });

    return buildDefaultValues;
  };

  const openForm = () => {
    const defaultValues = onBuildOptions(featuredPromotions);
    setPopUpAction({
      title: 'Editar promociones destacadas',
      open: true,
      data: featuredPromotions,
      defaultValues,
    });
  };

  const onDeletePromotion = (id) => {
    const newPromotions = featuredPromotions.filter((e) => e.id !== id);
    const defaultValues = onBuildOptions(newPromotions);
    setFeaturedPromotions(newPromotions);
    setPopUpAction({
      ...popUpAction,
      data: newPromotions,
      defaultValues,
    });
  };

  const initData = async () => {
    setLoading(true);
    const res = await getFeaturedPromotion();
    setLoading(false);
    if (res?.status === 200) setFeaturedPromotions(res.data);
    else setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar la información' });
  };

  useEffect(() => {
    initData();
  }, []);

  if (loading) return <Loading />;

  return (
    <Paper className={classes.paper}>
      <Box className={classes.row}>
        <h1>Promociones destacadas</h1>
        <Box className={classes.buttons}>
          <Tooltip title="Editar promociones destacadas" aria-label="edit" placement="bottom">
            <ButtonStyled onClick={openForm}>
              Editar
            </ButtonStyled>
          </Tooltip>
        </Box>
      </Box>
      {featuredPromotions.length === 0
        ? <h3>Aún no hay promociones destacadas</h3>
        : (
          <DataTable
            data={featuredPromotions}
            setAlert={setAlert}
          />
        )}
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        type={popUpAction.type}
        defaultValues={popUpAction.defaultValues}
        onDeletePromotion={onDeletePromotion}
        onClose={(refresh) => {
          if (refresh) initData();
          setPopUpAction(POPUP_VALUES);
        }}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </Paper>
  );
};

export default FeaturedScreen;
