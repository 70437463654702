export const PRIMARY = '#50B940';
export const PRIMARY_80 = '#73C766';
export const PRIMARY_60 = '#96D58C';
export const PRIMARY_40 = '#B9E3B3';
export const PRIMARY_20 = '#DCF1D9';
export const PRIMARY_10 = '#EEF8EC';

export const BLACK = '#1A1A1A';
export const BLACK_80 = '#2D3134';
export const BLACK_60 = '#41474C';
export const BLACK_40 = '#5E676E';
export const BLACK_20 = '#8B959D';
export const BLACK_10 = '#CDD1D5';
export const BLACK_5 = '#F4F5F5';
export const WHITE = '#FFFFFF';

export const FIREBRICK = '#F53D3D';
export const FIREBRICK_40 = '#FF8181';
export const FIREBRICK_10 = '#FFF4F4';

export const THEME = {
  primary: PRIMARY,
  primary80: PRIMARY_80,
  primary60: PRIMARY_60,
  primary40: PRIMARY_40,
  primary20: PRIMARY_20,
  primary10: PRIMARY_10,
  black: BLACK,
  black80: BLACK_80,
  black60: BLACK_60,
  black40: BLACK_40,
  black20: BLACK_20,
  black10: BLACK_10,
  black5: BLACK_5,
  white: WHITE,
  firebrick: FIREBRICK,
  firebrick40: FIREBRICK_40,
  firebrick10: FIREBRICK_10,
};
