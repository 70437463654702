/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  downloadPromotionService,
} from '../../../../services';
import { CustomTable } from '../../../../components';
import { getMoneyFormat, removeFileExtension } from '../../../../utils';
import useStyle from './useStyle';

const PromotionTable = (props) => {
  const providerRef = React.useRef();
  const {
    promotions, actionsDisabled, setAlert, detailCsv, enteredFilter,
  } = props;
  const [promotionsRows, setPromotionsRows] = useState([]);
  const [promotionsListCopy, setPromotionsListCopy] = useState([]);
  const classes = useStyle();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const promotionsList = codesMapped != null ? codesMapped : promotionsListCopy;
    setPromotionsRows(promotionsList.filter(
      (promotion) => promotion.fileName
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || String(promotion.totalUserCount)
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || String(promotion.totalAmount)
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promotion.status
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || promotion.promoDate
          .indexOf(enteredFilterParam) >= 0
        || enteredFilterParam === '',
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const openModalSeePromo = (promoLinkSelected, promoFileSelected, status, users, amount) => {
    detailCsv(promoLinkSelected, promoFileSelected, status, users, amount);
  };

  const downloadFile = async (promoLinkSelected, promoFileSelected) => {
    const newFileName = removeFileExtension(promoFileSelected);
    try {
      const res = await downloadPromotionService(promoLinkSelected, `${newFileName}.csv`, 'blob');
      if (res && res.status === 404) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
      } else {
        setAlert({ status: true, type: 'success', message: 'Archivo de la promoción descargado.' });
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al descargar el archivo.' });
    }
  };

  function createActions(promoFile, promoLink, status, users, amount) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalSeePromo(promoLink, promoFile, status, users, amount)}
          style={{ marginLeft: 20 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <VisibilityIcon className={classes.seeIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Descargar"
          size="small"
          onClick={() => downloadFile(promoLink, promoFile)}
          style={{ marginRight: 20 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Descargar" aria-label="download" placement="top">
            <CloudDownloadIcon className={classes.downloadIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(promotionsParam) {
    const promotionsInfo = [];
    promotionsParam?.forEach((promotion, index) => {
      let fileName = '';
      if (promotion?.file_name !== undefined) {
        fileName = promotion.file_name.split('.csv')[0];
      }
      const promotionRow = {
        id: fileName + index,
        fileName: removeFileExtension(fileName),
        totalUserCount: promotion.total_user_count,
        totalAmount: getMoneyFormat(promotion.total_amount),
        promoDate: promotion.promo_date,
        finishDate: promotion.finish_date ? promotion.finish_date : 'N/A',
        status: promotion.status,
        downloadLink: promotion.download_link,
        actions: createActions(fileName,
          promotion.download_link,
          promotion.status,
          promotion.total_user_count,
          promotion.total_amount),
        active: !actionsDisabled,
      };
      promotionsInfo.push(promotionRow);
    });
    setPromotionsRows(promotionsInfo);
    setPromotionsListCopy(promotionsInfo);
  }

  useEffect(() => {
    setPromotionsRows([]);
    mapCodes(promotions);
  }, [promotions]);

  const columns = [
    {
      id: 'fileName', label: 'Archivo de la Promoción', paddingLeft: '40px', width: 300, position: 1,
    },
    {
      id: 'totalUserCount', label: 'Usuarios impactados', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'totalAmount',
      label: 'Monto Total',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'promoDate',
      label: 'Creación de Promoción',
      paddingLeft: '',
      width: 10,
      position: 4,
    },
    {
      id: 'finishDate',
      label: 'Finalización de Promoción',
      paddingLeft: '',
      width: 10,
      position: 5,
    },
    {
      id: 'status',
      label: 'Estatus',
      paddingLeft: '40px',
      width: 165,
      position: 6,
    },
    {
      id: 'actions',
      label: 'Acciones',
      paddingLeft: '',
      width: 165,
      position: 7,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={promotionsRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />
    </SnackbarProvider>
  );
};

export default PromotionTable;
