/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import formStyle, { WrapContainer } from './formStyle';
import { Rules } from '../../../helpers/RHFRules';
import {
  Loading,
  Toaster,
  CustomInput,
  CustomSelect,
} from '../../../components';
import {
  useCreateAssignment,
  useUpdateAssignment,
} from '../../../services';
import { ActionPopUp } from '../../../Layouts';

const ActionForm = ({
  open,
  data,
  title,
  defaultValues,
  setDefaultValues,
  onClose,
  getUsers,
  dataRoles,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [canEdit, setCanEdit] = useState(false);

  const {
    registerAssignment,
    loading,
    error: errorResponse,
  } = useCreateAssignment();

  const {
    updateAssignment,
    loading: loadingUpdate,
    error: errorUpdate,
  } = useUpdateAssignment();

  const classes = formStyle();
  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset, clearErrors,
  } = useForm({ defaultValues });

  const resetFields = () => {
    setCanEdit(false);

    setDefaultValues({
      id: '',
      userName: '',
      roleId: '',
    });
  };

  const enableEdition = () => {
    setCanEdit((prev) => !prev);
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };

  const handleSave = async (dataParam) => {
    clearErrors();
    const body = {
      userName: dataParam.userName,
      roleId: dataParam.roleId,
    };
    let message;
    let response;
    if (data) {
      body.id = data.id;
      response = await updateAssignment({ data: body });
      message = 'Se ha editado el rol del usuario.';
    } else {
      response = await registerAssignment({ data: body });
      message = 'Se ha creado la asignacion del rol exitosamente';
    }
    if (response && [200, 201].includes(response.status)) {
      getUsers();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      resetFields();
      setCanEdit(false);
      onClose();
    }
  };

  useEffect(() => {
    if (errorResponse) {
      const message = `Ocurrio un error al crear el registro. Intentelo nuevamente. ${errorResponse.message || ''}`;
      providerRef.current.enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }

    if (errorUpdate) {
      const message = `Ocurrio un error al actualizar el registro. Intentelo nuevamente. ${errorUpdate.message || ''}`;
      providerRef.current.enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }, [errorResponse, errorUpdate]);

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data !== null) setCanEdit(true);
  }, [data]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlert({ status: true, type: 'warning', message: 'campos obligatorios faltantes' });
    }
  }, [errors]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          {data !== null && <Button variant="outlined" color="primary" onClick={enableEdition}>Habilitar edición</Button>}
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off" className={classes.formContainer}>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  Asignar rol a usuario:
                </Typography>
              </Grid>
              {/* @#promocion */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre de usuario *"
                  name="userName"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 48,
                  }}
                  disabled={canEdit}
                />
              </Grid>
              {/* @#agregar secciones */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Elegir rol*"
                  name="roleId"
                  control={control}
                  error={errors}
                  disabled={canEdit}
                  options={dataRoles || []}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} />
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>
        {(loading || loadingUpdate) && <Loading />}
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
