/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const getVariant = ({ variant, theme }) => ({
  backgroundColor: variant === 'outlined' ? theme.white : theme.primary,
  color: variant === 'outlined' ? theme.primary : theme.white,
  ...(variant === 'outlined' && { boxShadow: 'none' }),
});

const getDisabledButton = ({ theme }) => ({
  backgroundColor: theme.black10,
  color: theme.black20,
  cursor: 'auto',
  border: `1px solid ${theme.black10}`,
  boxShadow: 'none',
});

export const ButtonStyled = styled('button')(
  ({ theme, variant, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.primary}`,
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '6px 16px',
    lineHeight: 1.75,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: '0.02857em',
    boxShadow: ' 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    ...getVariant({ variant, theme }),
    ...(disabled && getDisabledButton({ theme })),
    '@media (max-width: 960px)': {
      width: '100%',
      marginTop: '8px',
    },
  }),
);
