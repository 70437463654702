import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const DivImage = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const tableStyle = makeStyles(() => ({
  seeIcon: {
    color: '#50B940',
  },
  downloadIcon: {
    color: '#449FDD',
  },
}));

export default tableStyle;
