import Config from '../config';

const envUrlCommerce = () => {
  let urlCommerce;
  switch (Config.API_URL) {
    case 'https://dev.api.mipana.com/admin':
      urlCommerce = 'https://dev.commerce.api.mipana.com';
      break;
    case 'https://qa.api.mipana.com/admin':
      urlCommerce = 'https://qa.commerce.api.mipana.com';
      break;
    case 'https://uat.api.mipana.com/admin':
      urlCommerce = 'https://uat.commerce.api.mipana.com';
      break;
    default:
      urlCommerce = 'https://comercios.api.billet.do';
  }
  return urlCommerce;
};

export default envUrlCommerce;
