/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Toaster } from '../../../../components';
import { ActionPopUp } from '../../../../Layouts';
import useStyle from './useStyle';
import { createNationalHolidaysService } from '../../../../services';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getNationalHolidays,
  defaultValues,
}) => {
  const classes = useStyle();

  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [holidayDate, setHolidayDate] = useState(moment().format('MM/DD/yyyy'));

  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset,
  } = useForm({ defaultValues });

  const createNationalHolidays = async (nationalHoliday) => {
    const res = await createNationalHolidaysService(nationalHoliday)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const handleOnclose = () => {
    onClose();
  };

  const handleSave = async () => {
    const body = {
      holiday: moment(holidayDate).format('yyyy-MM-DD'),
    };
    let isSavedorUpdate = false;
    const message = 'Se ha creado el feriado exitosamente';
    isSavedorUpdate = await createNationalHolidays(body);
    if (isSavedorUpdate) {
      await getNationalHolidays();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      onClose();
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
            >
              <FormControl>
                <Controller
                  render={() => (
                    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.dateCustom}
                        id="holidayDate"
                        variant="inline"
                        inputVariant="outlined"
                        label="Día feriado *"
                        format="dd/MM/yyyy"
                        size="small"
                        FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                        onChange={(e) => setHolidayDate(e)}
                        value={holidayDate}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="holidayDate"
                  control={control}
                  error={data === null ? errors : null}
                />
              </FormControl>
            </Grid>
          </form>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
