/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import useStyle from './useStyle';
import {
  CustomInput, Toaster, ProgressBar, CustomSwitch, CustomSelect, Loading,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import {
  createDeviceService,
  updateDeviceService,
} from '../../../../services';
import createImageDeviceService from '../../../../services/pos/createImageDeviceService';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getDevices,
  defaultValues,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = React.useState(true);
  const [activeFee, setActiveFee] = React.useState(false);
  const [zeroDayFee, setZeroDayFee] = React.useState(false);
  const [invalidImageBig, setInvalidImageBig] = useState('');
  const [fileNameBig, setFileNameBig] = useState('');
  const [startBarProgressBig, setStartBarProgressBig] = useState(false);
  const [fileNameMid, setFileNameMid] = useState('');
  const [fileNameLit, setFileNameLit] = useState('');
  const [files, setFiles] = useState([]);
  const providerRef = React.useRef();
  const classes = useStyle();
  const [openDrop, setOpenDrop] = React.useState(false);

  useEffect(() => {
    if (data) {
      setActiveFee(data?.payment_quotas);
      setActive(data?.active);
      setZeroDayFee(data?.zero_quotas);
    }
  }, [data]);
  const frequencyOptions = [
    { value: 'WEEKLY', label: 'Semanal' },
    { value: 'BIWEEKLY', label: 'Quincenal' },
    { value: 'MONTHLY', label: 'Mensual' },
  ];
  const dayChargeOptions = [
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sabado' },
    { value: 7, label: 'Domingo' },
  ];
  const {
    handleSubmit, control, errors, reset, setError, clearErrors,
  } = useForm({ defaultValues });

  const createDevice = async (device) => {
    const res = await createDeviceService(device)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const updateDevice = async (device) => {
    const res = await updateDeviceService(device)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const resetFields = () => {
    setFiles([]);
    setInvalidImageBig('');
    setFileNameBig('');
    setFileNameMid('');
    setFileNameLit('');
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };
  const textValidator = (text) => {
    if (text && text.length) {
      const regex = /\s\s/;
      let errorString = '';
      if (regex.test(text)) {
        errorString += 'No puede contener mas de 2 espacios en blanco consecutivos';
      }
      return errorString || true;
    }
    return true;
  };
  const priceValidator = (price) => {
    let errorString = '';
    if (price) {
      const digits = price.toString().split('.')?.[0]?.length || 0;
      if (digits > 6) {
        errorString += 'Solo se aceptan 6 digitos';
      }
      const decimals = price.toString().split('.')?.[1]?.length || 0;
      if (decimals > 2) {
        if (errorString) {
          errorString += 'y 2 decimales';
        } else {
          errorString += 'Solo se aceptan 2 decimales';
        }
      }
      return errorString || true;
    }
    return true;
  };

  const validateAmountWithLimits = (minAmount, maxAmount) => (amount) => {
    let errorString = '';
    if (amount) {
      if (maxAmount && amount > maxAmount) {
        errorString = `Este campo debe ser menor a ${maxAmount + 1}`;
      } else if (amount >= minAmount) {
        const regex = /[.]/;
        if (regex.test(amount)) {
          errorString = 'Este campo no puede contener decimales';
        }
      } else {
        errorString = `Este campo debe ser mayor a ${minAmount - 1}`;
      }
    } else {
      errorString = 'Este campo no puede enviarse vacio';
    }
    if (errorString) {
      return errorString;
    }
    return true;
  };

  const validateFortnightly = (day) => {
    let errorString = '';
    if (day) {
      if (day >= 1 && day <= 15) {
        const regex = /[.]/;
        if (regex.test(day)) {
          errorString = 'Este campo no puede contener decimales';
        }
      } else {
        errorString = 'Este campo solo puede tener un valor entre 1 y 15';
      }
    } else {
      errorString = 'Este campo no puede enviarse vacio';
    }
    if (errorString) {
      return errorString;
    }
    return true;
  };
  const validateWeekly = (day) => ((day < 1 || day > 7 || !day) ? 'Este campo no puede enviarse vacio' : true);
  const validateMonthly = (day) => {
    let errorString = '';
    if (day) {
      if (day >= 1 && day <= 31) {
        const regex = /[.]/;
        if (regex.test(day)) {
          errorString = 'Este campo no puede contener decimales';
        }
      } else {
        errorString = 'Este campo solo puede tener un valor entre 1 y 31';
      }
    } else {
      errorString = 'Este campo no puede enviarse vacio';
    }
    if (errorString) {
      return errorString;
    }
    return true;
  };
  const handleSave = async (dataParam) => {
    setOpenDrop(true);
    if (fileNameBig === '' && data === null) {
      setError('imageBig', 'Campo obligatorio');
      return;
    }
    const imagesBase64 = {
      big: '',
    };
    files.forEach((file) => {
      const uploadedFile = file.uploaded_file;
      const imageBase64 = uploadedFile?.split(',');
      const lengthCustom = imageBase64 !== undefined ? imageBase64[1]?.length : 0;
      if (file.file_id === 'fileSupportBig') {
        imagesBase64.big = imageBase64[1]?.slice(0, lengthCustom);
      }
    });
    if (imagesBase64.big) {
      try {
        const responseImageService = await createImageDeviceService({
          bucket_prefix: 'device_pos',
          image_base_64: imagesBase64.big,
        });
        const body = {
          price: dataParam.price,
          id: dataParam.id,
          description: dataParam.description,
          name: dataParam.name,
          max_devices_per_transaction: Number(dataParam.max_devices_per_transaction),
          active,
          image_url: responseImageService?.data?.image_url,
        };
        if (activeFee) {
          body.payment_quotas = activeFee;
          body.zero_quotas = zeroDayFee;
          body.amount_quotas = dataParam.numberOfFees;
          body.frequency_quota = dataParam.chargeFrequency;
          body.pay_day = dataParam.dayCharge;
        }
        let isSavedorUpdate = false;
        let message;
        if (data) {
          body.id = data.id;
          isSavedorUpdate = await updateDevice(body);
          message = 'Se ha editado el dispositivo exitosamente';
        } else {
          isSavedorUpdate = await createDevice(body);
          message = 'Se ha creado el dispositivo exitosamente';
        }
        if (isSavedorUpdate) {
          await getDevices();
          providerRef.current.enqueueSnackbar(message, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'botom',
              horizontal: 'center',
            },
          });
          resetFields();
          onClose();
        }
      } catch (e) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
    } else if (data && data.image_url) {
      const body = {
        price: dataParam.price,
        id: data.id,
        description: dataParam.description,
        name: dataParam.name,
        max_devices_per_transaction: Number(dataParam.max_devices_per_transaction),
        active,
        image_url: data.image_url,
      };
      if (activeFee) {
        body.payment_quotas = activeFee;
        body.zero_quotas = zeroDayFee;
        body.amount_quotas = dataParam.numberOfFees;
        body.frequency_quota = dataParam.chargeFrequency;
        body.pay_day = dataParam.dayCharge;
      }
      try {
        const isSavedorUpdate = await updateDevice(body);
        if (isSavedorUpdate) {
          await getDevices();
          providerRef.current.enqueueSnackbar('Se ha editado el dispositivo exitosamente', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'botom',
              horizontal: 'center',
            },
          });
          resetFields();
          onClose();
        }
      } catch (e) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
    }
    setOpenDrop(false);
  };

  useEffect(() => {
    if (fileNameBig !== '') {
      clearErrors('imageBig');
    }
  }, [fileNameBig]);

  useEffect(() => {
    if (fileNameMid !== '') {
      clearErrors('imageMid');
    }
  }, [fileNameMid]);

  useEffect(() => {
    if (fileNameLit !== '') {
      clearErrors('imageLit');
    }
  }, [fileNameLit]);

  const onFileUpload = (event) => {
    event.preventDefault();
    setInvalidImageBig('');
    // Get the file Id
    const { id } = event.target;
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(png)$/)) {
      setInvalidImageBig('Debe cargar un archivo valido (png).');
      return false;
    }
    if (file.size > 2097152) {
      setInvalidImageBig('El tamaño del archivo supera el máximo permitido (2MB).');
      return false;
    }
    setStartBarProgressBig(true);
    file_reader.onload = () => {
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
    };
    setFileNameBig(file !== null && file.name !== null ? file.name : '');
    setTimeout(() => {
      if (file && file.name !== '') {
        setStartBarProgressBig(false);
      }
    }, 2000);

    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };
  const validateAmount = (type) => {
    switch (type) {
      case 'min':
        return 'El valor debe ser mayor a 0';
      case 'required':
        return 'Campo obligatorio';
      default:
        return '';
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);
  const localChargeFrequency = useWatch({
    control,
    name: 'chargeFrequency',
  });
  const localDayCharge = useWatch({
    control,
    name: 'dayCharge',
  });
  const switchFrequency = (frequency) => {
    switch (frequency) {
      case 'WEEKLY':
        return (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CustomSelect
              label="Dia de cobro *"
              name="dayCharge"
              control={control}
              rule={{
                required: activeFee ? 'Campo obligatorio' : false,
                validate: validateWeekly,
              }}
              error={errors}
              helperText="Este campo no puede estar vacio"
              options={dayChargeOptions}
            />
          </Grid>
        );
      case 'BIWEEKLY':
        return (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomInput
                label="Dia de cobro *"
                name="dayCharge"
                disabled={!activeFee}
                inputMode="numeric"
                pattern="[0-9]*'"
                type="number"
                control={control}
                rule={{
                  required: activeFee ? 'Campo obligatorio' : false,
                  validate: validateFortnightly,
                }}
                error={errors}
                helperText={
              validateAmount(errors.amount
                ? errors.amount.type
                : '')
            }
                InputPropsParam={{
                  inputProps: { type: 'number', min: 1, max: 15 },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Segundo dia de cobro *"
                disabled
                size="small"
                type="number"
                fullWidth
                variant="outlined"
                value={(parseInt(localDayCharge || '0', 10) + 15) > 31 ? 31 : (parseInt(localDayCharge || '0', 10) + 15)}
              />
            </Grid>
          </>
        );
      case 'MONTHLY':
        return (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CustomInput
              label="Dia de cobro *"
              name="dayCharge"
              disabled={!activeFee}
              inputMode="numeric"
              pattern="[0-9]*'"
              type="number"
              control={control}
              rule={{
                required: activeFee ? 'Campo obligatorio' : false,
                validate: validateMonthly,
              }}
              error={errors}
              helperText={
              validateAmount(errors.amount
                ? errors.amount.type
                : '')
            }
              InputPropsParam={{
                inputProps: { type: 'number', min: 1, max: 31 },
              }}
            />
          </Grid>
        );
      default:
        return <Grid item xs={12} sm={12} md={4} lg={4} />;
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      {openDrop
        && <Loading />}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre *"
                  name="name"
                  control={control}
                  rule={{ ...Rules.required, validate: textValidator }}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  multiline
                  rows={3}
                  control={control}
                  rule={{ ...Rules.required, validate: textValidator }}
                  error={errors}
                  inputProps={{
                    maxLength: 180,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Precio *"
                  name="price"
                  inputMode="numeric"
                  type="number"
                  control={control}
                  rule={{
                    required: 'Campo obligatorio',
                    min: 0.01,
                    validate: priceValidator,
                  }}
                  error={errors}
                  helperText={
                    validateAmount(errors.amount
                      ? errors.amount.type
                      : '')
                  }
                  InputPropsParam={{
                    inputProps: { type: 'number', min: 1, max: 999999.999 },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          name="active"
                          color="primary"
                        />
                      )}
                      label="Estado"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage}>
                  <label htmlFor="fileSupportBig">
                    <input
                      onChange={onFileUpload}
                      id="fileSupportBig"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                    />

                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                    >
                      Imagen
                    </Button>

                    {invalidImageBig && (
                      <Typography className={classes.formErrors}>
                        {invalidImageBig}
                      </Typography>
                    )}
                  </label>
                  <Box style={{ width: '100%', paddingLeft: 15 }}>
                    <Typography className={classes.formLabel}>
                      {fileNameBig || (data?.image_url ? data.image_url : '')}
                    </Typography>
                    <Box style={{ width: '70%' }}>
                      <ProgressBar
                        startBarProgress={startBarProgressBig}
                      />
                    </Box>
                  </Box>
                </Box>
                {
                  Object.keys(errors).length > 0 && fileNameBig === '' && data === null
                  && (
                    <>
                      <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>
                        Campo
                        obligatorio
                      </div>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={activeFee}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setZeroDayFee(false);
                              const lastValues = control.getValues();
                              reset({
                                ...lastValues,
                                numberOfFees: 0,
                                chargeFrequency: '',
                                dayCharge: 0,
                              });
                            }
                            setActiveFee(e.target.checked);
                          }}
                          name="activeFee"
                          color="primary"
                        />
                      )}
                      label="Cuotas"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>
              {activeFee
              && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Paper variant="outlined" style={{ height: 40, width: 200 }}>
                    <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                      <FormControlLabel
                        control={(
                          <CustomSwitch
                            checked={zeroDayFee}
                            onChange={(e) => {
                              setZeroDayFee(e.target.checked);
                            }}
                            name="zeroDayFee"
                            color="primary"
                          />
                        )}
                        label="Primera Cuota cero"
                        labelPlacement="start"
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    label="Numero de cuotas *"
                    disabled={!activeFee}
                    name="numberOfFees"
                    inputMode="numeric"
                    pattern="[0-9]*'"
                    type="number"
                    control={control}
                    rule={{
                      required: activeFee ? 'Campo obligatorio' : false,
                      validate: validateAmountWithLimits(2),
                    }}
                    error={errors}
                    helperText={
                      validateAmount(errors.amount
                        ? errors.amount.type
                        : '')
                    }
                    InputPropsParam={{
                      inputProps: { type: 'number', min: 2, max: 999999 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomSelect
                    label="Frecuencia cobro *"
                    name="chargeFrequency"
                    control={control}
                    rule={{
                      required: activeFee ? 'Campo obligatorio' : false,
                    }}
                    error={errors}
                    options={frequencyOptions}
                  />
                </Grid>
                { localChargeFrequency && switchFrequency(localChargeFrequency)}
              </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Tope máximo de terminales por orden *"
                  name="max_devices_per_transaction"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={{
                    required: 'Campo obligatorio',
                    validate: validateAmountWithLimits(1, 99),
                  }}
                  error={errors}
                  helperText={
                      validateAmount(errors.amount
                        ? errors.amount.type
                        : '')
                    }
                  InputPropsParam={{
                    inputProps: { type: 'number', min: 1, max: 99 },
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
