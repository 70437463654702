/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomTable, CustomDialog } from '../../../../components';
import COLUMNS from './constants';
import { Button } from '../../styles';
import { getMoneyFormat } from '../../../../utils';

const RemittancesNoUser = (props) => {
  const {
    totalElementsData,
    onNext,
    onSelectPageSize,
    searchQuery,
    loading,
    remittances,
    handleCancelRemittance,
  } = props;
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [remittancesUpdated, setRemittancesUpdated] = useState([]);

  const providerRef = React.useRef();
  const selectRemmitanceRef = React.useRef('');

  const handleSelectRemmitence = (value) => {
    selectRemmitanceRef.current = value;
    setOpenAlertConfirm(true);
  };

  const handleConfirm = async () => {
    await handleCancelRemittance(selectRemmitanceRef.current.id);
    setOpenAlertConfirm(false);
  };

  function createActions(value) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Devolver remesa a BHD"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <></>
          </Box>
        </Tooltip>
        <Button
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => handleSelectRemmitence(value)}
        >
          Devolver a BHD
        </Button>
      </Box>
    );
  }

  const updateRemittances = () => {
    if (remittances.length > 0) {
      setRemittancesUpdated(remittances.map((item) => ({
        ...item,
        actions: createActions(item),
        active: true,
        amount: getMoneyFormat(item.amount),
        date: moment(item.created_date).format('DD/MM/YYYY'),
      })));
    } else {
      setRemittancesUpdated([]);
    }
  };

  useEffect(() => {
    updateRemittances();
  }, [remittances]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={remittancesUpdated}
        columns={COLUMNS}
        totalElementsData={totalElementsData}
        onNext={onNext}
        onSelectPageSize={onSelectPageSize}
        loading={loading}
        searchQuery={searchQuery}
      />
      <CustomDialog
        acceptButtonLabel="Devolver"
        colorButtonCance="secondary"
        isOpen={openAlertConfirm}
        title="Confirmar"
        text={`¿Estás seguro de devolver la Remesa ${selectRemmitanceRef.current.origin_sequence} a nombre de ${selectRemmitanceRef.current.beneficiary_name} por ${getMoneyFormat(selectRemmitanceRef.current.amount)} `}
        okFunction={handleConfirm}
        openCloseFx={() => setOpenAlertConfirm(false)}
        notOkFunction={() => { }}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default RemittancesNoUser;
