import React, { useState, useEffect } from 'react';
import {
  Paper,
} from '@material-ui/core';
import useStyle from './useStyle';
import ProvidersPaymentTable from './components/DataTable/ProvidersPaymentTable.readonly';
import { getProvidersPaymentService } from '../../services';
import { Toaster, Loading } from '../../components';

const ProvidersPaymentScreen = () => {
  const classes = useStyle();
  const [providersPayment, setProvidersPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getProvidersPayment = async () => {
    if (providersPayment.length === 0) {
      setLoading(true);
    }
    const res = await getProvidersPaymentService();
    setLoading(false);
    return setProvidersPayment(res?.data);
  };

  useEffect(() => {
    getProvidersPayment();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <div className={classes.root}>
          <ProvidersPaymentTable
            setProvidersPayment={setProvidersPayment}
            providers={providersPayment.data}
          />

          <Toaster
            show={alert.status}
            type={alert.type}
            text={alert.message}
            onClose={() => setAlert({ ...alert, status: false })}
          />
        </div>
      </>
    </Paper>
  );
};

export default ProvidersPaymentScreen;
