/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  FormControl,
  Grid,
  InputLabel,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { Toaster } from '../../components';
import { getCardNumber, sendDecodeCardId } from '../../services';
import { ButtonStyled } from './styles';

const CardsQueryScreen = () => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [userDocument, setUserDocument] = useState('');
  const [data, setData] = useState({ commerceName: '', document: '' });
  const [cardId, setCardId] = useState('');
  const [loading, setLoading] = useState(false);
  const providerRef = React.useRef();

  const {
    control, errors, setValue, handleSubmit,
  } = useForm({
    userId: '', defaultValues: '',
  });

  const classes = useStyle();

  const onSubmit = async (documentNumber) => {
    setUserDocument(documentNumber.userId);
  };

  const decodeCardId = async (idToDecode) => {
    setLoading(true);
    const responseDecode = await sendDecodeCardId(idToDecode)
      .catch(() => providerRef.current.enqueueSnackbar('Ha ocurrido un error, intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }));
    setLoading(false);
    if (responseDecode?.status !== 200) {
      return providerRef.current.enqueueSnackbar('No se ha encontrado información para este usuario', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    return setCardId(responseDecode?.data?.cardNumber);
  };

  const getDataUser = async (document) => {
    setLoading(true);
    const responseCardNumber = await getCardNumber(document)
      .catch(() => providerRef.current.enqueueSnackbar('Ha ocurrido un error, intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }));
    setLoading(false);
    if (responseCardNumber?.status !== 200) {
      return providerRef.current.enqueueSnackbar('No se ha encontrado información para este usuario', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    if (responseCardNumber?.status === 200) {
      decodeCardId(responseCardNumber?.data?.cardId);
    }

    return setData({
      commerceName: `${responseCardNumber?.data?.firstName} ${responseCardNumber?.data?.lastName} ${responseCardNumber?.data?.maternalSurname}`,
      document: responseCardNumber?.data?.documentNumber,
    });
  };

  const cleanUp = () => {
    setUserDocument('');
    setValue('userId', '');
    setData({ commerceName: '', document: '' });
    setCardId('');
  };

  useEffect(() => {
    if (userDocument !== '') {
      getDataUser(userDocument);
    }
    return function cleanup() {
      cleanUp();
    };
  }, [userDocument]);

  const validateUserId = (type) => {
    switch (type) {
      case 'min':
        return 'El valor debe ser mayor a 0';
      case 'required':
        return 'Campo obligatorio';
      default:
        return '';
    }
  };

  const copyToClipBoard = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      setAlert({ status: true, type: 'success', message: 'Se ha copiado el número de cuenta correctamente' });
    } catch (err) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  function UserIdNumberFormat(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        className={classes.textField}
        isNumericString
        allowLeadingZeros
      />
    );
  }

  UserIdNumberFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <SnackbarProvider ref={providerRef}>
        <Paper className={classes.paper}>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-start" spacing={5} className={classes.container} direction="row">
            <Grid item xs="auto">
              <InputLabel className={classes.textInputLabel}>Número de identificación:</InputLabel>
            </Grid>
            <form noValidate autoComplete="off">
              <Grid item>
                <FormControl>
                  <Controller
                    as={(
                      <FormControlLabel
                        control={(
                          <TextField
                            size="small"
                            variant="outlined"
                            inputProps={{
                              inputComponent: UserIdNumberFormat,
                              maxLength: 11,
                            }}
                            onChange={(e) => setValue('userId', e.target.value)}
                            color="primary"
                            FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                            helperText={
                                  validateUserId(errors.userId
                                    ? errors.userId.type
                                    : '')
                                  }
                          />
                          )}
                        labelPlacement="start"
                      />
                      )}
                    defaultValue=""
                    name="userId"
                    control={control}
                    error={errors.userId}
                    rules={{
                      required: 'Campo obligatorio',
                      min: 0.01,
                    }}
                  />
                </FormControl>
              </Grid>
            </form>
            <Grid container spacing={4} className={classes.buttonsContainer}>
              <Grid item>
                <ButtonStyled
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Buscar
                </ButtonStyled>
              </Grid>
              <Grid item>
                <ButtonStyled
                  variant="contained"
                  type="submit"
                  onClick={cleanUp}
                >
                  Limpiar
                </ButtonStyled>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.text} align="center">Nombre</TableCell>
                  <TableCell className={classes.text} align="center">Número de cuenta</TableCell>
                  <TableCell className={classes.text} align="center">Número de documento</TableCell>
                  <TableCell className={classes.text} align="center">Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.heightTable}>
                <TableRow hover>
                  <TableCell className={classes.text} align="center">{data.commerceName || '' }</TableCell>
                  <TableCell className={classes.text} align="center">
                    { cardId
                      ? (
                        <div>
                          {cardId}
                          {' '}
                          <IconButton
                            edge="start"
                            aria-label="Copy"
                            size="small"
                            onClick={() => copyToClipBoard(cardId)}
                          >
                            <Tooltip title="Copiar" aria-label="Copy" placement="top">
                              <FileCopyOutlined htmlColor="#3f51b5" />
                            </Tooltip>
                          </IconButton>
                        </div>
                      )
                      : ''}
                    {' '}
                  </TableCell>
                  <TableCell className={classes.text} align="center">{data.document || ''}</TableCell>
                  <TableCell className={classes.text} align="center" />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Toaster
            show={alert.status}
            type={alert.type}
            text={alert.message}
            onClose={() => setAlert({ ...alert, status: false })}
          />
        </Paper>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </SnackbarProvider>
    </InternalPage>
  );
};

export default CardsQueryScreen;
