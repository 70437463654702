import httpInstance from '../httpInstance';

const getAllSegmentations = async () => {
  let res;
  const uri = '/segmentation/v1/segmentation/all';
  await httpInstance.get(uri)
    .then((data) => {
      res = data;
    });
  return res;
};

export default getAllSegmentations;
