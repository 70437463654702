const defaultData = {
  documentNumber: '',
  idCommerce: '',
  commercialName: '',
  merchantIdCardnet: '',
  cardnetTerminalId: '',
  expirationDays: 7,
  activePaymentLinks: true,
  mcc: '',
  checkedItbis: false,
};

export default defaultData;
