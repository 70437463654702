/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useReducer } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import useStyle, { StyledImage } from './useStyle';
import {
  CustomInput, Toaster, CustomSwitch, CustomSelect,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import {
  categoryOptions,
  targetoptions,
  imageProps,
  orderValues,
} from '../constants';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
  setDefaultValues,
  promotions,
  banners,
  screens,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = React.useState(false);
  const [orderOpt, setOrderOpt] = useState([...targetoptions]);
  const [invalidImageBig, setInvalidImageBig] = useState('');
  const [startDate, setStartDate] = useState(moment().format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().add(1, 'day').format('MM/DD/yyyy'));
  const [images, updateImages] = useReducer((prev, next) => {
    const newImages = { ...prev, ...next };
    return newImages;
  }, {
    imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageMid: { ...imageProps },
  });
  const providerRef = React.useRef();
  const classes = useStyle();

  const {
    control, errors, reset, watch, clearErrors,
  } = useForm({ defaultValues });

  const { order, category } = watch(['order', 'category']);

  const resetFields = () => {
    setInvalidImageBig('');
    setOrderOpt([...orderValues]);
    setDefaultValues({
      name: '',
      description: '',
      category: categoryOptions[0].value,
      target: '',
      promotion: '',
      redirection: 'promotionScreen/',
      startDate: new Date(),
      endDate: new Date(),
      order: '0',
    });
    updateImages({
      imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageMid: { ...imageProps },
    });
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };

  useEffect(() => {
    if (images.imageBig.b64 !== '') {
      clearErrors('fileSupportBig');
    }
  }, [images]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlert({ status: true, type: 'warning', message: 'Campos obligatorios faltantes' });
    }
  }, [errors]);

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (!banners) return;
    const numbersC = [...orderValues];
    if (order) {
      let prevOpt = [];
      if (data?.order) {
        // default value for edition
        const index = numbersC.findIndex((elem) => elem.value === data.order.toString());
        prevOpt = [numbersC[index]];
      }
      const remove1 = banners.findIndex((elem) => elem.order.toString() === '1');
      const remove2 = banners.findIndex((elem) => elem.order.toString() === '2');
      const remove3 = banners.findIndex((elem) => elem.order.toString() === '3');

      if (remove1 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '1'), 1);
      if (remove2 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '2'), 1);
      if (remove3 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '3'), 1);
      setOrderOpt([...numbersC, ...prevOpt]);
    }
  }, [banners, order, defaultValues]);

  useEffect(() => {
    // Set Images for edition
    if (defaultValues.image_source_url
      && defaultValues.image_source_url.big
      && defaultValues.image_source_url.mid
      && defaultValues.image_source_url.lit) {
      updateImages({
        ...images,
        imageBig: { ...imageProps, urlImage: defaultValues.image_source_url.big },
        imageLit: { ...imageProps, urlImage: defaultValues.image_source_url.lit },
        imageMid: { ...imageProps, urlImage: defaultValues.image_source_url.mid },
      });
    }
    if (defaultValues.startDate !== '' && defaultValues.endDate !== '' && defaultValues.category === 'APP') {
      setStartDate(defaultValues.startDat);
      setEndDate(defaultValues.endDate);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.state);
    }
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        withoutSaveButton
        readOnly
      >

        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Detalles:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper variant="outlined" className={classes.paperbox}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          name="active"
                          color="primary"
                          disabled
                        />
                    )}
                      label={`Estado: ${active ? 'Activo' : 'inactivo'}`}
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre *"
                  name="name"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Banner orden *"
                  name="order"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={orderOpt}
                  disabled
                />
              </Grid>
              {/* datos promoción */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Asignar promoción y target:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Banner target *"
                  name="target"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={targetoptions}
                  disabled
                />
              </Grid>
              {category !== 'APP' ? (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomSelect
                    label="Asignar promoción*"
                    name="promotion"
                    control={control}
                    rule={Rules.required}
                    error={errors}
                    options={promotions}
                    disabled
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <CustomSelect
                      label="Redirección *"
                      name="redirection"
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      options={screens}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl>
                      <Controller
                        render={() => (
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              id="startDate"
                              variant="inline"
                              inputVariant="outlined"
                              label="Inicio vigencia *"
                              format="dd/MM/yyyy"
                              size="small"
                              minDate={moment().format('MM/DD/yyyy')}
                              FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                              onChange={(e) => setStartDate(e)}
                              value={startDate}
                              disablePast
                              minDateMessage={data === null && 'La fecha de inicio no puede ser menor a la actual.'}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        name="startDate"
                        control={control}
                        error={data === null ? errors.startDate : null}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl>
                      <Controller
                        render={() => (
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              id="endDate"
                              variant="inline"
                              inputVariant="outlined"
                              label="Fin vigencia *"
                              format="dd/MM/yyyy"
                              size="small"
                              FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                              onChange={(e) => setEndDate(e)}
                              value={endDate}
                              minDate={moment().add(1, 'day').format('MM/DD/yyyy')}
                              disablePast
                              minDateMessage={data === null && 'La fecha de final no puede ser menor o igual a la sinicial.'}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        name="endDate"
                        control={control}
                        error={data === null ? errors : null}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md lg />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" gutterBottom>
                  Imagen banner:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="fileSupportBig">
                    <input
                      id="fileSupportBig"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled
                    />

                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled
                    >
                      Imagen banner 300x112
                    </Button>

                    {invalidImageBig && (
                    <Typography className={classes.formErrors}>
                      {invalidImageBig}
                    </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageBig.b64 !== '' || images.imageBig.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewDest"
                      width={180}
                      src={images.imageBig.urlImage
                        ? images.imageBig.urlImage : images.imageBig.b64}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageBig.name}
                    </Typography>
                  </Grid>
                )}
                {
                Object.keys(errors).length > 0 && images.imageBig.b64 === '' && data === null
                && (
                <>
                  <div style={{ color: '#d61103', margin: '0 0 0 5px', fontSize: '0.6964285714285714rem' }}>Campo obligatorio</div>
                </>
                )
              }
              </Grid>
              <Grid item xs={12} sm={12} md lg />
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setDefaultValues: PropTypes.func,
  promotions: PropTypes.array,
  banners: PropTypes.array,
  screens: PropTypes.array,
};

export default ActionForm;
