/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  getRepositionService,
  getLabelReposition,
} from '../../services';
import { Toaster } from '../../components';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import { ButtonStyled } from './styles';

const RepositionScreen = () => {
  const [labelList, setLabelList] = useState([]);
  const [repositionList, setRepositionList] = useState([]);
  const [repositionListcopy, setRepositionListcopy] = useState([]);
  const [appRepositionList, setAppRepositionList] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const canEdit = false;

  const classes = useStyle();

  const getRepositionList = async () => {
    setLoading(true);
    const res = await getRepositionService()
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    setLoading(false);
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return setRepositionList(res.data);
  };

  const handlechangeapp = (key) => (event) => {
    let extract = appRepositionList[0][key];
    if (extract) {
      extract = event.target.value;
    }
  };

  const handlechangeappBlur = (key) => (event) => {
    const update = [...repositionListcopy];
    update[0][key] = parseFloat((event.target.value).replace(/,/g, ''));
    update[0].change = 1;
    setRepositionListcopy(update);
  };

  const getLabelsList = async () => {
    await getLabelReposition().then((res) => {
      if (res) {
        const tags = res.data.map((item) => {
          switch (item.toLowerCase()) {
            case 'día':
              return { label: item, key: 'day' };
            case 'semana':
              return { label: item, key: 'week' };
            case 'mes':
              return { label: item, key: 'month' };
            case 'año':
              return { label: item, key: 'year' };
            default:
              return {};
          }
        });
        setLabelList(tags);
      }
    });
  };

  useEffect(() => {
    getLabelsList();
  }, []);

  useEffect(() => {
    getRepositionList();
  }, [labelList]);

  useEffect(async () => {
    if (repositionList && repositionList.length > 0) {
      const listApp = [...repositionList];
      setAppRepositionList(listApp);
      setRepositionListcopy(repositionList);
    }
  }, [repositionList]);

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  return (
    <InternalPage loading={loading} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid container justifyContent="flex-end" style={{ marginRight: 10, marginTop: 10 }}>
                <ButtonStyled variant={canEdit ? 'outlined' : 'contained'} disabled>
                  Editar
                </ButtonStyled>
              </Grid>
              <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
                <Typography variant="h4" gutterBottom style={{ marginLeft: 10 }}>App</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Reposición</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Costo</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              {(appRepositionList.length > 0 && labelList.length > 0)
                && labelList.map((item, index) => (
                  <Grid container key={`${(index + 1)}_contitemapp`} style={{ marginTop: 10, marginLeft: 10 }}>
                    <Grid item xs={12} md={3} key={`${(index + 1)}_contitemapp_text`}>
                      <Typography>
                        {item.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} key={`${(index + 1)}_contitemappfield`}>
                      <TextField
                        id={`label${(index + 1)}`}
                        variant="outlined"
                        value={appRepositionList[0][item.key] || ''}
                        onChange={handlechangeapp(item.key)}
                        onBlur={handlechangeappBlur(item.key)}
                        name="numberformat"
                        disabled={!canEdit}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        placeholder="0"
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Paper>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </InternalPage>
  );
};

export default RepositionScreen;
