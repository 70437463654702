/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Paper,
} from '@material-ui/core';
import useStyle from './useStyle';
import ProvidersTable from './components/DataTable/ProvidersRechargeTable';
import {
  updateProviderPaymentService,
  getCategoriesPaymentService,
  getProvidersPaymentService,
} from '../../services';
import ActionForm from './components/ActionForm';
import { Toaster, Loading } from '../../components';

const ProvidersRechargeScreen = () => {
  const classes = useStyle();
  const [providers, setProviders] = useState([]);
  const [categoriesRecharge, setCategoriesRecharge] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });

  const defaultData = {
    reference: '',
    category: '',
    vendor: '',
    paymentMethod: '',
    partialPayment: false,
    beneficiaryId: '',
    beneficiaryType: '',
    maxAmount: 0,
    minAmount: 0,
    status: 1,
  };

  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getProviders = async () => {
    if (providers.length === 0) {
      setLoading(true);
    }
    const res = await getProvidersPaymentService();
    setLoading(false);
    const filteredData = res?.data?.data.filter((item) => item.serviceType === 'recharge');
    return setProviders(filteredData || []);
  };

  const updateProvider = async (category) => {
    const res = await updateProviderPaymentService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const getCategoriesRecharge = async () => {
    const res = await getCategoriesPaymentService();
    const categoriesFilter = res.data.filter((category) => category.categoryType === 'Recargas').map((categoryKey) => ({
      value: categoryKey.categoryId,
      label: `${categoryKey.creditorAgent} ${categoryKey.vendor}`,
    }));
    setCategories(res.data.filter((category) => category.categoryType === 'Recargas'));
    return setCategoriesRecharge(categoriesFilter);
  };

  useEffect(() => {
    getCategoriesRecharge();
    getProviders();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <div className={classes.root}>
          <ProvidersTable
            setProviders={setProviders}
            providers={providers}
            setDefaultValues={setDefaultValues}
            setPopUpAction={setPopUpAction}
            updateProvider={updateProvider}
            getProviders={getProviders}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            providers={providers}
            title={popUpAction.title}
            setDefaultValues={setDefaultValues}
            defaultValues={defaultValues}
            getProviders={getProviders}
            categoriesOptions={categoriesRecharge}
            categories={categories}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
            }}
            setAlertHome={setAlert}
            updateProvider={updateProvider}
          />
          <Toaster
            show={alert.status}
            type={alert.type}
            text={alert.message}
            onClose={() => setAlert({ ...alert, status: false })}
            providers={providers}
          />
        </div>
      </>
    </Paper>
  );
};

export default ProvidersRechargeScreen;
