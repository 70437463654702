import httpInstance from '../httpInstance';

const updateFeeService = async (dataParam) => {
  let res;
  const endpoint = '/fares/v1/fees';
  const feesSent = {};
  feesSent.id = dataParam.fees_id;
  feesSent.description_fee = dataParam.fees_documents_list[0].description;
  feesSent.transaction_code = dataParam.transaction_code;
  feesSent.transaction_name = dataParam.transaction_name;
  feesSent.fees = dataParam.fees_documents_list;

  // Data transformation
  await httpInstance.put(
    endpoint,
    feesSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateFeeService;
