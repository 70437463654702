/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Paper,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './useStyle';
import reload from '../../assets/img/iconos/reload.svg';
import { categoryOptions } from './components/constants';
import { Loading } from '../../components';
import BannerTable from './components/DataTable/BannerTable';
import newButton from '../../assets/img/iconos/newButton.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import ActionForm from './components/ActionForm/ActionForm';
import {
  getBannersService,
  getPromotionsApp,
  useScreensAppPersonas,
} from '../../services';

const BannersScreen = () => {
  const classes = useStyle();
  const [banners, setBanners] = useState([]);
  const [promos, setPromos] = useState([]);
  const [screens, setScreens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enteredFilter, setEnteredFilter] = useState('');
  const defaultData = {
    name: '',
    description: '',
    category: categoryOptions[0].value,
    target: '',
    promotion: '',
    redirection: '',
    order: '0',
    startDate: '',
    endDate: '',
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const getBanners = async () => {
    const res = await getBannersService();
    if (res.data?.length > 0) {
      const dataOrder = res?.data.sort((a, b) => {
        if ((a.order > b.order)) {
          return -1;
        }
        return 0;
      });
      return setBanners(dataOrder);
    }
    return setBanners([]);
  };
  const getPromotions = async () => {
    setLoading(true);
    const res = await getPromotionsApp();
    const filterActive = res.length > 0 ? res.filter((promo) => promo.status === 'activo').map((item) => ({ value: `${item.id}`, label: item.promotionName, extraData: { startDate: item.startDate, endDate: item.endDate } })) : res;
    setLoading(false);
    return setPromos(filterActive);
  };

  const getScreensPersonas = async () => {
    setLoading(true);
    const res = await useScreensAppPersonas();
    const filterActive = res.map((item) => ({ value: item.value, label: (item.key).replace(/_/g, ' ') }));
    setLoading(false);
    return setScreens(filterActive);
  };

  const createBanner = () => {
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: 'Crear banner',
      data: null,
    });
  };

  useEffect(() => {
    setLoading(true);
    getBanners();
    getPromotions();
    getScreensPersonas();
  }, []);

  return (

    <Paper className={classes.paper}>
      <>
        {loading && <Loading />}
        <div className={classes.root}>
          <div>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" style={{ paddingLeft: '10px' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              placeholder="Buscar"
            />
            <Tooltip title="Refrescar página" aria-label="Refrescar página">
              <IconButton
                onClick={getBanners}
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
                disabled={false}
              >
                <img src={reload} alt="reload" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Crear banner" aria-label="Nuevo banner">
              <IconButton
                onClick={createBanner}
                style={{ fontSize: '20px', float: 'right' }}
                edge="end"
                size="small"
              >
                <img src={newButton} alt="newButton" />
              </IconButton>
            </Tooltip>
          </div>
          <BannerTable
            setBanners={setBanners}
            banners={banners}
            setDefaultValues={setDefaultValues}
            enteredFilter={enteredFilter}
            setPopUpAction={setPopUpAction}
          />
          <ActionForm
            open={popUpAction.open}
            data={popUpAction.data}
            title={popUpAction.title}
            defaultValues={defaultValues}
            setDefaultValues={setDefaultValues}
            getBanners={getBanners}
            banners={banners}
            promotions={promos}
            screens={screens}
            onClose={() => {
              setPopUpAction({ open: false, title: '', data: null });
            }}
          />
        </div>
      </>
    </Paper>
  );
};

export default BannersScreen;
