/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import tableStyle, { ImgSearch } from './components/tableStyle';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import ScreensTable from './components/DataTable';
import ActionForm from './components/ActionForm';
import { Loading, Toaster } from '../../components';
import {
  useRegisteredScreens,
} from '../../services';

const ScreensRolesScreen = () => {
  const [enteredFilter, setEnteredFilter] = useState('');
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const classes = tableStyle();

  const {
    loading,
    data: dataScreens,
    error: errorGetScreens,
    getScreens,
  } = useRegisteredScreens();

  const defaultData = {
    id: '',
    screenName: '',
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);

  const openCategoryForm = () => {
    setPopUpAction({
      open: true,
      title: 'Registrar nueva pantalla',
      data: null,
    });
  };

  const initData = async () => {
    getScreens();
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (errorGetScreens) {
      setAlert({
        status: true,
        type: 'error',
        message: `Ha ocurrido un error, intente nuevamente.
      ${errorGetScreens.message || ''}`,
      });
    }
  }, [errorGetScreens]);

  return (
    <>
      {loading && <Loading />}
      <>
        <TextField
          className={classes.searchTextField}
          value={enteredFilter}
          onChange={(e) => setEnteredFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImgSearch src={searchIcon} alt="searchIcon" />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          placeholder="Buscar"
        />
        <Tooltip title="Refrescar página" aria-label="Refrescar página">
          <IconButton
            onClick={getScreens}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
          >
            <img src={reload} alt="reload" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
          <IconButton
            onClick={openCategoryForm}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
          >
            <img src={newButton} alt="newButton" />
          </IconButton>
        </Tooltip>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </>
      <ScreensTable
        screens={dataScreens}
        getScreens={getScreens}
        setPopUpAction={setPopUpAction}
        setDefaultValues={setDefaultValues}
        enteredFilter={enteredFilter}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        setDefaultValues={setDefaultValues}
        defaultValues={
          {
            ...defaultValues,
          }
        }
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
        }}
        setAlertHome={setAlert}
        getScreens={getScreens}
      />
    </>
  );
};

export default ScreensRolesScreen;
