/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  updateDistributionCenterService,
  deleteDistributionCenterService,
} from '../../../../services';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import { IconButton } from '../../styles';

const DistributionCenterTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction,
    enteredFilter,
    distributionCenters,
    setDistributionCenters,
    setDefaultValues,
  } = props;

  const [distributionCenterRows, setDistributionCenterRows] = React.useState([]);
  const [distributionCenterListCopy, setDistrbutionCenterListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    id: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const distributionCenterList = codesMapped != null ? codesMapped : distributionCenterListCopy;
    setDistributionCenterRows(
      distributionCenterList.filter(
        (distributionCenterParam) => distributionCenterParam.name
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || distributionCenterParam.streetAddress
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0
          || distributionCenterParam.description
            .toLowerCase()
            .indexOf(enteredFilterParam.toLowerCase()) >= 0,
      ),
    );
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleChangeState = (event) => {
    setGeneralInfo({
      id: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const handleDeleteDistributionCenter = (bannerId) => {
    setGeneralInfo({
      id: bannerId,
      checked: false,
    });
    setOpenAlertConfirmDelete(true);
  };

  const openModalEditDistributionCenter = (bannerIdSelected) => {
    const dataDistributionCenter = distributionCenters.find(
      (bannerRow) => bannerRow.id === bannerIdSelected,
    );
    setDefaultValues(dataDistributionCenter);
    setPopUpAction({
      open: true,
      title: 'Editar Centro de Distribución',
      data: dataDistributionCenter,
    });
  };

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele.id !== value);
  }

  const deleteDistributionCenter = async () => {
    const res = await deleteDistributionCenterService(generalInfo.id).catch(
      () => setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      }),
    );
    if (res?.status !== 200) {
      return setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    providerRef.current.enqueueSnackbar(
      'Se ha eliminado el distribution center exitosamente',
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      },
    );
    const bannersLocal = arrayRemove(distributionCenters, generalInfo.id);
    setDistributionCenters(bannersLocal);
    setOpenAlertConfirmDelete(false);
  };

  function createActions(active, distributionCenterId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip
          title="Cambiar estado"
          aria-label="changeState"
          placement="top"
        >
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              onChange={(e) => handleChangeState(e)}
              value={distributionCenterId}
              name="active"
            />
          </Box>
        </Tooltip>
        <IconButton
          aria-label="Editar"
          onClick={() => openModalEditDistributionCenter(distributionCenterId)}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          aria-label="Delete"
          onClick={() => handleDeleteDistributionCenter(distributionCenterId)}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function mapCodes(distributionCenterParam) {
    const distributionCenterInfo = [];
    distributionCenterParam?.forEach((distributionCenter) => {
      const {
        id,
        active,
        name,
        description,
        streetAddress,
        phone,
        postalCode,
        city,
        country,
        latitude,
        longitude,
        openingTime,
        closingTime,
        observations,
        availableTime,
        deliveryTypes,
      } = distributionCenter;
      const location = [latitude, longitude].join(', ');
      const distributionCenterRow = {
        name,
        description,
        streetAddress,
        phone,
        postalCode,
        city,
        country,
        latitude,
        longitude,
        openingTime,
        closingTime,
        observations,
        coordinates: location,
        availableTime,
        deliveryTypes: deliveryTypes.join(', '),
        actions: createActions(
          active,
          id,
        ),
        id,
        active: 1,
      };
      distributionCenterInfo.push(distributionCenterRow);
    });
    setDistributionCenterRows(distributionCenterInfo);
    setDistrbutionCenterListCopy(distributionCenterInfo);
    if (enteredFilter !== '') { filterInfoTable(enteredFilter, distributionCenterInfo); }
  }

  useEffect(() => {
    setDistributionCenterRows([]);
    mapCodes(distributionCenters);
  }, [distributionCenters]);

  const columns = [
    {
      id: 'name',
      label: 'Nombre',
    },
    {
      id: 'streetAddress',
      label: 'Dirección',
    },
    {
      id: 'availableTime',
      label: 'Horario',
    },
    {
      id: 'phone',
      label: 'Tel.',
    },
    {
      id: 'deliveryTypes',
      label: 'Tipo',
    },
    {
      id: 'coordinates',
      label: 'Coordenadas',
    },
    {
      id: 'actions',
      label: 'Acciones',
    },
  ];

  const updateDistributionCenter = async (distributionCenter) => {
    const res = await updateDistributionCenterService(distributionCenter).catch(
      () => setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      }),
    );
    if (res?.status !== 200) {
      return setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    return true;
  };

  const handleConfirmChecked = () => {
    const distributionCenterSelected = distributionCenters.find(
      (distributionCenter) => distributionCenter.id === generalInfo.id,
    );
    distributionCenterSelected.active = generalInfo.checked ? 1 : 0;
    mapCodes(distributionCenters);
    if (updateDistributionCenter(distributionCenterSelected)) {
      setGeneralInfo({
        id: '',
        checked: false,
      });
      setOpenAlertConfirm(false);
      providerRef.current.enqueueSnackbar(
        `Se ha ${
          activeCode ? 'activado' : 'inactivado'
        } el distribution center exitosamente`,
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'botom',
            horizontal: 'center',
          },
        },
      );
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={distributionCenterRows}
        columns={columns}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${
          activeCode ? 'activar' : 'inactivar'
        } este distribution center?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />

      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere eliminar este distribution center?"
        handleAccept={() => deleteDistributionCenter()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default DistributionCenterTable;
