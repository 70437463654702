/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CategoriesTable from './components/DataTable';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import ActionForm from './components/ActionForm';
import {
  getCategoryService,
} from '../../services';
import { Loading, Toaster } from '../../components';

const PromoCategoriesScreen = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const defaultData = {
    nameCategory: '',
    icon_base64: '',
    icon: '',
    order: 1,
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });

  const getCategories = async () => {
    if (categories.length === 0) {
      setLoading(true);
    }
    try {
      const res = await getCategoryService();
      if (res.status >= 300) {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
      } else {
        const dataOrder = res?.data.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          return true;
        });
        setCategories(dataOrder);
      }
    } catch (e) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error al cargar el historial de promociones.' });
    }
    setLoading(false);
  };

  const openCategoryForm = () => {
    let initialOrderValue = 1;
    if (categories.length > 0) {
      for (let v = 1; v < 100; v += 1) {
        const usedOrders = categories.find((category) => category.order === v);
        if (usedOrders === undefined) {
          initialOrderValue = v;
          break;
        }
      }
      setDefaultValues({ ...defaultData, order: initialOrderValue });
    }
    setPopUpAction({
      open: true,
      title: 'Crear nueva categoria',
      data: null,
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <>
        <Tooltip title="Refrescar página" aria-label="Refrescar página">
          <IconButton
            onClick={getCategories}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
            disabled={false}
          >
            <img src={reload} alt="reload" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
          <IconButton
            onClick={openCategoryForm}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
            disabled={false}
          >
            <img src={newButton} alt="newButton" />
          </IconButton>
        </Tooltip>
      </>
      <CategoriesTable
        categories={categories}
        getCategories={getCategories}
        setPopUpAction={setPopUpAction}
        setDefaultValues={setDefaultValues}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        categories={categories}
        title={popUpAction.title}
        setDefaultValues={setDefaultValues}
        defaultValues={
          {
            ...defaultValues,
          }
        }
        getCategories={getCategories}
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
        }}
        setAlertHome={setAlert}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </>
  );
};

export default PromoCategoriesScreen;
