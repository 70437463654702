import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../DataTable/DataTable';
import { Toaster, Loading } from '../../../../components';
import { addSegmentation } from '../../../../services';
import { ActionPopUp } from '../../../../Layouts';
import { INIT } from '../../constants';

const ActionForm = ({ newSegmentation, onClosePopUp, open }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const lastSeg = `S${newSegmentation}`;

  const onSave = async (id, { segmentation, description }) => {
    if (description.trim() !== '') {
      setLoading(true);
      const body = {
        segment: lastSeg,
        description,
        jsonLimits: JSON.stringify(segmentation),
      };
      const res = await addSegmentation(body)
        .catch(() => false)
        .finally(() => setLoading(false));

      if (res) {
        onClosePopUp(true);
        setAlert({ status: true, type: 'success', message: 'Creado correctamente.' });
      } else {
        setAlert({
          status: true,
          type: 'error',
          message: 'Ha ocurrido un error, intente nuevamente.',
        });
      }
    } else {
      setAlert({
        status: true,
        type: 'error',
        message: 'Completa la descripción del segmento.',
      });
    }
  };

  return (
    <ActionPopUp
      withoutSaveButton
      enabledControls={false}
      open={open}
      title=""
      onSave={onClosePopUp}
      onClose={onClosePopUp}
    >
      <>
        {loading && <Loading />}
        <DataTable
          onSave={onSave}
          data={{ ...INIT[0], segment: lastSeg }}
          index={1}
          newSegment
        />
      </>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </ActionPopUp>
  );
};

ActionForm.propTypes = {
  newSegmentation: PropTypes.number.isRequired,
  onClosePopUp: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ActionForm;
