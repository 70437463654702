import httpInstance from '../httpInstance';

const updateVersionService = async (version, username, latest) => {
  const URL = '/back-office/v1/device-version';
  const res = await httpInstance.put(URL,
    {
      minRequiredVersion: version,
      updatedBy: username,
      latest,
    });
  return res;
};

export default updateVersionService;
