/* eslint-disable import/prefer-default-export */
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import styled from 'styled-components';

const getDisabledButton = ({ disabled, theme }) => disabled && `
  border: 1px solid ${theme.primary10};
  color: ${theme.black10};
  cursor: auto;
  border: 1px solid ${theme.black10};
  box-shadow: none;
`;

export const LabelStyled = styled.label`
  margin-right: 10px;
  ${({ disabled, theme }) => disabled && `color: ${theme.black20};`}
`;

export const InputStyled = styled.input`
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 10px 0;
  width: 300px;
  background: none;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  padding: 18.5px 14px;
  border: 1px solid;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.black10};
  color: ${({ theme, disabled }) => (disabled ? theme.black20 : theme.black)}
`;

export const BoxFile = styled.div`
  display: flex;
  width: fit-content;
`;

export const InputFile = styled(InputStyled)`
  display: none;
`;

export const SpanButton = styled.span`
  align-items: center;
  justify-content: center;
  min-width: 150px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  width: 200px;
  display: flex;
  alignt-items: center;
  ${({ theme }) => `
    background-color: ${theme.white};
    color: ${theme.primary};
    box-shadow: none;
  `}
  ${getDisabledButton}
`;

export const PublishIconStyled = styled(PublishIcon)`
  fill: ${({ theme, disabled }) => (disabled ? theme.black10 : theme.primary)};
  margin-left: 5px;
`;

export const FindReplaceIconStyled = styled(FindReplaceIcon)`
  fill: ${({ theme, disabled }) => (disabled ? theme.black10 : theme.primary)};
  margin-left: 5px;
`;

export const ButtonDelete = styled(Button)`
  width: 300px;
  align-self: end;
`;

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  justify-content: space-between;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;
