import httpInstance from '../httpInstance';

const createNationalHolidaysService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1/holidays/create';
  const dataSent = dataParam;

  await httpInstance.post(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default createNationalHolidaysService;
