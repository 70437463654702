/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import columns from './constants';

const FeaturesFlagTable = ({ enteredFilter, featuresFlag }) => {
  const classes = useStyle();
  const [featuresFlagRows, setFeaturesFlagRows] = React.useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  const filterInfoTable = (enteredFilterParam, codesMapped) => {
    const featuresFlagList = codesMapped != null ? codesMapped : featuresFlag;
    const filteredList = featuresFlagList.filter(
      (featuresFlagParam) => featuresFlagParam.name
        .toLowerCase()
        .includes(enteredFilterParam.toLowerCase())
        || featuresFlagParam.code
          .toLowerCase()
          .includes(enteredFilterParam.toLowerCase())
        || featuresFlagParam.description
          .toLowerCase()
          .includes(enteredFilterParam.toLowerCase()),
    );
    setFeaturesFlagRows(filteredList);
  };

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const createActions = (active, featureFlagId) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
        <Box alignSelf="center">
          <CustomSwitch
            checked={active}
            value={featureFlagId}
            name="active"
            disabled
          />
        </Box>
      </Tooltip>
      <IconButton
        edge="end"
        aria-label="Ver"
        size="small"
        classes={{ disabled: classes.disabled }}
        disabled
      >
        <Tooltip title="Ver" aria-label="edit" placement="top">
          <EditIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Delete"
        size="small"
        style={{ paddingLeft: 10 }}
        classes={{ disabled: classes.disabled }}
        disabled
      >
        <Tooltip title="Eliminar" aria-label="delete" placement="top">
          <DeleteIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const mapFeatureFlags = (featureFlagParam) => {
    const featuresFlagInfo = featureFlagParam?.map((featureFlag) => ({
      name: featureFlag.name,
      description: featureFlag.description,
      code: featureFlag.code,
      actions: createActions(featureFlag.active, featureFlag.id),
      id: featureFlag.id,
      active: 1,
    }));

    setFeaturesFlagRows(featuresFlagInfo);

    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, featuresFlagInfo);
    }
  };

  useEffect(() => {
    setFeaturesFlagRows([]);
    mapFeatureFlags(featuresFlag);
  }, [featuresFlag]);

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={featuresFlagRows}
        columns={columns}
        readOnly
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

export default FeaturesFlagTable;
