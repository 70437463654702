/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  updateFeaturesFlagCommerceService,
  deleteFeaturesFlagCommerceService,
} from '../../../../services';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import useStyle from './useStyle';
import columns from './constants';

const FeaturesFlagTable = ({
  setPopUpAction,
  enteredFilter,
  featuresFlag,
  setFeaturesFlag,
  setDefaultValues,
}) => {
  const classes = useStyle();
  const [featuresFlagRows, setFeaturesFlagRows] = useState([]);
  const [featuresFlagListCopy, setFeaturesFlagListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    id: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  const filterInfoTable = (enteredFilterParam, codesMapped) => {
    const featuresFlagList = codesMapped != null ? codesMapped : featuresFlagListCopy;
    const filteredList = featuresFlagList.filter(
      (featuresFlagParam) => featuresFlagParam.name
        .toLowerCase()
        .includes(enteredFilterParam.toLowerCase())
        || featuresFlagParam.code
          .toLowerCase()
          .includes(enteredFilterParam.toLowerCase())
        || featuresFlagParam.description
          .toLowerCase()
          .includes(enteredFilterParam.toLowerCase()),
    );
    setFeaturesFlagRows(filteredList);
  };

  const handleChangeState = (event) => {
    setGeneralInfo({
      id: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const handleDeleteFeatureFlag = (bannerId) => {
    setGeneralInfo({
      id: bannerId,
      checked: false,
    });
    setOpenAlertConfirmDelete(true);
  };

  const openModalEditFeatureFlag = (bannerIdSelected) => {
    const featureFlagEdit = featuresFlag.find(
      (bannerRow) => bannerRow.id === bannerIdSelected,
    );
    const { name, description, code } = featureFlagEdit;
    setDefaultValues({
      name,
      description,
      code,
    });
    setPopUpAction({
      open: true,
      title: 'Editar feature flag comercio',
      data: featureFlagEdit,
    });
  };

  const deleteFeatureFlag = async () => {
    const res = await deleteFeaturesFlagCommerceService(generalInfo.id).catch(() => setAlert({
      status: true,
      type: 'error',
      message: 'Ha ocurrido un error, intente nuevamente.',
    }));
    if (res?.status !== 200) {
      return setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    providerRef.current.enqueueSnackbar(
      'Se ha eliminado el feature flag comercio exitosamente',
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      },
    );
    const bannersLocal = featuresFlag.filter((ele) => ele.id !== generalInfo.id);
    setFeaturesFlag(bannersLocal);
    setOpenAlertConfirmDelete(false);
  };

  const createActions = (active, featureFlagId) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Tooltip
        title="Cambiar estado"
        aria-label="changeState"
        placement="top"
      >
        <Box alignSelf="center">
          <CustomSwitch
            checked={active}
            onChange={(e) => handleChangeState(e)}
            value={featureFlagId}
            name="active"
          />
        </Box>
      </Tooltip>
      <IconButton
        edge="end"
        aria-label="Editar"
        size="small"
        onClick={() => openModalEditFeatureFlag(featureFlagId)}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <EditIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Delete"
        size="small"
        onClick={() => handleDeleteFeatureFlag(featureFlagId)}
        style={{ paddingLeft: 10 }}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Eliminar" aria-label="delete" placement="top">
          <DeleteIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const mapFeatureFlags = (featureFlagParam) => {
    const featuresFlagInfo = featureFlagParam?.map((featureFlag) => ({
      name: featureFlag.name,
      description: featureFlag.description,
      code: featureFlag.code,
      actions: createActions(featureFlag.active, featureFlag.id),
      id: featureFlag.id,
      active: 1,
    }));

    setFeaturesFlagRows(featuresFlagInfo);
    setFeaturesFlagListCopy(featuresFlagInfo);
    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, featuresFlagInfo);
    }
  };

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  useEffect(() => {
    setFeaturesFlagRows([]);
    mapFeatureFlags(featuresFlag);
  }, [featuresFlag]);

  const updateFeatureFlag = async (featureFlag) => {
    const res = await updateFeaturesFlagCommerceService(featureFlag).catch(() => setAlert({
      status: true,
      type: 'error',
      message: 'Ha ocurrido un error, intente nuevamente.',
    }));
    if (res?.status !== 200) {
      return setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    return true;
  };

  const handleConfirmChecked = () => {
    const featureFlagSelected = featuresFlag.find(
      (featureFlag) => featureFlag.id === generalInfo.id,
    );
    featureFlagSelected.active = generalInfo.checked ? 1 : 0;
    mapFeatureFlags(featuresFlag);
    if (updateFeatureFlag(featureFlagSelected)) {
      setGeneralInfo({
        id: '',
        checked: false,
      });
      setOpenAlertConfirm(false);
      providerRef.current.enqueueSnackbar(
        `Se ha ${
          activeCode ? 'activado' : 'inactivado'
        } el feature flag comercio exitosamente`,
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'botom',
            horizontal: 'center',
          },
        },
      );
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable data={featuresFlagRows} columns={columns} />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${
          activeCode ? 'activar' : 'inactivar'
        } este feature flag comercio?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />

      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere eliminar este feature flag comercio?"
        handleAccept={() => deleteFeatureFlag()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default FeaturesFlagTable;
