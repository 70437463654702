/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useReducer } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import useStyle, { StyledImage } from './useStyle';
import {
  CustomInput, Toaster, CustomSwitch, CustomSelect, Loading,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import {
  createBannerService,
  updateBannerService,
} from '../../../../services';
import {
  categoryOptions,
  targetoptions,
  imageProps,
  orderValues,
} from '../constants';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getBanners,
  defaultValues,
  setDefaultValues,
  promotions,
  banners,
  screens,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = React.useState(false);
  const [orderOpt, setOrderOpt] = useState([...targetoptions]);
  const [loading, setLoading] = useState(false);
  const [invalidImageBig, setInvalidImageBig] = useState('');
  const [dates, setDates] = useState(null);
  const [startDate, setStartDate] = useState(moment().format('MM/DD/yyyy'));
  const [endDate, setEndDate] = useState(moment().add(1, 'day').format('MM/DD/yyyy'));
  const [images, updateImages] = useReducer((prev, next) => {
    const newImages = { ...prev, ...next };
    return newImages;
  }, {
    imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageMid: { ...imageProps },
  });
  const providerRef = React.useRef();
  const classes = useStyle();

  const {
    handleSubmit, control, errors, reset, watch, setError, clearErrors, setValue,
  } = useForm({ defaultValues });

  const {
    promotion,
    order,
    category,
  } = watch(['promotion', 'order', 'category']);

  const createBanner = async (banner) => {
    setLoading(true);
    const res = await createBannerService(banner)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      setLoading(false);
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const updateBanner = async (banner) => {
    setLoading(true);
    const res = await updateBannerService(banner)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      setLoading(false);
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const resetFields = () => {
    setInvalidImageBig('');
    setDates(null);
    setOrderOpt([...orderValues]);
    setDefaultValues({
      name: '',
      description: '',
      category: categoryOptions[0].value,
      target: '',
      promotion: '',
      redirection: '',
      startDate: moment().format('MM/DD/yyyy'),
      endDate: moment().add(1, 'day').format('MM/DD/yyyy'),
      order: '0',
    });
    updateImages({
      imageBig: { ...imageProps }, imageLit: { ...imageProps }, imageMid: { ...imageProps },
    });
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
  };
  const handleSave = async (dataParam) => {
    if (images.imageBig.b64 === '' && data === null) {
      setError('fileSupportBig', { type: 'custom', message: 'custom message' });
      return;
    }

    const imagesBase64 = {
      big: images.imageBig.b64?.split(',')[1] || '',
      mid: images.imageBig.b64?.split(',')[1] || '',
      lit: images.imageBig.b64?.split(',')[1] || '',
    };

    const body = {
      category: dataParam.category === 'APP' ? categoryOptions[0].value : categoryOptions[1].value, // dataParam.category,
      description: dataParam.description,
      name: dataParam.name,
      state: active ? 1 : 0,
      destination_url: '',
      application_path: dataParam.category === 'APP' ? dataParam.redirection : '',
      image_source_url: imagesBase64,
      target: dataParam.target,
      id_promotion: dataParam.promotion || '',
      order: dataParam.order,
    };
    let isSavedorUpdate = false;
    let message;
    if (data) {
      const datesPromo = promotions.filter((item) => item.value === dataParam.promotion);
      if (datesPromo.length > 0 && dataParam.category === 'PROMO') {
        body.id = data.id;
        body.date_end = dates.endDate;
        body.date_start = dates.startDate;
      } else if (dataParam.category === 'APP') {
        body.id = data.id;
        body.date_end = moment(endDate).format('yyyy-MM-DDT:00:00:00');
        body.date_start = moment(startDate).format('yyyy-MM-DDT:00:00:00');
      }
      isSavedorUpdate = await updateBanner(body);
      message = 'Se ha editado el banner exitosamente';
    } else {
      // Get dates promotion
      const datesPromo = promotions.filter((item) => item.value === dataParam.promotion);
      if (datesPromo.length > 0 && dataParam.category === 'PROMO') {
        body.date_end = dates.endDate;
        body.date_start = dates.startDate;
        isSavedorUpdate = await createBanner(body);
        message = 'Se ha creado el banner exitosamente';
      } else if (dataParam.category === 'APP') {
        body.date_end = moment(endDate).format('yyyy-MM-DDT:00:00:00'); // dates.endDate // moment(endDate).format('yyyy-MM-DD')
        body.date_start = moment(startDate).format('yyyy-MM-DDT:00:00:00'); // dates.startDate // moment(startDate).format('yyyy-MM-DD'),
        isSavedorUpdate = await createBanner(body);
        message = 'Se ha creado el banner exitosamente';
      } else {
        return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, al asignar la promoción.' });
      }
    }
    if (isSavedorUpdate) {
      await getBanners();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      setLoading(false);
      resetFields();
      onClose();
    }
  };

  useEffect(() => {
    if (images.imageBig.b64 !== '') {
      clearErrors('fileSupportSize');
      clearErrors('fileSupportBig');
    }
  }, [images]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setAlert({ status: true, type: 'warning', message: 'Campos obligatorios faltantes' });
    }
  }, [errors]);

  const onFileUpload = (prop) => (event) => {
    event.preventDefault();
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
      updateImages({ ...images, [prop]: { ...imageProps } });
      setError('fileSupportFormat', { type: 'custom', message: 'Debe cargar un archivo válido (.jpg, .jpeg, png, pdf).' });
      file_reader.abort();
      return false;
    }

    if (file.size > 341000 && prop === 'imageBig') {
      updateImages({ ...images, [prop]: { ...imageProps } });
      file_reader.abort();
      setAlert({ status: true, type: 'warning', message: 'El peso del archivo supera el máximo permitido (341KB).' });
      setError('fileSupportSize', { type: 'custom', message: 'El peso del archivo supera el máximo permitido (341KB).' });
      return false;
    }

    file_reader.onload = () => {
      updateImages({ ...images, [prop]: { ...imageProps, b64: file_reader.result, name: file?.name } });
    };

    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (promotions) {
      const datesPromo = promotions.filter((item) => item.value === promotion);
      if (datesPromo.length > 0) {
        setDates({
          startDate: new Date(datesPromo[0].extraData.startDate).toISOString(),
          endDate: new Date(datesPromo[0].extraData.endDate).toISOString(),
        });
      }
    }
  }, [promotion]);

  useEffect(() => {
    if (!banners) return;
    const numbersC = [...orderValues];
    if (order) {
      let prevOpt = [];
      if (data?.order) {
        // default value for edition
        const index = numbersC.findIndex((elem) => elem.value === data.order.toString());
        prevOpt = [numbersC[index]];
      }
      const remove1 = banners.findIndex((elem) => elem.order.toString() === '1');
      const remove2 = banners.findIndex((elem) => elem.order.toString() === '2');
      const remove3 = banners.findIndex((elem) => elem.order.toString() === '3');

      if (remove1 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '1'), 1);
      if (remove2 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '2'), 1);
      if (remove3 !== -1) numbersC.splice(numbersC.findIndex((elem) => elem.value === '3'), 1);
      setOrderOpt([...numbersC, ...prevOpt]);
    }
  }, [banners, order, defaultValues]);

  useEffect(() => {
    // Set Images for edition
    if (defaultValues.image_source_url
      && defaultValues.image_source_url.big
      && defaultValues.image_source_url.mid
      && defaultValues.image_source_url.lit) {
      updateImages({
        ...images,
        imageBig: { ...imageProps, urlImage: defaultValues.image_source_url.big },
        imageLit: { ...imageProps, urlImage: defaultValues.image_source_url.lit },
        imageMid: { ...imageProps, urlImage: defaultValues.image_source_url.mid },
      });
    }

    if (defaultValues.startDate !== '' && defaultValues.endDate !== '' && defaultValues.category === 'APP') {
      setStartDate(defaultValues.startDat);
      setEndDate(defaultValues.endDate);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.state);
    }
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >

        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Detalles:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper variant="outlined" className={classes.paperbox}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          onChange={(e) => {
                            setActive(
                              e.target.checked,
                            );
                            if (!e.target.checked) {
                              setValue('order', '0');
                            }
                          }}
                          name="active"
                          color="primary"
                        />
                    )}
                      label={`Estado: ${active ? 'Activo' : 'inactivo'}`}
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre *"
                  name="name"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Banner orden *"
                  name="order"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={orderOpt}
                  disabled={orderOpt.length === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Tipo de redirección *"
                  name="category"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={categoryOptions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} />
              {/* datos promoción */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {category !== 'APP' ? 'Asignar promoción y target' : 'Asignar target y pantalla' }
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Banner target *"
                  name="target"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={targetoptions}
                  disabled={targetoptions.length === 0}
                />
              </Grid>
              {category !== 'APP' ? (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomSelect
                    label="Asignar promoción*"
                    name="promotion"
                    control={control}
                    rule={Rules.required}
                    error={errors}
                    options={promotions}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <CustomSelect
                      label="Redirección *"
                      name="redirection"
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      options={screens}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl>
                      <Controller
                        render={() => (
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              id="startDate"
                              variant="inline"
                              inputVariant="outlined"
                              label="Inicio vigencia *"
                              format="dd/MM/yyyy"
                              size="small"
                              minDate={moment().format('MM/DD/yyyy')}
                              FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                              onChange={(e) => setStartDate(e)}
                              value={startDate}
                              disablePast
                              minDateMessage={data === null && 'La fecha de inicio no puede ser menor a la actual.'}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        name="startDate"
                        control={control}
                        error={data === null ? errors.startDate : null}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl>
                      <Controller
                        render={() => (
                          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.dateCustom}
                              id="endDate"
                              variant="inline"
                              inputVariant="outlined"
                              label="Fin vigencia *"
                              format="dd/MM/yyyy"
                              size="small"
                              FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: '#d61103' } }}
                              onChange={(e) => setEndDate(e)}
                              value={endDate}
                              minDate={moment().add(1, 'day').format('MM/DD/yyyy')}
                              disablePast
                              minDateMessage={data === null && 'La fecha de final no puede ser menor o igual a la sinicial.'}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        name="endDate"
                        control={control}
                        error={data === null ? errors : null}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md lg />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" gutterBottom>
                  Imagen banner:
                </Typography>
                <div className={classes.subInfoLabel}>* Dimensiones 300 x 112px (peso maximo 341 KB)</div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display="flex" className={classes.uploadImage} justifyContent="center">
                  <label htmlFor="fileSupportBig">
                    <input
                      onChange={onFileUpload('imageBig')}
                      id="fileSupportBig"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                    >
                      Cargar imagen banner
                    </Button>

                    {invalidImageBig && (
                    <Typography className={classes.formErrors}>
                      {invalidImageBig}
                    </Typography>
                    )}
                  </label>
                </Box>
                {(images.imageBig.b64 !== '' || images.imageBig.urlImage !== '') && (
                  <Grid container justifyContent="center" alignContent="center" direction="column">
                    <StyledImage
                      name="imagepreviewDest"
                      width={180}
                      src={images.imageBig.urlImage ? images.imageBig.urlImage : images.imageBig.b64}
                      aspectRatio={(60 / 10)}
                    />
                    <Typography className={classes.formLabel}>
                      {images.imageBig.name}
                    </Typography>
                  </Grid>
                )}
                {
                  Object.keys(errors).length > 0 && images.imageBig.b64 === '' && data === null
                  && (
                  <>
                    <div className={classes.errorSubText}>Campo obligatorio</div>
                  </>
                  )
                }
                {
                  Object.keys(errors).length > 0 && (errors.fileSupportSize)
                  && (
                  <>
                    <div className={classes.errorSubText}>{errors.fileSupportSize.message}</div>
                  </>
                  )
                }
                {
                  Object.keys(errors).length > 0 && (errors.fileSupportFormat)
                  && (
                  <>
                    <div className={classes.errorSubText}>{errors.fileSupportFormat.message}</div>
                  </>
                  )
                }
              </Grid>
              <Grid item xs={12} sm={12} md lg />
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
          {loading && <Loading />}
        </>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  getBanners: PropTypes.func,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setDefaultValues: PropTypes.func,
  promotions: PropTypes.array,
  banners: PropTypes.array,
  screens: PropTypes.array,
};

export default ActionForm;
