import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import { SnackbarProvider } from 'notistack';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useStyle from './useStyle';
import { Toaster, CustomSwitch } from '../../../../components';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  updateTaxService,
} from '../../../../services';

const DataTable = ({ data, onRowClick }) => {
  const classes = useStyle;
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [generalInfo, setGeneralInfo] = useState({
    id: '',
    checked: false,
  });
  const providerRef = React.useRef();

  const handleChangeState = (event) => {
    setGeneralInfo({
      id: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const updateTax = async (tax) => {
    const res = await updateTaxService(tax)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return true;
  };

  const handleConfirmChecked = () => {
    const taxSelected = data
      .find((tax) => tax.tax_id === generalInfo.id);
    taxSelected.state = generalInfo.checked ? 1 : 0;
    if (updateTax(taxSelected)) {
      setGeneralInfo({
        id: '',
        checked: false,
      });
      setOpenAlertConfirm(false);
      providerRef.current.enqueueSnackbar(`Se ha ${activeCode ? 'activado' : 'inactivado'} el impuesto exitosamente`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  };

  function createActions(active, featureFlagId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box alignSelf="center">
            <CustomSwitch
              checked={active}
              onChange={(e) => handleChangeState(e)}
              value={featureFlagId}
              name="active"
            />
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <SnackbarProvider ref={providerRef}>
      <TableContainer component={Paper}>
        {data && (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Transacción</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell align="right">$</TableCell>
              <TableCell align="right">%</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (

              <TableRow hover key={row.transaction_code}>
                <TableCell onClick={() => onRowClick(index)}>{row.transaction_name}</TableCell>
                <TableCell onClick={() => onRowClick(index)}>{row.taxes[0].name}</TableCell>
                <TableCell onClick={() => onRowClick(index)}>{row.taxes[0].description}</TableCell>
                <TableCell onClick={() => onRowClick(index)}>{row.channel}</TableCell>
                <TableCell align="right" onClick={() => onRowClick(index)}>{row.taxes[0].amount}</TableCell>
                <TableCell align="right" onClick={() => onRowClick(index)}>{row.taxes[0].percent}</TableCell>
                <TableCell align="center">{createActions(row.state, row.tax_id)}</TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
        )}
      </TableContainer>
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${activeCode ? 'activar' : 'inactivar'
        } este impuesto?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />

    </SnackbarProvider>
  );
};

DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default DataTable;
