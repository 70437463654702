import httpInstance from '../httpInstance';

const addSegmentation = async (body) => {
  let res;
  const uri = '/segmentation/v1/segmentation';
  await httpInstance.post(uri, body)
    .then((data) => {
      res = data;
    });
  return res;
};

export default addSegmentation;
