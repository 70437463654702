import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import tableStyle, { ImgSearch } from './components/tableStyle';
import ActionForm from './components/ActionForm.readonly';
import newButton from '../../assets/img/iconos/newButton.svg';
import reload from '../../assets/img/iconos/reload.svg';
import searchIcon from '../../assets/img/iconos/searchIcon.svg';
import PromotionsTable from './components/DataTable.readonly';
import { Loading, Toaster } from '../../components';
import { getCategoryService, getPromotionsApp } from '../../services';
import getTypePromotionsService from '../../services/typePromotions/getTypePromotions';

const PromotionsAppScreen = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [typePromotions, setTypePromotions] = useState([]);
  const [enteredFilter, setEnteredFilter] = useState('');
  const [promotions, setPromotions] = useState([]);
  const [popUpAction, setPopUpAction] = useState({ open: false, title: '', data: null });
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const defaultData = {
    highlight: '',
    categoryOrder: 1,
    featureOrder: 1,
    promotionName: '',
    commerceName: '',
    commerceLogo: '',
    description: '',
    startDate: '',
    endDate: '',
    details: '',
    imageBig_base64: '',
    imageLit_base64: '',
    featureImage_base64: '',
    category: '',
    promotionType: '',
    commerceLocation: {
      address: '',
      latitud: '',
      longitude: '',
      virtualCommerce: false,
    },
    address: '',
    lat: '',
    long: '',
    contactInformation: {
      socialMedia: '',
      phone: '',
      whatsapp: '',
      webPage: '',
    },
    phone: '',
    weburl: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    status: '',
    featurePromotion: false,
  };
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const classes = tableStyle();

  const refreshPromos = () => {
    getPromotionsApp().then((res) => setPromotions(res));
  };

  const initData = async () => {
    await getCategoryService().then((res) => {
      if (res.status === 200) {
        const optionsCat = res.data.map((item) => ({ value: `${item.id},${item.nameCategory},${item.order}`, label: item.nameCategory }));
        setCategories(optionsCat);
      }
    });

    await getTypePromotionsService().then((data) => {
      if (data) {
        const optionsTypes = data.map(
          (item) => ({ value: `${item.id},${item.promotionTypeName}`, label: item.promotionTypeName }),
        );
        setTypePromotions(optionsTypes);
      }
    });

    refreshPromos();
    setLoading(false);
  };

  useEffect(() => {
    if (categories.length === 0 && typePromotions.length === 0) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [categories, typePromotions]);

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <>
        <TextField
          className={classes.searchTextField}
          value={enteredFilter}
          onChange={(e) => setEnteredFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImgSearch src={searchIcon} alt="searchIcon" />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          placeholder="Buscar"
        />
        <Tooltip title="Refrescar página" aria-label="Refrescar página">
          <IconButton
            onClick={getPromotionsApp}
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
            disabled={false}
          >
            <img src={reload} alt="reload" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Crear nueva categoria" aria-label="Crear nueva categoria">
          <IconButton
            style={{ fontSize: '20px', float: 'right' }}
            edge="end"
            size="small"
            disabled
          >
            <img src={newButton} alt="newButton" style={{ opacity: 0.4 }} />
          </IconButton>
        </Tooltip>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </>
      <PromotionsTable
        promotions={promotions}
        setPopUpAction={setPopUpAction}
        setDefaultValues={setDefaultValues}
        enteredFilter={enteredFilter}
        categories={categories}
        typePromotions={typePromotions}
      />
      <ActionForm
        open={popUpAction.open}
        data={popUpAction.data}
        title={popUpAction.title}
        setDefaultValues={setDefaultValues}
        defaultValues={
          {
            ...defaultValues,
          }
        }
        onClose={() => {
          setPopUpAction({ open: false, title: '', data: null });
        }}
        setAlertHome={setAlert}
        categories={categories}
        typePromotions={typePromotions}
      />
    </>
  );
};

export default PromotionsAppScreen;
