/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable import/prefer-default-export */
import { updateFeaturedPromotion } from '../../../services';

const ERROR_MSG = 'Ha ocurrido un error, intente de nuevo';

const onCleanData = (data) => {
  const cleanData = {};
  for (const property in data) {
    const value = data[property];
    if (value) cleanData[property] = value;
  }

  return cleanData;
};

const validIfAreDifferentValues = (data) => {
  const values = [];
  for (const property in data) {
    const value = data[property];
    if (value) values.push(value);
  }
  const unicValues = [...new Set(values)];

  return unicValues.length === values.length;
};

const validResponse = async (promises) => {
  const response = await Promise.all(promises);
  return response.every((e) => e.status === 200);
};

export const onUpdateOrderFeaturedPromotion = async ({ dataForm, data }) => {
  const areDifferentValues = validIfAreDifferentValues(dataForm);

  if (!areDifferentValues) return { type: 'error', message: 'El orden debe ser diferente' };

  const promises = [];
  for (const property in dataForm) {
    const id = property.split('/')[1];
    const promotion = data.find((e) => e.id === id);
    promises.push(updateFeaturedPromotion({ ...promotion, featureOrder: dataForm[property] }));
  }

  const isValidResponse = await validResponse(promises);

  if (!isValidResponse) return { type: 'error', message: ERROR_MSG };

  return { type: 'success', message: 'Se ha actualizado el orden correctamente.' };
};

export const onAddFeaturedPromotion = async ({ dataForm, promotions }) => {
  const cleanData = onCleanData(dataForm);
  const areDifferentValues = validIfAreDifferentValues(cleanData);

  if (!areDifferentValues) return { type: 'error', message: 'No puedes utilizar la misma promoción u orden.' };

  const size = Object.keys(cleanData).length / 2;
  const promises = [];
  let c = 0;

  for (const property in cleanData) {
    if (c <= size) {
      const index = property.split('-')[1];
      const id = cleanData[`promotion-${index}`];
      const order = cleanData[`order-${index}`];
      const dataPromotion = promotions.find((e) => e.id === id);
      promises.push(updateFeaturedPromotion({
        ...dataPromotion,
        featurePromotion: true,
        featureOrder: order,
      }));
    }
    c += 1;
  }

  const isValidResponse = await validResponse(promises);

  if (!isValidResponse) return { type: 'error', message: ERROR_MSG };

  const message = size === 2 ? 'Promoción agregado como destacada.' : 'Promociones agregadas como destacadas';
  return { type: 'success', message };
};
