/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
  useState,
  Fragment,
} from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { InternalPage } from '../../Layouts';
import { Card, IconButtonStyled } from './styles';
import { DataTable, ActionForm } from './components/index';
import newButton from '../../assets/img/iconos/newButton.svg';
import { getAllSegmentations, updateSegmentation } from '../../services';
import { Toaster, Loading } from '../../components';
import { INIT } from './constants';

const SegmentationScreen = () => {
  const [segmentations, setSegmentations] = useState(INIT);
  const [newSegmentation, setNewSegmentation] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [popUpAction, setPopUpAction] = useState(false);

  const getLastSegmentation = (segmentation) => {
    const [lastSeg] = segmentation
      .map((e) => e.segment[1])
      .sort()
      .slice(-1);
    setNewSegmentation(Number(lastSeg || 0) + 1);
  };

  const onInit = async () => {
    setLoading(true);
    const res = await getAllSegmentations()
      .catch(() => false)
      .finally(() => setLoading(false));

    if (res) {
      getLastSegmentation(res.data);
      setSegmentations(res.data);
    } else {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  const onSave = async (id, { segmentation, description }) => {
    setLoading(true);
    const segmnetation = segmentations.find((e) => e.id === id);
    const body = {
      id,
      description,
      segment: segmnetation.segment,
      jsonLimits: JSON.stringify(segmentation),
    };

    const res = await updateSegmentation(body)
      .catch(() => false)
      .finally(() => setLoading(false));
    if (res) {
      setAlert({ status: true, type: 'success', message: 'Guardado correctamente' });
    } else {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  const onClosePopUp = (reload) => {
    if (reload) {
      onInit();
    }

    setPopUpAction(false);
  };

  return (
    <InternalPage loading={false} error={alert.type === 'error'}>
      {loading && <Loading />}
      <Card>
        <div>
          <Tooltip title="Crear banner" aria-label="Nuevo banner">
            <IconButtonStyled
              onClick={() => setPopUpAction(true)}
              edge="end"
              size="small"
            >
              <img src={newButton} alt="newButton" />
            </IconButtonStyled>
          </Tooltip>
        </div>
        {segmentations.length === 0 && <Typography variant="h5" component="h5">Aún no hay segmentos registrados</Typography>}
        {segmentations.map((segmentation, i) => (
          <Fragment key={`${segmentation.id}-${i}`}>
            <Box marginY={2}>
              <DataTable
                onSave={onSave}
                data={segmentation}
                index={i + 1}
              />
            </Box>
          </Fragment>
        ))}
      </Card>
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
      <ActionForm
        newSegmentation={newSegmentation}
        onClosePopUp={onClosePopUp}
        open={popUpAction}
      />
    </InternalPage>
  );
};

export default SegmentationScreen;
