import { useState } from 'react';
import { useAxiosInstance } from '../httpInstance';

export const getRemittanceConfig = () => {
  const [{ loading, data, error }, getRemittance] = useAxiosInstance(
    {
      url: 'back-office/v1/remittanceConfig',
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    getRemittance,
  };
};

export const getRemittanceConfigById = () => {
  const [assignId, setAssignId] = useState();
  const [{ loading, data, error }, getRemittanceID] = useAxiosInstance(
    {
      url: `back-office/v1/remittanceConfig/${assignId}`,
      method: 'GET',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    getRemittanceID,
    assignId,
    setAssignId,
  };
};

export const deleteRemittanceConfig = () => {
  const [assignId, setAssignId] = useState();

  const [{ loading, data, error }, deleteRemittance] = useAxiosInstance(
    {
      url: `/back-office/v1/remittanceConfig/${assignId}`,
      method: 'DELETE',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    deleteRemittance,
    setAssignId,
  };
};

export const saveRemittanceConfig = () => {
  const [{ loading, data, error }, saveRemittance] = useAxiosInstance(
    {
      url: 'back-office/v1/remittanceConfig',
      method: 'POST',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    saveRemittance,
  };
};

export const updateRemittanceConfig = () => {
  const [{ loading, data, error }, updateRemittance] = useAxiosInstance(
    {
      url: 'back-office/v1/remittanceConfig',
      method: 'PUT',
    },
    { manual: true },
  );

  return {
    loading,
    data,
    error,
    updateRemittance,
  };
};

export default {
  getRemittanceConfig,
  getRemittanceConfigById,
  deleteRemittanceConfig,
  saveRemittanceConfig,
  updateRemittanceConfig,
};
