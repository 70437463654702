import httpInstancePaymentLink from '../httpInstancePaymentLink';

const postDisableMerchantService = async (merchantId) => {
  let res;
  const endpoint = `api/v1/merchants/${merchantId}/Disable`;
  await httpInstancePaymentLink.post(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default postDisableMerchantService;
