/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { WrapContainer } from './formStyle';
import {
  CustomInput, Toaster, CustomSelect,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import {
  createCategoryService,
} from '../../../services';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getProviders,
  defaultValues,
  setDefaultValues,
  providers,
  categoriesOptions,
  categories,
  updateProvider,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [imagePreview, setImagePreview] = useState(null);
  const providerRef = React.useRef();

  const {
    handleSubmit, control, errors, reset,
  } = useForm({ defaultValues });

  const resetFields = () => {
    setDefaultValues({
      nameCategory: '',
      icon: '',
      order: '',
    });
  };

  const createCategory = async (category) => {
    const res = await createCategoryService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    resetFields();
    return true;
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
    setImagePreview(null);
  };

  const handleSave = async (dataParam) => {
    if (!dataParam.reference.replace(/\s/g, '').length || dataParam.reference === '') {
      setAlert({ status: true, type: 'error', message: 'El nombre de la categoría es inválido' });
      return;
    }
    const categoryId = categories.find((category) => category.categoryId === dataParam.creditorAgent);

    const body = {
      id: defaultValues.id,
      beneficiaryId: defaultValues.beneficiaryId,
      beneficiaryType: defaultValues.beneficiaryType,
      reference: dataParam.reference,
      creditorAgent: categoryId.creditorAgent,
      creditorAgentUrlImage: null,
      beneficiaryUrlImg: null,
      categoryId: categoryId.categoryId,
      status: defaultValues.status,
    };

    let isSavedorUpdate = false;
    let message;
    if (data) {
      isSavedorUpdate = await updateProvider(body);
      resetFields();
      message = 'Se ha editado el proveedor exitosamente';
    } else {
      const categoryOrder = providers.find((categoryRow) => categoryRow.order === parseInt(dataParam.order, 10));
      if (categoryOrder !== undefined || categoryOrder === false) {
        setAlert({ status: true, type: 'error', message: 'El numero de orden ya ha sido usado. El numero de orden debe estar entre 1 y 99' });
        return;
      }
      isSavedorUpdate = await createCategory(body);
      message = 'Se ha creado la categoria exitosamente';
    }
    if (isSavedorUpdate) {
      await getProviders();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      resetFields();
      onClose();
      setImagePreview(null);
    }
  };

  useEffect(() => {
  }, [imagePreview]);

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >

        <>
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre proveeedor*"
                  name="reference"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Asignar categoría*"
                  name="creditorAgent"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={categoriesOptions}
                />
              </Grid>
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>

        <Toaster
          show={alert?.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
