import httpInstance from '../httpInstance';

const updateSegmentation = async (body) => {
  let res;
  const uri = '/segmentation/v1/segmentation';
  await httpInstance.put(uri, body)
    .then((data) => {
      res = data;
    });
  return res;
};

export default updateSegmentation;
