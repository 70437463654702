import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { InternalPage } from '../../Layouts';
import { ButtonStyled } from './styles';
import { Toaster, NumberFormatCustom, Loading } from '../../components';
import useStyle from './useStyle';
import getCreditCardConfig from '../../services/creditCard/getCreditCardConfig';
import inputSectionsInitialValues from './constants';
import putCreditCardConfig from '../../services/creditCard/putCreditCardConfig';
import postCreditCardConfig from '../../services/creditCard/postCreditCardConfig';

const CardsCostLimitScreen = ({ permissionToEdit }) => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [canEdit, setCanEdit] = useState(false);
  const [inputSections, setInputSections] = useState(inputSectionsInitialValues);
  const [creditCardConfig, setCreditCardConfig] = useState({ data: undefined, status: undefined });
  const [errors, setErrors] = useState();

  const payloadFormData = useRef({ ...creditCardConfig.data, additionalData: null });
  const inputRef = useRef();

  const classes = useStyle();

  const getConfig = useCallback(async () => {
    setLoading(true);
    setInputSections(inputSectionsInitialValues);
    const { data, status } = await getCreditCardConfig();
    if (data) {
      payloadFormData.current = data;
    }
    if (data?.additionalData) {
      payloadFormData.current.additionalData = data.additionalData;
      setInputSections(() => [...inputSectionsInitialValues, {
        title: 'Información adicional cuotas',
        fields: data?.additionalData?.map(({ title, value, type }) => ({
          label: title,
          name: 'additionalData',
          placeholder: value,
          type,
        })),
      }]);
    }
    setLoading(false);

    return setCreditCardConfig({ data, status });
  });

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
    };

    let inputElements;

    setTimeout(() => {
      inputElements = Array.from(document.getElementsByTagName('input'));
      inputElements.forEach((inputElement) => {
        inputElement.addEventListener('wheel', handleWheel, { passive: false });
      });
    }, 2000);

    return () => {
      inputElements?.forEach((inputElement) => {
        inputElement?.removeEventListener('wheel', handleWheel);
      });
    };
  }, []);

  const handleEditForm = ({ target: { value, name } }, field) => {
    const regex = /^[0-9].*$/;
    if (!regex.test(value)) {
      setErrors((prevState) => ({ ...prevState, [name]: true }));
    } else {
      setErrors((prevState) => ({ ...prevState, [name]: false }));
    }

    if (name === 'additionalData') {
      const itemIndex = payloadFormData.current.additionalData
        .findIndex(({ title }) => title === field);
      payloadFormData.current.additionalData[itemIndex].value = value;
      return;
    }

    payloadFormData.current = {
      ...payloadFormData.current,
      [name]: value,
    };
  };

  const handleEdit = () => {
    setCanEdit((prev) => !prev);
    // reset();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (creditCardConfig?.status === 404) await postCreditCardConfig({ id: '1', ...payloadFormData.current });
      else await putCreditCardConfig({ id: '1', ...payloadFormData.current });
      getConfig();
      setAlert({ status: true, type: 'success', message: 'Datos guardados correctamente' });
    } catch (error) {
      setAlert({ status: true, type: 'error', message: 'Error durante la edición' });
    } finally {
      getConfig();
    }
  };

  const disableSaveButton = () => errors && Object.values(errors).some((value) => value);

  const cleanInputs = () => {
    getConfig();
    handleEdit();
  };
  if (loading) return <Loading />;
  return (
    <InternalPage error={alert.type === 'error'}>
      <Paper className={classes.paper}>

        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 32 }}>
          <Typography variant="h4">Límites y Costos</Typography>
          {permissionToEdit && (
          <ButtonStyled variant={canEdit ? 'outlined' : 'contained'} onClick={handleEdit}>
            Editar
          </ButtonStyled>
          )}
        </Grid>

        <form onSubmit={onSubmit}>
          <Grid container spacing={4}>
            {inputSections.map((section) => (
              <Grid container item spacing={2} key={section.title}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: 12 }}>{section.title}</Typography>
                  <Divider />
                </Grid>
                {section.fields.map((field) => (
                  <Grid container item xs={12} key={field.label} alignItems="center">
                    <Grid container item spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography variant="body1">{field.label}</Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          ref={inputRef}
                          className={classes.input}
                          id={field.name}
                          name={field.name}
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={field.value}
                          disabled={!canEdit}
                          onChange={(e) => handleEditForm(e, field.name === 'additionalData' ? field.label : null)}
                          error={errors && errors[field.name]}
                          helperText={errors && errors[field.name] ? 'Formato incorrecto' : ''}
                          inputProps={{
                            type: field.type === 'int' ? 'number' : 'text',
                            inputMode: 'decimal',
                            step: '0.01',
                            min: 0,
                            inputComponent: NumberFormatCustom,
                            inputProps: {
                              thousandSeparator: !field.percentage,
                              suffix: field.percentage ? '%' : '',
                              name: field.name,
                            },
                          }}
                            // eslint-disable-next-line no-nested-ternary
                          placeholder={field.name === 'additionalData' ? field.placeholder : creditCardConfig?.data ? `${creditCardConfig?.data[field.name] || '0'}${field.percentage ? '%' : ''}` : field.placeholder}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>

          {canEdit && (
          <Grid container justifyContent="flex-end" className={classes.seccionButton}>
            <ButtonStyled variant="outlined" onClick={cleanInputs}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled type="submit" variant="contained" disabled={disableSaveButton()}>
              Guardar
            </ButtonStyled>
          </Grid>
          )}
        </form>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </Paper>
    </InternalPage>
  );
};

CardsCostLimitScreen.propTypes = {
  permissionToEdit: PropTypes.bool,
};

CardsCostLimitScreen.defaultProps = {
  permissionToEdit: false,
};

export default CardsCostLimitScreen;
