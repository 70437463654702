import styled from 'styled-components';

const DivImage = styled.div`
  ${({ url }) => (url ? `display: flex;
    align-items: center;
    justify-content: center;
    background: #50b93f;
    border-radius: 20%;
    width: 50px;
    height: 50px;`
    : `display: flex;
    text-align: center;
    justify-content: center;`)}
  
`;

export const Img = styled.img`
  width: 25px;
  height: 25px;
`;

export default DivImage;
