import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';

import useStyle from './useStyle';
import RemittancesTable from './components/DataTable/RemittancesNoUserTable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { Loading, Toaster } from '../../components';
import FILTER_OPTIONS from './constants';
import getRemittanceNoUserService from '../../services/remittances/getRemittanceNoUserService';
import cancelRemittanceService from '../../services/remittances/cancelRemittanceService';

const PaymentsLinkScreen = () => {
  const classes = useStyle();
  const chunkSize = 80;
  const [totalElementsData, setTotalElementsData] = useState(0);
  const [remittances, setRemittances] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [keyFilter, setKeyFilter] = useState(FILTER_OPTIONS[0].value);
  const [showAlert, setShowAlert] = useState(false);
  const [currentChunk, setCurrentChunk] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const getRemittances = async () => {
    try {
      const res = await getRemittanceNoUserService({
        pageSize: chunkSize,
        keyFilter: FILTER_OPTIONS.find((filter) => filter.value === keyFilter)?.id,
        searchQuery,
      });
      if (res.status === 200) {
        const remittancesData = res.data.pending_remittances;
        setRemittances(remittancesData);
        setTotalElementsData(res.data.number_of_elements);
        setLoading(false);
      } else {
        setLoading(false);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
    }
  };

  const handleSearchPress = () => {
    if (searchQuery) {
      setLoading(true);
      setRemittances([]);
      getRemittances();
    }
  };

  const handleCancelRemittance = async (id) => {
    try {
      setLoading(true);
      const response = await cancelRemittanceService(id);
      if (response.status === 200) {
        await getRemittances();
      } else {
        setLoading(false);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
    }
  };

  const handleNext = () => {
    if (remittances.length === totalElementsData) return;
    if (
      remittances.length < (currentPage + 2) * currentPageSize
    ) {
      setCurrentChunk((oldChunk) => oldChunk + 1);
    }
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handleNewPageSize = (newPageSize) => {
    setCurrentPageSize(Number(newPageSize));
    setCurrentPage(0);
  };

  useEffect(() => {
    getRemittances();
  }, [currentChunk, chunkSize]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {loading ? <Loading /> : null}
        <div style={{ marginBottom: 20 }}>
          <SearchBox
            placeholder="Buscar remesa"
            options={FILTER_OPTIONS}
            handleSearchPress={handleSearchPress}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setKeyFilter={setKeyFilter}
          />
        </div>
        <RemittancesTable
          remittances={remittances}
          searchQuery={searchQuery}
          onNext={handleNext}
          onSelectPageSize={handleNewPageSize}
          totalElementsData={totalElementsData}
          handleCancelRemittance={handleCancelRemittance}
        />
      </div>
      <Toaster
        show={showAlert}
        type="error"
        text="Ha ocurrido un error, intente nuevamente."
        onClose={() => setShowAlert(false)}
      />
    </Paper>
  );
};
export default PaymentsLinkScreen;
