const getMoneyFormat = (
  balance,
) => {
  const decimalCount = 2;
  const thousandChar = ',';
  const decimalChar = '.';
  const isNotRounded = false;
  const negativeSign = balance < 0 ? '-' : '';
  const newBalance = isNotRounded
    ? Math.round((Math.abs(balance) + Number.EPSILON) * 100) / 100
    : Math.abs(balance);

  const balanceString = parseInt(newBalance.toFixed(decimalCount), 10).toString();
  const thousanCount = balanceString.length > 3 ? balanceString.length % 3 : 0;

  const moneyIntFormat = negativeSign
    + (thousanCount ? balanceString.substr(0, thousanCount) + thousandChar : '')
    + balanceString.substr(thousanCount).replace(/(\d{3})(?=\d)/g, `$1${thousandChar}`);

  const decimals = Math.abs(balance - parseInt(balanceString, 10));

  const moneyDecimalFormat = decimalCount
    ? decimalChar + decimals.toFixed(decimalCount).slice(-2)
    : '';

  return `RD$ ${moneyIntFormat}${moneyDecimalFormat}`;
};

export default getMoneyFormat;
