import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useUserStatus = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const checkUserAuthentication = async () => {
      const user = await Auth.currentAuthenticatedUser()
        .catch(() => {
          setCurrentUser(undefined);
          history.push('/login');
        });

      setCurrentUser({ user });
    };
    Hub.listen('auth', checkUserAuthentication);
    checkUserAuthentication();
    return () => Hub.remove('auth', checkUserAuthentication);
  }, []);

  return currentUser;
};

export default useUserStatus;
