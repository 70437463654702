/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import Image from 'material-ui-image';
import { SnackbarProvider } from 'notistack';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import formStyle, { WrapContainer, MessageRequired } from './formStyle';
import {
  CustomInput, Toaster, ProgressBar,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import {
  createCategoryService,
  updateCategoryService,
} from '../../../services';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getCategories,
  defaultValues,
  setDefaultValues,
  categories,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [invalidImageLit, setInvalidImageLit] = useState('');
  const [fileNameLit, setFileNameLit] = useState('');
  const [startBarProgressLit, setStartBarProgressLit] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [files, setFiles] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const providerRef = React.useRef();
  const classes = formStyle();

  const {
    handleSubmit, control, errors, reset, setError, clearErrors,
  } = useForm({ defaultValues });

  const resetFields = () => {
    setFiles([]);
    setCanEdit(true);
    setInvalidImageLit('');
    setFileNameLit('');
    setStartBarProgressLit(false);
    setDefaultValues({
      nameCategory: '',
      icon: '',
      order: '',
    });
  };

  const createCategory = async (category) => {
    const res = await createCategoryService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 201) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    resetFields();
    return true;
  };

  const updateCategory = async (category) => {
    const res = await updateCategoryService(category)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    resetFields();
    return true;
  };

  const handleOnclose = () => {
    resetFields();
    onClose();
    setImagePreview(null);
  };
  const handleSave = async (dataParam) => {
    if (fileNameLit === '' && data === null) {
      setError('imageLit', 'Campo obligatorio');
      return;
    }
    if (dataParam.order < 1 || dataParam.order > 99) {
      setAlert({ status: true, type: 'error', message: 'El numero de orden debe estar entre 1 y 99' });
      return;
    }
    let imagesBase64 = (data !== null && data.icon) ? data.icon : '';
    files.forEach((file) => {
      const uploadedFile = file.uploaded_file;
      const imageBase64 = uploadedFile;
      if (file.file_id === 'fileIcon') {
        imagesBase64 = imageBase64 !== undefined ? imageBase64 : '';
      }
    });
    const body = {
      order: dataParam.order,
      nameCategory: dataParam.nameCategory,
      icon: imagesBase64,
    };
    let isSavedorUpdate = false;
    let message;
    if (data) {
      body.id = data.id;
      isSavedorUpdate = await updateCategory(body);
      message = 'Se ha editado la categoria exitosamente';
    } else {
      const categoryOrder = categories.find((categoryRow) => categoryRow.order === parseInt(dataParam.order, 10));
      if (categoryOrder !== undefined || categoryOrder === false) {
        setAlert({ status: true, type: 'error', message: 'El numero de orden ya ha sido usado. El numero de orden debe estar entre 1 y 99' });
        return;
      }
      isSavedorUpdate = await createCategory(body);
      message = 'Se ha creado la categoria exitosamente';
    }
    if (isSavedorUpdate) {
      await getCategories();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      resetFields();
      onClose();
      setImagePreview(null);
    }
  };

  const enableEdition = () => {
    setCanEdit((prev) => !prev);
  };

  useEffect(() => {
    if (fileNameLit !== '') {
      clearErrors('fileIcon');
    }
  }, [fileNameLit]);

  useEffect(() => {
  }, [imagePreview]);

  const onFileUploadIcon = (event) => {
    event.preventDefault();
    setInvalidImageLit('');
    // Get the file Id
    const { id } = event.target;
    // eslint-disable-next-line camelcase
    const file_reader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|pdf)$/)) {
      setInvalidImageLit('Debe cargar un archivo valido (.jpg, .jpeg, png, pdf).');
      return false;
    }
    if (file.size > 30348) {
      setInvalidImageLit('El tamaño del archivo supera el máximo permitido (30KB).');
      return false;
    }
    setStartBarProgressLit(true);
    file_reader.onload = () => {
      setFiles([...files, { file_id: id, uploaded_file: file_reader.result }]);
      setImagePreview(file_reader.result);
    };
    setFileNameLit(file !== null && file.name !== null ? file.name : '');
    setTimeout(() => {
      if (file && file.name !== '') {
        setStartBarProgressLit(false);
      }
    }, 2000);

    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data !== null) setCanEdit(true);
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >

        <>
          {data !== null && <Button variant="outlined" color="primary" onClick={enableEdition}>Habilitar edición</Button>}
          <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre categoria*"
                  name="nameCategory"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled={canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Orden *"
                  name="order"
                  control={control}
                  inputMode="numeric"
                  pattern="[1-9]*'"
                  type="number"
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 2,
                    max: 99,
                    min: 1,
                  }}
                  disabled={canEdit}
                />
              </Grid>

              <Grid container item xs={12} sm={12} md={4} lg={4} justifyContent="center" alignItems="center" direction="column">
                <Box display="flex" className={classes.uploadImage}>
                  <label htmlFor="fileIcon">
                    <input
                      onChange={onFileUploadIcon}
                      id="fileIcon"
                      accept=".jpeg, .jpg, .png"
                      type="file"
                      style={{ display: 'none' }}
                      disabled={canEdit}
                    />
                    <Button
                      color="inherit"
                      variant="contained"
                      component="span"
                      className={classes.buttonImage}
                      endIcon={<AddCircle htmlColor="#50B940" className={classes.mat_icon} />}
                      disabled={canEdit}
                    >
                      IMAGEN ICONO (192 x 192 px)
                    </Button>

                    {invalidImageLit && (
                    <Typography className={classes.formErrors}>
                      {invalidImageLit}
                    </Typography>
                    )}
                  </label>
                  <Box style={{ width: '100%', paddingLeft: 15 }}>
                    <Typography className={classes.formLabel}>
                      {fileNameLit}
                    </Typography>
                    <Box style={{ width: '70%' }}>
                      <ProgressBar
                        startBarProgress={startBarProgressLit}
                      />
                    </Box>
                  </Box>
                </Box>
                {(data !== null && data.icon && imagePreview === null) && (
                  <>
                    <Image
                      name="imageActual"
                      src={data.icon}
                      style={{ width: 60 }}
                      aspectRatio={(60 / 10)}
                    />
                    <span htmlFor="imageActual">Imagen actual</span>
                  </>
                )}
                {(imagePreview !== null) && (
                  <>
                    <Image
                      name="imagepreview"
                      src={imagePreview}
                      style={{ width: 60 }}
                      aspectRatio={(60 / 10)}
                    />
                    <span htmlFor="imagepreview">Nueva Imagen</span>
                  </>
                )}
                {
                Object.keys(errors).length > 0 && fileNameLit === '' && data === null
                && (
                <>
                  <MessageRequired>Campo obligatorio</MessageRequired>
                </>
                )
              }
              </Grid>
            </Grid>
            <WrapContainer marginTop={15}>&nbsp;</WrapContainer>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
