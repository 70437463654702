import httpInstance from '../httpInstance';

const getRemittanceNoUserService = async ({
  pageSize, keyFilter, searchQuery,
}) => {
  let res;
  const endpoint = `/process-remittances/v1/process/all?pageSize=${pageSize || 10}${searchQuery ? `&${keyFilter}=${searchQuery}` : ''}`;
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getRemittanceNoUserService;
