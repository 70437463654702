import httpInstance from '../httpInstance';

const getDistributionCenterService = async () => {
  let res;
  const endpoint = '/delivery/v1/distribution-centers';
  await httpInstance.get(
    endpoint,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getDistributionCenterService;
