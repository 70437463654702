export const columns = [
  {
    id: 'id',
    label: 'ID',
    position: 1,
  },
  {
    id: 'userName',
    label: 'Usuario',
    position: 2,
  },
  {
    id: 'description',
    label: 'Rol',
    position: 2,
  },
  {
    id: 'actions',
    label: 'Acciones',
    position: 3,
  },
];

export default columns;
