/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const getVariant = ({ variant, error, theme }) => {
  if (variant === 'outlined') {
    return `
      background-color: ${theme.white};
      color: ${theme.primary};
      box-shadow: none;
    `;
  }

  return `
    background-color: ${error ? theme.firebrick : theme.primary};
    color: ${theme.white};
  `;
};

const getSize = ({ size }) => {
  if (size === 'small') {
    return 'padding: 4px 10px;';
  }

  return 'padding: 6px 16px;';
};

const getDisabledButton = ({ disabled, theme }) => disabled && `
  background-color: ${theme.black10};
  color: ${theme.black20};
  cursor: auto;
  border: 1px solid ${theme.black10};
  box-shadow: none;
`;

export const Card = styled.div`
  display: flex;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  flex-direction: column;
`;

export const Button = styled.button`
    align-items: center;
    justify-content: center;
    min-width: 64px;
    border: 1px solid ${({ theme, error }) => (error ? theme.firebrick : theme.primary)};
    border-radius: 4px;
    text-transform: uppercase;
    line-height: 1.75;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.02857em;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    ${getSize}
    ${getVariant}
    ${getDisabledButton}
`;
