import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY, BLACK_20 } from '../../theme/colors';

const useStyle = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: PRIMARY,
  },
  appBarReadOnly: {
    position: 'relative',
    backgroundColor: BLACK_20,
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default useStyle;
