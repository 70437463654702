/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Image from 'material-ui-image';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import {
  updateDeviceService,
  deleteDeviceService,
} from '../../../../services';
import { Toaster, CustomSwitch, CustomTable } from '../../../../components';
import Config from '../../../../config';
import useStyle from './useStyle';

const DeviceTable = (props) => {
  const classes = useStyle();
  const {
    setPopUpAction, enteredFilter, devices, setDevices, setDefaultValues, actionsDisabled,
  } = props;
  const [devicesRows, setDevicesRows] = React.useState([]);
  const [devicesListCopy, setDevicesListCopy] = React.useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    code: '',
    checked: false,
  });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openAlertConfirmDelete, setOpenAlertConfirmDelete] = useState(false);
  const [activeCode, setActiveCode] = useState(false);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const providerRef = React.useRef();

  function filterInfoTable(enteredFilterParam, codesMapped) {
    const devicesList = codesMapped != null ? codesMapped : devicesListCopy;
    setDevicesRows(devicesList.filter(
      (device) => device.name
        .toLowerCase()
        .indexOf(enteredFilterParam.toLowerCase()) >= 0
        || device.description
          .toLowerCase()
          .indexOf(enteredFilterParam.toLowerCase()) >= 0,
    ));
  }

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  const handleChangeState = (event) => {
    setGeneralInfo({
      id: event.target.value,
      checked: event.target.checked,
    });
    setOpenAlertConfirm(true);
    setActiveCode(event.target.checked);
  };

  const handleDeleteDevice = (deviceId) => {
    setGeneralInfo({
      id: deviceId,
      checked: false,
    });
    setOpenAlertConfirmDelete(true);
  };

  const openModalEditPromo = (deviceIdSelected) => {
    const deviceEdit = devices.find((deviceRow) => deviceRow.id === deviceIdSelected);
    setDefaultValues({
      name: deviceEdit.name,
      description: deviceEdit.description,
      price: deviceEdit.price,
    });
    setPopUpAction({
      open: true,
      title: 'Editar Dispositivo',
      data: deviceEdit,
    });
  };

  function arrayRemove(arr, value) {
    return arr.filter((ele) => ele.id !== value);
  }

  const deletePromo = async () => {
    const res = await deleteDeviceService(generalInfo.id)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    providerRef.current.enqueueSnackbar('Se ha eliminado el dispositivo exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
    const devicesLocal = arrayRemove(devices, generalInfo.id);
    setDevices(devicesLocal);
    setOpenAlertConfirmDelete(false);
  };

  function createActions(active, deviceId, diabledActions) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Tooltip title="Cambiar estado" aria-label="changeState" placement="top">
          <Box>
            <CustomSwitch
              checked={active}
              onChange={(e) => handleChangeState(e)}
              value={deviceId}
              name="active"
              disabled={diabledActions}
            />
          </Box>
        </Tooltip>
        <IconButton
          edge="end"
          aria-label="Editar"
          size="small"
          onClick={() => openModalEditPromo(deviceId)}
          style={{ paddingLeft: 10 }}
          disabled={diabledActions}
        >
          <Tooltip title="Editar" aria-label="edit" placement="top">
            <EditIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeleteDevice(deviceId)}
          style={{ paddingLeft: 10 }}
          disabled={diabledActions}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  const envUrl = () => {
    let urlStorage;
    switch (Config.API_URL) {
      case 'https://dev.api.mipana.com/admin':
        urlStorage = 'dev';
        break;
      case 'https://qa.api.mipana.com/admin':
        urlStorage = 'qa';
        break;
      case 'https://uat.api.mipana.com/admin':
        urlStorage = 'uat';
        break;
      default:
        urlStorage = 'prd';
    }
    return urlStorage;
  };

  const createImage = (url) => (
    <Image
      src={`https://${envUrl()}.storage.mipana.com/${url}`}
    />
  );
  const getFrequency = (frequency) => {
    if (frequency) {
      switch (frequency) {
        case 'WEEKLY':
          return 'Semanal';
        case 'BIWEEKLY':
          return 'Quincenal';
        case 'MONTHLY':
          return 'Mensual';
        default:
          return '';
      }
    } else {
      return 'inactivo';
    }
  };
  const getPayDay = (payday, frequency) => {
    if (payday) {
      if (frequency && frequency === 'WEEKLY') {
        switch (payday) {
          case 1:
            return 'Lunes';
          case 2:
            return 'Martes';
          case 3:
            return 'Miércoles';
          case 4:
            return 'Jueves';
          case 5:
            return 'Viernes';
          case 6:
            return 'Sabado';
          case 7:
            return 'Domingo';
          default:
            return '';
        }
      } else {
        return payday;
      }
    } else {
      return 'inactivo';
    }
  };
  function mapCodes(devicesParam) {
    const devicesInfo = [];
    devicesParam?.forEach((device) => {
      const deviceRow = {
        name: device.name,
        description: device.description,
        price: device.price,
        active: device.active,
        image: createImage(device.image_url),
        paymentQuotas: device.payment_quotas ? 'activo' : 'inactivo',
        zeroQuotas: device.zero_quotas ? 'activo' : 'inactivo',
        maxDevicesPerTransaction: device.max_devices_per_transaction,
        frequencyQuotas: getFrequency(device.frequency_quota),
        amountQuotas: device.amount_quotas ? device.amount_quotas : 'inactivo',
        payDay: getPayDay(device.pay_day, device.frequency_quota),
        actions: createActions(device.active, device.id, actionsDisabled),
        id: device.id,
      };
      devicesInfo.push(deviceRow);
    });
    setDevicesRows(devicesInfo);
    setDevicesListCopy(devicesInfo);
    if (enteredFilter !== '') filterInfoTable(enteredFilter, devicesInfo);
  }

  useEffect(() => {
    setDevicesRows([]);
    mapCodes(devices);
  }, [devices]);

  const columns = [
    {
      id: 'name', label: 'Nombre', paddingLeft: '40px', width: 150, position: 1,
    },
    {
      id: 'description', label: 'Descripción', paddingLeft: '40px', width: 150, position: 2,
    },
    {
      id: 'price',
      label: 'Precio',
      paddingLeft: '40px',
      width: 10,
      position: 3,
    },
    {
      id: 'image',
      label: 'Imagen',
      paddingLeft: '',
      width: 250,
      position: 4,
    },
    {
      id: 'actions',
      paddingLeft: '',
      label: 'Acciones',
      width: 5,
      position: 5,
    },
    {
      id: 'paymentQuotas',
      label: 'Pago en Cuotas',
      paddingLeft: '40px',
      width: 10,
      position: 6,
    },
    {
      id: 'zeroQuotas',
      label: 'Cuota Zero',
      paddingLeft: '40px',
      width: 10,
      position: 7,
    },
    {
      id: 'frequencyQuotas',
      label: 'Frecuencia',
      paddingLeft: '40px',
      width: 10,
      position: 8,
    },
    {
      id: 'amountQuotas',
      label: '# cuotas',
      paddingLeft: '40px',
      width: 10,
      position: 9,
    },
    {
      id: 'payDay',
      label: 'Dia cobro',
      paddingLeft: '40px',
      width: 10,
      position: 10,
    },
    {
      id: 'maxDevicesPerTransaction',
      label: 'Dispositivos Máximos',
      paddingLeft: '40px',
      width: 10,
      position: 11,
    },
  ];

  const updateDevice = async (device) => {
    const res = await updateDeviceService(device)
      .catch(() => setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' }));
    if (res?.status !== 200) return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    return true;
  };

  const handleConfirmChecked = () => {
    const deviceSelected = devices
      .find((device) => device.id === generalInfo.id);
    deviceSelected.active = generalInfo.checked;
    mapCodes(devices);

    if (updateDevice(deviceSelected)) {
      setGeneralInfo({
        code: '',
        checked: false,
      });
      setOpenAlertConfirm(false);
      providerRef.current.enqueueSnackbar(`Se ha ${activeCode ? 'activado' : 'inactivado'} el device exitosamente`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
    }
  };

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={devicesRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />

      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Confirmar"
        mensajeModal={`¿Esta seguro que quiere ${activeCode ? 'activar' : 'inactivar'
        } este dispositivo?`}
        handleAccept={() => handleConfirmChecked()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />

      <AlertConfirm
        isOpen={openAlertConfirmDelete}
        tituloModal="Confirmar"
        mensajeModal="¿Esta seguro que quiere eliminar este dispositivo?"
        handleAccept={() => deletePromo()}
        onCancel
        onClose={() => setOpenAlertConfirmDelete(false)}
      />

      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default DeviceTable;
