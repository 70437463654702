import httpInstance from '../httpInstance';

const updateAmountService = async (dataParam) => {
  let res;
  const endpoint = '/back-office/v1';
  const dataSent = {};

  // Data transformation
  dataSent.id = dataParam.amount_id;
  dataSent.min_amount = dataParam.minimum_amount;
  dataSent.max_amount = dataParam.maximum_amount;
  dataSent.txn_code = dataParam.transaction_code;
  dataSent.txn_name = dataParam.amount_name;

  await httpInstance.put(
    endpoint,
    dataSent,
  ).then((data) => {
    res = data;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateAmountService;
