/* eslint-disable import/prefer-default-export */
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const TypographyStyled = styled.p`
  font-size: 1rem;
  color: ${({ theme, disabled }) => (disabled ? theme.black : theme.primary)};
  margin-left: 10px;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: ${({ theme, disabled }) => (disabled ? theme.black : theme.primary)};
`;
