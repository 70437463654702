/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import SVG from 'react-inlinesvg';
import { CustomTable, Toaster } from '../../../components';
import tableStyle, { DivImage } from './tableStyle';
import AlertConfirm from '../../../components/Crosscutting/AlertConfirm';
import { deleteCategoryByIdService } from '../../../services';

const CategoriesTable = (props) => {
  const providerRef = React.useRef();
  const {
    categories, actionsDisabled, setDefaultValues, getCategories, setPopUpAction,
  } = props;
  const [categoriesRows, setCategoriesRows] = useState([]);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [alertPromos, setAlertPromos] = useState(false);
  const [messageRelatedPromos, setMessageRelatedPromos] = useState([]);
  const [dataAction, setDataAction] = useState(null);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const classes = tableStyle();

  const handleDeletecategory = (categoryId) => {
    setDataAction(categoryId);
    setOpenAlertConfirm(true);
  };

  const openModalEdit = (categoryIdSelected) => {
    const categoryEdit = categories.find((categoryRow) => categoryRow.id === categoryIdSelected);
    setDefaultValues({
      nameCategory: categoryEdit.nameCategory,
      order: categoryEdit.order,
      icon_base64: '',
      icon: categoryEdit.icon,
    });
    setPopUpAction({
      open: true,
      title: 'Editar categoria',
      data: categoryEdit,
    });
  };

  const messegePromosRelated = () => {
    if (messageRelatedPromos?.length > 0) {
      const tagsPromos = messageRelatedPromos.map((item) => (
        <Typography>
          {`id: ${item.id.split('-')[0]} - promoción: ${item.promotionName}`}
        </Typography>
      ));
      return (
        <div>
          <Typography>Aún hay promociones ligadas a este registro:</Typography>
          {tagsPromos}
        </div>
      );
    }
    return <div />;
  };

  const deleteCategory = async () => {
    const res = await deleteCategoryByIdService(dataAction)
      .catch(() => {
        setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        setOpenAlertConfirm(false);
      });

    if (res?.status !== 200) {
      setOpenAlertConfirm(false);
      getCategories();
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      if (res?.data.details[0]?.error_code === 'category-use-in-a-promotion') {
        setMessageRelatedPromos(res?.data.details[0]?.error_detail);
        setAlertPromos(true);
      }
    } else {
      providerRef.current.enqueueSnackbar('Se ha eliminado el banner exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      setOpenAlertConfirm(false);
      getCategories();
    }
  };

  function createActions(categoryId) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <IconButton
          edge="end"
          aria-label="Ver"
          size="small"
          onClick={() => openModalEdit(categoryId)}
          style={{ marginLeft: 0 }}
          disabled={actionsDisabled}
        >
          <Tooltip title="Ver" aria-label="see" placement="top">
            <EyeIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="Delete"
          size="small"
          onClick={() => handleDeletecategory(categoryId)}
          disabled={actionsDisabled}
          style={{ paddingLeft: 10 }}
        >
          <Tooltip title="Eliminar" aria-label="delete" placement="top">
            <DeleteIcon className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }

  function createImageSvg(strSvg) {
    return (
      <DivImage>
        <SVG src={strSvg} fill="#2D3134" />
      </DivImage>
    );
  }

  function mapCodes(categoriesParam) {
    const categoriesInfo = [];
    categoriesParam?.forEach((category, index) => {
      const categoryRow = {
        // eslint-disable-next-line prefer-template
        id: 'CAT' + (index + 1),
        nameCategory: category.nameCategory,
        order: category.order,
        icon: createImageSvg(category.icon),
        actions: createActions(category.id),
        active: !actionsDisabled,
      };
      categoriesInfo.push(categoryRow);
    });
    setCategoriesRows(categoriesInfo);
  }

  useEffect(() => {
    setCategoriesRows([]);
    mapCodes(categories);
  }, [categories]);

  const columns = [
    {
      id: 'id',
      label: 'ID',
      position: 1,
    },
    {
      id: 'nameCategory',
      label: 'Nombre categoria',
      position: 2,
    },
    {
      id: 'order',
      label: 'Orden',
      position: 3,
    },
    {
      id: 'icon',
      label: 'Preview icono',
      position: 4,
    },
    {
      id: 'actions',
      label: 'Acciones',
      position: 9,
    },
  ];

  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable
        data={categoriesRows}
        columns={columns}
        actionsDisabled={actionsDisabled}
      />
      <AlertConfirm
        isOpen={openAlertConfirm}
        tituloModal="Eliminar"
        textButtonAcept="Eliminar"
        colorButtonAcept="secondary"
        mensajeModal="¿Esta seguro que quiere eliminar esta categoria?"
        handleAccept={() => deleteCategory()}
        onCancel
        onClose={() => setOpenAlertConfirm(false)}
      />
      <AlertConfirm
        isOpen={alertPromos}
        tituloModal="Error al eliminar la categoria"
        mensajeModal={messegePromosRelated()}
        onClose={() => setAlertPromos(false)}
        handleAccept={() => setAlertPromos(false)}
        textButtonAcept="Entendido"
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default CategoriesTable;
