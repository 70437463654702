const inputSections = [
  {
    title: 'Límites tarjetas de crédito',
    fields: [
      {
        label: 'Mínimo TC garantizada',
        name: 'creditLimitsMinGuaranteed',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Mínimo TC clásica',
        name: 'creditLimitsMinClassic',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Máximo garantizado',
        name: 'creditLimitsMaxGuaranteed',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Máximo clásica',
        name: 'creditLimitsMaxClassic',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Cantidad máxima de cuotas',
        name: 'creditLimitsMaxInstallments',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Mínimo de cuotas',
        name: 'creditLimitsMinInstallments',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Máximo de cuotas',
        name: 'creditLimitsMaxInstallmentAmount',
        placeholder: '0',
        type: 'int',
      },
    ],
  },
  {
    title: 'Datos de garantía',
    fields: [
      {
        label: 'Monto de la garantía a prestar al cliente',
        name: 'guaranteeDataAmount',
        percentage: true,
        placeholder: '0%',
        type: 'int',
      },
      {
        label: 'Gastos legales para tarjeta garantizada',
        name: 'guaranteeDataLegalExpensesGuaranteed',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Gastos legales de cancelación de tarjeta garantizada',
        name: 'guaranteeDataCancellationLegalExpensesGuaranteed',
        placeholder: '0',
        type: 'int',
      },
    ],
  },
  {
    title: 'Comisiones',
    fields: [
      {
        label: 'Costo de emision TC garantizada',
        name: 'commissionsIssuanceCostGuaranteed',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Costo de emisión TC clásica',
        name: 'commissionsIssuanceCostClassic',
        placeholder: '0',
        type: 'int',
      },
      {
        label: 'Tasa de interés por financiamiento TC (porcentaje)',
        name: 'commissionsFinancingInterestRate',
        placeholder: 0 || '0%',
        percentage: true,
        type: 'int',
      },
      {
        label: 'Tasa de interés avance Cuotas',
        name: 'commissionsInstallmentInterestRate',
        placeholder: '0%',
        percentage: true,
        type: 'int',
      },
      {
        label: 'Comisión avance de efectivo TC (porcentaje)',
        placeholder: '0',
        name: 'commissionsCashAdvanceFee',
        percentage: true,
        type: 'int',
      },
      {
        label: 'Comisión avance de efectivo de cuotas (porcentaje)',
        name: 'commissionsInstallmentCashAdvanceFee',
        placeholder: '0%',
        percentage: true,
        type: 'int',
      },
    ],
  },
];

export default inputSections;
