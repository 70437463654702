export const validateAmountWithLimits = (minAmount, maxAmount) => (amount) => {
  let errorString = '';
  if (amount) {
    if (maxAmount && amount > maxAmount) {
      errorString = `Este campo debe ser menor a ${maxAmount + 1}`;
    } else if (amount >= minAmount) {
      const regex = /[.]/;
      if (regex.test(amount)) {
        errorString = 'Este campo no puede contener decimales';
      }
    } else {
      errorString = `Este campo debe ser mayor a ${minAmount - 1}`;
    }
  } else {
    errorString = 'Este campo no puede enviarse vacio';
  }
  if (errorString) {
    return errorString;
  }
  return true;
};

export const validateAmount = (type) => {
  switch (type) {
    case 'min':
      return 'El valor debe ser mayor a 0';
    case 'required':
      return 'Campo obligatorio';
    default:
      return '';
  }
};

export const getDisabledField = (documentNumber, commercialName) => {
  if (documentNumber.length > 8 && commercialName.length >= 1) return false;
  return true;
};
