/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { Auth } from 'aws-amplify';
import Config from '../config';

const httpInstance = axios.create({
  baseURL: Config.API_URL,
  timeout: 30000,
});

// Add a request interceptor
httpInstance.interceptors.request.use(async (config) => {
  const newConfig = { ...config };
  // get authenticated user data
  const user = await Auth.currentAuthenticatedUser()
  // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
  const { jwtToken } = user.signInUserSession.idToken;
  if (user) newConfig.headers.Authorization = `Bearer ${jwtToken}`;
  newConfig.headers['X-Version'] = '1.0.0';
  const demoToken = CryptoJS.HmacSHA256(JSON.stringify(newConfig.data),
    Config.GW_API_REQ).toString();
  newConfig.headers['X-Signature'] = demoToken;
  return newConfig;
}, (error) => {
  return Promise.reject(error);
});
// Add a response interceptor
httpInstance.interceptors.response.use((response) => {
  // console.log(response);
  return response;
}, (error) => {
  // console.log(error);
  return Promise.reject(error);
});

export const useAxiosInstanceMake = makeUseAxios({
  axios: httpInstance,
  defaultOptions: { manual: true },
});

export const useAxiosInstance = (params) => {
  const [
    {
      data, loading, error, response,
    }, execute] = useAxiosInstanceMake(
    typeof params === 'string'
      ? params
      : {
        ...params,
      },
  );

  return [
    {
      data, loading, error, response,
    }, execute];
};

export default httpInstance;
