import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  controlsContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'visible',
    flexDirection: 'column',
    width: '100%',
  },
  seccion: {
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 20,
    marginBottom: 10,
  },
  seccionButton: {
    marginTop: 50,
    marginBottom: 20,
  },
}));

export default useStyle;
